<template>
  <section>
    <!--Breadcrumb navigation area-->
    <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>Home</el-breadcrumb-item>
        <el-breadcrumb-item>User Module</el-breadcrumb-item>
    </el-breadcrumb>
    <el-row>
      <span v-show="Object.keys(userSearchCriteria).length > 0 && userSearchCriteria.userProject.length > 0? true: false" style="margin-right: 10px;">
        <b>Project:</b>
        <el-tag v-for="project in userSearchCriteria.userProject" :key="project.id" closable style="margin-left: 3px;" @close="criteriaTagClose('userProject', 'id', project.id)">{{project.projectName}}</el-tag>
      </span>
      <span v-show="Object.keys(userSearchCriteria).length > 0 && userSearchCriteria.userRole.length > 0? true: false" style="margin-right: 10px;">
        <b>UserRole:</b>
        <el-tag v-for="role in userSearchCriteria.userRole" :key="role.id" closable style="margin-left: 3px;" @close="criteriaTagClose('userRole', 'id', role.id)">{{role.roleName}}</el-tag>
      </span>
      <span v-show="Object.keys(userSearchCriteria).length > 0 && userSearchCriteria.userStatus.length > 0? true: false" style="margin-right: 10px;">
        <b>UserStatus:</b>
        <el-tag v-for="status in userSearchCriteria.userStatus" :key="status.statusCode" closable style="margin-left: 3px;" @close="criteriaTagClose('userStatus', 'statusCode', status.statusCode)">{{status.statusName}}</el-tag>
      </span>
      <el-button v-show="(Object.keys(userSearchCriteria).length>0)
                          && ((userSearchCriteria.userProject.length>0)
                               ||(userSearchCriteria.userRole.length>0)
                               ||(userSearchCriteria.userStatus.length>0))? true: false"
                  @click="invokeClearCriteriaForm" type="primary" size="mini">Clear</el-button>
    </el-row>

    <el-tabs type="border-card" @tab-click="loadUsers">
      <!-- add the login user role to display the 'Add User' button-->
      <el-tab-pane label="All Users">
        <user-table ref="allUserListTable" projectID=""></user-table>
      </el-tab-pane>
      <el-tab-pane v-for="(projectRole, index) in currentLoginUser.projectRoles" :key="index" :label="projectRole.project.projectName" :name="projectRole.project.id">
        <span v-if="projectRedDot" slot="label"> {{projectRole.project.projectName}} <el-badge style="margin-left: 10px;" :value="projectRedDot[projectRole.project.id]" /></span>
        <user-table :projectID="projectRole.project.id" :data-projectID="projectRole.project.id"></user-table>
      </el-tab-pane>
      <el-tab-pane>
        <span slot="label"><i class="el-icon-s-help"></i> Help</span>
        <user-help></user-help>
      </el-tab-pane>
    </el-tabs>

    <!--add new user dialog-->
    <el-dialog title="New User" :visible.sync="addUserDialogVisible" width="45%" @close="addDialogClosed">
      <el-form :model="addUserForm" :rules="userFormRules" ref="addUserFormRef" label-width="130px" label-position="top">
        <el-row>
          <el-form-item label="User Name" prop="userName">
            <el-input ref="userNameRef" v-model="addUserForm.userName"></el-input>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item label="Name" prop="realName">
            <el-input v-model="addUserForm.realName"></el-input>
          </el-form-item>
        </el-row>
        <el-table v-show="addUserForm.projectRoles.length > 0" :data="addUserForm.projectRoles" style="margin-top: 0;" row-key="relationID">
          <el-table-column type="index" :index="editUserForm.projectRoles !== null ? editUserForm.projectRoles.length + 1 : 1" label="#" align="center"></el-table-column>
          <el-table-column label="Project" align="center">
            <span></span>
            <template v-slot:="slotProps">
              <el-select v-model="slotProps.row.project.id" style="width: 100%;" @change="projectSelected(slotProps.row)">
                <el-option v-for="projectOption in projectList" :key="projectOption.id" :label="projectOption.projectName" :value="projectOption.id"></el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column label="Role" align="center">
            <span></span>
            <template v-slot:="slotProps">
              <el-select v-model="slotProps.row.role.id" style="width: 100%;" @change="userRoleSelected(slotProps.row)">
                <el-option v-for="roleOption in roleList" :key="roleOption.id" :label="roleOption.roleName" :value="roleOption.id"></el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column label="Supervisor" align="center">
            <template v-slot:="slotProps">
              <el-select v-if="slotProps.row.hasSupervisor" v-model="slotProps.row.supervisor.id" style="width: 100%;">
               <el-option v-for="supervisorOption in supervisorOptions" :key="supervisorOption.id" :label="supervisorOption.realName" :value="supervisorOption.id"></el-option>
              </el-select>
              <span v-else>-</span>
            </template>
          </el-table-column>
          <el-table-column label="Status" align="center">UNSAVED</el-table-column>
          <el-table-column label="Action" align="center">
            <template v-slot:="slotProps">
              <el-button style="margin-left: 10px;" type="danger" @click="deleteProjectRole(addUserForm, slotProps.row.project.id)" size="mini">Delete</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-row>
          <el-form-item label="Email" prop="userEmail">
            <el-input v-model="addUserForm.userEmail"></el-input>
          </el-form-item>
          <el-form-item label="University" prop="university">
            <el-input v-model="addUserForm.university"></el-input>
          </el-form-item>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogClosed">Reset</el-button>
        <el-button @click="newProjectRole">New ProjectRole</el-button>
        <el-button type="primary" @click="addUser">Submit</el-button>
      </span>
    </el-dialog>

    <!--edit user info-->
    <el-dialog title="Edit User" class="userDialog" :visible.sync="editUserDialogVisible" width="45%" @close="editDialogClosed">
      <el-form :model="editUserForm" :rules="editUserFormRules" ref="editUserFormRef" label-width="130px" label-position="top">
        <el-row>
          <el-form-item label="User Name" prop="userName">
            <el-input ref="userNameRef" v-model="editUserForm.userName" :readonly="currentLoginUser.adminFlag? false: true" :disabled="!writeSwitch"></el-input>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item label="Name" prop="realName">
            <el-input v-model="editUserForm.realName" :readonly="currentLoginUser.adminFlag? false: true" :disabled="!writeSwitch"></el-input>
          </el-form-item>
        </el-row>
        <!--this table used to display the existing relations of user role project-->
        <el-table v-if="editUserForm.projectRoles !== null && editUserForm.projectRoles.length > 0" :data="editUserForm.projectRoles" stripe row-key="relationID">
          <el-table-column type="index" label="#" align="center"></el-table-column>
          <el-table-column prop="project.projectName" label="Project" align="center"></el-table-column>
          <el-table-column prop="role.roleName" label="Role" align="center"></el-table-column>
          <el-table-column label="Supervisor" align="center">
            <template v-slot:="slotProps">
              <section v-if="currentLoginUser.adminFlag">
                <span v-if="slotProps.row.status.statusCode !== 0 && slotProps.row.hasSupervisor">
                  <section v-for="enabledPI in projectPIList" :key="enabledPI.id">
                    <section v-if="enabledPI.project.id === slotProps.row.project.id">
                      <el-select :value="slotProps.row.supervisor !== null? slotProps.row.supervisor.id : ''" style="width: 100%;" @change="setEditUserFormSupervisorID($event, slotProps.row)">
                        <el-option v-for="pi in enabledPI.piList" :key="pi.id" :label="pi.realName" :value="pi.id"></el-option>
                      </el-select>
                    </section>
                  </section>
                </span>
                <span v-else> {{slotProps.row.supervisor !== null? slotProps.row.supervisor.realName: '-'}} </span>
              </section>
              <section v-else>
                <span> {{slotProps.row.supervisor !== null? slotProps.row.supervisor.realName: '-'}} </span>
              </section>
            </template>
          </el-table-column>
          <el-table-column prop="status.statusName" label="Status" align="center"></el-table-column>
          <el-table-column v-if="currentLoginUser.adminFlag && writeSwitch" label="Action" align="center">
            <template v-slot:="slotProps">
              <el-button type="danger"  v-if="slotProps.row.status.statusCode === 0" icon="el-icon-turn-off" size="mini" @click="disableEditFormUserProjectRole(slotProps.row)"></el-button>
              <el-button type="success" v-else icon="el-icon-open" size="mini" @click="enableEditFormUserProjectRole(slotProps.row)"></el-button>
              <el-button style="margin-left: 10px;" type="danger" @click="deleteProjectRole(editUserForm, slotProps.row.project.id)" size="mini">Delete</el-button>
            </template>
          </el-table-column>
        </el-table>
        <!--this table is used to display the unsaved relation of the user role project-->
        <el-table v-show="userProjects.length > 0" :data="userProjects" :show-header="editUserForm.projectRoles === null || editUserForm.projectRoles.length === 0" style="margin-top: 0;" row-key="relationID">
          <el-table-column type="index" :index="editUserForm.projectRoles !== null ? editUserForm.projectRoles.length + 1 : 1" label="#" align="center"></el-table-column>
          <el-table-column label="Project" align="center">
            <span></span>
            <template v-slot:="slotProps">
              <el-select v-model="slotProps.row.project.id" style="width: 100%;" @change="projectSelected(slotProps.row)">
                <el-option v-for="projectOption in projectList" :key="projectOption.id" :label="projectOption.projectName" :value="projectOption.id"></el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column label="Role" align="center">
            <span></span>
            <template v-slot:="slotProps">
              <el-select v-model="slotProps.row.role.id" style="width: 100%;" @change="userRoleSelected(slotProps.row)">
                <el-option v-for="roleOption in roleList" :key="roleOption.id" :label="roleOption.roleName" :value="roleOption.id"></el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column label="Supervisor" align="center">
            <template v-slot:="slotProps">
              <el-select v-if="slotProps.row.hasSupervisor" v-model="slotProps.row.supervisor.id" style="width: 100%;">
               <el-option v-for="supervisorOption in supervisorOptions" :key="supervisorOption.id" :label="supervisorOption.realName" :value="supervisorOption.id"></el-option>
              </el-select>
              <span v-else>-</span>
            </template>
          </el-table-column>
          <el-table-column label="Status" align="center">UNSAVED</el-table-column>
          <el-table-column label="Action" align="center">
            <template v-slot:="slotProps">
              <el-button style="margin-left: 10px;" type="danger" @click="deleteUserProjectRelationUnsaved(slotProps.row)" size="mini">Delete</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-row>
          <el-form-item label="Email" prop="userEmail">
            <el-input v-model="editUserForm.userEmail" :readonly="currentLoginUser.adminFlag? false: true" :disabled="!writeSwitch"></el-input>
          </el-form-item>
          <el-form-item label="University" prop="university">
            <el-input v-model="editUserForm.university" :readonly="currentLoginUser.adminFlag? false: true" :disabled="!writeSwitch"></el-input>
          </el-form-item>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer" v-if="currentLoginUser.adminFlag">
        <el-button @click="resetEditForm(editUserForm.id)">Reset</el-button>
        <el-button @click="newEditProjectRole" v-if="writeSwitch">New ProjectRole</el-button>
        <el-button type="primary" @click="updateUser" v-if="writeSwitch">Update</el-button>
      </span>
    </el-dialog>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import UserTable from '@/components/user_component/UserTable.vue'
import { EVENT_CONFIG } from '@/event_bus'
import UserHelp from './UserHelp.vue'

export default {
  components: {
    UserTable,
    UserHelp
  },
  async mounted () {
    this.currentLoginUser = await this.$userStorage.getCurrentUser()
    this.getRoleList()
    this.getUserRedDot()
    EVENT_CONFIG.EventBus.$on(EVENT_CONFIG.USER_DETAIL_EVENT, this.displayEditDialogHandler)
    EVENT_CONFIG.EventBus.$on(EVENT_CONFIG.USER_ADD_EVENT, this.displayAddDialogHandler)
    EVENT_CONFIG.EventBus.$on(EVENT_CONFIG.USERLIST_CRITERIA_EVENT, this.updateUserSearchCriteriaHandler)
    EVENT_CONFIG.EventBus.$emit(EVENT_CONFIG.USERLIST_LOAD_EVENT)
    EVENT_CONFIG.EventBus.$on(EVENT_CONFIG.USER_TAB_REDDOT_EVENT, this.getUserRedDot)
  },
  beforeDestroy () {
    console.debug('UserList.vue beforeDestory method executes.....')
    EVENT_CONFIG.EventBus.$off(EVENT_CONFIG.USER_DETAIL_EVENT)
    EVENT_CONFIG.EventBus.$off(EVENT_CONFIG.USER_ADD_EVENT)
    EVENT_CONFIG.EventBus.$off(EVENT_CONFIG.USERLIST_CRITERIA_EVENT)
    EVENT_CONFIG.EventBus.$off(EVENT_CONFIG.USER_TAB_REDDOT_EVENT)
  },
  computed: {
    ...mapState({
      writeSwitch: (state) => state.writableStatus
    })
  },
  data () {
    const checkUserName = async (rule, value, callback) => {
      console.log('check user name rule:%o value:%s', rule, value)
      const userNameReg = /(^[a-z]{4,4}[0-9]{4,4}$)/
      if (userNameReg.test(value)) {
        const { data: checkUserNameResp } = await this.$http.get(this.serverAPI.USER_CHECK_URL + value)
        console.log('check user name response: ', checkUserNameResp)
        if (checkUserNameResp.data) {
          callback(new Error('User name \'' + value + '\' is already existing'))
        }
        return callback()
      }
      callback(new Error('The username should follow the format of "abcd1234"'))
    }

    const checkEditUserName = async (rule, value, callback) => {
      console.log('check user name rule:%o value:%s', rule, value)
      const userNameReg = /(^[a-z]{4,4}[0-9]{4,4}$)/
      if (userNameReg.test(value)) {
        const { data: checkUserNameResp } = await this.$http.get(this.serverAPI.USER_CHECK_URL + value + '/' + this.editUserForm.id)
        console.log('check user name response: ', checkUserNameResp)
        if (checkUserNameResp.data) {
          callback(new Error('User name \'' + value + '\' is already existing'))
        }
        return callback()
      }
      callback(new Error('The username should follow the format of "abcd1234"'))
    }

    const checkProjectRoles = (rule, value, callback) => {
      console.log('check user project: ', value)

      if (this.userProjects.length > 0) {
        if (this.userProjects[this.userProjects.length - 1].project.id === '') {
          callback(new Error('The project should be specified'))
        }

        if (this.userProjects[this.userProjects.length - 1].role.id === '') {
          callback(new Error('The role should be specified'))
        }
      }

      return callback()
    }

    return {
      projectRedDot: {},
      currentLoginUser: {},
      projectList: [], // the project option list used for user project select component
      roleList: [], // the role option list used for user role select component
      supervisorOptions: [], // the supervisor option list used for user supervisor select component
      projectPIList: [],
      addUserDialogVisible: false,
      addUserForm: {
        userName: '',
        realName: '',
        projectRoles: [],
        userEmail: ''
      },
      userFormRules: {
        userName: [
          { required: true, message: 'Please input user name', trigger: 'blur' },
          { validator: checkUserName, trigger: 'blur' }
        ],
        realName: [
          { required: true, message: 'Please input name', trigger: 'blur' }
        ],
        userEmail: [
          { required: true, message: 'Please input email', trigger: 'blur' }
        ],
        projectRoles: [
          { validator: checkProjectRoles, trigger: 'blur' }
        ]
      },
      editUserDialogVisible: false,
      editUserForm: {
        projectRoles: []
      },
      editUserFormRules: {
        userName: [
          { required: true, message: 'Please input user name', trigger: 'blur' },
          { validator: checkEditUserName, trigger: 'blur' }
        ],
        realName: [
          { required: true, message: 'Please input name', trigger: 'blur' }
        ],
        userEmail: [
          { required: true, message: 'Please input email', trigger: 'blur' }
        ],
        projectRoles: [
          { validator: checkProjectRoles, trigger: 'blur' }
        ]
      },
      userProjects: [], // user project role collection
      userSearchCriteria: {} // user this object to show the search criteria information
    }
  },
  methods: {
    async getUserRedDot () {
      console.log('get user red dot')
      const { data: redDotResp } = await this.$http.get(this.serverAPI.USER_RED_DOT_URL)
      console.log('get user red dot response: ', redDotResp)
      this.projectRedDot = redDotResp.data
    },
    updateUserSearchCriteriaHandler (newUserSearchCriteria) {
      console.log('new user search criteria information:', newUserSearchCriteria)
      this.userSearchCriteria = newUserSearchCriteria
    },
    displayAddDialogHandler () {
      this.addUserDialogVisible = true
    },
    addDialogClosed () {
      this.$refs.addUserFormRef.resetFields()
      this.addUserForm.projectRoles = []
      this.projectList = []
      this.supervisorOptions = []
    },
    addUser () {
      // submit user info
      this.$refs.addUserFormRef.validate(async result => {
        if (!result) return
        // const { data: res } = await this.$http.post('/user/add', JSON.stringify(this.addUserForm), { headers: { 'content-type': 'application/json' } })
        const { data: res } = await this.$http.post(this.serverAPI.USER_ADD_URL, JSON.stringify(this.addUserForm), { headers: { 'content-type': 'application/json' } })
        console.log('add user response: ', res)

        this.addUserDialogVisible = false
        EVENT_CONFIG.EventBus.$emit(EVENT_CONFIG.USERLIST_LOAD_EVENT)
      })
    },
    async newProjectRole () {
      const unsignedPos = this.addUserForm.projectRoles.findIndex(projectRole => {
        return projectRole.project.id === ''
      })

      if (unsignedPos >= 0) {
        // this.$alert('New adding project role item is not specified', 'Error Message', {
        //   confirmButtonText: 'OK',
        //   center: true
        // })
        this.$message({
          showClose: true,
          message: 'New adding project role item is not specified',
          center: true,
          type: 'error'
        })

        return
      }

      this.addUserProjectRoleRelation(this.addUserForm.projectRoles)

      const projectIDs = this.addUserForm.projectRoles.map(projectRole => projectRole.project.id)
      // const { data: projectResp } = await this.$http.post('user/project/', projectIDs, { headers: { 'content-type': 'application/json' } })
      const { data: projectResp } = await this.$http.post(this.serverAPI.USER_PROJECT_URL, projectIDs, { headers: { 'content-type': 'application/json' } })
      console.log('project option response: ', projectResp)
      this.projectList = projectResp.data
    },
    async newEditProjectRole () {
      const unsignedPos = this.userProjects.findIndex(projectRole => {
        return projectRole.project.id === '' && projectRole.role.id === []
      })

      if (unsignedPos >= 0) {
        // this.$alert('New adding project role item is not specified', 'Error Message', {
        //   confirmButtonText: 'OK',
        //   center: true
        // })
        this.$message({
          showClose: true,
          message: 'New adding project role item is not specified',
          center: true,
          type: 'error'
        })

        return
      }

      this.addUserProjectRoleRelation(this.userProjects)

      let editFormProjectIDs = []
      if (this.editUserForm.projectRoles !== null && this.editUserForm.projectRoles !== undefined) {
        editFormProjectIDs = this.editUserForm.projectRoles.map(projectRole => projectRole.project.id)
      }
      const unsaveProjectIDs = this.userProjects.map(projectRole => projectRole.project.id)
      const projectIDs = editFormProjectIDs.concat(unsaveProjectIDs)
      // const { data: projectResp } = await this.$http.post('user/project/', projectIDs, { headers: { 'content-type': 'application/json' } })
      const { data: projectResp } = await this.$http.post(this.serverAPI.USER_PROJECT_URL, projectIDs, { headers: { 'content-type': 'application/json' } })
      console.log('project option response: ', projectResp)
      this.projectList = projectResp.data
    },
    async getRoleList () {
      const { data: roleResp } = await this.$http.get(this.serverAPI.USER_ROLEOPTION_URL)
      this.roleList = roleResp.data
    },
    deleteProjectRole (userForm, projectID) {
      const delItemPos = userForm.projectRoles.findIndex(projectRole => {
        return projectRole.project.id === projectID
      })

      if (delItemPos >= 0) {
        userForm.projectRoles.splice(delItemPos, 1)
      }
    },
    editDialogClosed () {
      this.$refs.editUserFormRef.resetFields()
      this.editUserForm.projectRoles = []
      this.projectList = []
      this.supervisorOptions = []
      this.userProjects = []
    },
    async displayEditDialogHandler (uid) {
      // const { data: userDetailResp } = await this.$http.get('/user/detail/' + uid)
      const { data: userDetailResp } = await this.$http.get(this.serverAPI.USER_DETAIL_URL + uid)
      console.log('get user detail resp: ', userDetailResp)
      this.editUserForm = userDetailResp.data.userDetail
      this.projectPIList = userDetailResp.data.projectPIList

      // const { data: projectResp } = await this.$http.post('user/project/', [], { headers: { 'content-type': 'application/json' } })
      const { data: projectResp } = await this.$http.post(this.serverAPI.USER_PROJECT_URL, [], { headers: { 'content-type': 'application/json' } })
      console.log('project option response: ', projectResp)
      this.projectList = projectResp.data

      this.editUserDialogVisible = true
      this.supervisorOptions = []
      this.userProjects = []
    },
    setEditUserFormSupervisorID (selectedPIID, projectRuleDetail) {
      console.log('selected PI', selectedPIID)
      console.log('edit user form project role detail:', projectRuleDetail)
      projectRuleDetail.supervisor = {}
      projectRuleDetail.supervisor.id = selectedPIID
    },
    async resetEditForm (uid) {
      this.displayEditDialogHandler(uid)
    },
    async updateUser () {
      this.$refs.editUserFormRef.validate(async result => {
        if (!result) return
        const editFormStr = JSON.stringify(this.editUserForm)
        const userDetailObj = JSON.parse(editFormStr)

        let editFormProjectRoles = []
        if (userDetailObj.projectRoles !== null && userDetailObj.projectRoles !== undefined) {
          editFormProjectRoles = userDetailObj.projectRoles
        }
        const newProjectRoles = editFormProjectRoles.concat(this.userProjects)
        userDetailObj.projectRoles = newProjectRoles
        // const { data: updateResp } = await this.$http.post('user/update', JSON.stringify(userDetailObj), { headers: { 'content-type': 'application/json' } })
        const { data: updateResp } = await this.$http.post(this.serverAPI.USER_UPDATE_URL, JSON.stringify(userDetailObj), { headers: { 'content-type': 'application/json' } })
        console.log('update user response: ', updateResp)

        if (updateResp.code !== 1) {
          this.$alert(updateResp.msg, 'Error Message', {
            confirmButtonText: 'OK'
          })
        } else {
          this.$message({
            message: 'Update user detail successfully!',
            type: 'success'
          })
        }

        this.editUserDialogVisible = false
        this.userProjects = []
        EVENT_CONFIG.EventBus.$emit(EVENT_CONFIG.USERLIST_LOAD_EVENT)
      })
    },
    addUserProjectRoleRelation (userProjectRoles) {
      userProjectRoles.push({
        project: {
          id: ''
        },
        role: {
          id: ''
        },
        supervisor: {
          id: ''
        },
        hasSupervisor: true
      })
    },
    deleteUserProjectRelationUnsaved (unSaveUserProject) {
      const unsavedUserProjectPos = this.userProjects.findIndex(userProject => {
        const projectRoleMatch = userProject.project.id === unSaveUserProject.project.id && userProject.role.id === unSaveUserProject.role.id
        if (unSaveUserProject.supervisor.id !== '') {
          return projectRoleMatch && userProject.supervisor.id === unSaveUserProject.supervisor.id
        }
        return projectRoleMatch
      })

      this.userProjects.splice(unsavedUserProjectPos, 1)
      this.supervisorOptions = []
    },
    async userRoleSelected (userProject) {
      console.log('userproject: ', userProject)
      console.log('selected roleID: ', userProject.role.id)
      const selectedRole = this.roleList.find(role => {
        return role.id === userProject.role.id
      })
      console.log('selected role: ', selectedRole)
      if (selectedRole.roleAuthLevel.authLevel > 2) {
        userProject.hasSupervisor = true
      } else {
        userProject.hasSupervisor = false
      }

      // TODO: check project is selected, if project is null them promt else get piList
      if (!userProject.project.id) {
        console.log('selected project: ', userProject.project)
        this.$alert('Please select one project!', 'Error', {
          confirmButtonText: 'OK',
          center: true
        })
      } else {
        if (userProject.hasSupervisor) {
          await this.getProjectPIs(userProject)
        }
      }
    },
    async projectSelected (userProject) {
      if (userProject.project.id && userProject.role.id && userProject.hasSupervisor) {
        console.log('project selected: ', userProject)
        await this.getProjectPIs(userProject)
      }
    },
    async getProjectPIs (userProject) {
      // const { data: projectPIResp } = await this.$http.get('user/project/pis/' + userProject.project.id)
      const { data: projectPIResp } = await this.$http.get(this.serverAPI.USER_PROJECT_PIS_URL + userProject.project.id)
      console.log('project pi response: ', projectPIResp.data)
      this.supervisorOptions = projectPIResp.data
    },
    loadUsers (activeTab) {
      console.log('The tab is clicked:', activeTab)
      EVENT_CONFIG.EventBus.$emit(EVENT_CONFIG.USERLIST_LOAD_EVENT, activeTab.name)
    },
    async disableEditFormUserProjectRole (relation) {
      console.log('disable relationID: ', relation.relationID)
      const { data: response } = await this.$http.post(this.serverAPI.USER_RELATION_DISABLE_URL, JSON.stringify(relation), { headers: { 'content-type': 'application/json' } })
      console.log('disable user relation repsonse:', response)

      this.displayEditDialogHandler(this.editUserForm.id)

      if (response.code === 1) {
        this.$message({
          showClose: true,
          message: 'disable user project relation successfully',
          center: true,
          type: 'success'
        })
      } else {
        this.$message({
          showClose: true,
          message: response.msg,
          center: true,
          type: 'error'
        })
      }
    },
    async enableEditFormUserProjectRole (relation) {
      console.log('enable relationID: ', relation.relationID)
      const { data: response } = await this.$http.post(this.serverAPI.USER_RELATION_ENABLE_URL, JSON.stringify(relation), { headers: { 'content-type': 'application/json' } })
      console.log('enable user relation repsonse:', response)

      this.displayEditDialogHandler(this.editUserForm.id)
      if (response.code === 1) {
        this.$message({
          showClose: true,
          message: 'enable user project relation successfully',
          center: true,
          type: 'success'
        })
      } else {
        this.$message({
          showClose: true,
          message: response.msg,
          center: true,
          type: 'error'
        })
      }
    },
    criteriaTagClose (formFieldName, itemFieldName, criteriaContent) {
      console.log('close the tage formFieldName:', formFieldName)
      console.log('close the tage criteria:', criteriaContent)

      const formField = {
        formField: formFieldName,
        itemKey: itemFieldName,
        itemValue: criteriaContent
      }

      // only the 'All User' tab can have the 'Search Criteria' Dialog
      EVENT_CONFIG.EventBus.$emit(EVENT_CONFIG.USERLIST_LOAD_EVENT, '', formField)
    },
    invokeClearCriteriaForm () {
      console.log('invoke the event of clearing the searchForm')
      EVENT_CONFIG.EventBus.$emit(EVENT_CONFIG.USERLIST_CLEAR_CRITERIA_EVENT)
    }
  }
}
</script>
<style scoped>
div >>> div[role='dialog'] {
  min-width: 800px;
}

div.el-form-item {
  margin-bottom: 0;
  /* margin-top: 10px; */
}

form label {
  line-height: 30px !important;
  padding: 0 !important;
}

.clearfix:before, .clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both
}
div >>> div.el-form-item {
  margin-bottom: 0px !important;
}
div >>> label.el-form-item__label {
  padding-bottom: 0 !important;
}

div >>> div.el-dialog__body {
  padding-top: 0;
}
</style>
