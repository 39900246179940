import axios from 'axios'
import { SERVER_API } from '../../../server_api'
import JsSHA from 'jssha'

const getPaperDetail = async (paperID) => {
  const paperDetailResp = await axios.get(SERVER_API.PAPER_DETAIL_URL + paperID)
  console.debug('paper detail response: ', paperDetailResp)
  return paperDetailResp
}

const gernerateFormSignature = (paperForm) => {
  const articleFormStr = JSON.stringify(paperForm)

  const paperFormSigSHA = new JsSHA('SHA-256', 'TEXT')
  paperFormSigSHA.update(articleFormStr)
  const paperFormSignature = paperFormSigSHA.getHash('HEX')
  console.debug('article paper form signature: ', paperFormSignature)

  return paperFormSignature
}

const checkPaperTitle = async (paperTitle, paperID) => {
  // const { data: paperTitleResponse } = await this.$http.get('/paper/search/' + value)
  let paperCheckTitleURL = SERVER_API.PAPER_CHECKTITLE_URL + paperTitle
  if (paperID !== null && paperID !== undefined && paperID !== '') {
    paperCheckTitleURL = paperCheckTitleURL + '/' + paperID
  }
  const { data: paperTitleResponse } = await axios.get(paperCheckTitleURL)
  console.log('search paper title response: ', paperTitleResponse)
  return paperTitleResponse
}

const checkPaperDOI = (rule, value, callback) => {
  const regPaperDOI = /^(10.)(\S{5,})/
  if (!value) {
    return callback()
  }
  if (regPaperDOI.test(value)) {
    return callback()
  }
  callback(new Error('Paper DOI should start with \'10.\''))
}

const checkPaperURL = (rule, value, callback) => {
  const regPaperURL = /^(H|h)((T|t){2}(P|p)(S|s)?:\/\/)|(^$)/
  if (regPaperURL.test(value)) {
    return callback()
  }
  callback(new Error('Paper url should start with \'http://\' or \'https://\''))
}

const checkPageRange = (rule, value, callback) => {
  const regPageRange = /(^[1-9](\d*|\d*-?[1-9]\d*)$)|(^$)/
  if (regPageRange.test(value)) {
    const pageNumbers = value.split('-')
    if (pageNumbers.length === 2) {
      const startPage = Number(pageNumbers[0])
      const endPage = Number(pageNumbers[1])
      if (startPage >= endPage) {
        callback(new Error('Endpage number should be bigger than startPage number '))
      }
    }
    return callback()
  }
  callback(new Error('Page-range should start with non-zero number and use \'-\' to different page numbers'))
}

const changePaperAuthor = (rule, value, callback) => {
  // const regPaperAuthor = /^(([A-Z])?([a-z])+)((\s{1}([A-Z])?([a-z])+)+$|((\s{1}([A-Z])?([a-z])+)+,\s{1}([A-Z])?([a-z])+(\s{1}([A-Z])?([a-z])+)+))+$/
  /**
   * this regular expression only support characters in english and integer characters
   */
  // const regPaperAuthor = /^(\w+)(\s{1}\w+)+$|((\s{1}\w+)+,\s{1}\w+(\s{1}\w+)+)+$/

  /**
   * optimize the regular expression to support as many as possible language characters
   */
  const regPaperAuthor = /^(\S+)(,*(\s\S+){1,})+$/
  // const regPaperAuthor = /^([a-zA-ZàâäôéèëêïîçùûüÿæœÀÂÄÔÉÈËÊÏÎŸÇÙÛÜÆŒöÖßąĄćĆęĘłŁńŃóÓśŚźŹżŻìÌíÍòÒúÚáÁñÑåÅøØ0-9_'\\.\\-]+)(,*(\s[a-zA-ZàâäôéèëêïîçùûüÿæœÀÂÄÔÉÈËÊÏÎŸÇÙÛÜÆŒöÖßąĄćĆęĘłŁńŃóÓśŚźŹżŻìÌíÍòÒúÚáÁñÑåÅøØ0-9_'\\.\\-]+){1,})+$/
  // const regPaperAuthor = /^([a-zA-ZàâäôéèëêïîçùûüÿæœÀÂÄÔÉÈËÊÏÎŸÇÙÛÜÆŒöÖßąĄćĆęĘłŁńŃóÓśŚźŹżŻìÌíÍòÒúÚáÁñÑåÅøØ0-9_'\\.\\-]+)(\s{1}[a-zA-ZàâäôéèëêïîçùûüÿæœÀÂÄÔÉÈËÊÏÎŸÇÙÛÜÆŒöÖßąĄćĆęĘłŁńŃóÓśŚźŹżŻìÌíÍòÒúÚáÁñÑåÅøØ0-9_'\\.\\-]+)+$|((\s{1}[a-zA-ZàâäôéèëêïîçùûüÿæœÀÂÄÔÉÈËÊÏÎŸÇÙÛÜÆŒöÖßąĄćĆęĘłŁńŃóÓśŚźŹżŻìÌíÍòÒúÚáÁñÑåÅøØ0-9_'\\.\\-]+)+,\s{1}[a-zA-ZàâäôéèëêïîçùûüÿæœÀÂÄÔÉÈËÊÏÎŸÇÙÛÜÆŒöÖßąĄćĆęĘłŁńŃóÓśŚźŹżŻìÌíÍòÒúÚáÁñÑåÅøØ0-9_'\\.\\-]+(\s{1}[a-zA-ZàâäôéèëêïîçùûüÿæœÀÂÄÔÉÈËÊÏÎŸÇÙÛÜÆŒöÖßąĄćĆęĘłŁńŃóÓśŚźŹżŻìÌíÍòÒúÚáÁñÑåÅøØ0-9_'\\.\\-]+)+)+$/
  // const regPaperAuthor = /^([a-zA-ZàâäôéèëêïîçùûüÿæœÀÂÄÔÉÈËÊÏÎŸÇÙÛÜÆŒöÖßąĄćĆęĘłŁńŃóÓśŚźŹżŻìÌíÍòÒúÚáÁñÑåÅøØ0-9_'\\.]+)(\s[a-zA-ZàâäôéèëêïîçùûüÿæœÀÂÄÔÉÈËÊÏÎŸÇÙÛÜÆŒöÖßąĄćĆęĘłŁńŃóÓśŚźŹżŻìÌíÍòÒúÚáÁñÑåÅøØ0-9_'\\.]+)+$|((\s[a-zA-ZàâäôéèëêïîçùûüÿæœÀÂÄÔÉÈËÊÏÎŸÇÙÛÜÆŒöÖßąĄćĆęĘłŁńŃóÓśŚźŹżŻìÌíÍòÒúÚáÁñÑåÅøØ0-9_'\\.]+)+,\s[a-zA-ZàâäôéèëêïîçùûüÿæœÀÂÄÔÉÈËÊÏÎŸÇÙÛÜÆŒöÖßąĄćĆęĘłŁńŃóÓśŚźŹżŻìÌíÍòÒúÚáÁñÑåÅøØ0-9_'\\.]+(\s[a-zA-ZàâäôéèëêïîçùûüÿæœÀÂÄÔÉÈËÊÏÎŸÇÙÛÜÆŒöÖßąĄćĆęĘłŁńŃóÓśŚźŹżŻìÌíÍòÒúÚáÁñÑåÅøØ0-9_'\\.]+)+)+$/
  console.log('check paper author result: ', regPaperAuthor.test(value))
  if (regPaperAuthor.test(value)) {
    return callback()
  }
  callback(new Error('Paper-author should use comma to separate names'))
}

const confirmChangePaperType = async (vueprototype) => {
  const confirmChangePaperTypeResult = await vueprototype.$confirm('Confirm to change the paper type and the unsaved modifications will be lost?', {
    confirmButtonText: 'Yes',
    cancelButtonText: 'No'
  }).then(() => {
    console.log('confirm to remove the unsaved modifications')
    return true
  }).catch(() => {
    console.log('cancel to remove the unsaved modifications')
    return false
  })

  return confirmChangePaperTypeResult
}

export const PAPER_FUNCS = {
  checkPaperTitle,
  checkPaperDOI,
  checkPaperURL,
  checkPageRange,
  changePaperAuthor,
  getPaperDetail,
  gernerateFormSignature,
  confirmChangePaperType
}
