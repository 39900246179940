<template>
  <section>
    <el-row :style="!Object.keys(searchForm.projectPI).length?
                     (!Object.keys(searchForm.experimenter).length?
                       (!Object.keys(searchForm.uploader).length?
                         '' : 'border-bottom: solid 1px #d7dae2; margin-bottom: 3px; padding-bottom: 3px;')
                     : 'border-bottom: solid 1px #d7dae2; margin-bottom: 3px; padding-bottom: 3px;')
                    : 'border-bottom: solid 1px #d7dae2; margin-bottom: 3px; padding-bottom: 3px;'">
      <span v-show="(searchForm.projectPI !== null && searchForm.projectPI !== undefined && Object.keys(searchForm.projectPI).length > 0)? true: false" style="margin-right: 10px;">
        <b>ProjectPI:</b>
        <el-tag closable style="margin-left: 3px;" @close="criteriaTagClose('projectPI', 'id', searchForm.projectPI.id)">{{searchForm.projectPI.realName}}</el-tag>
      </span>
      <span v-show="(searchForm.experimenter !== null && searchForm.experimenter !== undefined && Object.keys(searchForm.experimenter).length > 0)? true: false" style="margin-right: 10px;">
        <b>Experimenter:</b>
        <el-tag closable style="margin-left: 3px;" @close="criteriaTagClose('experimenter', 'id', searchForm.experimenter.id)">{{searchForm.experimenter.realName}}</el-tag>
      </span>
      <span v-show="(searchForm.uploader !== null && searchForm.uploader !== undefined && Object.keys(searchForm.uploader).length > 0)? true: false" style="margin-right: 10px;">
        <b>Uploader:</b>
        <el-tag closable style="margin-left: 3px;" @close="criteriaTagClose('uploader', 'id', searchForm.uploader.id)">{{searchForm.uploader.realName}}</el-tag>
      </span>
    </el-row>

    <el-row type="flex" justify="center" align="middle">
        <el-col style="display: flex; justify-content: flex-end; margin-right: 5px;">
          <el-button type="primary" @click="criteriaDialog">Search Criteria</el-button>
        </el-col>
        <el-col :span="6" style="display: flex; justify-content: flex-end;">
        <el-input v-model="dataSearchKey" clearable @change="searchDataHandler">
          <el-button slot="append" icon="el-icon-search" @click="searchDataHandler"></el-button>
        </el-input>
      </el-col>
    </el-row>

    <el-table v-loading="dataTableLoading" :data="dataList" stripe border :highlight-current-row="true">
      <el-table-column type="index" label="#" align="center" :index="indexProjectDataFileRow"></el-table-column>
      <el-table-column label="Data Title" :show-overflow-tooltip="true">
        <template v-slot="dataSlot">
          <a class="project-title" @click.prevent="getSubFile(dataSlot.row)">
            <img :src="dataSlot.row | folderIcon" style="width: 20px; height: 20px; margin-right: 3px; vertical-align:middle"/>
            {{ dataSlot.row.title }}
          </a>
        </template>
      </el-table-column>
      <el-table-column prop="project.projectName" label="Project" align="center"></el-table-column>
      <el-table-column prop="author.realName" label="Experimenter" align="center"></el-table-column>
      <el-table-column prop="responsiblePI.realName" label="PI" align="center"></el-table-column>
      <el-table-column prop="creator.realName" label="Uploader" align="center"></el-table-column>
      <el-table-column label="Update Time" align="center">
        <template v-slot="dataSlot">
          {{dataSlot.row.updateTime | formatDate}}
        </template>
      </el-table-column>
    </el-table>

    <el-pagination style="float: right; margin-bottom: 10px;"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="paginationInfo.currentPage"
      :page-sizes="[10, 15, 20, 30, 50]"
      :page-size="paginationInfo.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="paginationInfo.totalCount">
    </el-pagination>

    <el-dialog title="Search Criteria" width="45%" :visible.sync="searchDialogVisible" @close="searchDialogClose" :before-close="confirmCloseDialog">
      <el-form :model="criteriaForm" ref="criteriaFormRef" label-width="130px" label-position="top">
        <el-row type="flex" justify="start" align="middle">
          <el-col :span="12" style="margin-right: 8px;">
            <el-form-item label="PI" prop="dataPI">
              <el-select :value="criteriaForm.projectPI.id" style="width: 100%;" filterable clearable placeholder="principal investigator" @change="selectedPIChangeHandler">
                <el-option v-for="projectRelatedPI in projectRelatedPICriteria" :key="projectRelatedPI.id" :label="projectRelatedPI.realName" :value="projectRelatedPI.id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12" style="margin-left: 8px;">
            <el-form-item label="Experimenter" prop="experimenter">
              <el-select :value="criteriaForm.experimenter.id" style="width: 100%;" filterable clearable placeholder="the person of doing the experiment corresponding to the data" @change="selectedUserChangeHandler">
                <el-option v-for="projectRelatedUser in projectRelatedUserCriteria" :key="projectRelatedUser.id" :label="projectRelatedUser.realName" :value="projectRelatedUser.id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" justify="start" align="middle">
          <el-col :span="12" style="padding-right: 8px;">
            <el-form-item label="Uploader" prop="uploader">
              <el-select :value="criteriaForm.uploader.id" style="width: 100%;" filterable clearable placeholder="the person of uploading the experimental data" @change="selectedUploaderChangeHandler">
                <el-option v-for="projectRelatedUploader in projectRelatedUserCriteria" :key="projectRelatedUploader.id" :label="projectRelatedUploader.realName" :value="projectRelatedUploader.id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <el-row type="flex" width="45%" justify="center" align="middle">
        <el-col style="display: flex; justify-content: flex-end; margin-top: 10px;">
          <el-button @click="clearSearchCriteriaDialog">Clear</el-button>
          <el-button @click="resetSearchCriteria">Reset</el-button>
          <el-button type="primary" @click="submitSearchCriteria">Search</el-button>
        </el-col>
      </el-row>
    </el-dialog>

    <el-dialog :title="projectFileListDialogTitle" width="50%" :visible.sync="projectFileListFlag">
      <el-row style="padding-bottom: 20px;">
        <el-table v-loading="projectFileLoading" size="mini" ref="projectFileTable"
          :data="projectFileList" :row-key="row => row.rowkey" border stripe
          style="margin-top: 0;" :tree-props="{hasChildren: 'isFolder'}" lazy :load="load">
          <el-table-column type="selection" :reserve-selection="true" width="55" align="center"></el-table-column>
          <el-table-column label="File Path" :show-overflow-tooltip="true">
            <template v-slot="fileProps">
              {{ fileProps.row.fileName }}
            </template>
          </el-table-column>
          <el-table-column label="File Type" align="center">
            <template v-slot="fileProps">
              {{ fileProps.row | showFileType }}
              <img :src="fileProps.row | getFileIcon" class="name-col-icon" alt="file type icon" style="width: 20px; height: 20px; vertical-align:middle"/>
            </template>
          </el-table-column>
          <el-table-column label="Status" align="center">
            <template v-slot="fileProps">
              <span v-show="fileProps.row.fileStatus.statusCode === 1"><i class="el-icon-question" style="font-size: 12px; color: #e6a23c; margin-left: 3px;"></i>Unmerged</span>
              <span v-show="fileProps.row.fileStatus.statusCode === 2"><i class="el-icon-loading" style="font-size: 12px; color: #409EFF; margin-left: 3px;"></i>Merging</span>
              <span v-show="fileProps.row.fileStatus.statusCode === 3"><i class="el-icon-info" style="font-size: 12px; color: #909399; margin-left: 3px;"></i>Merged</span>
              <span v-show="fileProps.row.fileStatus.statusCode === 4"><i class="el-icon-error" style="font-size: 12px; color: #f56c6c; margin-left: 3px;"></i>Failed</span>
              <span v-show="fileProps.row.fileStatus.statusCode === 5"><i class="el-icon-success" style="font-size: 12px; color: #67c23a; margin-left: 3px;"></i>Success</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="Size">
            <template v-slot="fileProps">
              {{ fileProps.row | fileSizeFormat }}
            </template>
          </el-table-column>
          <el-table-column align="center" width="100">
            <template v-slot="fileProps">
              <el-button v-if="!fileProps.row.isFolder" type="text" style="font-weight: bold;" @click="checkFileStatusBeforeDownload(fileProps.row.id)">Download</el-button>
              <span v-else> - </span>
            </template>
          </el-table-column>
        </el-table>
      </el-row>
    </el-dialog>
  </section>
</template>
<script>
import { FILE_TYPE } from '@/components/file_component/fileconfig'
import { EVENT_CONFIG } from '@/event_bus'

export default {
  props: ['project'],
  async created () {
    this.searchForm.dataProject.push(this.project)
    this.criteriaForm.dataProject.push(this.project)
    await this.searchDataList()

    EVENT_CONFIG.EventBus.$on(EVENT_CONFIG.FILE_PROJECT_LIST_EVENT, this.projectFileListHandler)
  },
  beforeDestroy () {
    console.log('the project data file list table destoryed')
    EVENT_CONFIG.EventBus.$off(EVENT_CONFIG.FILE_PROJECT_LIST_EVENT)
  },
  filters: {
    folderIcon () {
      return require('@/' + FILE_TYPE.FOLDER.typeIcon)
    },
    getFileIcon (fieldFileRelation) {
      let fileType
      if (fieldFileRelation.isFolder) {
        fileType = FILE_TYPE.FOLDER
      } else {
        fileType = FILE_TYPE.getFileType(fieldFileRelation.fileName)
      }

      const iconPath = require('@/' + `${fileType.typeIcon}`)
      return iconPath
    },
    showFileType (file) {
      if (file.isFolder) {
        return FILE_TYPE.FOLDER.typeName
      }

      const fileType = FILE_TYPE.getFileType(file.fileName)
      return fileType.typeName
    },
    fileSizeFormat (file) {
      console.debug('file name: %s and file size %s', file.fileName, file.fileSize)
      if (file.fileSize === null) return '-'
      if (file.fileSize < 1024) {
        // lower than 1024, the unit is 'Bytes'
        return file.fileSize + ' Bytes'
      }
      if (file.fileSize < (1024 * 1024)) {
        // bigger than 1024 and smaller than 1024*1024, the unit is 'KB'
        const _kb = (file.fileSize / 1024).toFixed(2)
        return parseFloat(_kb) + ' KB'
      }

      if (file.fileSize < (1024 * 1024 * 1024)) {
        // bigger than 1024*1024 and smaller than 1024*1024*1024, the unit is 'MB'
        const _mb = (file.fileSize / (1024 * 1024)).toFixed(2)
        return parseFloat(_mb) + ' MB'
      }

      const _gb = (file.fileSize / (1024 * 1024 * 1024)).toFixed(2)
      return parseFloat(_gb) + ' GB'
    }
  },
  data () {
    return {
      projectFileListDialogTitle: '',
      projectFileListFlag: false,
      projectFileLoading: true,
      projectFileList: [],
      searchDialogVisible: false,
      dataSearchKey: '',
      dataTableLoading: true,
      paginationInfo: {
        currentPage: 1,
        pageSize: 15,
        totalCount: 0
      },
      criteriaForm: {
        dataProject: [],
        projectPI: {},
        uploader: {},
        experimenter: {}
      },
      searchForm: {
        dataSearchKey: null,
        dataProject: [],
        projectPI: {},
        uploader: {},
        experimenter: {}
      },
      projectRelatedPICriteria: [],
      dataPICriteria: [],
      projectRelatedUserCriteria: [],
      dataUserCriteria: [],
      dataList: []
    }
  },
  methods: {
    projectFileListHandler (activeProjectID) {
      if (this.project.id === activeProjectID) {
        console.log('active projectID=', activeProjectID)

        this.searchDataList()
      }
    },
    /**
     * initiate the selection option list based on the 'criteriaForm' data
     */
    initiatePrejectRelatedSelection () {
      if (this.criteriaForm.dataProject.length === 0) {
        this.genreateProjecrPICriteria()
        this.generateProjectUserCriteria()
        return
      }

      this.projectRelatedPICriteria = []
      this.projectRelatedUserCriteria = []

      this.criteriaForm.dataProject.forEach(projectItem => {
        const projectPI = this.dataPICriteria.find(projectPI => {
          return projectPI.project.id === projectItem.id
        })

        if (projectPI !== null && projectPI !== undefined && projectPI.piList !== null) {
          if (this.projectRelatedPICriteria.length === 0) {
            this.projectRelatedPICriteria = this.projectRelatedPICriteria.concat(projectPI.piList)
          } else {
            projectPI.piList.forEach(projectPIItem => {
              const relatedPIIndex = this.projectRelatedPICriteria.findIndex(projectRelatedPI => {
                return projectRelatedPI.id === projectPIItem.id
              })

              if (relatedPIIndex === -1) {
                this.projectRelatedPICriteria.push(projectPIItem)
              }
            })
          }
        }

        const projectUser = this.dataUserCriteria.find(projectUser => {
          return projectUser.project.id === projectItem.id
        })
        if (projectUser !== null && projectUser !== undefined && projectUser.userList !== null) {
          if (this.projectRelatedUserCriteria.length === 0) {
            this.projectRelatedUserCriteria = this.projectRelatedUserCriteria.concat(projectUser.userList)
          } else {
            projectUser.userList.forEach(projectUserItem => {
              const relatedUserIndex = this.projectRelatedUserCriteria.findIndex(projectRelatedUser => {
                return projectRelatedUser.id === projectUserItem.id
              })

              if (relatedUserIndex === -1) {
                this.projectRelatedUserCriteria.push(projectUserItem)
              }
            })
          }
        }
      })
    },
    handleSizeChange (newPageSize) {
      console.log('page size change', newPageSize)
      this.paginationInfo.pageSize = newPageSize
      this.searchDataList()
    },
    handleCurrentChange (newCurrentPage) {
      console.log('current page', newCurrentPage)
      this.paginationInfo.currentPage = newCurrentPage
      this.searchDataList()
    },
    async criteriaDialog () {
      console.log('open data list criteria dialog')

      const { data: criteriaResp } = await this.$http.get(this.serverAPI.DATA_LIST_CRITERIA_URL)
      console.log('data list criteria response content: ', criteriaResp)

      this.dataPICriteria = criteriaResp.data.piCriteria
      this.dataUserCriteria = criteriaResp.data.userCriteria

      const searchStr = JSON.stringify(this.searchForm)
      this.criteriaForm = JSON.parse(searchStr)

      this.initiatePrejectRelatedSelection()

      this.searchDialogVisible = true
    },
    searchDialogClose () {
      this.searchDialogVisible = false
    },
    confirmCloseDialog (done) {
      this.$confirm('Close the dialog and reset search criteria?', {
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        closeOnClickModal: false,
        closeOnPressEscape: false
      }).then(_ => {
        done()
      }).catch(_ => {
        console.log('cancel to close the search data list criteria data')
      })
    },
    /**
     * reset the criteriaForm based on the searchForm
     */
    resetSearchCriteria () {
      this.$confirm('Reset search criteria?', {
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        closeOnClickModal: false,
        closeOnPressEscape: false
      }).then(_ => {
        const searchStr = JSON.stringify(this.searchForm)
        this.criteriaForm = JSON.parse(searchStr)

        this.initiatePrejectRelatedSelection()
      }).catch(_ => {
        console.log('cancel reset data search criteria')
      })
    },
    // assing the search criteria parameter from the criteriaForm to searchFrom
    async submitSearchCriteria () {
      const criteriaStr = JSON.stringify(this.criteriaForm)
      this.searchForm = JSON.parse(criteriaStr)

      this.searchDialogClose()
      // call the search data list
      await this.searchDataList()
    },
    async searchDataHandler () {
      this.paginationInfo.currentPage = 1
      this.paginationInfo.pageSize = 10

      await this.searchDataList()
    },
    /**
     * clear the criteriaForm
     */
    clearSearchCriteriaDialog () {
      this.$confirm('Clear data search criteria?', {
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        closeOnClickModal: false,
        closeOnPressEscape: false
      }).then(_ => {
        this.criteriaForm = {
          dataProject: [this.project],
          projectPI: {},
          uploader: {},
          experimenter: {}
        }

        this.initiatePrejectRelatedSelection()
        this.clearCriteria()
      }).catch(_ => {
        console.log('cancel to clear the search data list criteria data')
      })
    },
    /**
     * assign the projectPI based on the selected project pi id
     */
    selectedPIChangeHandler (selectedPIID) {
      console.log('selected project PI ID:', selectedPIID)
      this.criteriaForm.projectPI = {}
      if (selectedPIID !== null && selectedPIID !== '' && selectedPIID !== undefined) {
        const selectedProjectPI = this.projectRelatedPICriteria.find(projectRelatedPI => {
          if (projectRelatedPI.id === selectedPIID) {
            return projectRelatedPI
          }
        })
        this.criteriaForm.projectPI = selectedProjectPI
      }
    },
    /**
     * assign the experimenter based on the selected project user ID
     */
    selectedUserChangeHandler (selectedUserID) {
      console.log('selected project user ID:', selectedUserID)
      this.criteriaForm.experimenter = {}
      if (selectedUserID !== null && selectedUserID !== '' && selectedUserID !== undefined) {
        const selectedProjectUser = this.projectRelatedUserCriteria.find(projectRelatedUser => {
          if (projectRelatedUser.id === selectedUserID) {
            return projectRelatedUser
          }
        })
        this.criteriaForm.experimenter = selectedProjectUser
      }
    },
    selectedUploaderChangeHandler (selectedUploaderID) {
      console.log('selected experimental data uploader ID:', selectedUploaderID)
      this.criteriaForm.uploader = {}
      if (selectedUploaderID !== null && selectedUploaderID !== '' && selectedUploaderID !== undefined) {
        const selectedProjectUploader = this.projectRelatedUserCriteria.find(projectRelatedUser => {
          if (projectRelatedUser.id === selectedUploaderID) {
            return projectRelatedUser
          }
        })
        this.criteriaForm.uploader = selectedProjectUploader
      }
    },
    async searchDataList () {
      this.dataTableLoading = true
      const urlParams = this.$qs.stringify(this.paginationInfo)
      // const { data: dataListResp } = await this.$http.get('/data/list/' + this.dataSearchKey + '?' + urlParams)
      this.searchForm.dataSearchKey = this.dataSearchKey
      const { data: dataListResp } = await this.$http.post(this.serverAPI.DATA_LIST_URL + '?' + urlParams, JSON.stringify(this.searchForm), { headers: { 'content-type': 'application/json' } })
      console.log('data search list response content: ', dataListResp)
      this.dataList = dataListResp.data.records
      this.paginationInfo.currentPage = dataListResp.data.current
      this.paginationInfo.pageSize = dataListResp.data.size
      this.paginationInfo.totalCount = dataListResp.data.total
      this.dataTableLoading = false
    },
    async criteriaTagClose (formFieldName, itemFieldName, criteriaContent) {
      console.log('close the tage formFieldName:', formFieldName)
      console.log('close the tage criteria:', criteriaContent)

      if (this.searchForm[formFieldName] instanceof Array) {
        const arrayContent = this.searchForm[formFieldName]
        const criteriaIndex = arrayContent.findIndex(item => {
          return item[itemFieldName] === criteriaContent
        })
        arrayContent.splice(criteriaIndex, 1)
      } else if (this.searchForm[formFieldName] instanceof Object) {
        console.log('get search form object field value:', this.searchForm[formFieldName])
        this.searchForm[formFieldName] = {}
      } else {
        console.log('get search form other types field value:', this.searchForm[formFieldName])
        this.searchForm[formFieldName] = ''
      }
      // call the search data list
      await this.searchDataList()
    },
    async getSubFile (dataRow) {
      this.projectFileListFlag = true
      this.projectFileListDialogTitle = 'File List of the Data \'' + dataRow.title + '\''
      this.projectFileLoading = true

      const subFileURL = this.serverAPI.DATA_FILELIST_URL + dataRow.dataID
      this.projectFileList = await this.requestSubFile(subFileURL)
      this.projectFileLoading = false
    },
    async requestSubFile (subFileURL) {
      const { data: subFileResp } = await this.$http.get(subFileURL)
      console.log('project file list response: ', subFileResp)
      const subfileList = subFileResp.data
      for (const subFile of subfileList) {
        subFile.rowkey = subFile.id + '-' + new Date().getTime()
      }

      return subfileList
    },
    async load (file, tableTreeCof, resolve) {
      const subFileURL = this.serverAPI.FILE_SUBLIST_URL + file.id
      const subFileList = await this.requestSubFile(subFileURL)
      resolve(subFileList)
    },
    async checkFileStatusBeforeDownload (fileID) {
      console.log('before download file, check the file status fileID', fileID)
      const { data: checkResp } = await this.$http.get(this.serverAPI.FILE_STATUS_URL + fileID)
      console.log('check file status response: ', checkResp)
      if (checkResp.code === 1) {
        if (!checkResp.data.isFolder) {
          window.location.href = this.serverAPI.FILE_DOWNLOAD_URL + checkResp.data.id
          return
        }
        console.log('this is a folder')
      } else {
        this.$alert('<font color="red">' + checkResp.msg + '</font>', 'File Download Notification', {
          dangerouslyUseHTMLString: true
        })
      }
    },
    indexProjectDataFileRow (index) {
      const newIndex = (this.paginationInfo.currentPage - 1) * this.paginationInfo.pageSize + index + 1
      return newIndex
    }
  }
}
</script>
<style scoped>
div /deep/ .el-form-item__label {
  padding-bottom: 0px;
}
div /deep/ .el-dialog__body {
  padding-bottom: 10px;
  padding-top: 0;
}
div /deep/ th{
  line-height: 28px;
  font-weight: bold;
}
.project-title:hover {
  cursor: pointer;
  text-decoration: none;
  font-weight: bold;
  color: #409EFF;
}
</style>
