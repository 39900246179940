<template>
  <section>
    <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/home' }">Home</el-breadcrumb-item>
        <el-breadcrumb-item>Data Module</el-breadcrumb-item>
        <el-breadcrumb-item>Data History</el-breadcrumb-item>
    </el-breadcrumb>

    <div style="margin-top: 15px; margin-bottom: 10px;">
      <el-button type="primary" size="mini" plain style="font-size: 16px;" @click="backToDataDetail">Data Deatil</el-button>
    </div>

    <el-row type="flex">
      <el-col style="border-right: solid 1px #d7dae2;">
        <el-select filterable v-model="originVersion" placeholder="Select the comparing version" @change="dataHisVersionChangeHandler">
          <el-option v-for="originHistory in originHistories" :key="'origin' + originHistory.id" :label="originHistory.version == currentVersion ? 'Current version': originHistory.version" :value="originHistory.version">
          </el-option>
        </el-select>

        <span style="margin-left: 15px; padding: 15px 0; color: #409EFF; font-weight: bold;">Right Data Version: {{originData.currentVersion}} </span>

        <el-form>
          <el-row type="flex" class="field-title-container" @click.native="shiftCommonFiledsFlag" justify="start" align="middle">
            <el-col class="field-title">General Information</el-col>
            <el-col class="field-title-button">
              <el-button type="text" style="padding-left: 9px; padding-right: 5px; font-weight: bold;" @click.stop="shiftCommonFiledsFlag">Toggle<i :class="commonFieldsFlag? 'el-icon-arrow-down' : 'el-icon-arrow-right'"/></el-button>
            </el-col>
          </el-row>

          <el-collapse-transition>
            <el-card v-show="commonFieldsFlag">
              <CommonPropertyCompare :dataHistoryDetail="originData" :compareDataDetail="compareData"></CommonPropertyCompare>
              <el-button class="toggle-button-bottom" type="text" style="padding-left: 9px; padding-right: 5px; font-weight: bold;" @click.stop="shiftCommonFiledsFlag">Toggle<i class="el-icon-arrow-up"/></el-button>
            </el-card>
          </el-collapse-transition>
        </el-form>
      </el-col>
      <el-col style="border-left: solid 1px #d7dae2;">
        <el-select filterable v-model="compareVersion" placeholder="Select the comparing version" @change="dataHisVersionChangeHandler">
          <el-option v-for="compareHistory in compareHistories" :key="'compare' + compareHistory.id" :label="compareHistory.version == currentVersion ? 'Current version': compareHistory.version" :value="compareHistory.version">
          </el-option>
        </el-select>

        <span style="margin-left: 15px; padding: 15px 0; color: #409EFF; font-weight: bold;">Left Data Version: {{compareData.currentVersion}} </span>

        <el-form>
          <el-row type="flex" class="field-title-container" @click.native="shiftCommonFiledsFlag" justify="start" align="middle">
            <el-col class="field-title">General Information</el-col>
            <el-col class="field-title-button">
              <el-button type="text" style="padding-left: 9px; padding-right: 5px; font-weight: bold;" @click.stop="shiftCommonFiledsFlag">Toggle<i :class="commonFieldsFlag? 'el-icon-arrow-down' : 'el-icon-arrow-right'"/></el-button>
            </el-col>
          </el-row>

          <el-collapse-transition>
            <el-card v-show="commonFieldsFlag">
              <CommonPropertyCompare :dataHistoryDetail="compareData" :compareDataDetail="originData"></CommonPropertyCompare>
              <el-button class="toggle-button-bottom" type="text" style="padding-left: 9px; padding-right: 5px; font-weight: bold;" @click.stop="shiftCommonFiledsFlag">Toggle<i class="el-icon-arrow-up"/></el-button>
            </el-card>
          </el-collapse-transition>
        </el-form>
      </el-col>
    </el-row>

    <el-row type="flex">
      <el-col style="border-right: solid 1px #d7dae2;">
        <el-form>
          <el-row type="flex" class="field-title-container" @click.native="shiftRelatedFieldsFlag" justify="start" align="middle">
            <el-col class="field-title">Project Specified Information</el-col>
            <el-col class="field-title-button">
              <el-button type="text" style="padding-left: 9px; padding-right: 5px; font-weight: bold;" @click.stop="shiftRelatedFieldsFlag">Toggle<i :class="relatedFieldsFlag? 'el-icon-arrow-down' : 'el-icon-arrow-right'"/></el-button>
            </el-col>
          </el-row>

          <el-collapse-transition>
            <el-card v-show="relatedFieldsFlag">
              <OptionalPropertyCompare :optionalDataDetail="originData.optionalContent"
                :compareDataDetail="compareData.optionalContent" :projectList="projectList"
                :isRelated="true">
              </OptionalPropertyCompare>
              <el-button class="toggle-button-bottom" type="text" style="padding-left: 9px; padding-right: 5px; font-weight: bold;" @click.stop="shiftRelatedFieldsFlag">Toggle<i class="el-icon-arrow-up"/></el-button>
            </el-card>
          </el-collapse-transition>
        </el-form>
      </el-col>
      <el-col style="border-right: solid 1px #d7dae2;">
        <el-form>
          <el-row type="flex" class="field-title-container" @click.native="shiftRelatedFieldsFlag" justify="start" align="middle">
            <el-col class="field-title">Project Specified Information</el-col>
            <el-col class="field-title-button">
              <el-button type="text" style="padding-left: 9px; padding-right: 5px; font-weight: bold;" @click.stop="shiftRelatedFieldsFlag">Toggle<i :class="relatedFieldsFlag? 'el-icon-arrow-down' : 'el-icon-arrow-right'"/></el-button>
            </el-col>
          </el-row>

          <el-collapse-transition>
            <el-card v-show="relatedFieldsFlag">
              <OptionalPropertyCompare :optionalDataDetail="compareData.optionalContent"
                :compareDataDetail="originData.optionalContent" :projectList="projectList"
                :isRelated="true">
              </OptionalPropertyCompare>
              <el-button class="toggle-button-bottom" type="text" style="padding-left: 9px; padding-right: 5px; font-weight: bold;" @click.stop="shiftRelatedFieldsFlag">Toggle<i class="el-icon-arrow-up"/></el-button>
            </el-card>
          </el-collapse-transition>
        </el-form>
      </el-col>
    </el-row>

    <el-row type="flex">
      <el-col style="border-right: solid 1px #d7dae2;">
        <el-form>
          <el-row type="flex" class="field-title-container" @click.native="shiftOptionalFieldsFlag" justify="start" align="middle">
            <el-col class="field-title">Additional Information</el-col>
            <el-col class="field-title-button">
              <el-button type="text" style="padding-left: 9px; padding-right: 5px; font-weight: bold;" @click.stop="shiftOptionalFieldsFlag">Toggle<i :class="optionalFieldsFlag? 'el-icon-arrow-down' : 'el-icon-arrow-right'"/></el-button>
            </el-col>
          </el-row>

          <el-collapse-transition>
            <el-card v-show="optionalFieldsFlag">
              <OptionalPropertyCompare :optionalDataDetail="originData.optionalContent"
                :compareDataDetail="compareData.optionalContent" :projectList="projectList">
              </OptionalPropertyCompare>
              <el-button class="toggle-button-bottom" type="text" style="padding-left: 9px; padding-right: 5px; font-weight: bold;" @click.stop="shiftOptionalFieldsFlag">Toggle<i class="el-icon-arrow-up"/></el-button>
            </el-card>
          </el-collapse-transition>
        </el-form>
      </el-col>
      <el-col style="border-right: solid 1px #d7dae2;">
        <el-form>
          <el-row type="flex" class="field-title-container" @click.native="shiftOptionalFieldsFlag" justify="start" align="middle">
            <el-col class="field-title">Additional Information</el-col>
            <el-col class="field-title-button">
              <el-button type="text" style="padding-left: 9px; padding-right: 5px; font-weight: bold;" @click.stop="shiftOptionalFieldsFlag">Toggle<i :class="optionalFieldsFlag? 'el-icon-arrow-down' : 'el-icon-arrow-right'"/></el-button>
            </el-col>
          </el-row>

          <el-collapse-transition>
            <el-card v-show="optionalFieldsFlag">
              <OptionalPropertyCompare :optionalDataDetail="compareData.optionalContent"
                :compareDataDetail="originData.optionalContent" :projectList="projectList">
              </OptionalPropertyCompare>
              <el-button class="toggle-button-bottom" type="text" style="padding-left: 9px; padding-right: 5px; font-weight: bold;" @click.stop="shiftOptionalFieldsFlag">Toggle<i class="el-icon-arrow-up"/></el-button>
            </el-card>
          </el-collapse-transition>
        </el-form>
      </el-col>
    </el-row>

    <el-backtop target=".el-scrollbar__wrap" ref="backtopRef" style="display:none;"></el-backtop>
  </section>
</template>
<script>
import CommonPropertyCompare from '@/components/history_component/CommonPropertyCompare'
import OptionalPropertyCompare from '@/components/history_component/OptionalPropertyCompare'
import { mapState, mapMutations } from 'vuex'

export default {
  props: ['dataID', 'historyID'],
  components: {
    CommonPropertyCompare,
    OptionalPropertyCompare
  },
  async beforeDestroy () {
    console.log('asdsd')
    window.onunload = null
  },
  async mounted () {
    console.log('dataID=', this.dataID)
    console.log('historyID=', this.historyID)

    window.onunload = () => {
      this.setRouterActivePath(`/data/history/${this.dataID}/${this.historyID}`)
    }

    await this.loadDataDetail()

    this.setRouterActivePath('/data/list')
  },
  computed: {
    ...mapState({
      projectRelatedFields: (state) => state.dataModule.projectRelatedFields
    })
  },
  data () {
    return {
      originData: {},
      compareData: {},
      currentVersion: 0,
      dataHistories: [],
      originHistories: [],
      compareHistories: [],
      originVersion: 0,
      compareVersion: 0,
      commonFieldsFlag: true,
      relatedFieldsFlag: true,
      optionalFieldsFlag: true,
      projectList: []
    }
  },
  methods: {
    ...mapMutations({
      setRouterActivePath: 'setRouterActivePath',
      changeNonCommonFormRule: 'dataModule/changeNonCommonFormRule',
      setProjectRelatedFields: 'dataModule/setProjectRelatedFields',
      changeNonCommonFieldRelated: 'dataModule/changeNonCommonFieldRelated'
    }),
    backToDataDetail () {
      this.$router.push({
        path: `/data/detail/${this.dataID}`
      })
    },
    async loadDataDetail () {
      const { data: dataHistoryResp } = await this.$http.get(`${this.serverAPI.DATA_HISTORY_URL}${this.dataID}/${this.historyID}`)
      console.log('data history response: ', dataHistoryResp)

      this.parseDataHistoryDetail(dataHistoryResp)
    },
    parseDataHistoryDetail (dataHistoryResp) {
      this.originData = dataHistoryResp.data.originData
      this.compareData = dataHistoryResp.data.compareData
      this.currentVersion = dataHistoryResp.data.currentVersion
      this.dataHistories = dataHistoryResp.data.dataHistoryList
      this.projectList = dataHistoryResp.data.projectList

      const relatedFields = dataHistoryResp.data.relatedFields
      console.log('data history detail related fields: ', relatedFields)
      relatedFields.forEach(relatedField => {
        const ruleContent = {}
        ruleContent.fieldName = relatedField.fieldName
        ruleContent.required = relatedField.required
        this.changeNonCommonFormRule(ruleContent)
        this.setProjectRelatedFields(relatedField.fieldName)
      })
      this.changeNonCommonFieldRelated(this.projectRelatedFields)

      const originDataVersion = this.originData.currentVersion
      this.originVersion = originDataVersion
      const originVersionIndex = this.dataHistories.findIndex(dataHistory => {
        return dataHistory.version === originDataVersion
      })
      console.log('origin data version index=', originVersionIndex)
      if (originVersionIndex > -1) {
        this.compareHistories = this.dataHistories.slice(0, originVersionIndex).concat(this.dataHistories.slice(originVersionIndex + 1, this.dataHistories.length))
      } else {
        this.compareHistories = this.dataHistories.slice()
      }

      const compareDataVersion = this.compareData.currentVersion
      this.compareVersion = compareDataVersion
      const compareVersionIndex = this.dataHistories.findIndex(dataHistory => {
        return dataHistory.version === compareDataVersion
      })
      console.log('compare data version index=', compareVersionIndex)
      if (compareVersionIndex > -1) {
        this.originHistories = this.dataHistories.slice(0, compareVersionIndex).concat(this.dataHistories.slice(compareVersionIndex + 1, this.dataHistories.length))
      } else {
        this.originHistories = this.dataHistories.slice()
      }

      if (compareDataVersion === this.currentVersion) {
        this.compareHistories.push({ version: this.currentVersion })
      }

      if (originDataVersion === this.currentVersion) {
        this.originHistories.push({ version: this.currentVersion })
      }

      if (compareDataVersion !== this.currentVersion && originDataVersion !== this.currentVersion) {
        this.compareHistories.push({ version: this.currentVersion })
        this.originHistories.push({ version: this.currentVersion })
      }
    },
    async dataHisVersionChangeHandler () {
      console.log('originVersion=%s, compareVersion=%s', this.originVersion, this.compareVersion)
      const { data: dataHistoryResp } = await this.$http.get(`${this.serverAPI.DATA_HISTORY_URL}${this.dataID}/${this.originVersion}/${this.compareVersion}`)
      console.log('data history response: ', dataHistoryResp)

      this.parseDataHistoryDetail(dataHistoryResp)

      this.commonFieldsFlag = true
      this.optionalFieldsFlag = true
      this.relatedFieldsFlag = true
    },
    shiftCommonFiledsFlag () {
      this.triggerBacktop()
      this.commonFieldsFlag = !this.commonFieldsFlag
    },
    shiftOptionalFieldsFlag () {
      this.triggerBacktop()
      this.optionalFieldsFlag = !this.optionalFieldsFlag
    },
    shiftRelatedFieldsFlag () {
      this.triggerBacktop()
      this.relatedFieldsFlag = !this.relatedFieldsFlag
    },
    triggerBacktop () {
      console.log('backtop ref', this.$refs.backtopRef)
      if (this.$refs.backtopRef.$el.click) {
        this.$refs.backtopRef.$el.click()
      }
    }
  }
}
</script>
<style scoped>
.toggle-button-bottom {
  float: right;
}
.field-title-container {
  border: solid 1px #d7dae2;
  border-bottom-width: 0;
  border-style:solid none;
  background-color: #F5F7FA;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.field-title-container {
  cursor: pointer;
}
.field-title {
  font-weight: bold;
  font-size: 16px;
  color: #303133;
  line-height: 48px;
  padding-left: 20px;
}
.field-title-button {
  display: flex;
  justify-content: flex-end;
  padding-right: 15px;
}
div /deep/ .el-card__body {
  padding-top: 0;
}
</style>
