const isProjectPI = (projectID, userProjectRoles) => {
  if (userProjectRoles !== null && userProjectRoles !== undefined && userProjectRoles.length > 0) {
    for (let i = 0; i < userProjectRoles.length; i++) {
      if (userProjectRoles[i].project.id === projectID) {
        console.log('projectID=', projectID)
        console.log('projectRole:', userProjectRoles[i])
        return !userProjectRoles[i].hasSupervisor
      }
    }
  }

  return false
}

export const CheckUserRole = {
  isProjectPI
}
