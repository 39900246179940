function countdown (countdownNum) {
  const messageBox = document.querySelector('#countdownNum')
  if (messageBox) {
    messageBox.innerHTML = countdownNum + (countdownNum > 1 ? ' seconds' : ' second')
  }
}

const msgBoxOption = {
  type: 'warning',
  confirmButtonText: 'Go',
  center: true,
  showClose: true,
  showCancelButton: true,
  roundButton: true,
  closeOnHashChange: true
}

function alertCountdown (that, title, goFunc, countdownValue) {
  if (!countdownValue) {
    countdownValue = 3
  }

  var interval = setInterval(() => {
    countdownValue--
    countdown(countdownValue)
    console.log('interval countdown value=', countdownValue)
    if (!countdownValue) {
      that.$msgbox.close()
      window.clearInterval(interval)
      if (goFunc) {
        goFunc()
      }
    }
  }, 1000)

  msgBoxOption.title = title
  const creator = that.$createElement
  const countdownNum = creator('span', { attrs: { id: 'countdownNum' } }, countdownValue + (countdownValue > 1 ? ' seconds' : ' second'))
  msgBoxOption.message = creator('span', { attrs: { id: 'countdownContent' } }, ['The page will automatically jump after ', countdownNum])

  that.$msgbox(
    msgBoxOption
  ).then(() => {
    if (goFunc) {
      goFunc()
    }
  }).catch(() => {
    console.log('cancel page change automatically operation')
  }).finally(() => {
    window.clearInterval(interval)
  })
  countdown(countdownValue)
}

export default alertCountdown
