const baseAPIUrl = process.env.VUE_APP_SERVER_API_BASE_URL
const baseFileUrl = process.env.VUE_APP_SERVER_FILE_API_BASE_URL

const WEB_SOCKET_URL = process.env.VUE_APP_WEBSOCKET_URL

/** used in Home.vue ---start **/
// URL to get current login user
const CURRENT_USER_URL = baseAPIUrl + 'user/current'
// URL that the current login user can logout the DMS
const USER_LOGOUT_URL = baseAPIUrl + 'logout' // used in UserRegister.vue
// URL that get the navigation menu list
const USER_NAVIS_URL = baseAPIUrl + 'menu/navi'
/** used in Home.vue ---end **/

/** used in Menu.vue ---start **/
// URL to get the navigation menu items on the left side
const MENU_LIST_URL = baseAPIUrl + 'menu/list' // used in Home.vue, Menu.vue
const MENU_ROOT_URL = baseAPIUrl + 'menu/rootMenu'
const MENU_ADD_URL = baseAPIUrl + 'menu/add'
const MENU_UPDATE_URL = baseAPIUrl + 'menu/update'
const MENU_DETAIL_URL = baseAPIUrl + 'menu/detail/'
const MENU_REMOVE_URL = baseAPIUrl + 'menu/remove/'
const MENU_SWITCH_STATUS_URL = baseAPIUrl + 'menu/switchStatus'
const MENU_WRITE_SWITCH_URL = baseAPIUrl + 'menu/writeSwitch'
/** used in Menu.vue ---end **/

/** used in Project.vue ---start **/
const PROJECT_LIST_URL = baseAPIUrl + 'project/list'
const PROJECT_SAVE_URL = baseAPIUrl + 'project/save'
const PROJECT_DETAIL_URL = baseAPIUrl + 'project/' // parameter(projectID) in the url path
const PROJECT_UPDATE_URL = baseAPIUrl + 'project/update'
const PROJECT_DISABLE_URL = baseAPIUrl + 'project/disable/' // parameter(projectID) in the url path
const PROJECT_ENABLE_URL = baseAPIUrl + 'project/enable/' // parameter(projectID) in the url path
const FIELD_GROUP_URL = baseAPIUrl + 'field/groupFields'
const PROJECT_RELATED_FIELDS_URL = baseAPIUrl + 'field/relatedList/' // parameter(projectID) in the url path
const PROJECT_GROUP_URL = baseAPIUrl + 'project/group'
/** used in Project.vue ---end **/

/** used in UserRegister.vue ---start **/
const CHECK_USERNAME_URL = baseAPIUrl + 'register/check/' // parameter(userName) in the url path
const REGISTER_RES_URL = baseAPIUrl + 'register/resource'
// user logout url used in Home.vue
const REGISTER_SUBMIT_URL = baseAPIUrl + 'register/submit'
const RESUBMIT_REGISTER_URL = baseAPIUrl + 'register/resubmit/'
/** used in UserRegister.vue ---end **/

/** used in FileExplorer.vue ---start **/
const FILE_SUBLIST_URL = baseAPIUrl + 'file/sub/' // parameter(parentFID) in the url path
const FILE_LIST_URL = baseAPIUrl + 'file/list/' // parameter(parentFID) in the url path
const FILE_SEARCH_URL = baseAPIUrl + 'file/search/' // parameter(fileName) in the url path
const FILECHUNK_LIST_URL = baseAPIUrl + 'file/chunk/list/'
const FILE_DOWNLOAD_URL = baseAPIUrl + 'file/download/' // parameter(fileUniqueID) in the url path
const FILE_STATUS_URL = baseAPIUrl + 'file/check/status/' // parameter(fileUniqueID) in the url path
// the below is the new urls for the new file list -start
const FILE_VIEW_PROJECT_URL = baseAPIUrl + 'file/data/level'
const FILE_VIEW_PAPER_URL = baseAPIUrl + 'file/paper/level'
// end
/** used in FileExplorer.vue ---end **/

/** used in Field.vue ---start **/
const FIELD_DETAIL_URL = baseAPIUrl + 'field/' // parameter(fieldID) in the url path
const FIELD_LIST_URL = baseAPIUrl + 'field/list'
const FIELD_SAVE_URL = baseAPIUrl + 'field/save'
const FIELD_UPDATE_URL = baseAPIUrl + 'field/update'
const FIELD_REMOVED_URL = baseAPIUrl + 'field/remove' // parameter(fieldID) in the url path
/** used in Field.vue ---end **/

/** used in UserList.vue ---start **/
// check user name existence
const USER_LIST_CRITERIA_URL = baseAPIUrl + 'user/criteria' // get the criteria information from ther server
const USER_UPDATE_CURRENT_URL = baseAPIUrl + 'user/update/current'
const USER_SEARCH_URL = baseAPIUrl + 'user/search' // parameter(search key) in the parameter after the "?" symbole
const USER_ADD_URL = baseAPIUrl + 'user/add'
const USER_PROJECT_URL = baseAPIUrl + 'user/project' // get the project options for adding the relationship of user and role
const USER_ROLEOPTION_URL = baseAPIUrl + 'user/role/option' // get the user role option for adding  the relationship of the user and role
const USER_DETAIL_URL = baseAPIUrl + 'user/detail/' // parameter(userID) in the url path
const USER_UPDATE_URL = baseAPIUrl + 'user/update'
const USER_PROJECT_PIS_URL = baseAPIUrl + 'user/project/pis/' // parameter(projectID) in the url path
const USER_CHECK_URL = baseAPIUrl + 'user/check/' // parameter(userName, userID[optional]) in the url path
const USER_RELATION_ENABLE_URL = baseAPIUrl + 'user/enable/relation/' // parameter(relationID) in the url path
const USER_RELATION_DISABLE_URL = baseAPIUrl + 'user/disable/relation/' // parameter(relationID) in the url path
const USER_PUSH_NOTIFICATION_URL = baseAPIUrl + 'user/push/'
const USER_RED_DOT_URL = baseAPIUrl + 'user/reddot'
/** used in UserList.vue ---end **/

/** used in UserRoleList.vue ---start **/
const USERROLE_SEARCHE_URL = baseAPIUrl + 'user/role/search/' // parameter(search keyword userRoleName) in the url path
const USERROLE_ADD_URL = baseAPIUrl + 'user/role/add'
const USERROLE_LIST_URL = baseAPIUrl + 'user/role/list'
const USERROLE_DETAIL_URL = baseAPIUrl + 'user/role/detail/' // parameter(userRoleID) in the url path
const USERROLE_UPDATE_URL = baseAPIUrl + 'user/role/update'
const USERROLE_AUTH_SCOPE_URL = baseAPIUrl + 'user/role/auth'
const USERROLE_REMOVE_URL = baseAPIUrl + 'user/role/remove/' // parameter(userRoleID) in the url path
const USERROLE_MENUS_URL = baseAPIUrl + 'user/role/menus/'
/** used in UserRoleList.vue ---end **/

/** used in PaperAdd.vue ---start **/
const PAPER_SUBMITTOKEN_URL = baseAPIUrl + 'paper/token/'
const PAPER_CHECKTITLE_URL = baseAPIUrl + 'paper/check/'
const PROJECT_ENABLE_LIST_URL = baseAPIUrl + 'project/enableList'
const PAPER_NEWARTICLE_URL = baseAPIUrl + 'paper/newArticle'
const PAPER_NEWBOOK_URL = baseAPIUrl + 'paper/newBook'
const PAPER_NEWINBOOK_URL = baseAPIUrl + 'paper/newInBook'
const PAPER_NEWTHESIS_URL = baseAPIUrl + 'paper/newThesis'
const PAPER_NEWBASEPAPER_URL = baseAPIUrl + 'paper/newBasePaper'
/** used in PaperAdd.vue ---end **/

/** used in PaperList.vue ---start **/
const PAPER_LIST_URL = baseAPIUrl + 'paper/list/'
const PAPER_LIST_CRITERIA_URL = baseAPIUrl + 'paper/criteria'
const PAPER_FILELIST_URL = baseAPIUrl + 'paper/related/files/'
/** used in PaperList.vue ---end **/

/** used in PaperUpdate.vue ---start **/
const PROJECT_ENABLELIST_URL = baseAPIUrl + 'project/enableList'
const PAPER_DETAIL_URL = baseAPIUrl + 'paper/detail/'
// data search url also used in PaperAdd.vue
const PAPER_UPDATEARTICLE_URL = baseAPIUrl + 'paper/updateArticle'
const PAPER_UPDATEBOOK_URL = baseAPIUrl + 'paper/updateBook'
const PAPER_UPDATEINBOOK_URL = baseAPIUrl + 'paper/updateInBook'
const PAPER_UPDATETHESIS_URL = baseAPIUrl + 'paper/updateThesis'
const PAPER_UPDATEBASEPAPER_URL = baseAPIUrl + 'paper/updateUnpublished'
const PAPER_HISTORY_URL = baseAPIUrl + 'paper/compare/'
/** used in PaperUpdate.vue ---end **/

/** public paper list page Index.vue ---start */
const PAPER_PUBLIC_URL = baseAPIUrl + 'open/papers'
const PAPER_PUBLIC_INIT_URL = baseAPIUrl + 'open/paper/init'
const PAPER_PUBLIC_FILE_URL = baseAPIUrl + 'open/file/download/'
const OPEN_STATICS_URL = baseAPIUrl + 'open/index'
/** public paper list page Index.vue ---end */

/** used in DataAdd.vue ---start **/
const DATA_ADDCONFIG_URL = baseAPIUrl + 'data/addConfig'
const DATA_ADD_URL = baseAPIUrl + 'data/new'
const DATA_LIST_URL = baseAPIUrl + 'data/list/'
const DATA_LIST_CRITERIA_URL = baseAPIUrl + 'data/criteria'
const DATA_UPDATE_URL = baseAPIUrl + 'data/update'
/** used in DataAdd.vue ---end **/

/** used in DataList.vue ---start **/
// data list url used in DataAdd.vue
const DATA_DETAIL_URL = baseAPIUrl + 'data/detail/'
const DATA_FILELIST_URL = baseAPIUrl + 'data/related/files/'
const DATA_HISTORY_URL = baseAPIUrl + 'data/compare/'
// data add url used in DataAdd.vue
/** used in DataList.vue ---end **/

/** used in DetaUpdate.vue ---start **/
// data detail url used in DataList.vue
/** used in DetaUpdate.vue ---end **/

/** used in CommonFields.vue ---start **/
const DATA_PROJECTS_RELATIONS_URL = baseAPIUrl + 'data/project/' // parameter(projectID) in the url path
const DATA_PROJECT_FIELD_RELATION_URL = baseAPIUrl + 'field/relatedNonCommonList/' // parameter(projectID) in the url path
/** used in CommonFields.vue ---end **/

/** new file upload api url for the file upload function in FileObjUploader.vue ---start */
const FILE_UPLOAD_CHUNK_URL = baseFileUrl + 'upload/file/chunk'
const FILE_UPLOAD_FOLDER_URL = baseFileUrl + 'upload/file/folderinfo'
const FILE_UPLOAD_INFO_URL = baseFileUrl + 'upload/file/fileinfo'
const FILE_UPLOAD_MERGE_URL = baseFileUrl + 'upload/file/merge'
const FILE_DUPLICATION_URL = baseFileUrl + 'file/duplicated'
/** new file upload api url for the file upload function in FileObjUploader.vue ---end */

/** used in ProjectFieldRelation.vue ---start **/
const PROJECT_FIELD_RELATION_REMOVE_URL = baseAPIUrl + 'project/removeFieldRelation'
const PROJECT_FIELD_RELATION_ADD_URL = baseAPIUrl + 'project/addFieldRelation'
const PROJECT_FIELD_RELATION_REQUIREMENT_EDIT_URL = baseAPIUrl + 'project/editRequirement'
// get project related fieldList used in Project.vue
/** used in ProjectFieldRelation.vue ---end **/

/** used in Url.vue ---start  TODO */
const URL_ADD_URL = baseAPIUrl + 'url/save'
const URL_LIST_URL = baseAPIUrl + 'url/list'

export const SERVER_API = {
  WEB_SOCKET_URL,
  // Home.vue
  CURRENT_USER_URL,
  USER_LOGOUT_URL,
  USER_NAVIS_URL,
  // Menu.vue
  MENU_LIST_URL,
  MENU_ROOT_URL,
  MENU_ADD_URL,
  MENU_UPDATE_URL,
  MENU_DETAIL_URL,
  MENU_REMOVE_URL,
  MENU_SWITCH_STATUS_URL,
  MENU_WRITE_SWITCH_URL,
  // Project.vue
  PROJECT_LIST_URL,
  PROJECT_SAVE_URL,
  PROJECT_DETAIL_URL,
  PROJECT_UPDATE_URL,
  PROJECT_DISABLE_URL,
  PROJECT_ENABLE_URL,
  FIELD_GROUP_URL,
  PROJECT_RELATED_FIELDS_URL,
  PROJECT_GROUP_URL,
  // UserRegister.vue
  CHECK_USERNAME_URL,
  REGISTER_RES_URL,
  REGISTER_SUBMIT_URL,
  RESUBMIT_REGISTER_URL,
  // FileExplorer.vue
  FILE_SUBLIST_URL,
  FILE_LIST_URL,
  FILE_SEARCH_URL,
  FILECHUNK_LIST_URL,
  FILE_DOWNLOAD_URL,
  FILE_STATUS_URL,
  FILE_VIEW_PROJECT_URL,
  FILE_VIEW_PAPER_URL,
  // Field.vue
  FIELD_DETAIL_URL,
  FIELD_LIST_URL,
  FIELD_SAVE_URL,
  FIELD_UPDATE_URL,
  FIELD_REMOVED_URL,
  // UserList.vue
  USER_LIST_CRITERIA_URL,
  USER_UPDATE_CURRENT_URL,
  USER_SEARCH_URL,
  USER_ADD_URL,
  USER_PROJECT_URL,
  USER_ROLEOPTION_URL,
  USER_DETAIL_URL,
  USER_UPDATE_URL,
  USER_PROJECT_PIS_URL,
  USER_CHECK_URL,
  USER_RELATION_ENABLE_URL,
  USER_RELATION_DISABLE_URL,
  USER_PUSH_NOTIFICATION_URL,
  USER_RED_DOT_URL,
  // UserRoleList.vue
  USERROLE_SEARCHE_URL,
  USERROLE_ADD_URL,
  USERROLE_LIST_URL,
  USERROLE_DETAIL_URL,
  USERROLE_UPDATE_URL,
  USERROLE_AUTH_SCOPE_URL,
  USERROLE_REMOVE_URL,
  USERROLE_MENUS_URL,
  PAPER_PUBLIC_URL,
  PAPER_PUBLIC_INIT_URL,
  PAPER_PUBLIC_FILE_URL,
  OPEN_STATICS_URL,
  // PaperAdd.vue
  PAPER_SUBMITTOKEN_URL,
  PAPER_CHECKTITLE_URL,
  PROJECT_ENABLE_LIST_URL,
  PAPER_NEWARTICLE_URL,
  PAPER_NEWBOOK_URL,
  PAPER_NEWINBOOK_URL,
  PAPER_NEWTHESIS_URL,
  PAPER_NEWBASEPAPER_URL,
  // PaperList.vue
  PAPER_LIST_URL,
  PAPER_LIST_CRITERIA_URL,
  PAPER_FILELIST_URL,
  // PaperUpdate.vue
  PROJECT_ENABLELIST_URL,
  PAPER_DETAIL_URL,
  PAPER_UPDATEARTICLE_URL,
  PAPER_UPDATEBOOK_URL,
  PAPER_UPDATEINBOOK_URL,
  PAPER_UPDATETHESIS_URL,
  PAPER_UPDATEBASEPAPER_URL,
  PAPER_HISTORY_URL,
  // DataAdd.vue
  DATA_ADDCONFIG_URL,
  DATA_ADD_URL,
  DATA_LIST_URL,
  DATA_LIST_CRITERIA_URL,
  DATA_UPDATE_URL,
  // DataList.vue
  DATA_DETAIL_URL,
  DATA_FILELIST_URL,
  DATA_HISTORY_URL,
  // DataUpdate.vue
  // CommonFields.vue
  DATA_PROJECTS_RELATIONS_URL,
  DATA_PROJECT_FIELD_RELATION_URL,
  // FileObjUploader.vue
  FILE_UPLOAD_CHUNK_URL,
  FILE_UPLOAD_FOLDER_URL,
  FILE_UPLOAD_INFO_URL,
  FILE_UPLOAD_MERGE_URL,
  FILE_DUPLICATION_URL,
  // ProjectFieldRelation.vue
  PROJECT_FIELD_RELATION_REMOVE_URL,
  PROJECT_FIELD_RELATION_ADD_URL,
  PROJECT_FIELD_RELATION_REQUIREMENT_EDIT_URL,
  // Url.vue
  URL_ADD_URL,
  URL_LIST_URL
}
