<template>
  <section>
    <!--Breadcrumb navigation area-->
    <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>Home</el-breadcrumb-item>
        <el-breadcrumb-item>Data Module</el-breadcrumb-item>
        <el-breadcrumb-item>File List</el-breadcrumb-item>
    </el-breadcrumb>

    <el-tabs type="border-card" @tab-click="fileTabClick">
      <el-tab-pane label="File List">
        <div class="wl-explorer">

          <!--the area of file operation-->
          <el-form :inline="true" class="wl-header-file">
            <el-form-item :class="isSearching? 'file-path-search-box': 'file-path-box'">
              <div class="file-path-text">
                <img class="file-path-img" src="@/assets/file/images/folder@3x.png" alt="Folder" title="Folder" />
                {{currentPath.fileRelativePath}}
              </div>
            </el-form-item>
            <el-form-item :class="isSearching? 'file-search-searching-box' : 'file-search-box'">
              <el-input v-model="fileSearchKeyword" placeholder="Input Keywords" @focus="fileSearchFocusHandler" @blur="fileSearchBlurHandler" :clearable="true" @keyup.enter.native="searchFileList" @clear="searchFileList">
                <el-button slot="append" icon="el-icon-search file-search" @focus.native="fileSearchFocusHandler" @blur.native="fileSearchBlurHandler" @click="searchFileList"></el-button>
              </el-input>
            </el-form-item>
            <el-form-item class="file-handle-box">
              <!-- <i class="iconfont icon-wl-left file-path-handle"></i>
              <i class="iconfont icon-wl-right file-path-handle"></i> -->
              <i class="iconfont icon-wl-up file-path-handle" :class="{'u-disabled': isRoot}" @click="isRoot? '' : pathBtn()"></i>
              <i class="iconfont icon-file-list file-show-type"
                v-show="layout.show_list"
                @click="layout.show_list=!layout.show_list"></i>
              <i class="iconfont icon-file-grid file-show-type"
                v-show="!layout.show_list"
                @click="layout.show_list=!layout.show_list"></i>
              <i class="el-icon-refresh-right" :class="refreshAnimateFlag? 'file-list-refresh': ''" style="font-size: 20px; color: #409EFF; margin-left: 3px;"
                @click="reloadFileList"></i>
            </el-form-item>
          </el-form>

          <!-- main content area -->
          <!-- <el-scrollbar class="wl-main-scroll"> -->
          <section>
            <!-- file list area -->
            <el-row class="wl-main-list">
              <!-- tablur file list -->
              <el-table v-show="layout.show_list" :data="fileList" class="wl-table" ref="wl-table" empty-text="No File Information Data" border stripe>
                <!-- used to show the multi-select checkbox -->
                <el-table-column align="center" type="selection"></el-table-column>
                <el-table-column align="center" type="index" label="#"></el-table-column>
                <el-table-column :show-overflow-tooltip="true" label="FileName">
                  <template v-slot="fileProps">
                    <img :src="fileTypeIcon(fileProps.row)" class="name-col-icon" alt="file type icon" style="margin-right: 3px; vertical-align:middle;"/>
                    <el-link v-if="fileProps.row.currentFile.isFolder" :underline="false" @click="enterTheLower(fileProps.row)">{{ fileProps.row.currentFile.fileName }}</el-link>
                    <span v-else>{{ fileProps.row.currentFile.fileName }}</span>
                  </template>
                </el-table-column>
                <el-table-column align="center" :show-overflow-tooltip="true" label="FileStatus">
                  <template v-slot="fileProps">
                    <span v-show="fileProps.row.currentFile.fileStatus.statusCode === 1"><i class="el-icon-question" style="font-size: 12px; color: #e6a23c; margin-left: 3px;"></i>Unmerged</span>
                    <span v-show="fileProps.row.currentFile.fileStatus.statusCode === 2"><i class="el-icon-loading" style="font-size: 12px; color: #409EFF; margin-left: 3px;"></i>Merging</span>
                    <span v-show="fileProps.row.currentFile.fileStatus.statusCode === 3"><i class="el-icon-info" style="font-size: 12px; color: #909399; margin-left: 3px;"></i>Merged</span>
                    <span v-show="fileProps.row.currentFile.fileStatus.statusCode === 4"><i class="el-icon-error" style="font-size: 12px; color: #f56c6c; margin-left: 3px;"></i>Failed</span>
                    <span v-show="fileProps.row.currentFile.fileStatus.statusCode === 5"><i class="el-icon-success" style="font-size: 12px; color: #67c23a; margin-left: 3px;"></i>Success</span>
                  </template>
                </el-table-column>
                <el-table-column align="center" label="Type">
                  <template v-slot="fileProps">
                    {{ fileProps.row | showFileType }}
                  </template>
                </el-table-column>
                <el-table-column align="center" label="Size" :formatter="fileSizeFormat"></el-table-column>
                <el-table-column align="center" label="Operator">
                  <template v-slot="fileProps">
                    {{fileProps.row.currentFile.operator.realName}}
                  </template>
                </el-table-column>
                <el-table-column align="center" label="Created Date" prop="createTime">
                  <template v-slot="fileProps">
                    {{ fileProps.row.createTime | formatDate }}
                  </template>
                </el-table-column>
                <el-table-column align="center" width="100">
                  <template v-slot="fileProps">
                    <el-button v-if="!fileProps.row.isFolder" type="text" style="font-weight: bold;" @click="checkFileStatusBeforeDownload(fileProps.row.currentFile.id)">Download</el-button>
                    <span v-else> - </span>
                  </template>
                </el-table-column>
              </el-table>
              <!-- file list with icon -->
              <ul class="wl-list" v-show="!layout.show_list">
                <li class="wl-list-item wl-is-folder" v-for="i in fileList" :key="i.fileUniqueID">
                  <!-- <el-checkbox class="wl-checkbox" @change="listItemCheck($event,i)" v-model="i._checked"></el-checkbox> -->
                  <div @click="enterTheLower(i)">
                    <img :src="fileTypeIcon(i)" class="name-col-icon" alt="fileIconType" />
                    <el-tooltip effect="dark" placement="bottom-start">
                      <div slot="content"> {{ i.fileName }} <div v-if="!i.isFolder">{{ fileSizeFormat(i) }}</div> </div>
                      <p class="list-item-name"> {{ i.fileName }} </p>
                    </el-tooltip>
                  </div>
                </li>
              </ul>
            </el-row>

            <el-row v-if="paginationInfo.currentPage < paginationInfo.totalPage" type="flex" justify="center" align="middle" style="z-index: 21;">
              <el-col :span="2" style="display: flex; justify-content:center;">
                <el-button type="text" @click="loadMoreFiles">Load More</el-button>
              </el-col>
            </el-row>

            <!-- <el-row v-else class="no-more-file">
              <el-col :xs="11" :sm="11" :lg="11" :xl="10" style="display: flex; justify-content:flex-end;">-----------------</el-col>
              <el-col :xs="3" :sm="3" :lg="3" :xl="2" style="display: flex; justify-content:center;">
                No More Data
              </el-col>
              <el-col :xs="10" :sm="10" :lg="10" :xl="10" style="display: flex; justify-content:flex-start;">-----------------</el-col>
            </el-row> -->
            <div v-else class="el-table__empty-block" style="min-height: 20px;">
              <span class="el-table__empty-text no-more-file">-----------------&nbsp;&nbsp;&nbsp;&nbsp;No More Data&nbsp;&nbsp;&nbsp;&nbsp;-----------------</span>
            </div>
          </section>
          <!-- </el-scrollbar> -->
        </div>
      </el-tab-pane>
      <el-tab-pane label="Uncompleted File List">
        <el-table :data="progressList" empty-text="No uploading file" border stripe>
          <el-table-column align="center" type="index" label="#"></el-table-column>
          <el-table-column :show-overflow-tooltip="true" label="FileName" header-align="center">
            <template v-slot="progressProp">
              {{ progressProp.row.chunkInfo.filename }}
            </template>
          </el-table-column>
          <el-table-column :show-overflow-tooltip="true" label="FileSize(Byte)" header-align="center" align="center">
            <template v-slot="progressProp">
              {{ progressProp.row.chunkInfo.totalSize }}
            </template>
          </el-table-column>
          <el-table-column label="Chunks" header-align="center" align="center">
            <template v-slot="progressProp">
              {{ progressProp.row.chunkInfo.totalChunks }}
            </template>
          </el-table-column>
          <el-table-column label="Uploaded Chunks" header-align="center" align="center" prop="progress"></el-table-column>
          <el-table-column label="Progress" header-align="center" align="center">
            <template v-slot="progressProp">
              {{ formattFileProgressText((progressProp.row.progress/progressProp.row.chunkInfo.totalChunks) * 100) }}
            </template>
          </el-table-column>
          <el-table-column label="Start Time" header-align="center" align="center">
            <template v-slot="progressProp">
              {{ progressProp.row.chunkInfo.createTime | formatDate }}
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
    </el-tabs>
  </section>
</template>

<script>
import { EVENT_CONFIG } from '@/event_bus'
import { FILE_TYPE } from '../components/file_component/fileconfig'
import '@/assets/file/file.scss'

export default {
  components: {
  },
  data () {
    return {
      fileSearchKeyword: '',
      isSearching: false,
      currentLoginUser: {},
      paginationInfo: {
        currentPage: 1,
        pageSize: 10,
        totalCount: 0,
        totalPage: 0
      },
      currentPath: {},
      isRoot: true,
      fileList: [],
      layout: {
        show_list: true
      },
      fileType: FILE_TYPE,
      progressList: [],
      reloadProgressTimer: 0,
      refreshAnimateFlag: false
    }
  },
  destroyed () {
    clearInterval(this.reloadProgressTimer)
  },
  filters: {
    showFileType (fileRow) {
      if (fileRow.currentFile.isFolder) {
        return FILE_TYPE.FOLDER.typeName
      }

      const fileType = FILE_TYPE.getFileType(fileRow.currentFile.fileName)
      return fileType.typeName
    }
  },
  async mounted () {
    this.currentLoginUser = await this.$userStorage.getCurrentUser()
    console.log('load file list from server')
    this.loadRelatedFileList()
    EVENT_CONFIG.EventBus.$on(EVENT_CONFIG.RELOAD_FILE_LIST_EVENT, this.afterFileUploadingEventHandler)
  },
  beforeDestroy () {
    console.debug('FileExplorer.vue beforeDestory method executes.....')
    EVENT_CONFIG.EventBus.$off(EVENT_CONFIG.RELOAD_FILE_LIST_EVENT)
  },
  methods: {
    resetPaginationInfo () {
      this.paginationInfo = {
        currentPage: 1,
        pageSize: 10,
        totalCount: 0,
        totalPage: 0
      }
    },
    async fileTabClick (activeTab) {
      console.log('file tab click:', activeTab)
      clearInterval(this.reloadProgressTimer)
      this.resetPaginationInfo()
      if (activeTab.index === '0') {
        await this.loadRelatedFileList()
        return
      }

      if (activeTab.index === '1') {
        await this.loadUncompleteFileList()
        this.reloadProgressTimer = setInterval(async () => {
          console.log('hello', new Date())
          await this.loadUncompleteFileList()
        }, 5000)
      }
    },
    async searchFileList () {
      console.log('search file list keywords=', this.fileSearchKeyword)
      this.resetPaginationInfo()
      await this.reloadFileList()
    },
    fileSearchFocusHandler () {
      this.isSearching = true
    },
    fileSearchBlurHandler () {
      this.isSearching = false
    },
    async pathBtn () {
      let fileURL
      if (this.currentPath.parentFile === null || this.currentPath.parentFile === undefined) {
        fileURL = this.serverAPI.FILE_LIST_URL
      } else {
        fileURL = this.serverAPI.FILE_LIST_URL + this.currentPath.parentFile.id
        if (this.currentPath.parentPath.parentFile !== null && this.currentPath.parentPath.parentFile !== undefined) {
          fileURL = fileURL + '/' + this.currentPath.parentPath.parentFile.id
        }
      }

      const parentPath = this.currentPath.parentPath.parentPath
      await this.getFileList(fileURL)
      this.currentPath.parentPath = parentPath
      if (this.currentPath.currentFile === null || this.currentPath.currentFile.fileUniqueID === null) this.isRoot = true
    },
    async enterTheLower (row) { // Enter the lower-level file directory
      let subFileListURL = this.serverAPI.FILE_LIST_URL + row.currentFile.id
      if (this.currentPath.currentFile !== null && this.currentPath.currentFile !== undefined) {
        subFileListURL = subFileListURL + '/' + this.currentPath.currentFile.id
      }

      const parentPath = this.currentPath
      await this.getFileList(subFileListURL)
      this.currentPath.parentPath = parentPath

      this.isRoot = false
    },
    fileSizeFormat (row) {
      console.debug('file name: %s and file size %s', row.currentFile.fileName, row.currentFile.fileSize)
      if (row.currentFile.fileSize === null) return '-'
      if (row.currentFile.fileSize < 1024) {
        // lower than 1024, the unit is 'Bytes'
        return row.currentFile.fileSize + ' Bytes'
      }
      if (row.currentFile.fileSize < (1024 * 1024)) {
        // bigger than 1024 and smaller than 1024*1024, the unit is 'KB'
        const _kb = (row.currentFile.fileSize / 1024).toFixed(2)
        return parseFloat(_kb) + ' KB'
      }

      if (row.currentFile.fileSize < (1024 * 1024 * 1024)) {
        // bigger than 1024*1024 and smaller than 1024*1024*1024, the unit is 'MB'
        const _mb = (row.currentFile.fileSize / (1024 * 1024)).toFixed(2)
        return parseFloat(_mb) + ' MB'
      }

      const _gb = (row.currentFile.fileSize / (1024 * 1024 * 1024)).toFixed(2)
      return parseFloat(_gb) + ' GB'
    },
    async loadMoreFiles () {
      this.paginationInfo.currentPage = this.paginationInfo.currentPage + 1
      const currentFileList = this.fileList
      let loadMoreFilesUrl = this.serverAPI.FILE_LIST_URL
      if (this.currentPath.currentFile !== null && this.currentPath.currentFile !== undefined) {
        loadMoreFilesUrl = loadMoreFilesUrl + this.currentPath.currentFile.id
      }
      if (this.currentPath.parentFile !== null && this.currentPath.parentFile !== undefined) {
        loadMoreFilesUrl = loadMoreFilesUrl + '/' + this.currentPath.parentFile.id
      }
      const urlParams = this.$qs.stringify(this.paginationInfo)
      const currentParenPath = this.currentPath.parentPath
      await this.getFileList(loadMoreFilesUrl + '?' + urlParams)
      this.currentPath.parentPath = currentParenPath
      this.fileList = currentFileList.concat(this.fileList)
    },
    async loadRelatedFileList () { // reload file list
      // const { data: relatedFilesResp } = await this.$http.get('/file/list')
      const urlParams = this.$qs.stringify(this.paginationInfo)
      await this.getFileList(this.serverAPI.FILE_LIST_URL + '?' + urlParams)
    },
    async getFileList (fileListURLWithParams) {
      if (this.fileSearchKeyword !== '' && this.fileSearchKeyword !== null && this.fileSearchKeyword !== undefined) {
        if (fileListURLWithParams.indexOf('?') >= 0) {
          fileListURLWithParams = fileListURLWithParams + '&searchFileName=' + this.fileSearchKeyword
        } else {
          fileListURLWithParams = fileListURLWithParams + '?searchFileName=' + this.fileSearchKeyword
        }
      }
      const { data: relatedFilesResp } = await this.$http.get(fileListURLWithParams)
      console.log('get file list response content: ', relatedFilesResp)
      /* this.fileList = relatedFilesResp.data.subFileList
      this.currentPath = relatedFilesResp.data */
      this.currentPath = relatedFilesResp.data
      this.fileList = relatedFilesResp.data.subFilesPage.records
      this.paginationInfo.currentPage = relatedFilesResp.data.subFilesPage.current
      this.paginationInfo.pageSize = relatedFilesResp.data.subFilesPage.size
      this.paginationInfo.totalCount = relatedFilesResp.data.subFilesPage.total
      this.paginationInfo.totalPage = relatedFilesResp.data.subFilesPage.pages

      return relatedFilesResp.data
    },
    afterFileUploadingEventHandler (pathInfo) {
      console.log('finish uploading file info:', pathInfo)
      this.paginationInfo.currentPage = 1
      this.paginationInfo.pageSize = 10
      this.loadRelatedFileList()
    },
    fileTypeIcon (row) { // display the file icon according to the file suffix
      let fileType
      if (row.isFolder) {
        fileType = FILE_TYPE.FOLDER
      } else {
        fileType = FILE_TYPE.getFileType(row.currentFile.fileName)
      }

      const iconPath = require('@/' + `${fileType.typeIcon}`)
      return iconPath
    },
    async loadUncompleteFileList () {
      console.log('load the uncompleted file list')
      const { data: fileChunkListResp } = await this.$http.get(this.serverAPI.FILECHUNK_LIST_URL)
      console.log('get file chunk list resp: ', fileChunkListResp)
      this.progressList = fileChunkListResp.data
    },
    formattFileProgressText (percentage) {
      percentage = percentage.toFixed(2)
      return percentage + '%'
    },
    async reloadFileList () {
      this.refreshAnimateFlag = true
      console.log('reload file list')
      console.log('current path:', this.currentPath)
      let reloadFileListURL = this.serverAPI.FILE_LIST_URL
      if (this.currentPath.currentFile !== null && this.currentPath.currentFile !== undefined) {
        reloadFileListURL = reloadFileListURL + this.currentPath.currentFile.id
      }
      if (this.currentPath.parentFile !== null && this.currentPath.parentFile !== undefined) {
        reloadFileListURL = reloadFileListURL + '/' + this.currentPath.parentFile.id
      }

      // console.log('current pagination info: ', this.paginationInfo)
      const parentPath = this.currentPath.parentPath
      const reloadNum = this.paginationInfo.currentPage
      await this.getFileList(reloadFileListURL)
      this.currentPath.parentPath = parentPath
      // console.log('current pagination info1111: ', this.paginationInfo)
      for (let i = 2; i <= reloadNum; i++) {
        // console.log('after refresh load the rest page of the file list')
        await new Promise(resolve => {
          setTimeout(resolve, 1000)
        })
        await this.loadMoreFiles()
        // console.log('after refresh load the rest page of the file list', i)
      }
      this.refreshAnimateFlag = false
    },
    async checkFileStatusBeforeDownload (fileID) {
      console.log('before download file, check the file status fileID=%s', fileID)
      const { data: checkResp } = await this.$http.get(this.serverAPI.FILE_STATUS_URL + fileID)
      console.log('check file status response: ', checkResp)
      if (checkResp.code === 1) {
        if (!checkResp.data.isFolder) {
          window.location.href = this.serverAPI.FILE_DOWNLOAD_URL + checkResp.data.id
          return
        }
        console.log('this is a folder')
      } else {
        this.$alert('<font color="red">' + checkResp.msg + '</font>', 'File Download Notification', {
          dangerouslyUseHTMLString: true
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/file/clear.css';
@import '../assets/file/icons/iconfont.css';
.no-more-file {
  font: 14px;
  line-height: 20px;
  color: #C0C4CC;
}

.file-list-refresh {
  -webkit-transform:rotate(1080deg);
  transform:rotate(1080deg);
  -webkit-transition:-webkit-transform 2s linear;
  transition:transform 2s linear;
}
</style>
