import { USER_LOCAL_STORAGE } from '@/local_storage/UserLocalStorage'

/**
 * store the draft of the experimental data
 * @param { commonFormData: obj, nonCommonFormData: obj } dataObj
 */
const storeDataDraft = async (dataObj) => {
  const dataDraftKey = await generateDataDraftKey(dataObj.commonFormData.dataID)

  storeDraft(dataDraftKey, dataObj)
}

/**
 * generate storage key of the experimental data in the local session storage
 * @param {experimental data ID: long} dataID
 * @returns
 */
async function generateDataDraftKey (dataID) {
  const currentUser = await USER_LOCAL_STORAGE.getCurrentUser()
  let dataDraftKey = 'new-data-draft-' + currentUser.userName
  if (dataID) {
    dataDraftKey = 'update-data-draft-' + dataID + '-' + currentUser.userName
  }
  console.log('data draft key=', dataDraftKey)

  return dataDraftKey
}

/**
 * remove the experimental data object from the local session storage
 * @param {*} dataID
 */
const removeDataDraft = async (dataID) => {
  const dataDraftKey = await generateDataDraftKey(dataID)

  removeDraft(dataDraftKey)
}

/**
 * get the the experimental data object from the local session storage
 * @param {experimental data ID: long} dataID
 */
const getDataDraft = async (dataID) => {
  const dataDraftKey = await generateDataDraftKey(dataID)

  return getDraft(dataDraftKey)
}

/**
 * store the draft of the publication
 * @param {*} publicationObj
 */
const storePublicationDraft = async (publicationObj) => {
  console.log('store the draft of the publication: ', publicationObj)
  const publicationDraftKey = await generatePublicationDraftKey(publicationObj.id)

  storeDraft(publicationDraftKey, publicationObj)
}

/**
 * generate storage key of the publication in the local session storage
 * @param {*} publicationID
 * @returns
 */
async function generatePublicationDraftKey (publicationID) {
  const currentUser = await USER_LOCAL_STORAGE.getCurrentUser()
  console.log('draft generator current user: ', currentUser)
  let publicationDraftKey = 'new-publication-draft-' + currentUser.userName
  if (publicationID) {
    publicationDraftKey = 'update-publication-draft-' + publicationID + '-' + currentUser.userName
  }
  console.log('publication draft key=', publicationDraftKey)

  return publicationDraftKey
}

const removePublicationDraft = async (publicationID) => {
  const publicationDraftKey = await generatePublicationDraftKey(publicationID)

  removeDraft(publicationDraftKey)
}

/**
 * get the publication data object from the local session storage
 * @param {} publicationID
 * @returns
 */
const getPublicationDraft = async (publicationID) => {
  const publicationDraftKey = await generatePublicationDraftKey(publicationID)

  return getDraft(publicationDraftKey)
}

/**
 * provide the function code to interact with the local session storage to store the drafts
 * @param {*} key
 * @param {*} value
 */
function storeDraft (key, value) {
  window.localStorage.setItem(key, JSON.stringify(value))
}

/**
 * provide the function code to interact with the local session storage to remove the drafts
 * @param {*} key
 */
function removeDraft (key) {
  window.localStorage.removeItem(key)
}

/**
 * provode the function code to interact with the local session storage to get the drafts
 * @param {} key
 * @returns
 */
function getDraft (key) {
  const valueStr = window.localStorage.getItem(key)
  return JSON.parse(valueStr)
}

export const DRAFT_GENERATOR = {
  getDataDraft,
  storeDataDraft,
  removeDataDraft,
  getPublicationDraft,
  storePublicationDraft,
  removePublicationDraft
}
