<template>
  <el-container class="home-container">
    <!--Header-->
    <el-header>
      <div>
        <img src="@/assets/sfb-logo.png">
        <span style="font-weight: bold; font-size: 32px;">SFB1372 Data Management System</span>
      </div>
      <div>
        <span style="margin-right: 15px; font-size: 16px;">{{userForm.realName}}({{userForm.userName}})</span>
        <el-button type="info" style="font-size: 15px;" size="mini" @click="userLogout">Logout</el-button>
      </div>
    </el-header>
    <el-main>
      <el-row>
        <el-steps :active="registerActive" align-center finish-status="success">
          <el-step title="Step 1" description="User basic information"></el-step>
          <el-step title="Step 2" description="User application is submitted"></el-step>
          <el-step title="Step 3" process-status="error" description="User application is rejected"></el-step>
        </el-steps>
      </el-row>
      <el-row style="margin-top: 10px;">
        <el-col :xl="{span: 12, offset: 7}" :md="{span: 18, offset: 4}">
         <strong> The current status of your application is under approve. It means that you have to wait until the PIs of the projects you have selected to approve the application. </strong>
        </el-col>
      </el-row>
      <el-row style="margin-top: 10px;">
        <el-col :xl="{span: 12, offset: 7}" :md="{span: 18, offset: 4}">
          <el-card>
            <div slot="header" class="clearfix">
              <span> User Register Form </span>
            </div>
            <el-form ref="registerForm" :model="userForm" :rules="userFormRules" label-width="100px">
              <el-row>
                <el-form-item label="User Name" prop="userName">
                  <el-input v-model="userForm.userName" readonly></el-input>
                </el-form-item>
              </el-row>
              <el-row>
                <el-form-item label="Real Name" prop="realName">
                  <el-input v-model="userForm.realName" readonly></el-input>
                </el-form-item>
              </el-row>
              <el-row>
                <el-form-item label="Email" prop="userEmail">
                  <el-input v-model="userForm.userEmail" readonly></el-input>
                </el-form-item>
              </el-row>
              <el-row>
                <el-form-item label="University" prop="university">
                  <el-input v-model="userForm.university" :readonly="registerActive === 1? false: true"></el-input>
                </el-form-item>
              </el-row>
              <el-row>
                <el-form-item v-if="registerActive === 1" label="Project" prop="projectRoles">
                  <el-col :span=6 v-for="project in projects" :key="project.id">
                    <el-checkbox style="padding-left: 15px;" :label="project.id" :key="project.id" @change="userProjectValueChangeHandler($event, project.id)" :value="displaySelectedProject(userForm.projectRoles, project.id)"> {{project.projectName}} </el-checkbox>
                  </el-col>
                </el-form-item>
                <el-form-item v-else label="Project" prop="projectRoles">
                  <section class="el-input">
                    <el-tag v-for="projectRole in userForm.projectRoles" :key="projectRole.project.id" style="margin-right: 15px;">
                      {{projectRole.project.projectName}}
                    </el-tag>
                  </section>
                </el-form-item>
              </el-row>
              <el-row>
                <el-form-item v-if="registerActive === 1" label="Role" prop="projectRoles">
                  <section class="el-input">
                    <el-col :span="24/userRoles.length" v-for="role in userRoles" :key="role.id">
                      <el-radio style="padding-left: 15px;" :label="role.id" :value="displaySelectedRole(userForm.projectRoles, role.id)" @change="userRoleValueChangeHandler(role.id)"> {{role.roleName}}</el-radio>
                    </el-col>
                  </section>
                </el-form-item>
                <el-form-item v-else label="Role" prop="projectRoles">
                  <section class="el-input">
                    <el-tag> {{userForm.projectRoles !== null && userForm.projectRoles.length > 0 ? userForm.projectRoles[0].role.roleName : ''}} </el-tag>
                  </section>
                </el-form-item>
              </el-row>
              <el-row v-if="registerActive === 1">
                <el-form-item>
                  <div style="float: right;">
                    <el-button type="primary" @click="submitRegistration" :disabled="registerBtnDisable">Apply</el-button>
                    <el-button @click="resetUserForm">Cancel</el-button>
                  </div>
                </el-form-item>
              </el-row>
              <el-row v-else>
                <el-form-item v-if="userForm.projectRoles && userForm.projectRoles[0].status && userForm.projectRoles[0].status.statusCode === 2">
                  <el-button type="primary" @click="reSubmitReg" :disabled="reSubmitBtnDisable">Re-apply</el-button>
                </el-form-item>
              </el-row>
            </el-form>
          </el-card>
        </el-col>
      </el-row>
    </el-main>
  </el-container>
</template>
<script>
export default {
  data () {
    return {
      registerActive: 0,
      userProjectIDs: [], // the selected user project id
      userRoleID: '', // the selected user role id
      registerBtnDisable: true,
      reSubmitBtnDisable: false,
      userForm: {
        userName: '',
        realName: '',
        userEmail: '',
        university: '',
        userStatus: {
          statusName: '',
          statusCode: ''
        },
        projectRoles: [{
          project: { id: 0, projectName: '' },
          role: { id: 0, roleName: '' }
        }]
      },
      userFormRules: {
        userName: [
          { required: true, message: 'Username is required', trigger: 'change' }
        ],
        realName: [
          { required: true, message: 'Actualname is required', trigger: 'change' }
        ],
        userEmail: [
          { required: true, message: 'Email is required', trigger: 'change' }
        ],
        projectRoles: [
          { required: true, message: 'Project is required', type: 'array', trigger: 'change' }
        ]
      },
      projects: [], // the project select option list
      projectPIs: [], // the supervisor select option list
      userRoles: [] // the user role select option list
    }
  },
  mounted () {
    this.iniUserForm()
    this.getUserResource()
  },
  methods: {
    async iniUserForm () {
      // get user info from the localstorage
      this.userForm = await this.$userStorage.getCurrentUser()
      console.log('user register information: ', this.userForm)
      if (this.userForm === null || this.userForm === undefined) {
        // current login user infor is null, relogin
        this.$alert('Login user information is expired. Please relogin.', 'Notification', {
          confirmButtonText: 'OK',
          type: 'warning',
          center: true,
          callback: action => {
            this.userLogout()
          }
        })
      }

      // const { data: chekcRep } = await this.$http.get('/register/check/' + this.userForm.id)
      const { data: chekcRep } = await this.$http.get(this.serverAPI.CHECK_USERNAME_URL + this.userForm.id)
      console.log('check user registration response: ', chekcRep)

      if (chekcRep.data) {
        this.checkReponse(chekcRep.data)
        if (chekcRep.data.projectRoles.length > 0) {
          this.registerActive = 2
        } else {
          this.registerActive = 3
        }
        this.userForm = chekcRep.data
      } else {
        console.log('user register form: ', this.userForm)
        this.registerActive = 1
      }
    },
    async getUserResource () {
      // const { data: userResource } = await this.$http.get('/register/resource')
      const { data: userResource } = await this.$http.get(this.serverAPI.REGISTER_RES_URL)
      console.log('user resource content: ', userResource)
      this.checkReponse(userResource.data)
      this.projects = userResource.data.projectList
      this.userRoles = userResource.data.userRoles
    },
    async userLogout () {
      // const { data: logoutResp } = await this.$http.get('logout')
      const { data: logoutResp } = await this.$http.get(this.serverAPI.USER_LOGOUT_URL)
      console.log('logout respnse content:', logoutResp)
      this.$userStorage.removeCurrentUser()
      this.$loading.service({
        lock: true,
        text: 'Logging out Please wait...'
      })
      window.location.replace(logoutResp.data)
    },
    displaySelectedProject (projectRoles, optionID) {
      if (projectRoles === null || projectRoles === undefined) {
        return false
      }

      for (let i = 0; i < projectRoles.length; i++) {
        const projectRole = projectRoles[i]
        if (projectRole === null || projectRole === undefined) {
          continue
        }

        if (projectRole.project === null || projectRole.project === undefined) {
          continue
        }

        if (projectRole.project.id === optionID) {
          if (projectRole.role && projectRole.role.id) {
            this.registerBtnDisable = false
          }
          return true
        }
      }
      return false
    },
    userProjectValueChangeHandler (isChecked, checkedProjectID) {
      console.log('is checked', isChecked)
      console.log('check user project value: ', checkedProjectID)

      const projectRole = { project: { id: checkedProjectID } }

      const projectRoleArray = this.userForm.projectRoles
      if (projectRoleArray && this.userForm.projectRoles.length > 0) {
        const role = projectRoleArray[0].role
        if (role) {
          projectRole.role = { id: role.id }
        }

        for (let i = 0; i < projectRoleArray.length; i++) {
          const projectRoleItem = projectRoleArray[i]
          if (projectRoleItem.project && projectRoleItem.project.id === checkedProjectID) {
            if (!isChecked) {
              projectRoleArray.splice(i, 1)
              break
            }
            return
          }

          if (!projectRoleItem.project) {
            projectRoleArray.splice(i, 1)
          }
        }
      } else {
        this.userForm.projectRoles = []
      }

      if (isChecked) {
        this.userForm.projectRoles.push(projectRole)
      }
    },
    displaySelectedRole (projectRoles, optionID) {
      if (projectRoles === null || projectRoles === undefined || projectRoles.length === 0) {
        return ''
      }

      if (projectRoles[0] === null || projectRoles[0] === undefined) {
        return ''
      }

      if (projectRoles[0].role === null || projectRoles[0].role === undefined) {
        return ''
      }

      if (projectRoles[0].role.id === optionID) {
        if (projectRoles[0].project && projectRoles[0].project.id) {
          this.registerBtnDisable = false
        }
        return optionID
      }

      return ''
    },
    userRoleValueChangeHandler (checkedRoleID) {
      console.log('check user role value:', checkedRoleID)
      const projectRoleArray = this.userForm.projectRoles
      if (projectRoleArray && this.userForm.projectRoles.length > 0) {
        for (let i = 0; i < projectRoleArray.length; i++) {
          const projectRoleOBJ = {}

          projectRoleOBJ.role = {}
          projectRoleOBJ.role.id = checkedRoleID

          const projectRoleItem = projectRoleArray.splice(0, 1)

          if (projectRoleItem[0].project) {
            projectRoleOBJ.project = {}
            projectRoleOBJ.project.id = projectRoleItem[0].project.id
          }
          projectRoleArray.push(projectRoleOBJ)
        }
      } else {
        this.userForm.projectRoles = []
        const projectRole = { role: { id: checkedRoleID } }
        this.userForm.projectRoles.push(projectRole)
      }
    },
    submitRegistration () {
      // const { data: registerResp } = await this.$http.post('/register/submit', JSON.stringify(this.userForm), { headers: { 'content-type': 'application/json' } })
      this.$refs.registerForm.validate(async (checkResult) => {
        if (checkResult) {
          const { data: registerResp } = await this.$http.post(this.serverAPI.REGISTER_SUBMIT_URL, JSON.stringify(this.userForm), { headers: { 'content-type': 'application/json' } })
          console.log('user register response: ', registerResp)
          this.checkReponse(registerResp.data)
          if (registerResp.code === 1) {
            this.userForm = registerResp.data
            this.registerActive += 1

            this.$userStorage.setCurrentUser(registerResp.data)
          }
        }
      })
    },
    async reSubmitReg () {
      this.reSubmitBtnDisable = false
      console.log('resubmit the user registeration request userID=', this.userForm.id)
      const { data: resubmitResp } = await this.$http.get(this.serverAPI.RESUBMIT_REGISTER_URL + this.userForm.id)
      console.log('resubmit response content:', resubmitResp)
      window.location.reload()
    },
    resetUserForm () {
      this.$refs.registerForm.resetFields()
      this.iniUserForm()
      this.userProjectIDs = []
      this.userRoleID = ''
      this.registerBtnDisable = true
    },
    checkReponse (responseData) {
      console.log('check response content: ', responseData)
      try {
        if (typeof responseData === 'object') {
          return
        }
      } catch (error) {
        // current login user infor is null, relogin
        this.$alert('Login user information is expired. Please relogin.', 'Notification', {
          confirmButtonText: 'OK',
          type: 'warning',
          center: true,
          callback: action => {
            this.userLogout()
          }
        })
      }
    }
  }
}
</script>
<style lang="less" scoped>
.home-container {
  height: 100%;
}

.el-header {
  background-color: #373d41;
  display: flex;
  justify-content: space-between;
  padding-left: 0;
  align-items: center;
  color: #fff;
  font-size: 20px;
  > div {
    display: flex;
    align-items: center;
    span {
      margin-left: 15px;
      font-family: Arial, Helvetica, sans-serif;
    }
  }
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}

.bg-purple {
  background: #FFF;
}

</style>
