<template>
  <el-container class="home-container">
    <!--Header-->
    <el-header style="height: 120px; padding: 10px 0; display: block; background-color: white;">
      <el-row type="flex" align="middle" style="height: 100px;">
        <el-col :span="7">
          <a href="https://www.sfb1372.de/"><el-image :src="require('@/assets/sfb1372.png')" /></a>
        </el-col>
        <el-col :span="14" style="padding: 0 atuo;">
          <el-menu default-active="7" mode="horizontal">
            <el-menu-item index="1">
              <a href="https://www.sfb1372.de/">HOME</a>
              <!-- HOME -->
              </el-menu-item>
            <el-menu-item index="2">
              <a href="https://www.sfb1372.de/team">TEAM</a>
              <!-- TEAM -->
            </el-menu-item>
            <el-menu-item index="3">
              <a href="https://www.sfb1372.de/projects">PROJECTS</a>
              <!-- PROJECTS -->
            </el-menu-item>
            <el-menu-item index="4">
              <a href="https://www.sfb1372.de/publications">PUBLICATIONS</a>
              <!-- PUBLICATIONS -->
            </el-menu-item>
            <el-menu-item index="5">
              <a href="https://www.sfb1372.de/title">CAREER SUPPORT</a>
              <!-- CAREER SUPPORT -->
            </el-menu-item>
            <el-menu-item index="6">
              <a href="https://www.sfb1372.de/jobs-2">JOBS</a>
              <!-- JOBS -->
            </el-menu-item>
            <el-menu-item index="7">
              <a href="#">DMS</a>
              <!-- DMS -->
            </el-menu-item>
          </el-menu>
        </el-col>
        <el-col :span="3">
          <el-button type="text" class="el-icon-message-solid" style="font-size: 16px; color: black; padding-top: 0; padding-bottom: 9px;" @click="showFileMsg"></el-button>
          <el-badge v-show="notifications.length > 0" :value="notifications.length" class="item"/>
          <el-badge is-dot v-show="(notifications.length === 0 || !notifications) && (displayFileDot)"></el-badge>

          <el-dropdown trigger="click" :hide-on-click="false">
            <span class="el-dropdown-link" style="color: black; font-size: 16px;">
              {{currentUser.realName}}<i class="el-icon-caret-bottom el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <!-- <el-dropdown-item class="clearfix">
                Comments
                <el-badge v-show="notifications.length > 0" class="mark" :value="notifications.length" />
              </el-dropdown-item> -->
              <el-dropdown-item class="clearfix">
              <el-button type="text" style="font-size: 15px;" @click="showUserProfile">Profile</el-button>
              </el-dropdown-item>
              <!-- <el-dropdown-item class="clearfix">
                <el-button type="text" style="font-size: 15px;" @click="toIndex">Index</el-button>
              </el-dropdown-item> -->
              <el-dropdown-item class="clearfix">
                <el-button type="text" style="font-size: 15px; color: red" @click="userLogout">Logout</el-button>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </el-col>
      </el-row>
    </el-header>

    <el-scrollbar>
      <el-container>
        <!--Side Bar-->
        <el-aside :width="menuCollapse?'':'210px'" class="nave-menu">
          <el-row type="flex" justify="space-around">
            <el-tooltip class="item" effect="dark" :content="menuCollapse?'extend to the right':'collapse to the left'" placement="right-start">
              <el-link :class="menuCollapse?'el-icon-s-unfold':'el-icon-s-fold'" :underline="false" style="height: 36px; width: 36px; font-size: 20px;" @click="menuCollapse=!menuCollapse"></el-link>
            </el-tooltip>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-scrollbar>
                <el-menu background-color="#333744" text-color="#fff" active-text-color="#409EFF" router :default-active="activePath" width="100%" :collapse="menuCollapse" collapse-transition>
                  <div v-for="menuItem in menuData" :key="menuItem.menuID">
                    <el-menu-item v-if="!menuItem.subMenus || !menuItem.subMenus.length || ( menuItem.subMenus.length===1 && !menuItem.subMenus[0].menuID) " :index="menuItem.menuPath" @click="setCurrentPath(menuItem.menuPath)">
                      <!--first level menu-->
                      <!--first level menu icon-->
                      <i :class="menuItem.menuIcon"></i>
                      <template slot="title">
                        <!--first level menu name-->
                        <span slot="title"> {{menuItem.menuName}} </span>
                      </template>
                    </el-menu-item>
                    <el-submenu v-else :index="menuItem.menuID + ''">
                      <!--first level menu-->
                      <template slot="title">
                        <!--first level menu icon-->
                        <i :class="menuItem.menuIcon"></i>
                        <!--first level menu name-->
                        <span slot="title" v-show="!menuCollapse">{{menuItem.menuName}}</span>
                      </template>
                      <!--second level menu icon-->
                      <el-menu-item v-for="subMenuItem in menuItem.subMenus" :key="subMenuItem.menuID" :index="subMenuItem.menuPath" @click="setCurrentPath(subMenuItem.menuPath)">
                        <!--second level menu icon-->
                        <i :class="subMenuItem.menuIcon"></i>
                        <template slot="title">
                          <!--second level menu name-->
                          <span slot="title"> {{subMenuItem.menuName}} </span>
                        </template>
                      </el-menu-item>
                    </el-submenu>
                  </div>
                </el-menu>
              </el-scrollbar>
            </el-col>
          </el-row>
          <!--menu list-->
        </el-aside>

        <!--main content-->
        <el-container>
          <el-main>
            <!--all page linked by router in 'Home' Component will be displyed here-->
            <router-view></router-view>
            <!--- use the 'el-drawer' to show the file notification --->
            <el-drawer size="25%" title="File Notification" :visible.sync="drawer" direction="rtl" :before-close="handleClose">
              <div style="padding: 0 35px 0 15px;">
                <el-table :data="notifications" :show-header="false" v-show="notifications.length > 0? true: false" @row-click="tableRowClick">
                  <el-table-column label="message" :show-overflow-tooltip="true">
                    <template v-slot="notification">
                      <span v-if="notification.row.type === 'user'">
                        You have {{notification.row.msgContent}} user <span v-if="notification.row.msgContent > 1">applications</span><span v-else>application</span> to review, please click <el-button type="primary" size="mini" @click="toUserListPage(notification.row)">check</el-button>
                      </span>
                      <span v-else>
                        {{notification.row.msgContent}}
                      </span>
                      <el-badge is-dot class="item"></el-badge>
                    </template>
                  </el-table-column>
                  <el-table-column label="action" width="40" align="center">
                    <template v-slot="notification">
                      <i v-if="notification.row.type === 'file'" class="el-icon-close notifi-content-close" @click="removeUnreadedNotif(notification.row.fileUid)"></i>
                    </template>
                  </el-table-column>
                </el-table>
                <el-table :data="readedNotifs" :show-header="false" empty-text="No Any Notifications" v-show="readedNotifs.length > 0? true: notifications.length > 0 ? false : true">
                  <el-table-column label="message" prop="msgContent" :show-overflow-tooltip="true">
                    <template v-slot="readedNotif">
                      <span v-if="readedNotif.row.type === 'user'">
                        You have {{readedNotif.row.msgContent}} user <span v-if="readedNotif.row.msgContent > 1">applications</span><span v-else>application</span> to review, please click <el-button type="primary" size="mini" @click="toUserListPage(readedNotif.row)">check</el-button>
                      </span>
                      <span v-else>
                        {{readedNotif.row.msgContent}}
                      </span>
                    </template>
                  </el-table-column>
                  <el-table-column label="action" width="40" align="center">
                    <template v-slot="readedNotif">
                      <i v-if="readedNotif.row.type === 'file'" class="el-icon-close notifi-content-close" @click="removeNotif(readedNotif.row.fileUid)"></i>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </el-drawer>

          </el-main>
          <!-- <el-footer>Footer</el-footer> -->
        </el-container>
      </el-container>

      <el-backtop target=".el-scrollbar__wrap" :right="40" :bottom="140" :visibility-height="150"></el-backtop>
    </el-scrollbar>

    <el-dialog :visible.sync="profileDialogVisible" width="50%" :before-close="() => { this.profileDialogVisible = false }">
      <el-descriptions title="User Profile" direction="vertical" :column="3" border>
        <el-descriptions-item label="UserName">{{ currentUser.userName }}</el-descriptions-item>
        <el-descriptions-item label="RealName"> {{ currentUser.realName }} </el-descriptions-item>
        <el-descriptions-item label="Email"> {{ currentUser.userEmail }} </el-descriptions-item>
        <el-descriptions-item label="University" :span="2">
          <el-input v-model="currentUser.university"></el-input>
        </el-descriptions-item>
        <el-descriptions-item label="CreateTime">
          {{ currentUser.createTime | formatDate }}
        </el-descriptions-item>
        <el-descriptions-item label="Project Info">
          <el-table :data="currentUser.projectRoles" border stripe empty-text="-">
            <el-table-column label="Project" prop="project.projectName"></el-table-column>
            <el-table-column label="Role" prop="role.roleName"></el-table-column>
            <el-table-column label="Supervisor">
              <template v-slot:="slotProps">
                {{ slotProps.row.supervisor !== null? slotProps.row.supervisor.realName: '-' }}
              </template>
            </el-table-column>
            <el-table-column label="Status" prop="status.statusName"></el-table-column>
          </el-table>
        </el-descriptions-item>
      </el-descriptions>
      <span slot="footer" class="dialog-footer">
        <el-button @click="profileDialogVisible = false">Cancel</el-button>
        <el-button type="primary" @click="updateUserProfile">Update</el-button>
      </span>
    </el-dialog>

    <el-footer style="height: 70px; padding-bottom: 5px; padding-top: 5px; background-color: #2c3984;">
      <el-row type="flex" justify="center">
        <el-col>
          <div style="float: right; margin: 15px 7px;">
            <font color="#ffffff" style="caret-color: rgb(255, 255, 255); font-weight: 700; font-size: 1.2em; text-align:left;">
              <b>This project is funded by</b>
            </font>
          </div>
        </el-col>
        <el-col>
          <el-image style="max-width: 400px; padding: 3px 0;" :src="require('@/assets/dfg_logo.png')" fit="fill"></el-image>
        </el-col>
        <el-col>
          <el-row>
            <div style="float: left;">
              <font style="color: rgb(255, 255, 255); font-weight: 700; font-size: 1.2em; text-decoration: underline; text-align: right;" >
                <a runtime_url="/contact"  href="https://www.sfb1372.de/contact" style="color: rgb(255, 255, 255);">Contact</a>
              </font>
            </div>
          </el-row>
          <el-row>
            <div style="float: left; justify-content: center;">
              <font style="color: rgb(255, 255, 255); font-weight: 400; font-size: 1.2em; text-decoration: underline;">
                <a runtime_url="/impressum1" href="https://www.sfb1372.de/impressum1" style="color: rgb(255, 255, 255);">Impressum</a>
              </font>
            </div>
          </el-row>
        </el-col>
      </el-row>
    </el-footer>
  </el-container>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { EVENT_CONFIG } from '@/event_bus'
import { NOTIFICATION_LOCAL_STORAGE } from '../local_storage/NotificationLocalStorage'

const DETAULT_ACTIVE_PATH = '/data/list'
// const DETAULT_ACTIVE_PATH = '/home'

export default {
  name: 'Home',
  computed: {
    ...mapState({
      writeSwitch: (state) => state.writableStatus,
      routerActivePath: (state) => state.routerActivePath,
      notifications: (state) => state.NotificationModule.notifications
    })
  },
  data () {
    return {
      count: 1,
      activePath: DETAULT_ACTIVE_PATH,
      menuData: '',
      currentUser: {},
      drawer: false,
      profileDialogVisible: false,
      readedNotifs: [],
      displayFileDot: NOTIFICATION_LOCAL_STORAGE.getDotFlat(),
      webSocketClient: null,
      reconnectWebSocketFlag: false,
      menuCollapse: false
    }
  },
  watch: {
    routerActivePath (newValue, oldValue) {
      this.activePath = newValue
    },
    $route (to, from) {
      this.setRouterActivePath(to.path)
    }
  },
  destroyed () {
    window.removeEventListener('beforeunload', e => this.beforeunloadFn(e))
    this.clostWebSocket()
  },
  async created () {
    this.getSwitchStatus()
    const loadingInstance = this.$loading.service({
      lock: true,
      text: 'Loading...'
    })

    this.currentUser = await this.$userStorage.getCurrentUser()
    if (Object.keys(this.currentUser).length > 0) {
      loadingInstance.close()
    }

    console.debug('active path: %s router info: %s', this.activePath, this.$route)
    if (this.routerActivePath != null) {
      this.activePath = this.routerActivePath
      if (this.$route.path !== this.activePath) {
        this.$router.push(this.activePath)
      }
    }
    this.getNaviMenuTree()

    this.initWebSocket()

    EVENT_CONFIG.EventBus.$on(EVENT_CONFIG.RELOAD_NAVI_MENU_EVENT, this.getNaviMenuTree)
    window.addEventListener('beforeunload', e => this.beforeunloadFn(e))
  },
  beforeDestroy () {
    console.debug('Home.vue beforeDestory method executes.....')
    EVENT_CONFIG.EventBus.$off(EVENT_CONFIG.RELOAD_NAVI_MENU_EVENT)
  },
  methods: {
    ...mapMutations({
      switchWritableStatus: 'switchWritableStatus',
      setRouterActivePath: 'setRouterActivePath',
      removeRouterActivePath: 'removeRouterActivePath',
      emptyNotifications: 'NotificationModule/emptyNotifications',
      updateUserNotification: 'NotificationModule/updateUserNotification',
      removeUserNotification: 'NotificationModule/removeUserNotification',
      removeNotification: 'NotificationModule/removeNotification'
    }),
    async getSwitchStatus () {
      const { data: statusData } = await this.$http.get(this.serverAPI.MENU_SWITCH_STATUS_URL)
      console.log('home page get status menu: ', statusData)
      this.switchWritableStatus(statusData.data)
    },
    beforeunloadFn (e) {
      this.removeUserNotification()

      NOTIFICATION_LOCAL_STORAGE.addNotications(this.notifications)
      if (this.notifications !== null && this.notifications !== undefined && this.notifications.length > 0) {
        NOTIFICATION_LOCAL_STORAGE.setDot()
      }
    },
    setCurrentPath (currentPath) {
      if (this.activePath !== currentPath) {
        this.setRouterActivePath(currentPath)
        this.activePath = currentPath
      }
    },
    async getNaviMenuTree () {
      const { data: menuData } = await this.$http.get(this.serverAPI.USER_NAVIS_URL)
      console.debug('get menu tree data to init system menu', menuData)
      this.menuData = menuData.data
    },
    toIndex () {
      this.$router.push('/index')
    },
    async userLogout () {
      console.log('current window location:', window.location)
      // const locationHash = window.location.hash
      // const currentLocationPath = locationHash.replace('#', '')
      const currentLocationPath = window.location.pathname

      let toHome
      if (currentLocationPath !== DETAULT_ACTIVE_PATH) {
        toHome = await this.$router.push(DETAULT_ACTIVE_PATH)
        console.log('jump to home result:', toHome)
      }

      if (toHome || currentLocationPath === DETAULT_ACTIVE_PATH) {
        const logOutLoadingInst = this.$loading.service({
          lock: true,
          text: 'Logging out Please wait...'
        })
        // const { data: logoutResp } = await this.$http.get('logout')
        const { data: logoutResp } = await this.$http.get(this.serverAPI.USER_LOGOUT_URL)
        // remove the current login user from local session storage
        this.$userStorage.removeCurrentUser()
        this.clostWebSocket()
        this.reconnectWebSocketFlag = false
        this.removeRouterActivePath()
        window.location.replace(logoutResp.data)

        logOutLoadingInst.close()
      }
    },
    handleClose (done) {
      /* this.$confirm('确认关闭？')
        .then(_ => {
          done()
        })
        .catch(_ => {}) */
      const userNotification = this.notifications.find(notification => {
        return notification.type === 'user'
      })
      this.removeUserNotification()

      NOTIFICATION_LOCAL_STORAGE.addNotications(this.notifications)
      this.emptyNotifications()
      NOTIFICATION_LOCAL_STORAGE.hideDot()
      this.displayFileDot = NOTIFICATION_LOCAL_STORAGE.getDotFlat()
      this.drawer = false

      if (userNotification) {
        this.updateUserNotification(userNotification.msgContent)
      }
    },
    showFileMsg () {
      this.readedNotifs = NOTIFICATION_LOCAL_STORAGE.getNotifications()
      this.drawer = true
    },
    removeUnreadedNotif (fuid) {
      console.log('remove unreaded file notification fuid=', fuid)
      this.removeNotification(fuid)
    },
    removeNotif (fuid) {
      console.log('remove file notification fuid=', fuid)
      NOTIFICATION_LOCAL_STORAGE.removeNotification(fuid)
      this.readedNotifs = NOTIFICATION_LOCAL_STORAGE.getNotifications()
    },
    showUserProfile () {
      console.log('display the user profile dialog')
      this.profileDialogVisible = true
    },
    async updateUserProfile () {
      const updateJson = {}
      updateJson.userID = this.currentUser.id
      updateJson.university = this.currentUser.university
      const { data: updateResp } = await this.$http.post(this.serverAPI.USER_UPDATE_CURRENT_URL, this.$qs.stringify(updateJson))
      console.log('update user university response: ', updateResp)
      this.$userStorage.setCurrentUser(updateResp.data)
      this.currentUser = updateResp.data
    },
    tableRowClick (row, column, event) {
      console.log('table clicked row: ', row)
      if (row.type === 'user') {
        this.toUserListPage(row)
      }
    },
    toUserListPage (notificationItem) {
      console.log('notification item content: ', notificationItem)
      if (this.activePath !== '/user/list') {
        this.$router.push('/user/list')
      }
      this.setCurrentPath('/user/list')
      // clean the notification item
      this.removeUserNotification()

      this.drawer = false
    },
    initWebSocket () {
      this.webSocketClient = new WebSocket(this.serverAPI.WEB_SOCKET_URL + this.currentUser.id)
      // set the function of dealing with event of the websocket error
      this.webSocketClient.onerror = (event) => {
        console.log('web socket connection error：', event)
        console.log('re-connect the web socket', new Date())
        this.reconnectWebSocketFlag = true
        // this.reconnectWebSocket()
      }
      // set the function of dealing with the event of openning websocket connection
      this.webSocketClient.onopen = (event) => {
        console.log('web socket connection build response content from server:', event)
        this.reconnectWebSocketFlag = false
        this.pushWebSocketMessage()
      }
      // set the function of dealing with the event of receiveing the message from the server
      this.webSocketClient.onmessage = (event) => {
        console.log('message from server through the web socket conent：', event)
        this.parseWebSocketMessage(event.data)
      }
      // set the function of dealing with the event of closing the websocket connetion
      this.webSocketClient.onclose = (event) => {
        console.log('web socket connection is closed：', event)
      }
    },
    /**
     * this method is invoked while the web socket occured some errors
     * this will retry to build the connection util the connection is built successfully
     */
    reconnectWebSocket () {
      if (this.reconnectWebSocketFlag) {
        window.location.reload()
      }
    },
    clostWebSocket () {
      console.log('close the websocket client')
      if (this.webSocketClient) {
        this.webSocketClient.close()
      }
    },
    async pushWebSocketMessage () {
      const { data: pushResp } = await this.$http.get(this.serverAPI.USER_PUSH_NOTIFICATION_URL)
      console.log('request push user notification response content:', pushResp)
      if (pushResp.data) {
        this.updateUserNotification(pushResp.data)
      }
    },
    parseWebSocketMessage (message) {
      console.log('through web socket message: ', message)
      const msgObj = JSON.parse(message)
      console.log('message object:', msgObj)
      if (msgObj.data) {
        if (msgObj.data.msgType === 'user') {
          this.updateUserNotification(msgObj.data.msgValue)
        }
        // maybe there will be more other msgTypes
      }
    }
  }
}
</script>
<style scoped>
div >>> header.el-drawer__header {
  font-size: 16px;
  margin-bottom: 0;
  padding: 10px 10px 0;
  font-weight: bold;
}
div /deep/ div.el-scrollbar__wrap {
  overflow-x: hidden;
}
aside /deep/ .el-scrollbar__bar.is-vertical {
  opacity: 0;
}

aside /deep/ .el-scrollbar__view {
  margin-bottom: 15px;
}

header /deep/ li.el-menu-item {
  font-size: 18px;
  font-weight: 700;
}

header /deep/ ul.el-menu.el-menu--horizontal {
  border-bottom: none;
}

header /deep/ .el-menu--horizontal>.el-menu-item.is-active {
  border-bottom: none;
}

header /deep/ .is-active>a {
  border-bottom: solid #333;
}
</style>
<style lang="less" scoped>
.nave-menu {
  min-height: calc(100vh - 43px);
  padding-bottom: 30px;
}
.notifi-content-close:hover {
  cursor:pointer;
  color: red;
  font-weight: bold;
}
.home-container {
  height: 100%;
}

header {
  box-shadow: 0 5px 5px #d3d3d3;
  /* position: relative; */
  z-index: 10;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
}

.el-aside {
  background-color: #333744;
  /**Remove the extra border on the right side of the option**/
  .el-menu {
    border-right: none;
  }
}

.el-main {
  background-color: #eaedf1;
}

.el-menu {
  span {
    font-size: 16px;
    font-family: Arial, Helvetica, sans-serif;
  }
}
</style>
