const notificationModule = {
  namespaced: true,
  state: {
    notifications: []
  },
  mutations: {
    /**
     * @param {*} state
     * @param {*} fileNotification
     *                      : type 'file' means it is file related notification, 'user' means it is user related notification
     *                      : fileUid (optional): when the type property is 'file', it should not be null
     *                      : msgContent: the content of the notification
     */
    addNotification (state, fileNotification) {
      console.log('file notification:', fileNotification)
      state.notifications.push(fileNotification)
    },
    removeNotification (state, fileUID) {
      const matchedIndex = state.notifications.findIndex(notification => {
        return notification.fileUid === fileUID
      })

      if (matchedIndex >= 0) {
        state.notifications.splice(matchedIndex, 1)
      }
    },
    emptyNotifications (state) {
      state.notifications = []
    },
    updateUserNotification (state, count) {
      const userIndex = state.notifications.findIndex(notification => {
        return notification.type === 'user'
      })
      if (userIndex >= 0) {
        state.notifications[userIndex].msgContent = count
      } else {
        const msgObj = {
          type: 'user',
          msgContent: count
        }
        state.notifications.push(msgObj)
      }
    },
    removeUserNotification (state) {
      const userTypeNotiIndex = state.notifications.findIndex(notification => {
        return notification.type === 'user'
      })
      if (userTypeNotiIndex >= 0) {
        state.notifications.splice(userTypeNotiIndex, 1)
      }
    }
  }
}

export default notificationModule
