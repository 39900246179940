<template>
  <el-container style="height: 100%;">
    <el-header style="height: 120px; padding: 10px 0; display: block; background-color: white;">
      <el-row type="flex" align="middle" style="height: 100px;">
        <el-col :span="7">
          <a href="https://www.sfb1372.de/"><el-image :src="require('@/assets/sfb1372.png')" /></a>
        </el-col>
        <el-col :span="14" style="padding: 0 atuo;">
          <el-menu default-active="7" mode="horizontal">
            <el-menu-item index="1">
              <a href="https://www.sfb1372.de/">HOME</a>
              <!-- HOME -->
              </el-menu-item>
            <el-menu-item index="2">
              <a href="https://www.sfb1372.de/team">TEAM</a>
              <!-- TEAM -->
            </el-menu-item>
            <el-menu-item index="3">
              <a href="https://www.sfb1372.de/projects">PROJECTS</a>
              <!-- PROJECTS -->
            </el-menu-item>
            <el-menu-item index="4">
              <a href="https://www.sfb1372.de/publications">PUBLICATIONS</a>
              <!-- PUBLICATIONS -->
            </el-menu-item>
            <el-menu-item index="5">
              <a href="https://www.sfb1372.de/title">CAREER SUPPORT</a>
              <!-- CAREER SUPPORT -->
            </el-menu-item>
            <el-menu-item index="6">
              <a href="https://www.sfb1372.de/jobs-2">JOBS</a>
              <!-- JOBS -->
            </el-menu-item>
            <el-menu-item index="7">
              <a href="#">DMS</a>
              <!-- DMS -->
            </el-menu-item>
          </el-menu>
        </el-col>
        <el-col :span="3">
          <el-dropdown v-if="userInfo !== null" trigger="click" :hide-on-click="false">
            <span class="el-dropdown-link" style="font-size: 16px;">
              <i class="el-icon-user-solid"></i>
              {{userInfo.realName}}
              <i class="el-icon-caret-bottom el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item class="clearfix">
                <el-button type="text" style="font-size: 15px;" @click="toIndex">To DMS</el-button>
              </el-dropdown-item>
              <el-dropdown-item class="clearfix">
                <el-button type="text" style="font-size: 15px; color: red" @click="userLogout">Logout</el-button>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <el-link v-else icon="el-icon-s-promotion" style="font-size: 16px; float: left;" @click="loginDMS">Login DMS</el-link>
        </el-col>
      </el-row>
    </el-header>
    <el-scrollbar>
      <el-main class="open-paper-content" style="background-color: white;">
        <el-row style="margin-top: 10px;">
          <el-col :xl="{span: 14, offset: 5}" :md="{span: 20, offset: 2}" :sm="{span: 20, offset: 2}">
            <el-card>
              <div slot="header" class="clearfix">
                <span style="font-size: 24px;">Publications of SFB 1372</span>
              </div>
              <el-row>
                <el-col :offset="4" :sm="16" :md="16" :lg="17" :xl="17">
                  <el-row type="flex" justify="end">
                    <el-input clearable placeholder="Input the paper title to search" v-model="searchObj.paperTitle" @keyup.native.enter="queryOpenPapers" @clear="queryOpenPapers"></el-input>
                    <el-button type="primary" icon="el-icon-search" @keyup.enter="queryOpenPapers" @click="queryOpenPapers"></el-button>
                  </el-row>
                </el-col>
              </el-row>
              <el-row v-if="paperList && paperList.length > 0" type="flex" justify="end">
                <el-col :span="12" style="text-align: right; padding-right: 30px; color: #C0C4CC;">{{paginationInfo.totalCount}} results</el-col>
              </el-row>
              <el-row>
                <el-col :sm="5" :md="5" :lg="4" :xl="4">
                  <el-row>
                    <strong>Publish Year</strong>
                  </el-row>
                  <el-row>
                    <el-col :offset="2">
                      <el-row>
                        <el-link @click="queryOpenPapersClear" :style="(!searchObj.startYear && !searchObj.endYear && !customeFlag)?'color: #d14836;':''" :underline="!(!searchObj.startYear && !searchObj.endYear && !customeFlag)">Any time</el-link>
                      </el-row>
                      <el-row v-for="year in years" :key="year">
                        <el-link @click="queryOpenPapersYear(year)" :style="(searchObj.startYear === year && !searchObj.endYear && !customeFlag)?'color: #d14836;':''" :underline="!(searchObj.startYear === year && !searchObj.endYear && !customeFlag)"> Since {{year}} </el-link>
                      </el-row>
                      <el-row>
                        <el-link @click="showCustomeRange" :style="customeFlag?'color: #d14836;':''" :underline="!customeFlag">Custome range</el-link>
                        <div v-show="customeFlag">
                          <el-input-number size="mini" :controls="false" value="" v-model="searchObj.startYear" style="width: 35%;" placeholder="Start Year" @keyup.native.enter="querOpenPapersCustome"></el-input-number>
                          -
                          <el-input-number size="mini" :controls="false" value="" v-model="searchObj.endYear" style="width: 35%;" placeholder="End Year" @keyup.native.enter="querOpenPapersCustome"></el-input-number>
                        </div>
                      </el-row>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-row>
                      <strong>Project</strong>
                    </el-row>
                    <el-row>
                      <el-select clearable filterable size="mini" style="width: 84%;" v-model="selectedProject" placeholder="Select project to search" @change="queryOpenPapersProject">
                        <el-option v-for="project in projectList" :key="project.id" :label="project.projectName" :value="project.id"></el-option>
                      </el-select>
                    </el-row>
                  </el-row>
                  <el-row style="margin-bottom: 10px;">
                    <el-row>
                      <strong>Author</strong>
                    </el-row>
                    <el-row>
                      <el-input clearable size="mini" style="width: 84%;" placeholder="Input name to search" v-model="searchAuthor" @keyup.native.enter="queryOpenPapersAuthor" @clear="queryOpenPapersAuthor"></el-input>
                    </el-row>
                  </el-row>
                </el-col>
                <el-col :sm="19" :md="19" :lg="20" :xl="20">
                  <!-- <el-table border v-loading="paperListLoading" :data="paperList" :show-header="false" empty-text="No Publications Data" :span-method="columnSpanMethod"> -->
                  <el-table v-loading="paperListLoading" :data="paperList" :show-header="false" empty-text="No Publications Data">
                    <el-table-column>
                      <template v-slot="paperSlot">
                        <div style="font-size: 24px;">
                          <el-link style="font-size: 24px; color: #409EFF;" target="_blank" v-if="paperSlot.row.paperURL" :href="paperSlot.row.paperURL"> {{paperSlot.row.paperTitle}} </el-link>
                          <span v-else> {{paperSlot.row.paperTitle}} </span>
                        </div>

                        <div style="margin-bottom: 0px;">
                          <input type="checkbox" name="toggle" :id="'toggle' + paperSlot.row.id" style="display: none;" :data-paper-id="paperSlot.row.id" @change="checked => toggleAuthorContentHandler(checked, paperSlot.row.id)">
                          <p :id="'author' + paperSlot.row.id" :data-paper-id="paperSlot.row.id">
                            {{paperSlot.row.paperRelatedProjectAuthors}}
                          </p>
                          <label class="author-toggle-label" :for="'toggle' + paperSlot.row.id" style="display: none; float: right;">more</label>
                        </div>

                        <div>
                          <span>
                            <span v-if="paperSlot.row.paperType.typeCode === 1">
                              {{'-' + paperSlot.row.paperRelatedJson.paperJournal}}
                            </span>
                            <span v-if="paperSlot.row.paperType.typeCode === 2">
                              {{'-' + paperSlot.row.paperRelatedJson.paperBookISBN}}
                            </span>
                            <span v-if="paperSlot.row.paperType.typeCode === 3">
                              {{'-' + paperSlot.row.paperRelatedJson.paperBookTitle}}
                            </span>
                          </span>

                          ({{paperSlot.row.paperYear}}) <span v-if="paperSlot.row.paperMonth && paperSlot.row.paperMonth >= 10">{{paperSlot.row.paperMonth}}</span><span v-if="paperSlot.row.paperMonth && paperSlot.row.paperMonth < 9">{{'0' + paperSlot.row.paperMonth}}</span>

                          <span>
                            <span v-if="paperSlot.row.paperType.typeCode === 1">
                              {{paperSlot.row.paperRelatedJson.paperVolume}}
                              {{paperSlot.row.paperRelatedJson.paperPageRange}}
                            </span>
                            <span v-if="paperSlot.row.paperType.typeCode === 2">
                              {{paperSlot.row.paperRelatedJson.paperBookPublisher}}
                              {{paperSlot.row.paperRelatedJson.paperVolume}}
                            </span>
                            <span v-if="paperSlot.row.paperType.typeCode === 3">
                              {{paperSlot.row.paperRelatedJson.paperBookPublisher}}
                              {{paperSlot.row.paperRelatedJson.paperVolume}}
                              {{paperSlot.row.paperRelatedJson.paperPageRange}}
                            </span>
                          </span>

                          <span v-if="paperSlot.row.paperDOI"> doi: <el-link target="_blank" :href="`https://doi.org/${paperSlot.row.paperDOI}`" :underline="false" style="color: #409EFF;"> {{paperSlot.row.paperDOI}} </el-link></span>
                          <span v-for="(project, index) in paperSlot.row.paperRelatedProject" :key="project.id">
                            <span v-if="index === 0">{{ ' ' + project.projectName }}</span>
                            <span v-else>{{ ', ' + project.projectName }}</span>
                          </span>
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column width="150px" align="center">
                      <template v-slot="paperSlot">
                        <el-dropdown trigger="click" :hide-on-click="false" v-if="paperSlot.row.paperRelatedJson.downloadSwitch && paperSlot.row.paperFile && paperSlot.row.paperFile.length > 0">
                          <span class="el-dropdown-link" style="color: #409EFF; font-size: 16px; font-weight: 500;">
                            Download File <i class="el-icon-caret-bottom el-icon--right"></i>
                          </span>
                          <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item class="clearfix" v-for="file in paperSlot.row.paperFile" :key="file.id">
                              <el-button type="text" style="font-size: 15px;" @click="paperFileDownload(paperSlot.row.id, file.id)">{{file.fileName}}</el-button>
                            </el-dropdown-item>
                          </el-dropdown-menu>
                        </el-dropdown>
                      </template>
                    </el-table-column>
                  </el-table>

                  <el-pagination style="float: right; margin-bottom: 10px;"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="paginationInfo.currentPage"
                    :page-sizes="[5, 10, 20, 30, 50]"
                    :page-size="paginationInfo.pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="paginationInfo.totalCount">
                  </el-pagination>
                </el-col>
              </el-row>
            </el-card>
          </el-col>
        </el-row>
      </el-main>
      <el-footer style="height: 196px; padding: 40px; background-color: #2c3984;">
        <el-row type="flex" justify="center">
          <el-col :lg="12">
            <div style="float: left; margin: 8px 0; padding: 2px 0;">
              <font color="#ffffff" style="caret-color: rgb(255, 255, 255); font-weight: 700; font-size: 1.2em; text-align:left;">
                <b>This project is funded by</b>
              </font>
            </div>
            <div style="float: right;">
              <font style="color: rgb(255, 255, 255); font-weight: 700; font-size: 1.2em; text-decoration: underline; text-align: right;" >
                <a runtime_url="/contact"  href="https://www.sfb1372.de/contact" style="color: rgb(255, 255, 255);">Contact</a>
              </font>
            </div>
          </el-col>
        </el-row>
        <el-row type="flex" align="middle" justify="center">
          <el-col :lg="12">
            <div style="float: left;">
              <el-image style="max-width: 466px;" :src="require('@/assets/dfg_logo.png')" fit="fill"></el-image>
            </div>
            <div style="float: right; justify-content: center;">
              <font style="color: rgb(255, 255, 255); font-weight: 400; font-size: 1.2em; text-decoration: underline;">
                <a runtime_url="/impressum1" href="https://www.sfb1372.de/impressum1" style="color: rgb(255, 255, 255);">Impressum</a>
              </font>
            </div>
          </el-col>
        </el-row>
      </el-footer>

      <el-backtop target=".el-scrollbar__wrap" :visibility-height="150" :right="50" :bottom="200"></el-backtop>
    </el-scrollbar>
  </el-container>
</template>
<script>
import { USER_LOCAL_STORAGE } from '@/local_storage/UserLocalStorage'

export default {
  async created () {
    this.initPage()

    const currentYear = new Date().getFullYear()
    this.years.push(currentYear)
    this.years.push(currentYear - 1)
    this.years.push(currentYear - 4)

    const currentUser = USER_LOCAL_STORAGE.getCurrentUserStr()
    console.log('current login user str content:', currentUser)
    if (currentUser) {
      this.userInfo = JSON.parse(currentUser)
    }
    await this.queryOpenPapers()

    const paperAuthorConentList = document.querySelectorAll('p')
    paperAuthorConentList.forEach(paperAuthorContent => {
      paperAuthorContent.classList[paperAuthorContent.scrollHeight > paperAuthorContent.offsetHeight ? 'add' : 'remove']('truncated')

      if (paperAuthorContent.classList.contains('truncated')) {
        const paperID = paperAuthorContent.dataset.paperId
        const labelEle = document.querySelector('label[for=toggle' + paperID + ']')
        labelEle.style.removeProperty('display')
      }
    })

    this.paperListLoading = false
  },
  data () {
    return {
      paperListLoading: true,
      userInfo: null,
      paperList: [],
      projectList: [],
      selectedProject: null,
      searchAuthor: null,
      years: [],
      customeFlag: false,
      searchObj: {
        startYear: null,
        endYear: null,
        projectID: null,
        authorName: null,
        paperTitle: null
      },
      paginationInfo: {
        currentPage: 1,
        pageSize: 10,
        totalCount: 0
      }
    }
  },
  methods: {
    async initPage () {
      const { data: paperInitResp } = await this.$http.get(this.serverAPI.PAPER_PUBLIC_INIT_URL)
      console.log('init public paper response: ', paperInitResp)
      this.projectList = paperInitResp.data
    },
    loginDMS () {
      this.$router.push('/home')
    },
    toIndex () {
      this.$router.push('/home')
    },
    async userLogout () {
      const logOutLoadingInst = this.$loading.service({
        lock: true,
        text: 'Logging out Please wait...'
      })
      // const { data: logoutResp } = await this.$http.get('logout')
      const { data: logoutResp } = await this.$http.get(this.serverAPI.USER_LOGOUT_URL)
      // remove the current login user from local session storage
      this.$userStorage.removeCurrentUser()
      window.location.replace(logoutResp.data)

      logOutLoadingInst.close()
    },
    handleSizeChange (newPageSize) {
      this.paginationInfo.pageSize = newPageSize
      this.queryOpenPapers()
    },
    handleCurrentChange (currentPage) {
      this.paginationInfo.currentPage = currentPage
      this.queryOpenPapers()
    },
    showCustomeRange () {
      this.customeFlag = true
      this.searchObj.startYear = undefined
      this.searchObj.endYear = undefined
    },
    queryOpenPapersClear () {
      this.customeFlag = false
      this.searchObj.startYear = undefined
      this.searchObj.endYear = undefined
      this.queryOpenPapers()
    },
    queryOpenPapersYear (year) {
      this.searchObj.startYear = year
      this.searchObj.endYear = undefined
      this.queryOpenPapers()
    },
    querOpenPapersCustome () {
      if (this.searchObj.startYear > this.searchObj.endYear) {
        this.searchObj.startYear = this.searchObj.startYear + this.searchObj.endYear
        this.searchObj.endYear = this.searchObj.startYear - this.searchObj.endYear
        this.searchObj.startYear = this.searchObj.startYear - this.searchObj.endYear
      }

      if (this.searchObj.startYear > new Date().getFullYear()) {
        this.searchObj.startYear = new Date().getFullYear()
      }

      if (this.searchObj.endYear > new Date().getFullYear()) {
        this.searchObj.endYear = new Date().getFullYear()
      }

      if (this.searchObj.startYear < 2017) {
        this.searchObj.startYear = 2017
      }

      if (this.searchObj.endYear < 2017) {
        this.searchObj.endYear = 2017
      }

      this.queryOpenPapers()
    },
    queryOpenPapersAuthor () {
      this.searchObj.authorName = this.searchAuthor
      if (this.customeFlag && !this.searchObj.startYear && !this.searchObj.endYear) {
        this.customeFlag = false
      }
      this.queryOpenPapers()
    },
    queryOpenPapersProject () {
      this.searchObj.projectID = this.selectedProject
      if (this.customeFlag && !this.searchObj.startYear && !this.searchObj.endYear) {
        this.customeFlag = false
      }
      this.queryOpenPapers()
    },
    async queryOpenPapers () {
      this.paperListLoading = true
      console.log('query open paper list, searchObj: ', this.searchObj)

      const urlParams = this.$qs.stringify(this.paginationInfo)
      const { data: papersResp } = await this.$http.post(this.serverAPI.PAPER_PUBLIC_URL + '?' + urlParams, JSON.stringify(this.searchObj), { headers: { 'content-type': 'application/json' } })
      console.log('get public paper list response: ', papersResp)

      this.paperList = papersResp.data.records
      this.paginationInfo.currentPage = papersResp.data.current
      this.paginationInfo.pageSize = papersResp.data.size
      this.paginationInfo.totalCount = papersResp.data.total

      this.paperListLoading = false
    },
    toggleAuthorContentHandler (checked, paperID) {
      console.log('checked: ', checked)
      console.log('paperID: ', paperID)
      const authorEle = document.getElementById('author' + paperID)
      const labelEle = document.querySelector('label[for=toggle' + paperID + ']')

      if (checked.srcElement.checked) {
        authorEle.style.setProperty('-webkit-line-clamp', 'unset')
        labelEle.innerText = 'less'
      } else {
        authorEle.style.setProperty('-webkit-line-clamp', '2')
        labelEle.innerText = 'more'
      }
    },
    columnSpanMethod ({ row, column, rowIndex, columnIndex }) { // method to span the column of the table
      console.log('public paper column: ', row)
      if (!(row.paperRelatedJson.downloadSwitch && row.paperFile && row.paperFile.length > 0)) {
        if (columnIndex === 0) {
          return [1, 2]
        }
      }
    },
    paperFileDownload (paperID, fileID) {
      console.log(paperID, fileID)
      const beforeunloadFunc = window.onbeforeunload
      console.debug('FileFormField.vue beforeunload function:', beforeunloadFunc)
      window.onbeforeunload = null
      window.location.href = this.serverAPI.PAPER_PUBLIC_FILE_URL + fileID + '/' + paperID
      window.onbeforeunload = beforeunloadFunc
    }
  }
}
</script>

<style lang="less" scoped>
.open-paper-content {
  min-height: calc(100vh - 300px);
  overflow-y: hidden;
}
</style>

<style scoped>
.author-toggle-label {
  font-weight: 700;
  color: #409eff;
}

.author-toggle-label:hover {
  font-weight: 700;
  cursor: pointer;
  color: #409eff;
  text-decoration: underline;
}

p {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

header {
  box-shadow: 0 5px 5px #d3d3d3;
  /* position: relative; */
  z-index: 10;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
}

.el-main {
  background-color: #E9EEF3;
  color: #333;
}

div /deep/ ul.el-menu.el-menu--horizontal {
  border-bottom: none;
}

div /deep/ li.el-menu-item {
  font-size: 18px;
  font-weight: 700;
}

div /deep/ .el-menu--horizontal>.el-menu-item.is-active {
  border-bottom: none;
}

div /deep/ .is-active>a {
  border-bottom: solid #333;
}

div /deep/ .el-menu-item, .el-submenu__title {
  padding: 0 15px;
}

div /deep/ div.el-scrollbar__wrap {
  overflow-x: hidden;
}

div /deep/ div.el-card__header {
  background-color: white;
  border-bottom: none;
}

div /deep/ .el-card.is-always-shadow {
  box-shadow: none;
}

div /deep/ div.el-card {
  border: none;
  box-shadow: none!important;
}

div /deep/ div.el-card__body {
  padding-bottom: 0;
}

div /deep/ div.el-scrollbar__wrap {
  overflow-x: hidden;
}

div /deep/ .el-table td.el-table__cell, .el-table th.el-table__cell.is-leaf {
  border-bottom: none;
}

div /deep/ .el-table::before {
  height: 0;
}

div /deep/ .el-table {
  margin-top: 0;
}
</style>
