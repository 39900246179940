<template>
  <section>
    <el-row type="flex" class="field-title-container" @click.native="shiftRelatedHistoryFlag" justify="start" align="middle">
      <el-col :span="7" class="field-title">Publication History List</el-col>
      <el-col class="field-title-button">
        <el-button type="text" style="padding-left: 9px; padding-right: 5px; font-weight: bold;" @click.stop="shiftRelatedHistoryFlag"> {{relatedHistoryFlag? 'Hidde': 'More '}}<i :class="relatedHistoryFlag? 'el-icon-arrow-down' : 'el-icon-arrow-right'"/></el-button>
      </el-col>
    </el-row>
    <el-collapse-transition>
      <div v-show="relatedHistoryFlag">
        <el-card style="border-top-left-radius: 0; border-top-right-radius: 0;">
          <el-tag class="history-name" v-for="(history, index) in relatedHistory" :key="index" @click="paperHistoryDetail(history.relatedPaperID, history.id)">
            Version: {{history.version}} CreateTime: {{history.createTime | formatDate}}
          </el-tag>
        </el-card>
      </div>
    </el-collapse-transition>
  </section>
</template>
<script>
export default {
  props: ['relatedHistory'],
  data () {
    return {
      relatedHistoryFlag: false
    }
  },
  watch: {
    relatedHistory (newValue) {
      if (newValue && newValue.length > 0) {
        this.relatedHistoryFlag = true
      }
    }
  },
  methods: {
    shiftRelatedHistoryFlag () {
      this.relatedHistoryFlag = !this.relatedHistoryFlag
    },
    paperHistoryDetail (paperID, historyID) {
      this.$router.push({
        path: `/paper/history/${paperID}/${historyID}`
      })
    }
  }
}
</script>
<style scoped>
.history-name {
  width: 270px;
  margin-right: 5px;
  margin-bottom: 3px;
}
.history-name:hover {
  cursor: pointer;
  text-decoration: underline;
}
.field-title-container {
  border: solid 1px #d7dae2;
  border-bottom-width: 0;
  border-style:solid none;
  background-color: #F5F7FA;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.field-title-container {
  cursor: pointer;
}
.field-title {
  font-weight: bold;
  font-size: 16px;
  color: #303133;
  line-height: 48px;
  padding-left: 20px;
}
.field-title-button {
  display: flex;
  justify-content: flex-end;
  padding-right: 15px;
}
div /deep/ .el-collapse-item__header {
  font-size: 16px;
  font-weight: bold;
  padding-left: 20px;
  background-color: #F5F7FA;
}
div /deep/ .el-card__header {
  padding-top: 15px;
  padding-bottom: 5px;
  background-color: #F5F7FA;
}
div /deep/ .el-collapse-item__content {
  padding-bottom: 0;
}
</style>
