<template>
  <el-container style="height: 100%;">
    <el-header style="height: 120px; padding: 10px 0; display: block; background-color: white;">
      <el-row type="flex" align="middle" style="height: 100px;">
        <el-col :span="7">
          <a href="https://www.sfb1372.de/"><el-image :src="require('@/assets/sfb1372.png')" /></a>
        </el-col>
        <el-col :span="14" style="padding: 0 atuo;">
          <el-menu default-active="7" mode="horizontal">
            <el-menu-item index="1">
              <a href="https://www.sfb1372.de/">HOME</a>
              <!-- HOME -->
              </el-menu-item>
            <el-menu-item index="2">
              <a href="https://www.sfb1372.de/team">TEAM</a>
              <!-- TEAM -->
            </el-menu-item>
            <el-menu-item index="3">
              <a href="https://www.sfb1372.de/projects">PROJECTS</a>
              <!-- PROJECTS -->
            </el-menu-item>
            <el-menu-item index="4">
              <a href="https://www.sfb1372.de/publications">PUBLICATIONS</a>
              <!-- PUBLICATIONS -->
            </el-menu-item>
            <el-menu-item index="5">
              <a href="https://www.sfb1372.de/title">CAREER SUPPORT</a>
              <!-- CAREER SUPPORT -->
            </el-menu-item>
            <el-menu-item index="6">
              <a href="https://www.sfb1372.de/jobs-2">JOBS</a>
              <!-- JOBS -->
            </el-menu-item>
            <el-menu-item index="7">
              <a href="#">DMS</a>
              <!-- DMS -->
            </el-menu-item>
          </el-menu>
        </el-col>
        <el-col :span="3">
          <el-dropdown v-if="userInfo !== null" trigger="click" :hide-on-click="false">
            <span class="el-dropdown-link" style="font-size: 16px;">
              <i class="el-icon-user-solid"></i>
              {{userInfo.realName}}
              <i class="el-icon-caret-bottom el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item class="clearfix">
                <el-button type="text" style="font-size: 15px;" @click="toIndex">To DMS</el-button>
              </el-dropdown-item>
              <el-dropdown-item class="clearfix">
                <el-button type="text" style="font-size: 15px; color: red" @click="userLogout">Logout</el-button>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <el-link v-else icon="el-icon-s-promotion" style="font-size: 16px; float: left;" @click="loginDMS">Login DMS</el-link>
        </el-col>
      </el-row>
    </el-header>
    <el-scrollbar>
      <el-main class="open-paper-content" style="background-color: white;">
        <section v-if="staticalData">
          <el-row type="flex" justify="center" style="font-size: 20px;">
            At present, there are &nbsp;<span style="font-weight: bold;"> {{ staticalData.dataCount }} </span> &nbsp; rows of experimental data in the DMS,
          </el-row>
          <el-row type="flex" justify="center" style="font-size: 20px;">
            and a total of &nbsp;<span style="font-weight: bold;">{{ staticalData.publicationCount }}</span>&nbsp; publications, including &nbsp;<span style="font-weight: bold;">{{ staticalData.publishedCount }}</span>&nbsp; public publications,
          </el-row>
          <el-row type="flex" justify="center" style="font-size: 20px;">
            the number of the files in the DMS is &nbsp;<span style="font-weight: bold;">{{ staticalData.fileCount }}</span>.
          </el-row>
        </section>
        <el-row v-if="userInfo !== null" style="margin-top: 10px;" type="flex" justify="center">
          <h1>You can click <el-link href="/">here</el-link> to the DMS.</h1>
        </el-row>
        <el-row v-else style="margin-top: 10px;" type="flex" justify="center">
          <h1>You are not logging into the DMS, please click <el-link href="/">here</el-link> to log in.</h1>
        </el-row>
        <el-row style="margin-top: 10px;" type="flex" justify="center">
          <h1>You can click <el-link href="/publications/">here</el-link> to go to the publication list in the DMS.</h1>
        </el-row>
      </el-main>
      <el-footer style="height: 196px; padding: 40px; background-color: #2c3984;">
        <el-row type="flex" justify="center">
          <el-col :lg="12">
            <div style="float: left; margin: 8px 0; padding: 2px 0;">
              <font color="#ffffff" style="caret-color: rgb(255, 255, 255); font-weight: 700; font-size: 1.2em; text-align:left;">
                <b>This project is funded by</b>
              </font>
            </div>
            <div style="float: right;">
              <font style="color: rgb(255, 255, 255); font-weight: 700; font-size: 1.2em; text-decoration: underline; text-align: right;" >
                <a runtime_url="/contact"  href="https://www.sfb1372.de/contact" style="color: rgb(255, 255, 255);">Contact</a>
              </font>
            </div>
          </el-col>
        </el-row>
        <el-row type="flex" align="middle" justify="center">
          <el-col :lg="12">
            <div style="float: left;">
              <el-image style="max-width: 466px;" :src="require('@/assets/dfg_logo.png')" fit="fill"></el-image>
            </div>
            <div style="float: right; justify-content: center;">
              <font style="color: rgb(255, 255, 255); font-weight: 400; font-size: 1.2em; text-decoration: underline;">
                <a runtime_url="/impressum1" href="https://www.sfb1372.de/impressum1" style="color: rgb(255, 255, 255);">Impressum</a>
              </font>
            </div>
          </el-col>
        </el-row>
      </el-footer>

      <el-backtop target=".el-scrollbar__wrap"></el-backtop>
    </el-scrollbar>
  </el-container>
</template>
<script>
import { USER_LOCAL_STORAGE } from '@/local_storage/UserLocalStorage'

export default {
  async created () {
    const currentUser = USER_LOCAL_STORAGE.getCurrentUserStr()
    console.log('current login user str content:', currentUser)
    if (currentUser) {
      this.userInfo = JSON.parse(currentUser)
      this.$router.push('/')
    } else {
      this.getIndexData()
    }
  },
  filters: {
    fileSizeFormat (fileSize) {
      console.debug('file size %s', fileSize)
      if (fileSize === null) return '-'
      if (fileSize < 1024) {
        // lower than 1024, the unit is 'Bytes'
        return fileSize + ' Bytes'
      }
      if (fileSize < (1024 * 1024)) {
        // bigger than 1024 and smaller than 1024*1024, the unit is 'KB'
        const _kb = (fileSize / 1024).toFixed(2)
        return parseFloat(_kb) + ' KB'
      }

      if (fileSize < (1024 * 1024 * 1024)) {
        // bigger than 1024*1024 and smaller than 1024*1024*1024, the unit is 'MB'
        const _mb = (fileSize / (1024 * 1024)).toFixed(2)
        return parseFloat(_mb) + ' MB'
      }

      const _gb = (fileSize / (1024 * 1024 * 1024)).toFixed(2)
      return parseFloat(_gb) + ' GB'
    }
  },
  data () {
    return {
      userInfo: null,
      staticalData: null
    }
  },
  methods: {
    async getIndexData () {
      const { data: indexResp } = await this.$http.get(this.serverAPI.OPEN_STATICS_URL)
      console.log('open index data response: ', indexResp)
      this.staticalData = indexResp.data
    },
    loginDMS () {
      this.$router.push('/home')
    },
    toIndex () {
      this.$router.push('/home')
    },
    async userLogout () {
      const logOutLoadingInst = this.$loading.service({
        lock: true,
        text: 'Logging out Please wait...'
      })
      // const { data: logoutResp } = await this.$http.get('logout')
      const { data: logoutResp } = await this.$http.get(this.serverAPI.USER_LOGOUT_URL)
      // remove the current login user from local session storage
      this.$userStorage.removeCurrentUser()
      window.location.replace(logoutResp.data)

      logOutLoadingInst.close()
    }
  }
}
</script>

<style lang="less" scoped>
.open-paper-content {
  min-height: calc(100vh - 300px);
  overflow-y: hidden;
}
</style>

<style scoped>

h1 {
  display: block;
  font-size: 2em;
  margin-block-start: 0.67em;
  margin-block-end: 0.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

h1 > a {
  font-size: 100%;
  font-weight: bold;
}

h1 /deep/ .el-link.el-link--default {
  color: #409EFF;
}

header {
  box-shadow: 0 5px 5px #d3d3d3;
  /* position: relative; */
  z-index: 10;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
}

.el-main {
  background-color: #E9EEF3;
  color: #333;
}

div /deep/ ul.el-menu.el-menu--horizontal {
  border-bottom: none;
}

div /deep/ li.el-menu-item {
  font-size: 18px;
  font-weight: 700;
}

div /deep/ .el-menu--horizontal>.el-menu-item.is-active {
  border-bottom: none;
}

div /deep/ .is-active>a {
  border-bottom: solid #333;
}

div /deep/ .el-menu-item, .el-submenu__title {
  padding: 0 15px;
}

div /deep/ div.el-scrollbar__wrap {
  overflow-x: hidden;
}

div /deep/ div.el-card__header {
  background-color: white;
  border-bottom: none;
}

div /deep/ .el-card.is-always-shadow {
  box-shadow: none;
}

div /deep/ div.el-card {
  border: none;
  box-shadow: none!important;
}

div /deep/ div.el-card__body {
  padding-bottom: 0;
}

div /deep/ div.el-scrollbar__wrap {
  overflow-x: hidden;
}

div /deep/ .el-table td.el-table__cell, .el-table th.el-table__cell.is-leaf {
  border-bottom: none;
}

div /deep/ .el-table::before {
  height: 0;
}

div /deep/ .el-table {
  margin-top: 0;
}
</style>
