<template>
  <div>
    <!--Breadcrumb navigation area-->
    <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/home' }">Home</el-breadcrumb-item>
        <el-breadcrumb-item>Data Module</el-breadcrumb-item>
        <el-breadcrumb-item>Data List</el-breadcrumb-item>
    </el-breadcrumb>
    <el-row>
      <span v-show="searchForm.dataProject.length > 0? true:false" style="margin-right: 10px;">
        <b>Project:</b>
        <el-tag v-for="projectItem in searchForm.dataProject" :key="projectItem.id" closable style="margin-left: 3px;" @close="criteriaTagClose('dataProject', 'id', projectItem.id)">{{projectItem.projectName}}</el-tag>
      </span>
      <span v-show="(searchForm.projectPI !== null && searchForm.projectPI !== undefined && Object.keys(searchForm.projectPI).length > 0)? true: false" style="margin-right: 10px;">
        <b>ProjectPI:</b>
        <el-tag closable style="margin-left: 3px;" @close="criteriaTagClose('projectPI', 'id', searchForm.projectPI.id)">{{searchForm.projectPI.realName}}</el-tag>
      </span>
      <span v-show="(searchForm.experimenter !== null && searchForm.experimenter !== undefined && Object.keys(searchForm.experimenter).length > 0)? true: false" style="margin-right: 10px;">
        <b>Experimenter:</b>
        <el-tag closable style="margin-left: 3px;" @close="criteriaTagClose('experimenter', 'id', searchForm.experimenter.id)">{{searchForm.experimenter.realName}}</el-tag>
      </span>
      <span v-show="(searchForm.uploader !== null && searchForm.uploader !== undefined && Object.keys(searchForm.uploader).length > 0)? true: false" style="margin-right: 10px;">
        <b>Uploader:</b>
        <el-tag closable style="margin-left: 3px;" @close="criteriaTagClose('uploader', 'id', searchForm.uploader.id)">{{searchForm.uploader.realName}}</el-tag>
      </span>
    </el-row>

    <el-card>
      <el-row type="flex" justify="center" align="middle">
          <el-col style="display: flex; justify-content: flex-start;">
              <el-button type="primary" @click="toDataAddPage" v-if="writeSwitch">Add Data</el-button>
          </el-col>
          <el-col :span="2" style="display: flex; justify-content: flex-end; margin-right: 5px;">
            <el-button type="primary" @click="criteriaDialog">Search Criteria</el-button>
          </el-col>
          <el-col :span="6" style="display: flex; justify-content: flex-end;">
          <el-input v-model="dataSearchKey" clearable @change="searchDataHandler" placeholder="Input the data title to search">
            <el-button slot="append" icon="el-icon-search" @click="searchDataHandler"></el-button>
          </el-input>
        </el-col>
      </el-row>
      <el-table :data="dataList" :stripe="true" :border="true" :highlight-current-row="true">
        <el-table-column type="index" label="#" align="center" :index="indexDataRow"></el-table-column>
        <el-table-column prop="title" label="Data Title" align="center" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="project.projectName" label="Project" align="center"></el-table-column>
        <el-table-column prop="author.realName" label="Experimenter" align="center"></el-table-column>
        <el-table-column prop="responsiblePI.realName" label="PI" align="center"></el-table-column>
        <el-table-column prop="creator.realName" label="Uploader" align="center"></el-table-column>
        <el-table-column label="Update Time" align="center">
          <template v-slot="dataSlot">
            {{dataSlot.row.updateTime | formatDate}}
          </template>
        </el-table-column>
        <el-table-column label="Action" align="center" width="100px">
          <template v-slot:="slotProps">
            <el-button type="primary" :icon="slotProps.row.creator.userName === currentUser.userName ? 'el-icon-edit': 'el-icon-view'" size="mini" @click="toDetail(slotProps.row.dataID)"></el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination style="float: right; margin-bottom: 10px;"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="paginationInfo.currentPage"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="paginationInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="paginationInfo.totalCount">
      </el-pagination>
    </el-card>

    <el-dialog title="Search Criteria" width="45%" :visible.sync="searchDialogVisible" @close="searchDialogClose" :before-close="confirmCloseDialog">
      <el-form :model="criteriaForm" ref="criteriaFormRef" label-width="130px" label-position="top">
        <el-row>
          <el-form-item label="Project" prop="dataProject">
            <el-checkbox :value="criteriaForm.dataProject.map(item => item.projectName)" v-for="dataProject in dataProjectCriteria" :key="dataProject.id" :label="dataProject.projectName" @change="checkedDataProjectChangeHandler($event, dataProject)" :checked="checkedDataProject(dataProject.id)"></el-checkbox>
          </el-form-item>
        </el-row>
        <el-row type="flex" justify="start" align="middle">
          <el-col :span="12" style="margin-right: 8px;">
            <el-form-item label="PI" prop="dataPI">
              <el-select :value="criteriaForm.projectPI.id" style="width: 100%;" filterable clearable placeholder="principal investigator" @change="selectedPIChangeHandler">
                <el-option v-for="projectRelatedPI in projectRelatedPICriteria" :key="projectRelatedPI.id" :label="projectRelatedPI.realName" :value="projectRelatedPI.id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12" style="margin-left: 8px;">
            <el-form-item label="Experimenter" prop="experimenter">
              <el-select :value="criteriaForm.experimenter.id" style="width: 100%;" filterable clearable placeholder="the person of doing the experiment corresponding to the data" @change="selectedUserChangeHandler">
                <el-option v-for="projectRelatedUser in projectRelatedUserCriteria" :key="projectRelatedUser.id" :label="projectRelatedUser.realName" :value="projectRelatedUser.id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" justify="start" align="middle">
          <el-col :span="12" style="padding-right: 8px;">
            <el-form-item label="Uploader" prop="uploader">
              <el-select :value="criteriaForm.uploader.id" style="width: 100%;" filterable clearable placeholder="the person of uploading the experimental data" @change="selectedUploaderChangeHandler">
                <el-option v-for="projectRelatedUploader in projectRelatedUserCriteria" :key="projectRelatedUploader.id" :label="projectRelatedUploader.realName" :value="projectRelatedUploader.id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <el-row type="flex" width="45%" justify="center" align="middle">
        <el-col style="display: flex; justify-content: flex-end; margin-top: 10px;">
          <el-button @click="clearSearchCriteriaDialog">Clear</el-button>
          <el-button @click="resetSearchCriteria">Reset</el-button>
          <el-button type="primary" @click="submitSearchCriteria">Search</el-button>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

const DATA_LIST_CRITERIA_KEY = 'data_list_criteria'

export default {
  async created () {
    this.dataSearchKey = this.dataSearchKeyword
    this.paginationInfo.currentPage = this.currentDataPage
    this.paginationInfo.pageSize = this.dataPageSize

    const dataListCriteriaStr = window.sessionStorage.getItem(DATA_LIST_CRITERIA_KEY)
    if (dataListCriteriaStr) {
      this.searchForm = JSON.parse(dataListCriteriaStr)
      this.dataSearchKey = this.searchForm.dataSearchKey
    }
    await this.searchDataList()
    this.currentUser = await this.$userStorage.getCurrentUser()
  },
  data () {
    return {
      currentUser: {},
      dataSearchKey: '',
      dataList: [],
      paginationInfo: {
        currentPage: 1,
        pageSize: 10,
        totalCount: 0
      },
      searchDialogVisible: false,
      criteriaForm: {
        dataProject: [],
        projectPI: {},
        uploader: {},
        experimenter: {}
      },
      searchForm: {
        dataSearchKey: null,
        dataProject: [],
        projectPI: {},
        uploader: {},
        experimenter: {}
      },
      dataProjectCriteria: [],
      projectRelatedPICriteria: [],
      dataPICriteria: [],
      projectRelatedUserCriteria: [],
      dataUserCriteria: []
    }
  },
  computed: {
    ...mapState({
      writeSwitch: (state) => state.writableStatus,
      dataSearchKeyword: (state) => state.dataModule.dataSearchKeyword,
      currentDataPage: (state) => state.dataModule.currentDataPage,
      dataPageSize: (state) => state.dataModule.dataPageSize
    })
  },
  methods: {
    ...mapMutations({
      setDataSearchKeyword: 'dataModule/setDataSearchKeyword',
      setCurrentDataPage: 'dataModule/setCurrentDataPage',
      setDataPageSize: 'dataModule/setDataPageSize',
      setRouterActivePath: 'setRouterActivePath'
    }),
    async searchDataHandler () {
      this.paginationInfo.currentPage = 1
      this.paginationInfo.pageSize = 10

      await this.searchDataList()
    },
    async searchDataList () {
      this.setDataSearchKeyword(this.dataSearchKey)
      const urlParams = this.$qs.stringify(this.paginationInfo)
      // const { data: dataListResp } = await this.$http.get('/data/list/' + this.dataSearchKey + '?' + urlParams)
      this.searchForm.dataSearchKey = this.dataSearchKey
      const { data: dataListResp } = await this.$http.post(this.serverAPI.DATA_LIST_URL + '?' + urlParams, JSON.stringify(this.searchForm), { headers: { 'content-type': 'application/json' } })
      console.log('data search list response content: ', dataListResp)
      this.dataList = dataListResp.data.records
      this.paginationInfo.currentPage = dataListResp.data.current
      this.paginationInfo.pageSize = dataListResp.data.size
      this.paginationInfo.totalCount = dataListResp.data.total

      window.sessionStorage.setItem(DATA_LIST_CRITERIA_KEY, JSON.stringify(this.searchForm))
    },
    handleSizeChange (newPageSize) {
      console.log('page size change', newPageSize)
      this.setDataPageSize(newPageSize)
      this.paginationInfo.pageSize = newPageSize
      this.searchDataList()
    },
    handleCurrentChange (newCurrentPage) {
      console.log('current page', newCurrentPage)
      this.setCurrentDataPage(newCurrentPage)
      this.paginationInfo.currentPage = newCurrentPage
      this.searchDataList()
    },
    toDetail (dataID) {
      console.log('data id is', dataID)
      this.$router.push({ path: `/data/detail/${dataID}` })
    },
    toDataAddPage () {
      this.$router.push('/data/add')
    },
    async criteriaDialog () {
      console.log('open data list criteria dialog')

      const { data: criteriaResp } = await this.$http.get(this.serverAPI.DATA_LIST_CRITERIA_URL)
      console.log('data list criteria response content: ', criteriaResp)

      this.dataProjectCriteria = criteriaResp.data.projectCriteria
      this.dataPICriteria = criteriaResp.data.piCriteria
      this.dataUserCriteria = criteriaResp.data.userCriteria

      const searchStr = JSON.stringify(this.searchForm)
      this.criteriaForm = JSON.parse(searchStr)

      this.initiatePrejectRelatedSelection()

      this.searchDialogVisible = true
    },
    /**
     * generate the selection option list for the 'projectRelatedUserCriteria'
     */
    generateProjectUserCriteria () {
      this.dataUserCriteria.forEach(userItem => {
        if (this.projectRelatedUserCriteria.length === 0) {
          this.projectRelatedUserCriteria = this.projectRelatedUserCriteria.concat(userItem.userList)
        } else {
          if (userItem.userList) {
            userItem.userList.forEach(user => {
              const relatedUserIndex = this.projectRelatedUserCriteria.findIndex(projectRelatedUser => {
                return projectRelatedUser.id === user.id
              })

              if (relatedUserIndex === -1) {
                this.projectRelatedUserCriteria.push(user)
              }
            })
          }
        }
      })
    },
    /**
     * generate the selection option list for the 'projectRelatedPICriteria'
     */
    genreateProjecrPICriteria () {
      this.dataPICriteria.forEach(piItem => {
        if (this.projectRelatedPICriteria.length === 0) {
          this.projectRelatedPICriteria = this.projectRelatedPICriteria.concat(piItem.piList)
        } else {
          if (piItem.piList) {
            piItem.piList.forEach(pi => {
              const relatedPIIndex = this.projectRelatedPICriteria.findIndex(projectRelatedPI => {
                return projectRelatedPI.id === pi.id
              })

              if (relatedPIIndex === -1) {
                this.projectRelatedPICriteria.push(pi)
              }
            })
          }
        }
      })
    },
    checkedDataProject (projectID) {
      const projectIndex = this.criteriaForm.dataProject.findIndex(projectItem => {
        return projectItem.id === projectID
      })

      return projectIndex >= 0
    },
    searchDialogClose () {
      this.searchDialogVisible = false
    },
    confirmCloseDialog (done) {
      this.$confirm('Close the dialog and reset search criteria?', {
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        closeOnClickModal: false,
        closeOnPressEscape: false
      }).then(_ => {
        done()
      }).catch(_ => {
        console.log('cancel to close the search data list criteria data')
      })
    },
    /**
     * initiate the selection option list based on the 'criteriaForm' data
     */
    initiatePrejectRelatedSelection () {
      if (this.criteriaForm.dataProject.length === 0) {
        this.genreateProjecrPICriteria()
        this.generateProjectUserCriteria()
        return
      }

      this.projectRelatedPICriteria = []
      this.projectRelatedUserCriteria = []

      this.criteriaForm.dataProject.forEach(projectItem => {
        const projectPI = this.dataPICriteria.find(projectPI => {
          return projectPI.project.id === projectItem.id
        })

        if (projectPI !== null && projectPI !== undefined && projectPI.piList !== null) {
          if (this.projectRelatedPICriteria.length === 0) {
            this.projectRelatedPICriteria = this.projectRelatedPICriteria.concat(projectPI.piList)
          } else {
            projectPI.piList.forEach(projectPIItem => {
              const relatedPIIndex = this.projectRelatedPICriteria.findIndex(projectRelatedPI => {
                return projectRelatedPI.id === projectPIItem.id
              })

              if (relatedPIIndex === -1) {
                this.projectRelatedPICriteria.push(projectPIItem)
              }
            })
          }
        }

        const projectUser = this.dataUserCriteria.find(projectUser => {
          return projectUser.project.id === projectItem.id
        })
        if (projectUser !== null && projectUser !== undefined && projectUser.userList !== null) {
          if (this.projectRelatedUserCriteria.length === 0) {
            this.projectRelatedUserCriteria = this.projectRelatedUserCriteria.concat(projectUser.userList)
          } else {
            projectUser.userList.forEach(projectUserItem => {
              const relatedUserIndex = this.projectRelatedUserCriteria.findIndex(projectRelatedUser => {
                return projectRelatedUser.id === projectUserItem.id
              })

              if (relatedUserIndex === -1) {
                this.projectRelatedUserCriteria.push(projectUserItem)
              }
            })
          }
        }
      })
    },
    checkedDataProjectChangeHandler (checkedFlag, selectedDataProject) {
      if (checkedFlag) {
        this.criteriaForm.dataProject.push(selectedDataProject)
      } else {
        const removeIndex = this.criteriaForm.dataProject.findIndex(projectItem => {
          return projectItem.id === selectedDataProject.id
        })

        this.criteriaForm.dataProject.splice(removeIndex, 1)
      }

      this.initiatePrejectRelatedSelection()
    },
    /**
     * assign the projectPI based on the selected project pi id
     */
    selectedPIChangeHandler (selectedPIID) {
      console.log('selected project PI ID:', selectedPIID)
      this.criteriaForm.projectPI = {}
      if (selectedPIID !== null && selectedPIID !== '' && selectedPIID !== undefined) {
        const selectedProjectPI = this.projectRelatedPICriteria.find(projectRelatedPI => {
          if (projectRelatedPI.id === selectedPIID) {
            return projectRelatedPI
          }
        })
        this.criteriaForm.projectPI = selectedProjectPI
      }
    },
    /**
     * assign the experimenter based on the selected project user ID
     */
    selectedUserChangeHandler (selectedUserID) {
      console.log('selected project user ID:', selectedUserID)
      this.criteriaForm.experimenter = {}
      if (selectedUserID !== null && selectedUserID !== '' && selectedUserID !== undefined) {
        const selectedProjectUser = this.projectRelatedUserCriteria.find(projectRelatedUser => {
          if (projectRelatedUser.id === selectedUserID) {
            return projectRelatedUser
          }
        })
        this.criteriaForm.experimenter = selectedProjectUser
      }
    },
    selectedUploaderChangeHandler (selectedUploaderID) {
      console.log('selected experimental data uploader ID:', selectedUploaderID)
      this.criteriaForm.uploader = {}
      if (selectedUploaderID !== null && selectedUploaderID !== '' && selectedUploaderID !== undefined) {
        const selectedProjectUploader = this.projectRelatedUserCriteria.find(projectRelatedUser => {
          if (projectRelatedUser.id === selectedUploaderID) {
            return projectRelatedUser
          }
        })
        this.criteriaForm.uploader = selectedProjectUploader
      }
    },
    /**
     * reset the criteriaForm based on the searchForm
     */
    resetSearchCriteria () {
      this.$confirm('Reset search criteria?', {
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        closeOnClickModal: false,
        closeOnPressEscape: false
      }).then(_ => {
        const searchStr = JSON.stringify(this.searchForm)
        this.criteriaForm = JSON.parse(searchStr)

        this.initiatePrejectRelatedSelection()
      }).catch(_ => {
        console.log('cancel reset data search criteria')
      })
    },
    /**
     * clear the criteriaForm
     */
    clearSearchCriteriaDialog () {
      this.$confirm('Clear search criteria?', {
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        closeOnClickModal: false,
        closeOnPressEscape: false
      }).then(_ => {
        this.criteriaForm = {
          dataProject: [],
          projectPI: {},
          uploader: {},
          experimenter: {}
        }

        this.initiatePrejectRelatedSelection()
        this.clearCriteria()
      }).catch(_ => {
        console.log('cancel to clear the search data list criteria data')
      })
    },
    async clearCriteria () {
      this.searchForm = {
        dataProject: [],
        projectPI: {},
        uploader: {},
        experimenter: {}
      }

      this.paginationInfo.currentPage = 1
      this.paginationInfo.pageSize = 10

      // to call search data list
      await this.searchDataList()
    },
    // assing the search criteria parameter from the criteriaForm to searchFrom
    async submitSearchCriteria () {
      const criteriaStr = JSON.stringify(this.criteriaForm)
      this.searchForm = JSON.parse(criteriaStr)

      this.searchDialogClose()
      // call the search data list
      await this.searchDataList()
    },
    async criteriaTagClose (formFieldName, itemFieldName, criteriaContent) {
      console.log('close the tage formFieldName:', formFieldName)
      console.log('close the tage criteria:', criteriaContent)

      if (this.searchForm[formFieldName] instanceof Array) {
        const arrayContent = this.searchForm[formFieldName]
        const criteriaIndex = arrayContent.findIndex(item => {
          return item[itemFieldName] === criteriaContent
        })
        arrayContent.splice(criteriaIndex, 1)
      } else if (this.searchForm[formFieldName] instanceof Object) {
        console.log('get search form object field value:', this.searchForm[formFieldName])
        this.searchForm[formFieldName] = {}
      } else {
        console.log('get search form other types field value:', this.searchForm[formFieldName])
        this.searchForm[formFieldName] = ''
      }
      // call the search data list
      await this.searchDataList()
    },
    indexDataRow (index) {
      const newIndex = (this.paginationInfo.currentPage - 1) * this.paginationInfo.pageSize + index + 1
      console.log('caculating the index of the data row', newIndex)
      return newIndex
    }
  }
}
</script>
<style scoped>
div /deep/ .el-form-item__label {
  padding-bottom: 0px;
}
div /deep/ .el-dialog__body {
  padding-bottom: 10px;
  padding-top: 0;
}
</style>
