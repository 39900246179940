<template>
  <section>
    <el-card>
      <div slot="header" class="clearfix">
        <span>Thesis Information</span>
      </div>
      <el-form id="thesisForm" ref="thesisFormRef" :model="paperDataForm" :rules="thesisFormDataRules" @validate="afterThesisFormValidate">
        <el-row :gutter="20" style="margin-bottom: 5px;">
          <el-col :lg="12" :md="24">
            <el-form-item label="Publication Type" class="data-form-label" prop="paperType.typeCode">
              <el-select :disabled="!writeSwitch" v-if="isAdd" v-model="paperDataForm.paperType.typeCode" @change="paperTypeChangeHandler" style="width: 100%;" name="paperType" clearable>
                <el-option v-for="(paperType, index) in paperTypeOptions" :key="index" :value="paperType.value" :label="paperType.label"></el-option>
              </el-select>
              <el-input v-else :value="paperDataForm.paperType.typeName" readonly></el-input>
            </el-form-item>
          </el-col>
          <!-- <el-col :lg="12" :md="24">
            <el-form-item label="Publication Status" class="data-form-label" prop="paperStatus.statusCode">
              <el-select v-model="paperDataForm.paperStatus.statusCode" style="width: 100%;" name="paperStatus" clearable>
                <el-option v-for="(paperStatus, index) in paperStatuses" :key="index" :value="paperStatus.value" :label="paperStatus.label"></el-option>
              </el-select>
            </el-form-item>
          </el-col> -->
          <el-col :lg="12" :md="24">
            <el-form-item label="Publication Title" class="data-form-label" prop="paperTitle">
              <el-input :disabled="!writeSwitch" v-model="paperDataForm.paperTitle" style="width: 100%;" name="paperTitle" clearable :readonly="!(paperDataForm.uploader.id === currentUser.id)"></el-input>
            </el-form-item>
          </el-col>
          <el-col :lg="12" :md="24">
            <el-form-item label="Publication DOI" class="data-form-label" prop="paperDOI">
              <el-input :disabled="!writeSwitch" v-model="paperDataForm.paperDOI" style="width: 100%;" name="paperDOI" clearable :readonly="!(paperDataForm.uploader.id === currentUser.id)"></el-input>
            </el-form-item>
          </el-col>
          <el-col :lg="12" :md="24">
            <el-form-item label="Publication URL" class="data-form-label" prop="paperURL">
              <el-input :disabled="!writeSwitch" v-model="paperDataForm.paperURL" style="width: 100%;" name="paperURL" clearable :readonly="!(paperDataForm.uploader.id === currentUser.id)"></el-input>
            </el-form-item>
          </el-col>
          <el-col :lg="12" :md="24">
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="Year" class="data-form-label" prop="paperYear">
                  <el-select :disabled="!writeSwitch" v-if="paperDataForm.uploader.id === currentUser.id" v-model="paperDataForm.paperYear" style="width: 100%;" name="paperYear" clearable>
                    <el-option v-for="(i, index) in (yearOptions.endYear - yearOptions.startYear + 1)" :key="index" :value="yearOptions.endYear - index" :label="yearOptions.endYear - index"></el-option>
                  </el-select>
                  <el-input v-else :value="paperDataForm.paperYear" readonly></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Month" class="data-form-label" prop="paperMonth">
                  <el-select :disabled="!writeSwitch" v-if="paperDataForm.uploader.id === currentUser.id" v-model="paperDataForm.paperMonth" style="width: 100%;" name="paperMonth" clearable>
                    <el-option v-for="(month, index) in monthOptions" :key="index" :value="month.value" :label="month.label"></el-option>
                  </el-select>
                  <el-input v-else :value="paperDataForm.paperMonth" readonly></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
          <el-col :lg="12" :md="24">
            <el-form-item label="University" class="data-form-label" prop="paperUniversity">
              <el-input :disabled="!writeSwitch" v-model="paperDataForm.paperUniversity" style="width: 100%;" name="paperUniversity" clearable :readonly="!(paperDataForm.uploader.id === currentUser.id)"></el-input>
            </el-form-item>
          </el-col>
          <el-col :lg="12" :md="24" v-if="(paperDataForm.paperRelatedProject!==null&&paperDataForm.paperRelatedProject.length>0) || (paperDataForm.uploader.id === currentUser.id)">
            <el-form-item label="Related Projects" class="data-form-label" prop="paperRelatedProject">
              <PaperRelatedProject :paperForm="paperDataForm" :btnsVisible="paperDataForm.uploader.id === currentUser.id"></PaperRelatedProject>
            </el-form-item>
          </el-col>
          <el-col :lg="12" :md="24" v-if="(paperDataForm.paperRelatedData!==null&&paperDataForm.paperRelatedData.length>0) || (paperDataForm.uploader.id === currentUser.id)">
            <el-form-item label="Related Data" class="data-form-label" prop="paperRelatedData">
              <PaperRelatedData :paperForm="paperDataForm" :btnsVisible="paperDataForm.uploader.id === currentUser.id"></PaperRelatedData>
            </el-form-item>
          </el-col>
          <el-col :lg="12" :md="24">
            <el-form-item label="Publication File" class="data-form-label" prop="paperFile">
              <el-row></el-row>
              <FileFormField fieldName="paperFile" :formData="paperDataForm" :creator="paperDataForm.uploader" :targetValue="'0'"></FileFormField>
            </el-form-item>
          </el-col>
          <el-col :lg="12" :md="24">
            <el-form-item label="Related Authors" class="data-form-label" prop="paperRelatedProjectAuthors">
              <el-input :disabled="!writeSwitch" type="textarea" :rows="6" resize="none" v-model="paperDataForm.paperRelatedProjectAuthors" name="paperRelatedProjectAuthors" clearable :readonly="!(paperDataForm.uploader.id === currentUser.id)"></el-input>
              <div class="field-prompt">Please use commas(", ") to separate different names e.g: name1, name2</div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="paperDataForm.uploader.id === currentUser.id && writeSwitch" style="margin-top: 15px;" type="flex" justify="end">
          <el-col :span="4" :offset="22" style="padding-right: 15px">
            <el-button style="float: right;" @click="resetPaperFormData" :disabled="!thesisFormChange"> Reset </el-button>
          </el-col>
          <el-col :span="2">
            <el-button v-if="paperID!==null && paperID!==undefined" type="primary" @click="updateThesisFormData" :disabled="!thesisFormChange">Update</el-button>
            <el-button v-else type="primary" @click="submitThesisFormData" :disabled="!thesisFormChange">Submit</el-button>
          </el-col>
        </el-row>
      </el-form>
    </el-card>

    <PaperDetailHistoryList v-if="paperID !== null && paperID !== undefined" :relatedHistory="paperRelatedHistory"></PaperDetailHistoryList>
  </section>
</template>
<script>
import { mapState } from 'vuex'
import { PAPER_FUNCS } from './paper_res/PaperFormFunctions'
import { EVENT_CONFIG } from '@/event_bus'
import PaperRelatedProject from '@/components/paper_component/paper_fields/PaperRelatedProject.vue'
import PaperRelatedData from '@/components/paper_component/paper_fields/PaperRelatedData.vue'
import JsSHA from 'jssha'
import { formValidator } from '@/plugins/formValidator'
import alertCountdown from '@/plugins/alertCountdown.js'
import FileFormField from '@/components/data_component/FileFormField.vue'
import { DRAFT_GENERATOR } from '@/local_storage/DraftGenerator'
import PaperDetailHistoryList from '@/components/paper_component/PaperDetailHistoryList.vue'

export default {
  props: ['isAdd', 'paperID', 'monthOptions', 'yearOptions', 'paperTypeOptions', 'paperStatusOptions'],
  components: {
    PaperRelatedProject,
    PaperRelatedData,
    FileFormField,
    PaperDetailHistoryList
  },
  async mounted () {
    console.log('init thesis type paper detail page')
    const loadingInstance = this.$loading.service({
      lock: true,
      text: 'Paper information is loading...'
    })

    window.onbeforeunload = (event) => {
      this.thesisFormChange = formValidator.formDataChangeValidate(this.paperDataForm, this.thesisFormDigest)
      if (this.thesisFormChange) {
        DRAFT_GENERATOR.storePublicationDraft(this.paperDataForm)
        return 'confirm to refresh?'
      }
    }

    this.currentUser = await this.$userStorage.getCurrentUser()
    if (this.isAdd) {
      this.paperDataForm.uploader = this.currentUser
    }
    await this.getThesisPaperDetail()

    const thesisPaperDraft = await DRAFT_GENERATOR.getPublicationDraft(this.paperDataForm.id)
    if (thesisPaperDraft && thesisPaperDraft.paperType.typeCode === 4) {
      const toValidateForm = await this.$confirm('Load the publication draft?', {
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        closeOnClickModal: false,
        closeOnPressEscape: false
      }).then(_ => {
        console.log('to load thesis type paper draft')
        this.paperDataForm = thesisPaperDraft
        return true
      }).catch(_ => {
        console.log('not to load thesis type paper draft')
      }).finally(_ => {
        DRAFT_GENERATOR.removePublicationDraft(this.paperDataForm.id)
      })

      if (toValidateForm) {
        const thesisFormRef = this.$refs.thesisFormRef
        const validateThesisFormResult = await formValidator.formValidate(thesisFormRef)
        console.log('after reload form draft validate result: ', validateThesisFormResult)
      }
    }

    EVENT_CONFIG.EventBus.$on(EVENT_CONFIG.CHECK_FORM_FIELD_EVENT, this.checkFormDataField)
    loadingInstance.close()
  },
  computed: {
    ...mapState({
      writeSwitch: (state) => state.writableStatus
    })
  },
  watch: {
    thesisFormChange (newValue) {
      console.log('newvalue of the thesisFormChange:', newValue)
      EVENT_CONFIG.EventBus.$emit(EVENT_CONFIG.PAPER_FORM_CHANGE_EVENT, newValue)
    }
  },
  beforeDestroy () {
    console.log('PaperThesis.vue beforeDestroy method executes...')
    window.onbeforeunload = null
    EVENT_CONFIG.EventBus.$off(EVENT_CONFIG.CHECK_FORM_FIELD_EVENT)
  },
  data () {
    const checkPaperTitle = async (rule, value, callback) => {
      console.log('check thesis publication title')
      if (!value) {
        callback(new Error('paper title is required'))
        return
      }
      const checkPaperTitleResp = await PAPER_FUNCS.checkPaperTitle(value, this.paperID)
      console.log('check publication title response content: ', checkPaperTitleResp)
      if (checkPaperTitleResp.code === 1) {
        if (!checkPaperTitleResp.data) {
          return callback()
        } else {
          callback(new Error('Publication title exist, please try another one'))
        }
      } else {
        callback(new Error('Checking publication title existing error, please try later'))
      }
    }

    return {
      formToken: '', // formToken to prevent the repeated submission
      thesisFormChange: false, // the flag of the data form change
      thesisFormDigest: '',
      currentUser: { id: '' },
      paperRelatedHistory: [],
      paperDataForm: {
        paperType: {
          typeCode: 4
        },
        paperStatus: {
          statusCode: ''
        },
        paperTitle: '',
        paperDOI: '',
        paperURL: '',
        paperYear: '',
        paperMonth: '',
        paperUniversity: '',
        paperFile: [],
        paperRelatedProjectAuthors: '',
        paperRelatedProject: [],
        paperRelatedData: [],
        uploader: { id: '' }
      },
      thesisFormDataRules: {
        'paperType.typeCode': [
          { required: true, message: 'publication type is required', trigger: 'change' }
        ],
        'paperStatus.statusCode': [
          { required: true, message: 'publication status is required', trigger: 'change' }
        ],
        paperTitle: [
          { required: true, message: 'publication title is required', trigger: 'change' },
          { validator: checkPaperTitle, trigger: 'blur' }
        ],
        paperDOI: [
          { required: false, message: 'paper doi', trigger: 'change' },
          { validator: PAPER_FUNCS.checkPaperDOI, trigger: 'blur' }
        ],
        paperURL: [
          { required: false, message: 'paper url', trigger: 'change' },
          { validator: PAPER_FUNCS.checkPaperURL, trigger: 'blur' }
        ],
        paperYear: [
          { required: true, message: 'publication published year is required', trigger: 'change' }
        ],
        paperMonth: [
          { required: false, message: 'publication published month is required', trigger: 'change' }
        ],
        paperUniversity: [
          { required: false, message: 'publication university is required', trigger: 'change' }
        ],
        paperRelatedProject: [
          { required: false, message: 'publication related projects is null', trigger: 'change' }
        ],
        paperRelatedData: [
          { required: false, message: 'publication related data is null', trigger: 'change' }
        ],
        paperRelatedProjectAuthors: [
          { required: true, message: 'publication related author is required', trigger: 'change' }/* ,
          { validator: PAPER_FUNCS.changePaperAuthor, trigger: 'blur' } */
        ],
        paperFile: [
          { required: false, message: 'publication related files is null', trigger: 'change' }
        ]
      }
    }
  },
  methods: {
    async getThesisPaperDetail () {
      if (this.paperID !== null && this.paperID !== undefined) {
        const paperDetailResp = await PAPER_FUNCS.getPaperDetail(this.paperID)
        console.log('thesis detail response content: ', paperDetailResp)
        this.formToken = paperDetailResp.headers['form-token']
        const paperDetail = paperDetailResp.data
        if (paperDetail.code === 1) {
          this.paperDataForm = paperDetail.data
          this.paperRelatedHistory = paperDetail.data.relatedHistories

          if (this.paperDataForm.paperDOI === null) {
            this.paperDataForm.paperDOI = ''
          }

          if (this.paperDataForm.paperURL === null) {
            this.paperDataForm.paperURL = ''
          }

          if (this.paperDataForm.paperMonth === null) {
            this.paperDataForm.paperMonth = ''
          }

          if (this.paperDataForm.paperUniversity === null) {
            this.paperDataForm.paperUniversity = ''
          }
        } else {
          this.$alert(paperDetailResp.msg, 'publication detail page error', {
            confirmButtonText: 'OK',
            center: true
          })
        }
      } else {
        const paperTokenResp = await this.$http.get(this.serverAPI.PAPER_SUBMITTOKEN_URL)
        console.log('paper article add token response hrader: ', paperTokenResp)
        this.formToken = paperTokenResp.headers['form-token']
      }

      const startThesisDigest = new Date().getTime()
      const jsSHAThesis = new JsSHA('SHA-256', 'TEXT')
      const thesisFormDataStr = JSON.stringify(this.paperDataForm)
      console.debug('thesis form string: ', thesisFormDataStr)
      jsSHAThesis.update(thesisFormDataStr)
      this.thesisFormDigest = jsSHAThesis.getHash('HEX')
      console.log('publication thesis form digest time cost: ', new Date().getTime() - startThesisDigest)
      this.thesisFormChange = false
    },
    async paperTypeChangeHandler (selectedType) {
      console.log('thesis publication selected publication type:', selectedType)

      if (this.thesisFormChange) {
        const confirmChangePaperTypeResult = await PAPER_FUNCS.confirmChangePaperType(this)
        if (!confirmChangePaperTypeResult) {
          this.paperDataForm.paperType.typeCode = 4
          return
        }
      }

      this.resetPaperFormData()
      EVENT_CONFIG.EventBus.$emit(EVENT_CONFIG.PAPER_FORM_CHANGE_EVENT, false)
      EVENT_CONFIG.EventBus.$emit(EVENT_CONFIG.PAPER_TYPE_CHANGE_EVENT, selectedType)
    },
    async submitThesisFormData () {
      const thesisFormRef = this.$refs.thesisFormRef
      const validateThesisFormResult = await formValidator.formValidate(thesisFormRef)
      console.log('validate thesis type publication result: ', validateThesisFormResult)

      if (validateThesisFormResult !== true) {
        let msgContent = ''
        const validateValues = Object.values(validateThesisFormResult)
        validateValues.forEach(validateVal => {
          msgContent += validateVal[0].message + '<br>'
        })

        this.$alert('<span style="font-size: 15px; font-weight: bold;"> Exceptions in the publication form data: </span><br> <span style="color: red;">' + msgContent + '</span>', 'Notification of Publication Data', {
          dangerouslyUseHTMLString: true
        })

        return
      }

      this.thesisFormChange = false
      if (validateThesisFormResult === true) {
        console.log('submit the thesis publication:', this.thesisFormChange)

        try {
          // const { data: thesisResponse } = await this.$http.post('/paper/newThesis', JSON.stringify(this.paperDataForm), { headers: { 'content-type': 'application/json' } })
          const { data: thesisResponse } = await this.$http.post(this.serverAPI.PAPER_NEWTHESIS_URL, JSON.stringify(this.paperDataForm), { headers: { 'content-type': 'application/json', 'form-token': this.formToken } })
          console.log('save thesis type publication response', thesisResponse)

          if (thesisResponse.code === 1) {
            this.$message({
              showClose: true,
              message: 'Add publication successfully!',
              center: true,
              type: 'success'
            })
            // to the data list page automatically with time countdown
            this.$router.push({ path: `/paper/detail/${thesisResponse.data}/4` })
            alertCountdown(this, 'To Publication Detail', this.backToPublicationList, 5)
          } else {
            this.$alert('<font color="red">' + thesisResponse.msg + '</font>', 'Pulication Add Error', {
              dangerouslyUseHTMLString: true
            })

            this.thesisFormChange = true
          }
        } catch (error) {
          console.log('save thesis type publication publication response error: ', error)
          this.$alert(error, 'save thesis publication error', {
            confirmButtonText: 'OK',
            center: true
          })
          this.thesisFormChange = true
        }

        return
      }
      this.thesisFormChange = true
    },
    async updateThesisFormData () {
      const thesisFormRef = this.$refs.thesisFormRef
      const validateThesisFormResult = await formValidator.formValidate(thesisFormRef)
      console.log('update thesis publication page vlidate result: ', validateThesisFormResult)

      if (validateThesisFormResult !== true) {
        let msgContent = ''
        const validateValues = Object.values(validateThesisFormResult)
        validateValues.forEach(validateVal => {
          msgContent += validateVal[0].message + '<br>'
        })

        this.$alert('<span style="font-size: 15px; font-weight: bold;"> Exceptions in the publication form data: </span><br> <span style="color: red;">' + msgContent + '</span>', 'Notification of Publication Data', {
          dangerouslyUseHTMLString: true
        })

        return
      }

      this.thesisFormChange = false
      if (validateThesisFormResult === true) {
        console.log('update the thesis publication: ', this.thesisFormChange)

        try {
          // const { data: updateThesisResp } = await this.$http.post('/paper/updateThesis', JSON.stringify(this.thesisUpdateDataForm), { headers: { 'content-type': 'application/json' } })
          const { data: updateThesisResp } = await this.$http.post(this.serverAPI.PAPER_UPDATETHESIS_URL, JSON.stringify(this.paperDataForm), { headers: { 'content-type': 'application/json', 'form-token': this.formToken } })
          console.log('update thesis type publication response', updateThesisResp)

          if (updateThesisResp.code === 1) {
            this.$message({
              showClose: true,
              message: 'Update publication successfully!',
              center: true,
              type: 'success'
            })
            // to the data list page automatically with time countdown
            alertCountdown(this, 'To Publication List', this.backToPublicationList, 5)
            await this.getThesisPaperDetail()
          } else {
            this.$alert('<font color="red">' + updateThesisResp.msg + '</font>', 'Publication Update Error', {
              dangerouslyUseHTMLString: true
            })

            this.thesisFormChange = true
          }
        } catch (error) {
          console.log('update thesis type publication response error: ', error)
          this.$alert(error, 'update thesis publication error', {
            confirmButtonText: 'OK',
            center: true
          })
        }

        return
      }
      this.thesisFormChange = true
    },
    async resetPaperFormData () {
      const paperFormData = this.$refs.thesisFormRef
      if (this.paperID === null || this.paperID === undefined) {
        console.log('reset the thesis type publication add page')
        paperFormData.resetFields()

        this.thesisFormChange = formValidator.formDataChangeValidate(this.paperDataForm, this.thesisFormDigest)
        return
      }

      console.log('reset the thesis type publication update page')
      await this.getThesisPaperDetail()
      paperFormData.clearValidate()
    },
    backToPublicationList () {
      this.$router.push({
        path: '/paper/list'
      })
    },
    afterThesisFormValidate (props, callback) {
      console.log('validate thesis form field:', props)
      console.log('thesis form data digest ', this.thesisFormDigest)
      this.thesisFormChange = formValidator.formDataChangeValidate(this.paperDataForm, this.thesisFormDigest)
      console.log('thesis publication change: ', this.thesisFormChange)
    },
    checkFormDataField (isComon, fieldName) {
      console.log('check the thesis form data specified field: ', fieldName)
      const thesisFormRef = this.$refs.thesisFormRef
      formValidator.formFieldValidate(thesisFormRef, fieldName)
    }
  }
}
</script>
<style scoped>
form /deep/ label {
  line-height: 30px !important;
  padding: 0 !important;
}
</style>
<style scoped>
@import './paper_res/PaperStyle.css';
</style>
