<template>
  <div>
    <!--Breadcrumb navigation area-->
    <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>Home</el-breadcrumb-item>
        <el-breadcrumb-item>Menu Module</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card>
      <el-row type="flex" justify="start">
          <el-col>
              <el-button type="primary" @click="addMenuDialogVisible = true" v-if="writeSwitch">New Menu</el-button>
              <el-switch @change="changeSwitch"
              style="margin-left: 15px; height: 40px;"
              :value="writeSwitch"
              active-color="#13ce66"
              inactive-color="#ff4949"
              active-text="Writable"
              inactive-text="Readable" :disabled="disabledWriteSwitch">
            </el-switch>
          </el-col>
      </el-row>

      <!-- <el-row style="margin-top: 15px;">
        <el-input
          placeholder="Enter keywords to filter"
          v-model="filterText">
        </el-input>
      </el-row> -->

      <el-table :data="menuTree" row-key="menuID" border stripe :tree-props="{children: 'subMenus', hasChildren: 'hasChildren'}">
        <el-table-column label="Menu Name" prop="menuName"></el-table-column>
        <el-table-column label="Menu Path" prop="menuPath"></el-table-column>
        <el-table-column label="Menu Icon" prop="menuIcon"></el-table-column>
        <el-table-column label="Create Time">
          <template v-slot="menuSlot">
            {{menuSlot.row.createTime | formatDate}}
          </template>
        </el-table-column>
        <el-table-column label="Action">
          <template v-slot="menuSlot">
            <el-button type="primary" style="margin-right: 5px;" size="mini" @click="() => editMenu(menuSlot.row.menuID)"> Edit</el-button>
            <el-popconfirm  v-if="writeSwitch" icon="el-icon-info" icon-color="red" title="Delete this menu item and the cascading submenus will be deleted. Do you want to continue?" style="width: 160px" @confirm="removeMenu(menuSlot.row)">
              <el-button type="danger" size="mini" slot="reference">Remove</el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
    </el-card>

    <!--new menu dialog-->
    <el-dialog title="New Menu" :visible.sync="addMenuDialogVisible" width="50%" @close="addMenuDialogClosed">
      <!--main content area-->
      <el-form :model="addForm" :rules="addFormRules" ref="addFormRef" label-width="100px" label-position="top">
        <el-form-item label="Menu Name" prop="menuName">
          <el-input v-model="addForm.menuName"></el-input>
        </el-form-item>
        <el-form-item label="Menu Path" prop="menuPath">
          <el-input v-model="addForm.menuPath"></el-input>
        </el-form-item>
        <el-form-item label="Menu Icon" prop="menuIcon">
          <span :class="addForm.menuIcon" style="float: left; line-height:40px; padding-right: 12px;"></span>
          <el-select v-model="addForm.menuIcon" class="menu-select-icon">
            <el-option v-for="item in menuIcons" :key="item.name" :value="item.className" :label="item.name">
              <span style="float: left">{{ item.name }}</span>
              <span style="float: right; line-height: 34px;" :class="item.className"></span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Parent Menu" prop="parentMenuID">
          <el-select v-model="addForm.parentMenuID" class="menu-select">
            <el-option v-for="rootMenuItem in rootMenuData" :key="rootMenuItem.menuName" :value="rootMenuItem.menuID" :label="rootMenuItem.menuName">
              <span style="float: left">{{ rootMenuItem.menuName }}</span>
              <span style="float: right; line-height: 34px;" :class="rootMenuItem.menuIcon"></span>
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <!--Button area-->
      <span slot="footer" class="dialog-footer">
        <el-button @click="addMenuDialogVisible = false">Cancel</el-button>
        <el-button type="primary" @click="addMenuItem">Submit</el-button>
      </span>
    </el-dialog>

    <!--update menu dialog-->
    <el-dialog title="Update Menu" :visible.sync="updateMenuDialogVisible" width="50%" @close="updateMenuDialogClosed">
      <!--update content area-->
     <el-form :model="updateForm" :rules="updateFormRules" ref="updateFormRef" label-width="100px" label-position="top">
        <el-form-item label="Menu Name" prop="menuName">
          <el-input v-model="updateForm.menuName" :disabled="!writeSwitch"></el-input>
        </el-form-item>
        <el-form-item label="Menu Sort" prop="menuSort">
          <el-input-number size="medium" v-model="updateForm.menuSort" :disabled="!writeSwitch"></el-input-number>
        </el-form-item>
        <el-form-item label="Menu Path" prop="menuPath">
          <el-input v-model="updateForm.menuPath" :disabled="!writeSwitch"></el-input>
        </el-form-item>
        <el-form-item label="Menu Icon" prop="menuIcon">
          <span :class="updateForm.menuIcon" style="float: left; line-height:40px; padding-right: 15px;"></span>
          <el-select v-model="updateForm.menuIcon" class="menu-select-icon" :disabled="!writeSwitch">
            <el-option v-for="item in menuIcons" :key="item.name" :value="item.className" :label="item.name">
              <span style="float: left">{{ item.name }}</span>
              <span style="float: right; line-height: 34px;" :class="item.className"></span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Parent Menu" prop="parentMenuID">
          <el-select v-model="updateForm.parentMenuID" class="menu-select" :disabled="!writeSwitch">
            <el-option v-for="rootMenuItem in rootMenuData" :key="rootMenuItem.menuName" :value="rootMenuItem.menuID" :label="rootMenuItem.menuName">
              <span style="float: left">{{ rootMenuItem.menuName }}</span>
              <span style="float: right; line-height: 34px;" :class="rootMenuItem.menuIcon"></span>
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <!--Button area-->
      <span slot="footer" class="dialog-footer">
        <el-button @click="updateMenuDialogCancel">Cancel</el-button>
        <el-button type="primary" @click="updateMenuItem" v-if="writeSwitch">Update</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { mapState, mapMutations } from 'vuex'
import { EVENT_CONFIG } from '@/event_bus'

export default {
  data () {
    return {
      disabledWriteSwitch: true,
      addMenuDialogVisible: false,
      updateMenuDialogVisible: false,
      filterText: '',
      menuTree: [],
      rootMenuData: [],
      defaultProps: {
        children: 'subMenus',
        label: 'menuName'
      },
      addForm: {
        menuName: '',
        menuIcon: '',
        menuPath: '',
        parentMenuID: ''
      },
      updateForm: {
        menuID: '',
        menuName: '',
        menuIcon: '',
        menuPath: '',
        parentMenuID: ''
      },
      // the check rules of adding menu item
      addFormRules: {
        menuName: [
          { required: true, message: 'Please input the menu name', trigger: 'blur' }
        ],
        menuIcon: [
          { required: true, message: 'Please select the menu icon', trigger: 'blur' }
        ],
        menuPath: [
          { required: false, message: '', trigger: 'blur' }
        ],
        parentMenuID: [
          { required: false, message: '', trigger: 'blur' }
        ]
      },
      // the check rules of adding menu item
      updateFormRules: {
        menuName: [
          { required: true, message: 'Please input the menu name', trigger: 'blur' }
        ],
        menuSort: [
          { required: true, message: 'Please select the menu sort value', trigger: 'blur' }
        ],
        menuIcon: [
          { required: true, message: 'Please select the menu icon', trigger: 'blur' }
        ],
        menuPath: [
          { required: false, message: '', trigger: 'blur' }
        ],
        parentMenuID: [
          { required: false, message: '', trigger: 'blur' }
        ]
      },
      menuIcons: [
        {
          name: 'menu',
          className: 'el-icon-menu',
          isDefault: true
        },
        {
          name: 'home',
          className: 'el-icon-s-home'
        },
        {
          name: 'data',
          className: 'el-icon-s-data'
        },
        {
          name: 'file',
          className: 'el-icon-files'
        },
        {
          name: 'article',
          className: 'el-icon-s-management'
        },
        {
          name: 'user',
          className: 'el-icon-user-solid'
        },
        {
          name: 'setting',
          className: 'el-icon-setting'
        },
        {
          name: 'data-list',
          className: 'el-icon-s-order'
        },
        {
          name: 'data-add',
          className: 'el-icon-circle-plus'
        }
      ]
    }
  },
  computed: {
    ...mapState({
      writeSwitch: (state) => state.writableStatus
    })
  },
  created () {
    this.getSwitchStatus()
    this.getMenuTree()
    for (const menuIcon of this.menuIcons) {
      if (menuIcon.isDefault !== undefined && menuIcon.isDefault !== null && menuIcon) {
        this.addForm.menuIcon = menuIcon.className
      }
    }
  },
  watch: {
    filterText (val) {
      this.$refs.menuTree.filter(val)
    }
  },
  methods: {
    ...mapMutations({
      switchWritableStatus: 'switchWritableStatus'
    }),
    filterNode (value, data) {
      if (!value) return true
      return data.label.indexOf(value) !== -1
    },
    async changeSwitch () {
      this.disabledWriteSwitch = true
      const { data: statusData } = await this.$http.get(this.serverAPI.MENU_WRITE_SWITCH_URL)
      console.log('change the switch status: ', statusData)
      this.switchWritableStatus(statusData.data)
      this.disabledWriteSwitch = false
    },
    async getSwitchStatus () {
      const { data: statusData } = await this.$http.get(this.serverAPI.MENU_SWITCH_STATUS_URL)
      console.log('get status menu: ', statusData)
      this.switchWritableStatus(statusData.data)
      this.disabledWriteSwitch = false
    },
    async getMenuTree () {
      const { data: menuData } = await this.$http.get(this.serverAPI.MENU_LIST_URL)
      console.log('all menu ', menuData)
      if (menuData.data !== null && menuData.data !== undefined) {
        this.menuTree = menuData.data
      }

      // const { data: rootMenuData } = await this.$http.get('/menu/rootMenu')
      const { data: rootMenuData } = await this.$http.get(this.serverAPI.MENU_ROOT_URL)
      console.log('root menu', rootMenuData)
      if (rootMenuData.data !== null && rootMenuData.data !== undefined) {
        this.rootMenuData = rootMenuData.data
      }
    },
    async addMenuItem () { // add menu item and close dialog
      this.$refs.addFormRef.validate(async result => {
        if (!result) return
        // const { data: res } = await this.$http.post('/menu/add', JSON.stringify(this.addForm), { headers: { 'content-type': 'application/json' } })
        const { data: res } = await this.$http.post(this.serverAPI.MENU_ADD_URL, JSON.stringify(this.addForm), { headers: { 'content-type': 'application/json' } })
        console.log('add menu item response', res)
        this.addMenuDialogVisible = false

        // window.location.reload()
        EVENT_CONFIG.EventBus.$emit(EVENT_CONFIG.RELOAD_NAVI_MENU_EVENT)
        await this.getMenuTree()
      })
    },
    async updateMenuItem () { // update menu item and close dialog
      this.$refs.updateFormRef.validate(async result => {
        if (!result) return
        // const { data: res } = await this.$http.post('/menu/update', JSON.stringify(this.updateForm), { headers: { 'content-type': 'application/json' } })
        const { data: res } = await this.$http.post(this.serverAPI.MENU_UPDATE_URL, JSON.stringify(this.updateForm), { headers: { 'content-type': 'application/json' } })
        console.log('update menu item response', res)
        if (res.code === 1) {
          this.updateMenuDialogVisible = false
          this.$message({
            showClose: true,
            message: 'Update the menu item successfully',
            center: true,
            type: 'success'
          })
          // window.location.reload()
          EVENT_CONFIG.EventBus.$emit(EVENT_CONFIG.RELOAD_NAVI_MENU_EVENT)
          await this.getMenuTree()
        } else {
          this.$message({
            showClose: true,
            message: 'Failure to update the menu item',
            center: true,
            type: 'error'
          })
        }
      })
    },
    addMenuDialogClosed () {
      this.$refs.addFormRef.resetFields()
    },
    updateMenuDialogClosed () {
      this.$refs.updateFormRef.resetFields()
    },
    updateMenuDialogCancel () {
      // this.$refs.addFormRef.resetFields()
      this.updateMenuDialogVisible = false
      console.log('cancel update dialog')
    },
    async editMenu (menuID) {
      // const { data: menuResp } = await this.$http.get('/menu/detail/' + menuID)
      const { data: menuResp } = await this.$http.get(this.serverAPI.MENU_DETAIL_URL + menuID)
      console.log('get menu detail', menuResp)
      this.updateMenuDialogVisible = true

      this.updateForm = menuResp.data
    },
    async removeMenu (menuItem) { // remove menu item
      console.log('confirm to delete the menu item menuID=', menuItem.menuID)
      // axios delete request cannot send the params by setting the second params
      const { data: res } = await this.$http.delete(this.serverAPI.MENU_REMOVE_URL + menuItem.menuID)
      console.log('enable menu response', res)
      if (res.code === 1) {
        this.$message({
          showClose: true,
          message: 'Enable menu item:\'' + menuItem.menuName + '\' successfully',
          center: true,
          type: 'success'
        })
      } else {
        this.$alert(res.msg, 'menu delete error', {
          confirmButtonText: 'OK',
          center: true
        })
      }
      await this.getMenuTree()
    }
  }
}
</script>
<style scoped>
div >>> div.el-tree-node {
  margin-top: 5px;
}
.menu-select-icon {
  width: calc(100% - 29px);
}

.menu-select {
  width: 100%;
}

.custom-tree-node {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    padding-right: 8px;
  }
</style>
