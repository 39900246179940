<template>
  <section>
    <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>Home</el-breadcrumb-item>
        <el-breadcrumb-item>Publication Module</el-breadcrumb-item>
        <el-breadcrumb-item>Publication List</el-breadcrumb-item>
    </el-breadcrumb>
    <el-row>
      <span v-show="searchForm.paperType.length>0? true: false" style="margin-right: 10px;">
        <b>Publication Type:</b>
        <el-tag v-for="typeItem in searchForm.paperType" :key="typeItem.typeCode" closable style="margin-left: 3px;" @close="criteriaTagClose('paperType', 'typeCode', typeItem.typeCode)">{{typeItem.typeName}}</el-tag>
      </span>
      <span v-show="searchForm.paperStatus.length>0? true: false" style="margin-right: 10px;">
        <b>Publication Status:</b>
        <el-tag v-for="statusItem in searchForm.paperStatus" :key="statusItem.statusCode" closable style="margin-left: 3px;" @close="criteriaTagClose('paperStatus', 'statusCode', statusItem.statusCode)">{{statusItem.statusName}}</el-tag>
      </span>
      <span v-show="(searchForm.year !== '' && searchForm.year !== null && searchForm.year !== undefined)? true: false" style="margin-right: 10px;">
        <b>Publication Year:</b>
        <el-tag closable style="margin-left: 3px;" @close="criteriaTagClose('year', '', searchForm.year)">{{searchForm.year}}</el-tag>
      </span>
      <span v-show="(searchForm.creator !== null && searchForm.creator !== undefined && Object.keys(searchForm.creator).length > 0)? true: false" style="margin-right: 10px;">
        <b>Uploader:</b>
        <el-tag closable style="margin-left: 3px;" @close="criteriaTagClose('creator', 'id', searchForm.creator.id)">{{searchForm.creator.realName}}</el-tag>
      </span>
      <el-button v-show="(searchForm.paperType.length>0? true: false)
                      || (searchForm.paperStatus.length>0? true: false)
                      || ((searchForm.year !== '' && searchForm.year !== null && searchForm.year !== undefined)? true: false)
                      || ((searchForm.creator !== null && searchForm.creator !== undefined && Object.keys(searchForm.creator).length > 0)? true: false)"
               @click="clearCriteria" type="primary" size="mini">Clear</el-button>
    </el-row>
    <el-card>
      <el-row type="flex" justify="center" align="middle">
        <el-col style="display: flex; justify-content: flex-start;">
          <el-button v-if="writableStatus" type="primary" @click="toPaperAddPage">Add Publication</el-button>
          <el-button type="primary" :disabled="selectedPaperList.length === 0" @click="batchDownloadFiles">Download Publication Files{{selectedPaperList.length > 0 ? '(' + selectedPaperList.length + ')' : ''}}</el-button>
        </el-col>
        <el-col :span="2" style="display: flex; justify-content: flex-end; margin-right: 5px;">
          <el-button type="primary" @click="criteriaDialog">Search Criteria</el-button>
        </el-col>
        <el-col :span="6" style="display: flex; justify-content: flex-end;">
          <el-input v-model="paperSearchKey" clearable @change="searchPaperHandler" placeholder="Please input paper title to search">
            <el-button slot="append" icon="el-icon-search" @click="searchPaperHandler"></el-button>
          </el-input>
        </el-col>
      </el-row>
      <el-table v-loading="paperTableLoading" :data="paperList" :stripe="true" :border="true" :highlight-current-row="true" @selection-change="handleSelectionChange" :row-key="row => row.id">
        <el-table-column type="selection" :reserve-selection="true" align="center"></el-table-column>
        <el-table-column type="index" label="#" align="center" :index="indexPubRow"></el-table-column>
        <el-table-column prop="paperTitle" label="Title" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="paperType.typeName" label="Type" align="center"></el-table-column>
        <el-table-column label="Status" align="center">
          <template v-slot="paperSlot">
            {{ paperSlot.row.paperStatus !== null? paperSlot.row.paperStatus.statusName : '-'}}
          </template>
        </el-table-column>
        <el-table-column label="Year" align="center">
           <template v-slot="paperSlot">
            {{ paperSlot.row.paperYear !== null && paperSlot.row.paperYear !== ''? paperSlot.row.paperYear : '-'}}
          </template>
        </el-table-column>
        <el-table-column label="URL" align="center" :show-overflow-tooltip="true">
          <template v-slot="paperSlot">
            {{ paperSlot.row.paperURL !== null && paperSlot.row.paperURL !== ''? paperSlot.row.paperURL : '-'}}
          </template>
        </el-table-column>
        <el-table-column label="Project" align="center" :show-overflow-tooltip="true">
          <template v-slot="paperSlot">
            <el-tag style="margin-right: 5px;" v-for="project in paperSlot.row.paperRelatedProject" :key="project.id"> &nbsp;{{ project.projectName }}&nbsp; </el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="uploader.realName" label="Uploader" align="center"></el-table-column>
        <el-table-column label="Update Time" align="center" width="160">
          <template v-slot="paperSlot">
            {{paperSlot.row.updateTime | formatDate}}
          </template>
        </el-table-column>
        <el-table-column label="Action" align="center" width="150">
          <template v-slot:="paperSlot">
            <el-button type="primary" :icon="paperSlot.row.uploader.id === currentUser.id ? 'el-icon-edit' : 'el-icon-view'" size="mini" @click="toEditDetail(paperSlot.row)"></el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination style="float: right; margin-bottom: 10px;"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="paginationInfo.currentPage"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="paginationInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="paginationInfo.totalCount">
        </el-pagination>
    </el-card>

    <el-dialog title="Search Criteria" :visible.sync="searchDialogVisible" width="45%" @close="searchDialogClose" :before-close="confirmCloseDialog">
      <el-form :model="criteriaForm" ref="criteriaFormRef" label-width="130px" label-position="top">
        <el-row>
          <el-form-item label="Publication Type" prop="paperType">
            <el-checkbox :value="criteriaForm.paperType.map(item => item.typeName)" v-for="paperType in paperTypeCriteria" :key="paperType.typeCode" :label="paperType.typeName" :checked="checkedPaperType(paperType.typeCode)" @change="checkedPaperTypeChangeHandler($event, paperType)"></el-checkbox>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item label="Publication Status" prop="paperStatus">
            <el-checkbox :value="criteriaForm.paperStatus.map(item => item.statusName)" v-for="paperStatus in paperStatusCriteria" :key="paperStatus.statusCode" :label="paperStatus.statusName" :checked="checkedPaperStatus(paperStatus.statusCode)" @change="checkedPaperStatusChangeHandler($event, paperStatus)"></el-checkbox>
          </el-form-item>
        </el-row>
        <el-row type="flex" justify="start" align="middle">
          <el-col :span="12" style="margin-right: 8px;">
            <el-form-item label="Publication Year" prop="year">
              <el-select v-model="criteriaForm.year" clearable placeholder="publication year" style="width: 100%;">
                <el-option v-for="(i, index) in (paperYearCriteria.endYear - paperYearCriteria.startYear + 1)" :key="index" :label="paperYearCriteria.endYear - index" :value="paperYearCriteria.endYear - index">
              </el-option>
            </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12" style="margin-left: 8px;">
            <el-form-item label="Uploader" prop="creator">
              <el-select :value="criteriaForm.creator.id" filterable clearable placeholder="publication uploader" style="width: 100%;" @change="selectedCreatorChangeHandler">
                <el-option v-for="user in paperCreatorCriteria" :key="user.id" :label="user.realName" :value="user.id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <el-row type="flex" justify="center" align="middle">
        <el-col style="display: flex; justify-content: flex-end; margin-top: 10px;">
          <el-button @click="clearSearchCriteriaDialog">Clear</el-button>
          <el-button @click="resetSearchCriteria">Reset</el-button>
          <el-button type="primary" @click="submitSearchCriteria">Search</el-button>
        </el-col>
      </el-row>
    </el-dialog>

    <el-dialog title="Paper File Download" :visible.sync="downloadDialogFlag" width="30%" :show-close="false"
      :close-on-click-modal="false" :close-on-press-escape="false" :lock-scroll="false" :append-to-body="true">
      <div>There are {{selectedPaperList.length}} {{selectedPaperList.length > 1 ? 'papers' : 'paper'}} waitting for the associated files to be downloaded.</div>
      <div id="downloadMessag"></div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" size="medium" @click="handleDownloadDialogClose" :disabled="disableDownloadCloseBtn">Close</el-button>
      </span>
  </el-dialog>
  </section>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

const PAPER_LIST_CRITERIA_KEY = 'paper_list_criteria_key'

export default {
  async created () {
    this.paperSearchKey = this.paperSearchKeyword
    this.paginationInfo.currentPage = this.currentPaperPage
    this.paginationInfo.pageSize = this.paperPageSize

    const paperListCriteriaStr = window.sessionStorage.getItem(PAPER_LIST_CRITERIA_KEY)
    if (paperListCriteriaStr) {
      this.searchForm = JSON.parse(paperListCriteriaStr)
      this.paperSearchKey = this.searchForm.paperSearchKey
    }
    await this.searchPaperList()
    this.currentUser = await this.$userStorage.getCurrentUser()
  },
  computed: {
    ...mapState(['paperSearchKeyword', 'currentPaperPage', 'paperPageSize', 'writableStatus'])
  },
  data () {
    return {
      paperTableLoading: true,
      currentUser: {},
      paperSearchKey: '',
      paperList: [],
      paginationInfo: {
        currentPage: 1,
        pageSize: 10,
        totalCount: 0
      },
      searchDialogVisible: false,
      criteriaForm: {
        paperType: [],
        paperStatus: [],
        year: '',
        creator: {}
      },
      searchForm: {
        paperSearchKey: null,
        paperType: [],
        paperStatus: [],
        year: '',
        creator: {}
      },
      paperTypeCriteria: [],
      paperStatusCriteria: [],
      paperYearCriteria: {
        startYear: 2017,
        endYear: new Date().getFullYear()
      },
      paperCreatorCriteria: [],
      selectedPaperList: [],
      downloadDialogFlag: false,
      disableDownloadCloseBtn: true
    }
  },
  methods: {
    ...mapMutations(['setRouterActivePath', 'setPaperSearchKeyword', 'setCurrentPaperPage', 'setPaperPageSize']),
    async handleSizeChange (newPageSize) {
      console.debug('page size change', newPageSize)
      this.setPaperPageSize(newPageSize)
      this.paginationInfo.pageSize = newPageSize
      await this.searchPaperList()
    },
    async handleCurrentChange (newCurrentPage) {
      console.debug('current page', newCurrentPage)
      this.setCurrentPaperPage(newCurrentPage)
      this.paginationInfo.currentPage = newCurrentPage
      await this.searchPaperList()
    },
    toEditDetail (paper) {
      console.debug('paper id is', paper)
      this.$router.push({ path: `/paper/detail/${paper.id}/${paper.paperType.typeCode}` })
    },
    toPaperAddPage () {
      this.$router.push('/paper/add')
    },
    async searchPaperHandler () {
      this.paginationInfo.currentPage = 1
      this.paginationInfo.pageSize = 10

      await this.searchPaperList()
    },
    async searchPaperList () {
      this.paperTableLoading = true
      this.setPaperSearchKeyword(this.paperSearchKey)
      const urlParams = this.$qs.stringify(this.paginationInfo)
      // const { data: searchPaperListResp } = await this.$http.get('/paper/list/' + this.paperSearchKey + '?' + urlParams)
      this.searchForm.paperSearchKey = this.paperSearchKey
      const { data: searchPaperListResp } = await this.$http.post(this.serverAPI.PAPER_LIST_URL + '?' + urlParams, JSON.stringify(this.searchForm), { headers: { 'content-type': 'application/json' } })
      console.debug('paper search list reponse content: ', searchPaperListResp)
      this.paperList = searchPaperListResp.data.records
      this.paginationInfo.currentPage = searchPaperListResp.data.current
      this.paginationInfo.pageSize = searchPaperListResp.data.size
      this.paginationInfo.totalCount = searchPaperListResp.data.total
      this.paperTableLoading = false

      window.sessionStorage.setItem(PAPER_LIST_CRITERIA_KEY, JSON.stringify(this.searchForm))
    },
    async criteriaDialog () {
      console.log('open criteria dialog')

      const { data: criteriaResp } = await this.$http.get(this.serverAPI.PAPER_LIST_CRITERIA_URL)
      console.log('paper list criteria response content: ', criteriaResp)

      this.paperTypeCriteria = criteriaResp.data.paperTypes
      this.paperStatusCriteria = criteriaResp.data.paperStatuses
      this.paperCreatorCriteria = criteriaResp.data.userList

      const searchStr = JSON.stringify(this.searchForm)
      this.criteriaForm = JSON.parse(searchStr)

      this.searchDialogVisible = true
    },
    confirmCloseDialog (done) {
      this.$confirm('Close the dialog and reset search criteria?', {
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        closeOnClickModal: false,
        closeOnPressEscape: false
      }).then(_ => {
        done()
      }).catch(_ => {})
    },
    searchDialogClose () {
      this.searchDialogVisible = false
    },
    resetSearchCriteria () {
      this.$confirm('Reset search criteria?', {
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        closeOnClickModal: false,
        closeOnPressEscape: false
      }).then(_ => {
        const searchStr = JSON.stringify(this.searchForm)
        this.criteriaForm = JSON.parse(searchStr)
      }).catch(_ => {
        console.log('cancel reset paper search criteria')
      })
    },
    checkedPaperTypeChangeHandler (checked, selectedPaperType) {
      console.log('checked flag: ', checked)
      console.log('new Paper type', selectedPaperType)
      if (checked) {
        this.criteriaForm.paperType.push(selectedPaperType)
      } else {
        const removeIndex = this.criteriaForm.paperType.findIndex(typeItem => {
          return typeItem.typeCode === selectedPaperType.typeCode
        })

        this.criteriaForm.paperType.splice(removeIndex, 1)
      }
    },
    checkedPaperStatusChangeHandler (checked, selectedPaperStatus) {
      if (checked) {
        this.criteriaForm.paperStatus.push(selectedPaperStatus)
      } else {
        const removeIdex = this.criteriaForm.paperStatus.findIndex(statusItem => {
          return statusItem.statusCode === selectedPaperStatus.statusCode
        })
        this.criteriaForm.paperStatus.splice(removeIdex, 1)
      }
    },
    selectedCreatorChangeHandler (selectedCreatorID) {
      console.log('selected creator id=', selectedCreatorID)
      if (selectedCreatorID === null || selectedCreatorID === '' || selectedCreatorID === undefined) {
        this.criteriaForm.creator = {}
      } else {
        const selectedCreatorItem = this.paperCreatorCriteria.find(creatorItem => {
          if (creatorItem.id === selectedCreatorID) {
            return creatorItem
          }
        })
        console.log('selecte creator item:', selectedCreatorItem)
        this.criteriaForm.creator = selectedCreatorItem
      }
    },
    checkedPaperType (typeCode) {
      const codeIndex = this.criteriaForm.paperType.findIndex(item => {
        return item === typeCode
      })
      return codeIndex >= 0
    },
    checkedPaperStatus (statusCode) {
      const codeIndex = this.criteriaForm.paperStatus.findIndex(item => {
        return item === statusCode
      })
      return codeIndex >= 0
    },
    async submitSearchCriteria () {
      const criteriaStr = JSON.stringify(this.criteriaForm)
      this.searchForm = JSON.parse(criteriaStr)
      this.searchDialogClose()

      // submit the paper search criteria to the server
      await this.searchPaperList()
    },
    async criteriaTagClose (formFieldName, itemFieldName, criteriaContent) {
      console.log('close the tage formFieldName:', formFieldName)
      console.log('close the tage criteria:', criteriaContent)

      if (this.searchForm[formFieldName] instanceof Array) {
        console.log('get search form array field value:', this.searchForm[formFieldName])
        const arrayContent = this.searchForm[formFieldName]
        const criteriaIndex = arrayContent.findIndex(item => {
          return item[itemFieldName] === criteriaContent
        })
        arrayContent.splice(criteriaIndex, 1)
      } else if (this.searchForm[formFieldName] instanceof Object) {
        console.log('get search form object field value:', this.searchForm[formFieldName])
        this.searchForm[formFieldName] = {}
      } else {
        console.log('get search form other types field value:', this.searchForm[formFieldName])
        this.searchForm[formFieldName] = ''
      }

      // submit the paper search criteria to the server
      await this.searchPaperList()
    },
    async clearCriteria () {
      this.searchForm = {
        paperType: [],
        paperStatus: [],
        year: '',
        creator: {}
      }

      this.paginationInfo.currentPage = 1
      this.paginationInfo.pageSize = 10

      await this.searchPaperList()
    },
    clearSearchCriteriaDialog () {
      this.$confirm('Clear search criteria?', {
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        closeOnClickModal: false,
        closeOnPressEscape: false
      }).then(async _ => {
        this.criteriaForm = {
          paperType: [],
          paperStatus: [],
          year: '',
          creator: {}
        }

        await this.clearCriteria()
      }).catch(_ => {
        console.log('cancel to clear the paper list search criteria')
      })
    },
    indexPubRow (index) {
      const newIndex = (this.paginationInfo.currentPage - 1) * this.paginationInfo.pageSize + index + 1
      return newIndex
    },
    handleSelectionChange (selectedVals) {
      console.log('selected data rows:', selectedVals)
      this.selectedPaperList = selectedVals
    },
    async batchDownloadFiles () {
      this.downloadDialogFlag = true

      let downloadMessageContent = ''
      for (let i = 1; i <= this.selectedPaperList.length; i++) {
        const paper = this.selectedPaperList[i - 1]
        console.log('download the paper: ', paper)
        const { data: relatedFileListResp } = await this.$http.get(this.serverAPI.PAPER_FILELIST_URL + paper.id)
        console.log('paper related files : ', relatedFileListResp)

        const relatedPaperFileList = relatedFileListResp.data

        const downloadMsgContainer = document.getElementById('downloadMessag')
        downloadMessageContent += i + '. Paper: "<strong>' + paper.paperTitle + '</strong>" '
        if (!relatedPaperFileList.length) {
          downloadMessageContent += '<span style="color: red;">has no associated files</span>. <br>'
          downloadMsgContainer.innerHTML = downloadMessageContent
        } else {
          downloadMessageContent += 'has ' + relatedPaperFileList.length + ' associated' + (relatedFileListResp.data.length > 1 ? 'files' : 'file') + '.<br>'

          for (let i = 0; i < relatedPaperFileList.length; i++) {
            const relatedFile = relatedPaperFileList[i]
            const paperFileDownloadResp = await this.$http.get(this.serverAPI.FILE_DOWNLOAD_URL + relatedFile.id, { responseType: 'blob' }) // option: 'arraybuffer'
            console.log('paper download response: ', paperFileDownloadResp)
            const data = paperFileDownloadResp.data

            /* const statusFlag = document.getElementById(relatedFile.id)
            console.log('status flag: ', statusFlag)
            statusFlag.style['font-weight'] = 'bold' */
            if (!data) {
              downloadMessageContent += '&emsp;&emsp;' + relatedFile.fileName + '<i class="el-icon-circle-close" style="color: red; font-weight: bold;"></i><br>'
              // statusFlag.classList.add('el-icon-circle-close')
              // statusFlag.style.color = 'red'
            } else {
              downloadMessageContent += '&emsp;&emsp;' + relatedFile.fileName + '<i class="el-icon-circle-check" style="color: green; font-weight: bold;"></i><br>'
              // statusFlag.classList.add('el-icon-circle-check')
              // statusFlag.style.color = 'green'

              const fileBlob = new Blob([data])
              const fileUrl = window.URL.createObjectURL(fileBlob)

              const linka = document.createElement('a')
              linka.setAttribute('download', relatedFile.fileName)
              linka.style.display = 'none'
              linka.href = fileUrl
              // console.log('file name:' + relatedFile.fileName + ' fileUrl: ' + fileUrl)

              document.body.appendChild(linka)
              linka.click()

              window.URL.revokeObjectURL(linka.href)
              document.body.removeChild(linka)
            }

            downloadMsgContainer.innerHTML = downloadMessageContent
          }
        }
      }

      this.disableDownloadCloseBtn = false
    },
    handleDownloadDialogClose () {
      console.log('dialog close')
      this.downloadDialogFlag = false
      this.disableDownloadCloseBtn = true
      // clear the download message
      document.getElementById('downloadMessag').innerHTML = ''
    }
  }
}
</script>
<style scoped>
div >>> div.el-dialog__body {
  padding-top: 0;
  padding-bottom: 10px;
}
</style>
