<template>
  <section>
    <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>Home</el-breadcrumb-item>
        <el-breadcrumb-item>Publication Module</el-breadcrumb-item>
        <el-breadcrumb-item>Add Publication</el-breadcrumb-item>
    </el-breadcrumb>
    <h1 style="margin-top: 15px;margin-bottom: 10px; font-size: 20px;">Add Publication</h1>

    <!--article form start-->
    <PaperArticleForm ref="paperFormRef" v-if="articleFormVisible" :isAdd="true" :monthOptions="months" :yearOptions="years"
      :paperTypeOptions="paperTypes" :paperStatusOptions="paperStatuses"></PaperArticleForm>
    <!--article form end-->

    <!--book form start-->
    <PaperBookForm ref="paperFormRef" v-else-if="bookFormVisible" :isAdd="true" :monthOptions="months" :yearOptions="years"
      :paperTypeOptions="paperTypes" :paperStatusOptions="paperStatuses"></PaperBookForm>
    <!--book form end-->

    <!--inBook form start-->
    <PaperInBookForm ref="paperFormRef" v-else-if="inbookFormVisible" :isAdd="true" :monthOptions="months" :yearOptions="years"
      :paperTypeOptions="paperTypes" :paperStatusOptions="paperStatuses"></PaperInBookForm>
    <!--inBook form end-->

    <!--thesis form start-->
    <PaperThesisForm ref="paperFormRef" v-else-if="thesisFormVisible" :isAdd="true" :monthOptions="months" :yearOptions="years"
      :paperTypeOptions="paperTypes" :paperStatusOptions="paperStatuses"></PaperThesisForm>
    <!--thesis form end-->

    <!--draft form start-->
    <PaperManuscriptForm ref="paperFormRef" v-else-if="unpublishedFormVisible" :isAdd="true" :monthOptions="months" :yearOptions="years"
      :paperTypeOptions="paperTypes" :paperStatusOptions="paperStatuses"></PaperManuscriptForm>
    <!--draft form end-->
  </section>
</template>
<script>
import { mapMutations } from 'vuex'
import { EVENT_CONFIG } from '@/event_bus'
import PaperArticleForm from '@/components/paper_component/PaperArticleForm.vue'
import PaperBookForm from '@/components/paper_component/PaperBookForm.vue'
import PaperInBookForm from '@/components/paper_component/PaperInBookForm.vue'
import PaperThesisForm from '@/components/paper_component/PaperThesisForm.vue'
import PaperManuscriptForm from '@/components/paper_component/PaperManuscriptForm.vue'
import { DRAFT_GENERATOR } from '@/local_storage/DraftGenerator'

export default {
  async created () {
    EVENT_CONFIG.EventBus.$on(EVENT_CONFIG.PAPER_TYPE_CHANGE_EVENT, this.paperTypeChangeHandler)
    EVENT_CONFIG.EventBus.$on(EVENT_CONFIG.PAPER_FORM_CHANGE_EVENT, this.paperFormChangeHandler)

    // TODO: get the draft from local storage to init the publication add page
    const paperDraft = await DRAFT_GENERATOR.getPublicationDraft()
    if (paperDraft) {
      this.paperTypeChangeHandler(paperDraft.paperType.typeCode)
    }
  },
  beforeRouteLeave (to, from, next) {
    console.log('before leaving the PaperAdd.vue page changeFlag: ', this.paperFormChange)
    if (this.paperFormChange) {
      this.$confirm('Leave the publication add page and save the unsubmitted data as local draft?', {
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        closeOnClickModal: false,
        closeOnPressEscape: false
      }).then(_ => {
        const paperFormRef = this.$refs.paperFormRef
        console.log('confirm to leave PaperAdd.vue: ', paperFormRef.paperDataForm)
        DRAFT_GENERATOR.storePublicationDraft(paperFormRef.paperDataForm)
        next()
      }).catch((error) => {
        console.log('cancel to leave PaperAdd.vue', error)
        this.setRouterActivePath('/paper/add')
      })
    } else {
      next()
    }
  },
  beforeDestroy () {
    console.debug('PaperAdd.vue beforeDestory method executes.....')
    EVENT_CONFIG.EventBus.$off(EVENT_CONFIG.PAPER_TYPE_CHANGE_EVENT)
    EVENT_CONFIG.EventBus.$off(EVENT_CONFIG.PAPER_FORM_CHANGE_EVENT)
  },
  components: {
    PaperArticleForm,
    PaperBookForm,
    PaperInBookForm,
    PaperThesisForm,
    PaperManuscriptForm
  },
  data () {
    return {
      paperFormChange: false,
      articleFormVisible: true,
      bookFormVisible: false,
      inbookFormVisible: false,
      thesisFormVisible: false,
      unpublishedFormVisible: false,
      projectOptions: [],
      projectSearchSelectOptions: [],
      searchDataLoading: false,
      dataSelectOptions: [],
      dataSearchSelectOptions: [],
      diableSubmitPaperBtn: false,
      paperTypes: [
        { value: 1, label: 'Article' },
        { value: 2, label: 'Book' },
        { value: 3, label: 'Inbook' },
        { value: 4, label: 'Thesis' },
        { value: 5, label: 'Manuscript' }
      ],
      paperStatuses: [
        // { value: 1, label: 'unpublished' },
        { value: 2, label: 'submitted' },
        { value: 3, label: 'accepted' },
        { value: 4, label: 'published' }
      ],
      months: [
        { label: 'January', value: 1 },
        { label: 'Feburary', value: 2 },
        { label: 'March', value: 3 },
        { label: 'April', value: 4 },
        { label: 'May', value: 5 },
        { label: 'June', value: 6 },
        { label: 'July', value: 7 },
        { label: 'August', value: 8 },
        { label: 'September', value: 9 },
        { label: 'October', value: 10 },
        { label: 'November', value: 11 },
        { label: 'December', value: 12 }
      ],
      years: {
        startYear: 2017,
        endYear: new Date().getFullYear()
      }
    }
  },
  methods: {
    ...mapMutations({
      setRouterActivePath: 'setRouterActivePath'
    }),
    paperTypeChangeHandler (selectedType) {
      console.log('paper type changed typecode=', selectedType)
      this.articleFormVisible = false
      this.bookFormVisible = false
      this.inbookFormVisible = false
      this.thesisFormVisible = false
      this.unpublishedFormVisible = false

      if (selectedType === 1 || selectedType === undefined || selectedType === null || selectedType === '') {
        this.articleFormVisible = true
      }

      if (selectedType === 2) {
        this.bookFormVisible = true
      }

      if (selectedType === 3) {
        this.inbookFormVisible = true
      }

      if (selectedType === 4) {
        this.thesisFormVisible = true
      }

      if (selectedType === 5) {
        this.unpublishedFormVisible = true
      }
    },
    paperFormChangeHandler (formChange) {
      console.log('from the publication form change: ', formChange)
      this.paperFormChange = formChange
    }
  }
}
</script>
<style scoped>

</style>
