import './assets/global.css'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element.js'
import axios from 'axios'
import qs from 'qs'
import Moment from 'moment'
import uploader from 'vue-simple-uploader'
import { USER_LOCAL_STORAGE } from './local_storage/UserLocalStorage'
import { SERVER_API } from './server_api'
import { Loading } from 'element-ui'

axios.defaults.withCredentials = true
// TODO: set up the common request
axios.interceptors.request.use((config) => {
  return config
})
// parse the reponse from the server end
axios.interceptors.response.use(response => {
  console.debug('all response content: ', response)
  // user not login to user sso login page
  if (response.data.code === 2001) {
    Loading.service({
      lock: true,
      text: 'Loginng in please wait...'
    })
    USER_LOCAL_STORAGE.removeCurrentUser()
    window.location.replace(response.data.data.redirectURL)
  }

  if (response.data.code === 2002) {
    console.info('go to user register page:', response.data.data)
    // put the user info into the localstorage
    USER_LOCAL_STORAGE.setCurrentUser(response.data.data)
    // user not register in the system
    // router.push({ name: 'userAdd', params: { userInfo: response.data.data } })
    router.push({ name: 'userRegister' })
    return Promise.reject(response)
  }

  return response
})
Vue.prototype.$qs = qs
Vue.prototype.$http = axios
Vue.prototype.$userStorage = USER_LOCAL_STORAGE
Vue.prototype.serverAPI = SERVER_API

// Moment is a date format lib and used as a filter of Vue instance
Vue.filter('formatDate', function (value) {
  return Moment(value).format('YYYY-MM-DD HH:mm:ss')
})

Vue.use(uploader)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
