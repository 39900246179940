<template>
  <section>
  <el-row :style="!searchForm.paperType.length ?
                   (!searchForm.paperStatus.length?
                    (!(searchForm.year !== '' && searchForm.year !== null && searchForm.year !== undefined)?
                     (!(searchForm.creator !== null && searchForm.creator !== undefined && Object.keys(searchForm.creator).length > 0) ? '': 'border-bottom: solid 1px #d7dae2; margin-bottom: 3px; padding-bottom: 3px;')
                    : 'border-bottom: solid 1px #d7dae2; margin-bottom: 3px; padding-bottom: 3px;')
                   : 'border-bottom: solid 1px #d7dae2; margin-bottom: 3px; padding-bottom: 3px;')
                  : 'border-bottom: solid 1px #d7dae2; margin-bottom: 3px; padding-bottom: 3px;'">
      <span v-show="searchForm.paperType.length>0? true: false" style="margin-right: 10px;">
        <b>Publication Type:</b>
        <el-tag v-for="typeItem in searchForm.paperType" :key="typeItem.typeCode" closable style="margin-left: 3px;" @close="criteriaTagClose('paperType', 'typeCode', typeItem.typeCode)">{{typeItem.typeName}}</el-tag>
      </span>
      <span v-show="searchForm.paperStatus.length>0? true: false" style="margin-right: 10px;">
        <b>Publication Status:</b>
        <el-tag v-for="statusItem in searchForm.paperStatus" :key="statusItem.statusCode" closable style="margin-left: 3px;" @close="criteriaTagClose('paperStatus', 'statusCode', statusItem.statusCode)">{{statusItem.statusName}}</el-tag>
      </span>
      <span v-show="(searchForm.year !== '' && searchForm.year !== null && searchForm.year !== undefined)? true: false" style="margin-right: 10px;">
        <b>Publication Year:</b>
        <el-tag closable style="margin-left: 3px;" @close="criteriaTagClose('year', '', searchForm.year)">{{searchForm.year}}</el-tag>
      </span>
      <span v-show="(searchForm.creator !== null && searchForm.creator !== undefined && Object.keys(searchForm.creator).length > 0)? true: false" style="margin-right: 10px;">
        <b>Uploader:</b>
        <el-tag closable style="margin-left: 3px;" @close="criteriaTagClose('creator', 'id', searchForm.creator.id)">{{searchForm.creator.realName}}</el-tag>
      </span>
      <el-button
        v-show="(searchForm.paperType.length>0? true: false)
             || (searchForm.paperStatus.length>0? true: false)
             || ((searchForm.year !== '' && searchForm.year !== null && searchForm.year !== undefined)? true: false)
             || ((searchForm.creator !== null && searchForm.creator !== undefined && Object.keys(searchForm.creator).length > 0)? true: false)"
        @click="clearCriteria" type="primary" size="mini">
        Clear
      </el-button>
    </el-row>

    <el-row type="flex" justify="center" align="middle">
      <el-col style="display: flex; justify-content: flex-end; margin-right: 5px;">
        <el-button type="primary" @click="criteriaDialog">Search Criteria</el-button>
      </el-col>
      <el-col :span="6" style="display: flex; justify-content: flex-end;">
        <el-input v-model="paperSearchKey" clearable @change="searchPaperHandler" placeholder="Please input paper title to search">
          <el-button slot="append" icon="el-icon-search" @click="searchPaperHandler"></el-button>
        </el-input>
      </el-col>
    </el-row>

    <el-table v-loading="paperTableLoading" :data="paperList" :stripe="true" :border="true" :highlight-current-row="true">
      <el-table-column type="index" label="#" align="center" :index="indexPubFileRow"></el-table-column>
      <el-table-column label="Title" :show-overflow-tooltip="true">
        <template late v-slot="paperSlot">
          <a class="paper-title" @click.prevent="getSubFile(paperSlot.row)">
            <img :src="paperSlot.row | folderIcon" style="width: 20px; height: 20px; margin-right: 3px; vertical-align:middle"/>
            {{ paperSlot.row.paperTitle }}
          </a>
        </template>
      </el-table-column>
      <el-table-column prop="paperType.typeName" label="Type" align="center"></el-table-column>
      <el-table-column label="Status" align="center">
        <template v-slot="paperSlot">
          {{ paperSlot.row.paperStatus !== null? paperSlot.row.paperStatus.statusName : '-'}}
        </template>
      </el-table-column>
      <el-table-column label="Year" align="center">
          <template v-slot="paperSlot">
          {{ paperSlot.row.paperYear !== null && paperSlot.row.paperYear !== ''? paperSlot.row.paperYear : '-'}}
        </template>
      </el-table-column>
      <el-table-column label="URL" align="center" :show-overflow-tooltip="true">
        <template v-slot="paperSlot">
          {{ paperSlot.row.paperURL !== null && paperSlot.row.paperURL !== ''? paperSlot.row.paperURL : '-'}}
        </template>
      </el-table-column>
      <el-table-column prop="uploader.realName" label="Uploader" align="center"></el-table-column>
      <el-table-column label="Update Time" align="center" width="160">
        <template v-slot="paperSlot">
          {{paperSlot.row.updateTime | formatDate}}
        </template>
      </el-table-column>
    </el-table>

    <el-pagination style="float: right; margin-bottom: 10px;"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="paginationInfo.currentPage"
      :page-sizes="[10, 15, 20, 30, 50]"
      :page-size="paginationInfo.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="paginationInfo.totalCount">
    </el-pagination>

    <el-dialog title="Search Criteria" :visible.sync="searchDialogVisible" width="45%" @close="searchDialogClose" :before-close="confirmCloseDialog">
      <el-form :model="criteriaForm" ref="criteriaFormRef" label-width="130px" label-position="top">
        <el-row>
          <el-form-item label="Publication Type" prop="paperType">
            <el-checkbox :value="criteriaForm.paperType.map(item => item.typeName)" v-for="paperType in paperTypeCriteria" :key="paperType.typeCode" :label="paperType.typeName" :checked="checkedPaperType(paperType.typeCode)" @change="checkedPaperTypeChangeHandler($event, paperType)"></el-checkbox>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item label="Publication Status" prop="paperStatus">
            <el-checkbox :value="criteriaForm.paperStatus.map(item => item.statusName)" v-for="paperStatus in paperStatusCriteria" :key="paperStatus.statusCode" :label="paperStatus.statusName" :checked="checkedPaperStatus(paperStatus.statusCode)" @change="checkedPaperStatusChangeHandler($event, paperStatus)"></el-checkbox>
          </el-form-item>
        </el-row>
        <el-row type="flex" justify="start" align="middle">
          <el-col :span="12" style="margin-right: 8px;">
            <el-form-item label="Publication Year" prop="year">
              <el-select v-model="criteriaForm.year" clearable placeholder="publication year" style="width: 100%;">
                <el-option v-for="(i, index) in (paperYearCriteria.endYear - paperYearCriteria.startYear + 1)" :key="index" :label="paperYearCriteria.endYear - index" :value="paperYearCriteria.endYear - index">
              </el-option>
            </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12" style="margin-left: 8px;">
            <el-form-item label="Uploader" prop="creator">
              <el-select :value="criteriaForm.creator.id" filterable clearable placeholder="paper uploader" style="width: 100%;" @change="selectedCreatorChangeHandler">
                <el-option v-for="user in paperCreatorCriteria" :key="user.id" :label="user.realName" :value="user.id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <el-row type="flex" justify="center" align="middle">
        <el-col style="display: flex; justify-content: flex-end; margin-top: 10px; margin-bottom: 10px;">
          <el-button @click="clearSearchCriteriaDialog">Clear</el-button>
          <el-button @click="resetSearchCriteria">Reset</el-button>
          <el-button type="primary" @click="submitSearchCriteria">Search</el-button>
        </el-col>
      </el-row>
    </el-dialog>

    <el-dialog :title="subFileListDialogTitle" width="50%" :visible.sync="subFileListFlag">
      <el-row style="padding-bottom: 20px;">
        <el-table v-loading="subfileLoading" size="mini" ref="subfileTable"
          :data="subfileList" :row-key="row => row.rowkey" border stripe
          style="margin-top: 0;" :tree-props="{hasChildren: 'isFolder'}" lazy :load="load">
          <el-table-column type="selection" :reserve-selection="true" width="55" align="center"></el-table-column>
          <el-table-column label="File Path" :show-overflow-tooltip="true">
            <template v-slot="fileProps">
              {{ fileProps.row.fileName }}
            </template>
          </el-table-column>
          <el-table-column label="File Type" align="center">
            <template v-slot="fileProps">
              {{ fileProps.row | showFileType }}
              <img :src="fileProps.row | getFileIcon" class="name-col-icon" alt="file type icon" style="width: 20px; height: 20px; vertical-align:middle"/>
            </template>
          </el-table-column>
          <el-table-column label="Status" align="center">
            <template v-slot="fileProps">
              <span v-show="fileProps.row.fileStatus.statusCode === 1"><i class="el-icon-question" style="font-size: 12px; color: #e6a23c; margin-left: 3px;"></i>Unmerged</span>
              <span v-show="fileProps.row.fileStatus.statusCode === 2"><i class="el-icon-loading" style="font-size: 12px; color: #409EFF; margin-left: 3px;"></i>Merging</span>
              <span v-show="fileProps.row.fileStatus.statusCode === 3"><i class="el-icon-info" style="font-size: 12px; color: #909399; margin-left: 3px;"></i>Merged</span>
              <span v-show="fileProps.row.fileStatus.statusCode === 4"><i class="el-icon-error" style="font-size: 12px; color: #f56c6c; margin-left: 3px;"></i>Failed</span>
              <span v-show="fileProps.row.fileStatus.statusCode === 5"><i class="el-icon-success" style="font-size: 12px; color: #67c23a; margin-left: 3px;"></i>Success</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="Size">
            <template v-slot="fileProps">
              {{ fileProps.row | fileSizeFormat }}
            </template>
          </el-table-column>
          <el-table-column align="center" width="100">
            <template v-slot="fileProps">
              <el-button v-if="!fileProps.row.isFolder" type="text" style="font-weight: bold;" @click="checkFileStatusBeforeDownload(fileProps.row.fileUniqueID)">Download</el-button>
              <span v-else> - </span>
            </template>
          </el-table-column>
        </el-table>
      </el-row>
    </el-dialog>
  </section>
</template>
<script>
import { FILE_TYPE } from '@/components/file_component/fileconfig'
import { EVENT_CONFIG } from '@/event_bus'

export default {
  created () {
    EVENT_CONFIG.EventBus.$on(EVENT_CONFIG.FILE_PAPER_LIST_EVENT, this.searchPaperList)
  },
  beforeDestroy () {
    console.log('the publication file list table destoryed')
    EVENT_CONFIG.EventBus.$off(EVENT_CONFIG.FILE_PAPER_LIST_EVENT)
  },
  filters: {
    folderIcon () {
      return require('@/' + FILE_TYPE.FOLDER.typeIcon)
    },
    getFileIcon (fieldFileRelation) {
      let fileType
      if (fieldFileRelation.isFolder) {
        fileType = FILE_TYPE.FOLDER
      } else {
        fileType = FILE_TYPE.getFileType(fieldFileRelation.fileName)
      }

      const iconPath = require('@/' + `${fileType.typeIcon}`)
      return iconPath
    },
    showFileType (file) {
      if (file.isFolder) {
        return FILE_TYPE.FOLDER.typeName
      }

      const fileType = FILE_TYPE.getFileType(file.fileName)
      return fileType.typeName
    },
    fileSizeFormat (file) {
      console.debug('file name: %s and file size %s', file.fileName, file.fileSize)
      if (file.fileSize === null) return '-'
      if (file.fileSize < 1024) {
        // lower than 1024, the unit is 'Bytes'
        return file.fileSize + ' Bytes'
      }
      if (file.fileSize < (1024 * 1024)) {
        // bigger than 1024 and smaller than 1024*1024, the unit is 'KB'
        const _kb = (file.fileSize / 1024).toFixed(2)
        return parseFloat(_kb) + ' KB'
      }

      if (file.fileSize < (1024 * 1024 * 1024)) {
        // bigger than 1024*1024 and smaller than 1024*1024*1024, the unit is 'MB'
        const _mb = (file.fileSize / (1024 * 1024)).toFixed(2)
        return parseFloat(_mb) + ' MB'
      }

      const _gb = (file.fileSize / (1024 * 1024 * 1024)).toFixed(2)
      return parseFloat(_gb) + ' GB'
    }
  },
  data () {
    return {
      subFileListDialogTitle: '',
      subFileListFlag: false,
      subfileLoading: true,
      paperTableLoading: true,
      searchDialogVisible: false,
      paginationInfo: {
        currentPage: 1,
        pageSize: 15,
        totalCount: 0
      },
      paperTypeCriteria: [],
      paperStatusCriteria: [],
      paperYearCriteria: {
        startYear: 2017,
        endYear: new Date().getFullYear()
      },
      paperCreatorCriteria: [],
      criteriaForm: {
        paperType: [],
        paperStatus: [],
        year: '',
        creator: {}
      },
      searchForm: {
        paperType: [],
        paperStatus: [],
        year: '',
        creator: {}
      },
      paperSearchKey: '',
      paperList: [],
      subfileList: []
    }
  },
  methods: {
    async handleSizeChange (newPageSize) {
      console.debug('page size change', newPageSize)
      this.paginationInfo.pageSize = newPageSize
      await this.searchPaperList()
    },
    async handleCurrentChange (newCurrentPage) {
      console.debug('current page', newCurrentPage)
      this.paginationInfo.currentPage = newCurrentPage
      await this.searchPaperList()
    },
    async criteriaDialog () {
      console.log('open criteria dialog')

      const { data: criteriaResp } = await this.$http.get(this.serverAPI.PAPER_LIST_CRITERIA_URL)
      console.log('paper list criteria response content: ', criteriaResp)

      this.paperTypeCriteria = criteriaResp.data.paperTypes
      this.paperStatusCriteria = criteriaResp.data.paperStatuses
      this.paperCreatorCriteria = criteriaResp.data.userList

      const searchStr = JSON.stringify(this.searchForm)
      this.criteriaForm = JSON.parse(searchStr)

      this.searchDialogVisible = true
    },
    checkedPaperType (typeCode) {
      const codeIndex = this.criteriaForm.paperType.findIndex(item => {
        return item === typeCode
      })
      return codeIndex >= 0
    },
    checkedPaperStatus (statusCode) {
      const codeIndex = this.criteriaForm.paperStatus.findIndex(item => {
        return item === statusCode
      })
      return codeIndex >= 0
    },
    checkedPaperTypeChangeHandler (checked, selectedPaperType) {
      console.log('checked flag: ', checked)
      console.log('new Paper type', selectedPaperType)
      if (checked) {
        this.criteriaForm.paperType.push(selectedPaperType)
      } else {
        const removeIndex = this.criteriaForm.paperType.findIndex(typeItem => {
          return typeItem.typeCode === selectedPaperType.typeCode
        })

        this.criteriaForm.paperType.splice(removeIndex, 1)
      }
    },
    checkedPaperStatusChangeHandler (checked, selectedPaperStatus) {
      if (checked) {
        this.criteriaForm.paperStatus.push(selectedPaperStatus)
      } else {
        const removeIdex = this.criteriaForm.paperStatus.findIndex(statusItem => {
          return statusItem.statusCode === selectedPaperStatus.statusCode
        })
        this.criteriaForm.paperStatus.splice(removeIdex, 1)
      }
    },
    async criteriaTagClose (formFieldName, itemFieldName, criteriaContent) {
      console.log('close the tage formFieldName:', formFieldName)
      console.log('close the tage criteria:', criteriaContent)

      if (this.searchForm[formFieldName] instanceof Array) {
        console.log('get search form array field value:', this.searchForm[formFieldName])
        const arrayContent = this.searchForm[formFieldName]
        const criteriaIndex = arrayContent.findIndex(item => {
          return item[itemFieldName] === criteriaContent
        })
        arrayContent.splice(criteriaIndex, 1)
      } else if (this.searchForm[formFieldName] instanceof Object) {
        console.log('get search form object field value:', this.searchForm[formFieldName])
        this.searchForm[formFieldName] = {}
      } else {
        console.log('get search form other types field value:', this.searchForm[formFieldName])
        this.searchForm[formFieldName] = ''
      }

      // submit the paper search criteria to the server
      await this.searchPaperList()
    },
    async clearCriteria () {
      this.searchForm = {
        paperType: [],
        paperStatus: [],
        year: '',
        creator: {}
      }

      this.paginationInfo.currentPage = 1
      this.paginationInfo.pageSize = 15

      await this.searchPaperList()
    },
    async searchPaperHandler () {
      this.paginationInfo.currentPage = 1
      this.paginationInfo.pageSize = 15

      await this.searchPaperList()
    },
    searchDialogClose () {
      this.searchDialogVisible = false
    },
    confirmCloseDialog (done) {
      this.$confirm('Close the dialog and reset search criteria?', {
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        closeOnClickModal: false,
        closeOnPressEscape: false
      }).then(_ => {
        done()
      }).catch(_ => {})
    },
    clearSearchCriteriaDialog () {
      this.$confirm('Clear search criteria?', {
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        closeOnClickModal: false,
        closeOnPressEscape: false
      }).then(async _ => {
        this.criteriaForm = {
          paperType: [],
          paperStatus: [],
          year: '',
          creator: {}
        }

        await this.clearCriteria()
      }).catch(_ => {
        console.log('cancel to clear the paper list search criteria')
      })
    },
    resetSearchCriteria () {
      this.$confirm('Reset search criteria?', {
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        closeOnClickModal: false,
        closeOnPressEscape: false
      }).then(_ => {
        const searchStr = JSON.stringify(this.searchForm)
        this.criteriaForm = JSON.parse(searchStr)
      }).catch(_ => {
        console.log('cancel reset paper search criteria')
      })
    },
    async submitSearchCriteria () {
      const criteriaStr = JSON.stringify(this.criteriaForm)
      this.searchForm = JSON.parse(criteriaStr)
      this.searchDialogClose()

      // submit the paper search criteria to the server
      await this.searchPaperList()
    },
    selectedCreatorChangeHandler (selectedCreatorID) {
      console.log('selected creator id=', selectedCreatorID)
      if (selectedCreatorID === null || selectedCreatorID === '' || selectedCreatorID === undefined) {
        this.criteriaForm.creator = {}
      } else {
        const selectedCreatorItem = this.paperCreatorCriteria.find(creatorItem => {
          if (creatorItem.id === selectedCreatorID) {
            return creatorItem
          }
        })
        console.log('selecte creator item:', selectedCreatorItem)
        this.criteriaForm.creator = selectedCreatorItem
      }
    },
    async searchPaperList () {
      this.paperTableLoading = true
      const urlParams = this.$qs.stringify(this.paginationInfo)
      const { data: searchPaperListResp } = await this.$http.post(this.serverAPI.FILE_VIEW_PAPER_URL + this.paperSearchKey + '?' + urlParams, JSON.stringify(this.searchForm), { headers: { 'content-type': 'application/json' } })
      console.log('paper search list reponse content: ', searchPaperListResp)
      this.paperList = searchPaperListResp.data.records
      this.paginationInfo.currentPage = searchPaperListResp.data.current
      this.paginationInfo.pageSize = searchPaperListResp.data.size
      this.paginationInfo.totalCount = searchPaperListResp.data.total
      this.paperTableLoading = false
    },
    async getSubFile (paperRow) {
      this.subFileListFlag = true
      this.subFileListDialogTitle = 'File List of the Publication \'' + paperRow.paperTitle + '\''
      this.subfileLoading = true

      const paperListURL = this.serverAPI.PAPER_FILELIST_URL + paperRow.id
      this.subfileList = await this.requestSubFile(paperListURL)
      this.subfileLoading = false
    },
    async requestSubFile (filesUrl) {
      const { data: subFileResp } = await this.$http.get(filesUrl)
      console.log('sub file list response: ', subFileResp)
      const subfileList = subFileResp.data
      for (const subFile of subfileList) {
        subFile.rowkey = subFile.id + '-' + new Date().getTime()
      }

      return subfileList
    },
    async load (file, tableTreeCof, resolve) {
      const subFileListURL = this.serverAPI.FILE_SUBLIST_URL + file.id
      const subFileList = await this.requestSubFile(subFileListURL)
      resolve(subFileList)
    },
    indexPubFileRow (index) {
      const newIndex = (this.paginationInfo.currentPage - 1) * this.paginationInfo.pageSize + index + 1
      return newIndex
    }
  }
}
</script>
<style scoped>
div >>> div.el-dialog__body {
  padding-top: 0px;
  padding-bottom: 0px;
}
div >>> .el-table .selected-row td {
  background-color: #f0f9eb !important;
}
div >>> th{
  line-height: 28px;
  font-weight: bold;
}
.paper-title:hover {
  cursor: pointer;
  text-decoration: none;
  font-weight: bold;
  color: #409EFF;
}
</style>
