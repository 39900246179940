<template>
  <section>
    <el-row :gutter="layoutConf.row.gutter" :style="layoutConf.row.styleContent">
      <el-col v-if="isRelated && projectRelatedProjects !== null && projectRelatedProjects.length > 0" :lg="layoutConf.col.lgSpan" :md="layoutConf.col.mdSpan">
        <el-form-item label="Related Projects" class="data-form-label" prop="relatedProject">
          <el-checkbox-group v-if="creator.id === currentUser.id" v-model="nonCommonFormData.relatedProject">
            <el-checkbox :disabled="!writeSwitch" name="relatedProject" v-for="relatedProject in projectRelatedProjects" :label="relatedProject.id" :key="relatedProject.id">{{relatedProject.projectName}}</el-checkbox>
          </el-checkbox-group>
          <span v-else>
            <span v-if="projectList.length > 0">
              <el-tag v-for="(relatedProject, index) in nonCommonFormData.relatedProject" :key="relatedProject.id" :style="index > 0? 'margin-left: 5px' : ''">
                {{ projectList.find(project => project.id === relatedProject).projectName }}
              </el-tag>
            </span>
          </span>
        </el-form-item>
      </el-col>
      <el-col v-show="isRelated? nonCommonFieldRelated['geneName']: !nonCommonFieldRelated['geneName']" :lg="layoutConf.col.lgSpan" :md="layoutConf.col.mdSpan">
        <el-form-item label="Gene Name" class="data-form-label" prop="geneName">
          <el-input :disabled="!writeSwitch" name="geneName" v-model="nonCommonFormData.geneName" clearable :readonly="!(creator.id === currentUser.id)"></el-input>
        </el-form-item>
      </el-col>
      <el-col v-show="isRelated? nonCommonFieldRelated['proteinName']: !nonCommonFieldRelated['proteinName']" :lg="layoutConf.col.lgSpan" :md="layoutConf.col.mdSpan">
        <el-form-item label="Protein Name" class="data-form-label" prop="proteinName">
          <el-input :disabled="!writeSwitch" name="proteinName" v-model="nonCommonFormData.proteinName" clearable :readonly="!(creator.id === currentUser.id)"></el-input>
        </el-form-item>
      </el-col>
      <el-col v-show="isRelated? nonCommonFieldRelated['proteinStructure']: !nonCommonFieldRelated['proteinStructure']" :lg="layoutConf.col.lgSpan" :md="layoutConf.col.mdSpan">
        <el-form-item label="Protein Structure" class="data-form-label" prop="proteinStructure">
          <el-input :disabled="!writeSwitch" name="proteinStructure" v-model="nonCommonFormData.proteinStructure" clearable :readonly="!(creator.id === currentUser.id)"></el-input>
        </el-form-item>
      </el-col>
      <el-col v-show="isRelated? nonCommonFieldRelated['antibody']: !nonCommonFieldRelated['antibody']" :lg="layoutConf.col.lgSpan" :md="layoutConf.col.mdSpan">
        <el-form-item label="Antibody" class="data-form-label" prop="antibody">
          <el-input :disabled="!writeSwitch" name="antibody" v-model="nonCommonFormData.antibody" clearable :readonly="!(creator.id === currentUser.id)"></el-input>
        </el-form-item>
      </el-col>
      <el-col v-show="isRelated? nonCommonFieldRelated['cellType']: !nonCommonFieldRelated['cellType']" :lg="layoutConf.col.lgSpan" :md="layoutConf.col.mdSpan">
        <el-form-item label="Cell Type" class="data-form-label" prop="cellType">
          <el-input :disabled="!writeSwitch" name="cellType" v-model="nonCommonFormData.cellType" clearable :readonly="!(creator.id === currentUser.id)"></el-input>
        </el-form-item>
      </el-col>
      <el-col v-show="isRelated? nonCommonFieldRelated['nerveName']: !nonCommonFieldRelated['nerveName']" :lg="layoutConf.col.lgSpan" :md="layoutConf.col.mdSpan">
        <el-form-item label="Nerve Name" class="data-form-label" prop="nerveName">
          <el-input :disabled="!writeSwitch" name="nerveName" v-model="nonCommonFormData.nerveName" clearable :readonly="!(creator.id === currentUser.id)"></el-input>
        </el-form-item>
      </el-col>
      <el-col v-show="isRelated? nonCommonFieldRelated['brainRegion']: !nonCommonFieldRelated['brainRegion']" :lg="layoutConf.col.lgSpan" :md="layoutConf.col.mdSpan">
        <el-form-item label="Brain Region" class="data-form-label" prop="brainRegion">
          <el-input :disabled="!writeSwitch" name="brainRegion" v-model="nonCommonFormData.brainRegion" clearable :readonly="!(creator.id === currentUser.id)"></el-input>
        </el-form-item>
      </el-col>
      <el-col v-show="isRelated? nonCommonFieldRelated['sensorySystem']: !nonCommonFieldRelated['sensorySystem']" :lg="layoutConf.col.lgSpan" :md="layoutConf.col.mdSpan">
        <el-form-item label="Sensory System" class="data-form-label" prop="sensorySystem">
          <el-input :disabled="!writeSwitch" name="sensorySystem" v-model="nonCommonFormData.sensorySystem" clearable :readonly="!(creator.id === currentUser.id)"></el-input>
        </el-form-item>
      </el-col>
      <el-col v-show="isRelated? nonCommonFieldRelated['surgery']: !nonCommonFieldRelated['surgery']" :lg="layoutConf.col.lgSpan" :md="layoutConf.col.mdSpan">
        <el-form-item label="Surgery" class="data-form-label" prop="surgery">
          <el-input :disabled="!writeSwitch" name="surgery" v-model="nonCommonFormData.surgery" clearable :readonly="!(creator.id === currentUser.id)"></el-input>
        </el-form-item>
      </el-col>
      <el-col v-show="isRelated? nonCommonFieldRelated['experimentAnimalSpecies']: !nonCommonFieldRelated['experimentAnimalSpecies']" :lg="layoutConf.col.lgSpan" :md="layoutConf.col.mdSpan">
        <el-form-item label="Experiment Animal Species" class="data-form-label" prop="experimentAnimalSpecies">
          <el-input :disabled="!writeSwitch" name="experimentAnimalSpecies" v-model="nonCommonFormData.experimentAnimalSpecies" clearable :readonly="!(creator.id === currentUser.id)"></el-input>
        </el-form-item>
      </el-col>
      <el-col v-show="isRelated? nonCommonFieldRelated['experimentSample']: !nonCommonFieldRelated['experimentSample']" :lg="layoutConf.col.lgSpan" :md="layoutConf.col.mdSpan">
        <el-form-item label="Experiment Sample" class="data-form-label" prop="experimentSample">
          <el-input :disabled="!writeSwitch" name="experimentSample" v-model="nonCommonFormData.experimentSample" clearable :readonly="!(creator.id === currentUser.id)"></el-input>
        </el-form-item>
      </el-col>
      <el-col v-show="isRelated? nonCommonFieldRelated['sampleCarrier']: !nonCommonFieldRelated['sampleCarrier']" :lg="layoutConf.col.lgSpan" :md="layoutConf.col.mdSpan">
        <el-form-item label="Sample Carrier" class="data-form-label" prop="sampleCarrier">
          <el-input :disabled="!writeSwitch" name="sampleCarrier" v-model="nonCommonFormData.sampleCarrier" clearable :readonly="!(creator.id === currentUser.id)"></el-input>
        </el-form-item>
      </el-col>
      <el-col v-show="isRelated? nonCommonFieldRelated['rfpower']: !nonCommonFieldRelated['rfpower']" :lg="layoutConf.col.lgSpan" :md="layoutConf.col.mdSpan">
        <el-form-item label="RF Power" class="data-form-label" prop="rfpower">
          <el-input :disabled="!writeSwitch" name="rfpower" v-model="nonCommonFormData.rfpower" clearable :readonly="!(creator.id === currentUser.id)"></el-input>
        </el-form-item>
      </el-col>
      <el-col v-show="isRelated? nonCommonFieldRelated['rfprotocol']: !nonCommonFieldRelated['rfprotocol']" :lg="layoutConf.col.lgSpan" :md="layoutConf.col.mdSpan">
        <el-form-item label="RF Protocol" class="data-form-label" prop="rfprotocol">
          <el-input :disabled="!writeSwitch" name="rfprotocol" v-model="nonCommonFormData.rfprotocol" clearable :readonly="!(creator.id === currentUser.id)"></el-input>
        </el-form-item>
      </el-col>
      <el-col v-show="isRelated? nonCommonFieldRelated['diamond']: !nonCommonFieldRelated['diamond']" :lg="layoutConf.col.lgSpan" :md="layoutConf.col.mdSpan">
        <el-form-item label="Diamond Plate" class="data-form-label" prop="diamond">
          <el-input :disabled="!writeSwitch" name="diamond" v-model="nonCommonFormData.diamond" clearable :readonly="!(creator.id === currentUser.id)"></el-input>
        </el-form-item>
      </el-col>
      <el-col v-show="isRelated? nonCommonFieldRelated['opticalparam']: !nonCommonFieldRelated['opticalparam']" :lg="layoutConf.col.lgSpan" :md="layoutConf.col.mdSpan">
        <el-form-item label="Optical Parameter" class="data-form-label" prop="opticalparam">
          <el-input :disabled="!writeSwitch" name="opticalparam" v-model="nonCommonFormData.opticalparam" clearable :readonly="!(creator.id === currentUser.id)"></el-input>
        </el-form-item>
      </el-col>
      <el-col v-show="isRelated? nonCommonFieldRelated['laserpower']: !nonCommonFieldRelated['laserpower']" :lg="layoutConf.col.lgSpan" :md="layoutConf.col.mdSpan">
        <el-form-item label="Laser Power" class="data-form-label" prop="laserpower">
          <el-input :disabled="!writeSwitch" name="laserpower" v-model="nonCommonFormData.laserpower" clearable :readonly="!(creator.id === currentUser.id)"></el-input>
        </el-form-item>
      </el-col>
      <el-col v-show="isRelated? nonCommonFieldRelated['longitude']: !nonCommonFieldRelated['longitude']" :lg="layoutConf.col.lgSpan" :md="layoutConf.col.mdSpan">
        <el-form-item label="Longitude" class="data-form-label" prop="longitude">
          <el-input :disabled="!writeSwitch" name="longitude" v-model="nonCommonFormData.longitude" clearable :readonly="!(creator.id === currentUser.id)"></el-input>
        </el-form-item>
      </el-col>
      <el-col v-show="isRelated? nonCommonFieldRelated['latitude']: !nonCommonFieldRelated['latitude']" :lg="layoutConf.col.lgSpan" :md="layoutConf.col.mdSpan">
        <el-form-item label="Latitude" class="data-form-label" prop="latitude">
          <el-input :disabled="!writeSwitch" name="latitude" v-model="nonCommonFormData.latitude" clearable :readonly="!(creator.id === currentUser.id)"></el-input>
        </el-form-item>
      </el-col>
      <el-col v-show="isRelated? nonCommonFieldRelated['magneticDirection']: !nonCommonFieldRelated['magneticDirection']" :lg="layoutConf.col.lgSpan" :md="layoutConf.col.mdSpan">
        <el-form-item label="Magnetic Direction" class="data-form-label" prop="magneticDirection">
          <el-input name="magneticDirection" v-model="nonCommonFormData.magneticDirection" clearable :readonly="!(creator.id === currentUser.id)"></el-input>
        </el-form-item>
      </el-col>
      <el-col v-show="isRelated? nonCommonFieldRelated['season']: !nonCommonFieldRelated['season']" :lg="layoutConf.col.lgSpan" :md="layoutConf.col.mdSpan">
        <el-form-item label="Season" class="data-form-label" prop="season">
          <el-input :disabled="!writeSwitch" name="season" v-model="nonCommonFormData.season" clearable :readonly="!(creator.id === currentUser.id)"></el-input>
        </el-form-item>
      </el-col>
      <el-col v-show="isRelated? nonCommonFieldRelated['traceInjection']: !nonCommonFieldRelated['traceInjection']" :lg="layoutConf.col.lgSpan" :md="layoutConf.col.mdSpan">
        <el-form-item label="Trace Injection" class="data-form-label" prop="traceInjection">
          <el-input :disabled="!writeSwitch" name="traceInjection" v-model="nonCommonFormData.traceInjection" clearable :readonly="!(creator.id === currentUser.id)"></el-input>
        </el-form-item>
      </el-col>
      <el-col v-show="isRelated? nonCommonFieldRelated['behavioralCondition']: !nonCommonFieldRelated['behavioralCondition']" :lg="layoutConf.col.lgSpan" :md="layoutConf.col.mdSpan">
        <el-form-item label="Behavioral Conditioning" class="data-form-label" prop="behavioralCondition">
          <el-input :disabled="!writeSwitch" name="behavioralCondition" v-model="nonCommonFormData.behavioralCondition" clearable :readonly="!(creator.id === currentUser.id)"></el-input>
        </el-form-item>
      </el-col>
      <el-col v-show="isRelated? nonCommonFieldRelated['radioEquipment']: !nonCommonFieldRelated['radioEquipment']" :lg="layoutConf.col.lgSpan" :md="layoutConf.col.mdSpan">
        <!-- <el-form-item label="Radio Equipment" class="data-form-label" prop="radioEquipment">
          <el-input name="radioEquipment" v-model="nonCommonFormData.radioEquipment" clearable :readonly="!(creator.id === currentUser.id)"></el-input>
        </el-form-item> -->
        <el-form-item label="Radio Equipment" class="data-form-label" prop="radioEquipment">
          <FileFormField fieldName="radioEquipment" :formData="nonCommonFormData" :isCommon="false" :creator="creator" :enaleUploader="isRelated? nonCommonFieldRelated['radioEquipment']: !nonCommonFieldRelated['radioEquipment']"></FileFormField>
        </el-form-item>
      </el-col>
      <el-col v-show="isRelated? nonCommonFieldRelated['geneSequenceFile']: !nonCommonFieldRelated['geneSequenceFile']" :lg="layoutConf.col.lgSpan" :md="layoutConf.col.mdSpan">
        <el-form-item label="Gene Sequence File" class="data-form-label" prop="geneSequenceFile">
          <FileFormField fieldName="geneSequenceFile" :formData="nonCommonFormData" :isCommon="false" :creator="creator" :enaleUploader="isRelated? nonCommonFieldRelated['geneSequenceFile']: !nonCommonFieldRelated['geneSequenceFile']"></FileFormField>
        </el-form-item>
      </el-col>
      <el-col v-show="isRelated? nonCommonFieldRelated['aminoAcidSequenceFile']: !nonCommonFieldRelated['aminoAcidSequenceFile']" :lg="layoutConf.col.lgSpan" :md="layoutConf.col.mdSpan">
        <el-form-item label="Amino Acid Sequence File" class="data-form-label" prop="aminoAcidSequenceFile">
          <FileFormField fieldName="aminoAcidSequenceFile" :formData="nonCommonFormData" :isCommon="false" :creator="creator" :enaleUploader="isRelated? nonCommonFieldRelated['aminoAcidSequenceFile']: !nonCommonFieldRelated['aminoAcidSequenceFile']"></FileFormField>
        </el-form-item>
      </el-col>
      <el-col v-show="isRelated? nonCommonFieldRelated['experimentAnimalTreatmentFile']: !nonCommonFieldRelated['experimentAnimalTreatmentFile']" :lg="layoutConf.col.lgSpan" :md="layoutConf.col.mdSpan">
        <el-form-item label="Experiment Animal Treatment File" class="data-form-label" prop="experimentAnimalTreatmentFile">
          <FileFormField fieldName="experimentAnimalTreatmentFile" :formData="nonCommonFormData" :isCommon="false" :creator="creator" :enaleUploader="isRelated? nonCommonFieldRelated['experimentAnimalTreatmentFile']: !nonCommonFieldRelated['experimentAnimalTreatmentFile']"></FileFormField>
        </el-form-item>
      </el-col>
      <el-col v-show="isRelated? nonCommonFieldRelated['experimentAnimalInfoFile']: !nonCommonFieldRelated['experimentAnimalInfoFile']" :lg="layoutConf.col.lgSpan" :md="layoutConf.col.mdSpan">
        <el-form-item label="Experiment Animal Basic Information File" class="data-form-label" prop="experimentAnimalInfoFile">
          <FileFormField fieldName="experimentAnimalInfoFile" :formData="nonCommonFormData" :isCommon="false" :creator="creator" :enaleUploader="isRelated? nonCommonFieldRelated['experimentAnimalInfoFile']: !nonCommonFieldRelated['experimentAnimalInfoFile']"></FileFormField>
        </el-form-item>
      </el-col>
      <el-col v-show="isRelated? nonCommonFieldRelated['commentsFile']: !nonCommonFieldRelated['commentsFile']" :lg="layoutConf.col.lgSpan" :md="layoutConf.col.mdSpan">
        <el-form-item label="Comments File" class="data-form-label" prop="commentsFile">
          <FileFormField fieldName="commentsFile" :formData="nonCommonFormData" :isCommon="false" :creator="creator" :enaleUploader="isRelated? nonCommonFieldRelated['commentsFile']: !nonCommonFieldRelated['commentsFile']"></FileFormField>
        </el-form-item>
      </el-col>
      <el-col v-show="isRelated? nonCommonFieldRelated['lightStimulusFile']: !nonCommonFieldRelated['lightStimulusFile']" :lg="layoutConf.col.lgSpan" :md="layoutConf.col.mdSpan">
        <el-form-item label="Light Stimulus File" class="data-form-label" prop="lightStimulusFile">
          <FileFormField fieldName="lightStimulusFile" :formData="nonCommonFormData" :isCommon="false" :creator="creator" :enaleUploader="isRelated? nonCommonFieldRelated['lightStimulusFile']: !nonCommonFieldRelated['lightStimulusFile']"></FileFormField>
        </el-form-item>
      </el-col>
      <el-col v-show="isRelated? nonCommonFieldRelated['functionsFile']: !nonCommonFieldRelated['functionsFile']" :lg="layoutConf.col.lgSpan" :md="layoutConf.col.mdSpan">
        <el-form-item label="Functions File" class="data-form-label" prop="functionsFile">
          <FileFormField fieldName="functionsFile" :formData="nonCommonFormData" :isCommon="false" :creator="creator" :enaleUploader="isRelated? nonCommonFieldRelated['functionsFile']: !nonCommonFieldRelated['functionsFile']"></FileFormField>
        </el-form-item>
      </el-col>
      <el-col v-show="isRelated? nonCommonFieldRelated['magneticInfoFile']: !nonCommonFieldRelated['magneticInfoFile']" :lg="layoutConf.col.lgSpan" :md="layoutConf.col.mdSpan">
        <el-form-item label="Magnetic Stimulus File" class="data-form-label" prop="magneticInfoFile">
          <FileFormField fieldName="magneticInfoFile" :formData="nonCommonFormData" :isCommon="false" :creator="creator" :enaleUploader="isRelated? nonCommonFieldRelated['magneticInfoFile']: !nonCommonFieldRelated['magneticInfoFile']"></FileFormField>
        </el-form-item>
      </el-col>
      <el-col v-show="isRelated? nonCommonFieldRelated['weatherInfoFile']: !nonCommonFieldRelated['weatherInfoFile']" :lg="layoutConf.col.lgSpan" :md="layoutConf.col.mdSpan">
        <el-form-item label="Weather Information File" class="data-form-label" prop="weatherInfoFile">
          <FileFormField fieldName="weatherInfoFile" :formData="nonCommonFormData" :isCommon="false" :creator="creator" :enaleUploader="isRelated? nonCommonFieldRelated['weatherInfoFile']: !nonCommonFieldRelated['weatherInfoFile']"></FileFormField>
        </el-form-item>
      </el-col>
      <el-col v-show="isRelated? nonCommonFieldRelated['departureFile']: !nonCommonFieldRelated['departureFile']" :lg="layoutConf.col.lgSpan" :md="layoutConf.col.mdSpan">
        <el-form-item label="Departure Behavior File" class="data-form-label" prop="departureFile">
          <FileFormField fieldName="departureFile" :formData="nonCommonFormData" :isCommon="false" :creator="creator" :enaleUploader="isRelated? nonCommonFieldRelated['departureFile']: !nonCommonFieldRelated['departureFile']"></FileFormField>
        </el-form-item>
      </el-col>
      <el-col v-show="isRelated? nonCommonFieldRelated['radioFile']: !nonCommonFieldRelated['radioFile']" :lg="layoutConf.col.lgSpan" :md="layoutConf.col.mdSpan">
        <el-form-item label="Radio Frequency Information File" class="data-form-label" prop="radioFile">
          <FileFormField fieldName="radioFile" :formData="nonCommonFormData" :isCommon="false" :creator="creator" :enaleUploader="isRelated? nonCommonFieldRelated['radioFile']: !nonCommonFieldRelated['radioFile']"></FileFormField>
        </el-form-item>
      </el-col>
      <el-col v-show="isRelated? nonCommonFieldRelated['analysisFile']: !nonCommonFieldRelated['analysisFile']" :lg="layoutConf.col.lgSpan" :md="layoutConf.col.mdSpan">
        <el-form-item label="Data Analysis Protocol File" class="data-form-label" prop="analysisFile">
          <FileFormField fieldName="analysisFile" :formData="nonCommonFormData" :isCommon="false" :creator="creator" :enaleUploader="isRelated? nonCommonFieldRelated['analysisFile']: !nonCommonFieldRelated['analysisFile']"></FileFormField>
        </el-form-item>
      </el-col>
      <el-col v-show="isRelated? nonCommonFieldRelated['relatedDataFile']: !nonCommonFieldRelated['relatedDataFile']" :lg="layoutConf.col.lgSpan" :md="layoutConf.col.mdSpan">
        <el-form-item label="Experimental Result Data File" class="data-form-label" prop="relatedDataFile">
          <FileFormField fieldName="relatedDataFile" :formData="nonCommonFormData" :isCommon="false" :creator="creator" :enaleUploader="isRelated? nonCommonFieldRelated['relatedDataFile']: !nonCommonFieldRelated['relatedDataFile']"></FileFormField>
        </el-form-item>
      </el-col>
      <el-col v-show="isRelated? nonCommonFieldRelated['labProtocolFile']: !nonCommonFieldRelated['labProtocolFile']" :lg="layoutConf.col.lgSpan" :md="layoutConf.col.mdSpan">
        <el-form-item label="Lab Protocol File" class="data-form-label" prop="labProtocolFile">
          <FileFormField fieldName="labProtocolFile" :formData="nonCommonFormData" :isCommon="false" :creator="creator" :enaleUploader="isRelated? nonCommonFieldRelated['labProtocolFile']: !nonCommonFieldRelated['labProtocolFile']"></FileFormField>
        </el-form-item>
      </el-col>
    </el-row>
  </section>
</template>
<script>
import { mapState, mapMutations } from 'vuex'
import FileFormField from '@/components/data_component/FileFormField.vue'

export default {
  components: {
    FileFormField
  },
  props: ['isRelated', 'creator'],
  computed: {
    ...mapState({
      writeSwitch: (state) => state.writableStatus,
      nonCommonFormData: (state) => state.dataModule.nonCommonFormData,
      projectRelatedFields: (state) => state.dataModule.projectRelatedFields,
      projectRelatedProjects: (state) => state.dataModule.projectRelatedProjects,
      nonCommonFieldRelated: (state) => state.dataModule.nonCommonFieldRelated
    })
  },
  watch: {
    projectRelatedFields: function (newValue) {
      console.log('non-common field projectRelatedFields', newValue)
      this.changeNonCommonFieldRelated(newValue)
    }
  },
  async mounted () {
    const { data: projectListResp } = await this.$http.get(this.serverAPI.PROJECT_LIST_URL)
    this.projectList = projectListResp.data

    this.currentUser = await this.$userStorage.getCurrentUser()
  },
  data () {
    return {
      currentUser: {},
      layoutConf: {
        row: {
          gutter: 20,
          styleContent: 'margin-bottom: 5px;'
        },
        col: {
          lgSpan: 12,
          mdSpan: 24
        }
      },
      projectList: []
    }
  },
  methods: {
    ...mapMutations({
      changeNonCommonFieldRelated: 'dataModule/changeNonCommonFieldRelated'
    })
  }
}
</script>
<style scoped>
div >>> textarea.el-textarea__inner {
  height: 200px;
  overflow-y: auto;
  margin-bottom: 5px;
}
div.el-form-item {
  margin-bottom: 0;
  /* margin-top: 10px; */
}
form label {
    line-height: 30px !important;
    padding: 0 !important;
  }

.clearfix:before, .clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both
}
div >>> div.el-form-item {
  margin-bottom: 0px !important;
}
div >>> label.el-form-item__label {
  padding-bottom: 0 !important;
}
</style>
