<template>
  <div>
    <!--Breadcrumb navigation area-->
    <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>Home</el-breadcrumb-item>
        <el-breadcrumb-item>Field Module</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card>
      <el-row>
        <el-col>
          <el-button type="primary" @click="displayAddDialog" v-if="writeSwitch">Add Field</el-button>
        </el-col>
      </el-row>

      <el-table :data="fieldList" border stripe>
        <el-table-column fixed type="index" label="#" align="center"></el-table-column>
        <el-table-column fixed prop="fieldName" label="Field Name" align="center"></el-table-column>
        <el-table-column prop="fieldLabel" label="Field Label" align="center"></el-table-column>
        <el-table-column prop="fieldType.typeName" label="Field Type" align="center"></el-table-column>
        <el-table-column prop="isCommon" label="Common Field" align="center">
          <template v-slot:="slotProps">
            <el-tag type="success" v-if="slotProps.row.isCommon.fieldTypeCode">Yes</el-tag>
            <el-tag type="danger" v-else>No</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="fieldDescription" label="Field Description" align="center"></el-table-column>
        <el-table-column prop="placeholder" label="Placeholder" align="center"></el-table-column>
        <el-table-column label="Actions" align="center" width="300px">
          <template v-slot:="slotProps">
            <el-tooltip effect="dark" content="edit the field" placement="top" :enterable="false">
              <el-button type="primary" icon="el-icon-edit" size="mini" @click="displayEditDialog(slotProps.row.id)"></el-button>
            </el-tooltip>
            <el-tooltip effect="dark" content="disable the field" placement="top" :enterable="false">
              <el-button type="danger" icon="el-icon-delete" size="mini" @click="removeField(slotProps.row.id)"  v-if="writeSwitch"></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
    </el-card>

    <!--add field-->
    <el-dialog title="New Field" :visible.sync="addFieldDialogVisible" width="50%" @close="addDialogClosed">
      <el-form :model="addFieldForm" :rules="fieldFormRules" ref="addFormRef" label-width="130px" label-position="top">
        <el-form-item label="Field Name" prop="fieldName">
          <el-input ref="addFieldNameRef" v-model="addFieldForm.fieldName"></el-input>
        </el-form-item>
        <el-form-item label="Field Label" prop="fieldLabel">
          <el-input v-model="addFieldForm.fieldLabel"></el-input>
        </el-form-item>
        <el-form-item label="Field Type" prop="fieldType">
          <el-select clearable v-model="addFieldForm.fieldType" style="width: 100%;" placeholder="Please select field type" value-key="typeCode">
            <el-option v-for="fieldType in fieldTypes" :key="fieldType.typeCode" :label="fieldType.typeName" :value="fieldType"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Field and Project Relation" prop="isCommon">
          <template>
            <el-radio v-for="commonType in commonTypes" :key="commonType.fieldTypeCode" v-model="editFieldForm.isCommon" :label="commonType.fieldTypeCode" @change="setFormDataCommonField(addFieldForm, commonType)">
              <span v-if="commonType.fieldTypeCode">Common Field</span>
              <span v-else>Project Related Field</span>
            </el-radio>
          </template>
        </el-form-item>
        <el-form-item label="Field Description" prop="fieldDescription">
          <el-input v-model="addFieldForm.fieldDescription"></el-input>
        </el-form-item>
        <el-form-item label="Place Holder" prop="placeholder">
          <el-input v-model="addFieldForm.placeholder"></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="addFieldDialogVisible = false">Cancel</el-button>
        <el-button type="primary" @click="addField">Submit</el-button>
      </span>
    </el-dialog>

    <!--edit field-->
    <el-dialog title="New Field" :visible.sync="editFieldDialogVisible" width="50%" @close="editDialogClosed">
      <el-form :model="editFieldForm" :rules="fieldFormRules" ref="editFormRef" label-width="130px" label-position="top">
        <el-form-item label="Field Name" prop="fieldName">
          <el-input ref="editFieldNameRef" v-model="editFieldForm.fieldName" :disabled="!writeSwitch"></el-input>
        </el-form-item>
        <el-form-item label="Field Label" prop="fieldLabel">
          <el-input v-model="editFieldForm.fieldLabel" :disabled="!writeSwitch"></el-input>
        </el-form-item>
        <el-form-item label="Field Type" prop="fieldType">
          <el-select clearable v-model="editFieldForm.fieldType" style="width: 100%;" placeholder="Please select field type" value-key="typeCode" :disabled="!writeSwitch">
            <el-option v-for="fieldType in fieldTypes" :key="fieldType.typeCode" :label="fieldType.typeName" :value="fieldType"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Field and Project Relation" prop="isCommon">
          <template>
            <el-radio :disabled="!writeSwitch" v-for="commonType in commonTypes" :key="commonType.fieldTypeCode" :value="editFieldForm.isCommon.fieldTypeCode" :label="commonType.fieldTypeCode" @change="setFormDataCommonField(editFieldForm, commonType)">
              <span v-if="commonType.fieldTypeCode">Common Field</span>
              <span v-if="!commonType.fieldTypeCode">Project Related Field</span>
            </el-radio>
          </template>
        </el-form-item>
        <el-form-item label="Field Description" prop="fieldDescription">
          <el-input v-model="editFieldForm.fieldDescription" :disabled="!writeSwitch"></el-input>
        </el-form-item>
        <el-form-item label="Placeholder" prop="placeholder">
          <el-input v-model="editFieldForm.placeholder" :disabled="!writeSwitch"></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="editFieldDialogVisible = false">Cancel</el-button>
        <el-button type="primary" @click="updateField" v-if="writeSwitch">Submit</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { mapState } from 'vuex'

export default {
  data () {
    return {
      addFieldDialogVisible: false,
      editFieldDialogVisible: false,
      fieldList: [],
      commonTypes: [],
      fieldTypes: [
        {
          typeCode: 1,
          typeName: 'input'
        },
        {
          typeCode: 2,
          typeName: 'file'
        },
        {
          typeCode: 3,
          typeName: 'radio'
        },
        {
          typeCode: 4,
          typeName: 'select'
        },
        {
          typeCode: 5,
          typeName: 'textarea'
        },
        {
          typeCode: 6,
          typeName: 'date'
        },
        {
          typeCode: 7,
          typeName: 'checkbox'
        }
      ],
      fieldFormRules: {
        fieldName: [
          { required: true, message: 'Please input field name', trigger: 'blur' },
          { min: 3, max: 100, message: 'The length of the field name is limit from 3 to 100', trigger: 'blur' }
        ],
        fieldLabel: [
          { required: true, message: 'Please input field name', trigger: 'blur' },
          { min: 3, max: 100, message: 'The length of the field name is limit from 3 to 100', trigger: 'blur' }
        ],
        fieldType: [
          { required: true, message: 'Please select file type', trigger: 'blur' }
        ],
        isCommon: [
          { required: true, message: 'Please select file type', trigger: 'blur' }
        ],
        fieldDescription: [
          { required: true, message: 'Please input field description', trigger: 'blur' }
        ],
        placeholder: [
          { required: true, message: 'Please input field placehoder', trigger: 'blur' }
        ]
      },
      addFieldForm: {
        fieldName: '',
        fieldLabel: '',
        fieldType: {
          typeCode: 0
        },
        isCommon: {},
        fieldDescription: '',
        placeholder: ''
      },
      editFieldForm: {
        fieldName: '',
        fieldLabel: '',
        fieldType: {
          typeCode: 0
        },
        isCommon: {},
        fieldDescription: '',
        placeholder: ''
      }
    }
  },
  created () {
    this.getFieldList()
  },
  computed: {
    ...mapState({
      writeSwitch: (state) => state.writableStatus
    })
  },
  mounted () {
  },
  methods: {
    displayAddDialog () {
      this.addFieldDialogVisible = true
      this.$nextTick(_ => {
        console.log(this.$refs.addFieldNameRef)
        this.$refs.addFieldNameRef.focus()
      })
    },
    async displayEditDialog (id) {
      // const { data: fieldResp } = await this.$http.get('/field/' + fieldID)
      const { data: fieldResp } = await this.$http.get(this.serverAPI.FIELD_DETAIL_URL + id)
      this.editFieldDialogVisible = true
      this.editFieldForm = fieldResp.data
      this.$nextTick(_ => {
        console.log(this.$refs.editFieldNameRef)
        this.$refs.editFieldNameRef.focus()
      })
    },
    addDialogClosed () {
      this.$refs.addFormRef.resetFields()
    },
    editDialogClosed () {
      this.$refs.editFormRef.resetFields()
    },
    async getFieldList () {
      // const { data: fieldsResp } = await this.$http.get('/field/list')
      const { data: fieldsResp } = await this.$http.get(this.serverAPI.FIELD_LIST_URL)
      console.log('field list', fieldsResp)
      this.fieldList = fieldsResp.data.fieldList
      this.commonTypes = fieldsResp.data.commonTypes
      this.fieldTypes = fieldsResp.data.fieldTypes
    },
    addField () {
      this.$refs.addFormRef.validate(async result => {
        if (!result) return
        // const { data: res } = await this.$http.post('/field/save', JSON.stringify(this.addFieldForm), { headers: { 'content-type': 'application/json' } })
        const { data: res } = await this.$http.post(this.serverAPI.FIELD_SAVE_URL, JSON.stringify(this.addFieldForm), { headers: { 'content-type': 'application/json' } })
        console.log('add field response', res)
        this.addFieldDialogVisible = false
        this.getFieldList()
      })
    },
    updateField () {
      this.$refs.editFormRef.validate(async result => {
        if (!result) return
        // const { data: res } = await this.$http.post('/field/update', JSON.stringify(this.editFieldForm), { headers: { 'content-type': 'application/json' } })
        const { data: res } = await this.$http.post(this.serverAPI.FIELD_UPDATE_URL, JSON.stringify(this.editFieldForm), { headers: { 'content-type': 'application/json' } })
        console.log('update field response', res)
        this.editFieldDialogVisible = false
        this.getFieldList()
      })
    },
    async removeField (id) {
      console.log(id)
      // const { data: delResp } = await this.$http.delete('/field/remove/' + fieldID)
      const { data: delResp } = await this.$http.delete(this.serverAPI.FIELD_REMOVED_URL + id)
      console.log('delete fieldID=%s', id, delResp)
      this.getFieldList()
    },
    setFormDataCommonField (formData, commonType) {
      formData.isCommon = {}
      console.log('set isCommon Field', commonType)
      formData.isCommon = commonType
    }
  }
}
</script>
<style lang="less" scoped>

</style>
