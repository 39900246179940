import CommonDataForm from './CommonDataForm.json'
import CommonFormRules from './CommonFormRules.json'
import NonCommonDataForm from './NonCommonDataForm.json'
import NonCommonFormRules from './NonCommonFormRules.json'
import NonCommonFieldRelated from './NonCommonFieldRelated.json'
export const DataFiedConfig = {
  CommonDataForm,
  CommonFormRules,
  NonCommonDataForm,
  NonCommonFormRules,
  NonCommonFieldRelated
}
