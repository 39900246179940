<template>
  <div id="global-uploader">
    <uploader :autoStart="true" :options="options" :fileStatusText="fileStatusText" class="uploader-app" ref="uploaderRef"
        @file-complete="fileComplete" @complete="complete"
        @file-success="fileSuccessHandler"
        @files-added="onFilesAdded"
        @file-progress="fileProgress"
        @file-removed="fileRemoved">
      <uploader-unsupport uploader-unsupport></uploader-unsupport>

      <uploader-btn :attrs="attrs" ref="uploadFileBtn" :single="false">Files</uploader-btn>
      <uploader-btn :directory="true" ref="uploadFolderBtn">Folder</uploader-btn>

      <uploader-list v-if="displayFileList" v-show="panelShow" ref="uploaderListRef">
        <template v-slot="props">
          <div class="file-panel" :class="{'collapse': collapse}">
            <div class="file-title">
              <strong>File List</strong>
              <div class="operate">
                <el-button @click="fileListShow" type="text" :title="collapse ? 'Expand':'Collapse' ">
                  <i :class="[collapse ? 'el-icon-minus': 'el-icon-full-screen']"></i>
                </el-button>
                <el-button @click="close" type="text" title="close">
                  <i class="el-icon-close"></i>
                </el-button>
              </div>
            </div>

            <el-collapse-transition>
              <ul class="file-list" v-show="collapse">
                <li v-for="file in props.fileList" :key="file.id">
                  <uploader-file :class="'file_' + file.id" ref="files" :file="file" :list="true"></uploader-file>
                </li>
                <div class="no-file" v-if="!props.fileList.length"><i class="el-icon-files"></i> No Files to Be Uploaded</div>
              </ul>
            </el-collapse-transition>
          </div>
        </template>
      </uploader-list>
    </uploader>
  </div>
</template>

<script>
/**
 * simple-uploader document
 * https://github.com/simple-uploader/Uploader#events
 * https://github.com/simple-uploader/Uploader#configuration
 */
import { ACCEPT_CONFIG, FILE_TYPE } from './fileconfig'
import JsSHA from 'jssha'
import { EVENT_CONFIG } from '@/event_bus'
import { mapMutations } from 'vuex'

export default {
  // 'relatedDataFormFieldName' is used to store the field name of the file type
  props: ['displayFileList', 'relatedDataFormFieldName', 'enabled'],
  data () {
    return {
      currentLoginUser: {},
      // the project where files belong to
      fileTarget: null,
      skipedFilePathes: [],
      options: {
        target: this.serverAPI.FILE_UPLOAD_CHUNK_URL,
        testChunks: true,
        // Number of simultaneous uploads (Default: 3)
        simultaneousUploads: window.navigator.hardwareConcurrency,
        chunkSize: 5 * 1024 * 1024,
        forceChunkSize: true,
        allowDuplicateUploads: true,
        // set up the display of remain time
        parseTimeRemaining: function (timeRemaining, parsedTimeRemaining) {
          return parsedTimeRemaining
        },
        readFileFn (file, fileType, startByte, endByte, chunk) {
          console.log('readFileFn function', file, fileType, startByte, endByte, chunk)
          const blobSlice = File.prototype.slice || File.prototype.mozSlice || File.prototype.webkitSlice
          const fileSlice = blobSlice.call(file.file, startByte, endByte)
          const reader = new FileReader()
          reader.readAsArrayBuffer(fileSlice)
          // read the file slice and generate signature
          reader.onloadend = function () {
            const chunkShaObj = new JsSHA('SHA-256', 'ARRAYBUFFER')
            chunkShaObj.update(reader.result)
            const chunkSignature = chunkShaObj.getHash('HEX')
            console.log('filechunk signaure: ', chunkSignature)
            chunk.chunkSignature = chunkSignature
            chunk.readFinished(fileSlice)
          }
        },
        processParams (params, file, chunk, isTest) {
          console.log('request params:', params, file, chunk, isTest)
          // set the signature to params in order to send to the server
          params.chunkSignature = chunk.chunkSignature
          file.totalChunks = params.totalChunks
          if (!isTest) {
            chunk.bytes.arrayBuffer().then(buffer => {
              console.log('processParams buffer', buffer)
            })
          }
          params.fileTarget = file.fileTarget
          params.operatorID = file.operator.id
          return params
        },
        generateUniqueIdentifier (file) {
          const fileName = file.name.toLowerCase()
          const fileSize = file.size
          const lastModified = file.lastModified
          console.log('file name: %s size: %s lastModified: %s', fileName, fileSize, file.lastModified)
          const fileIdSHA = new JsSHA('SHA-256', 'TEXT')
          fileIdSHA.update(fileName + fileSize + lastModified)
          const fileIdentifier = fileIdSHA.getHash('HEX')
          console.log(fileIdentifier)

          return fileIdentifier
        },
        categoryMap: FILE_TYPE.getCategoryMap()
      },
      attrs: {
        accept: ACCEPT_CONFIG.getAll()
      },
      fileStatusText: {
        success: 'success',
        error: 'error',
        uploading: 'uploading',
        paused: 'paused',
        waiting: 'waiting'
      },
      panelShow: true,
      collapse: false,
      fileTypeConfig: FILE_TYPE,
      filesProgressUpdateFlag: {} // it is a Object, 'fileName' is key and the value is boolean type as 'true' continue 'false': stop
    }
  },
  async mounted () {
    this.$nextTick(() => {
      window.uploader = this.$refs.uploaderRef.uploader
    })

    EVENT_CONFIG.EventBus.$on(EVENT_CONFIG.FILE_UPLOAD_EVENT, this.uploadEventHandler)
    EVENT_CONFIG.EventBus.$on(EVENT_CONFIG.FILE_REMOVE_EVENT, this.fileRemoveHandler)

    this.currentLoginUser = await this.$userStorage.getCurrentUser()
  },
  beforeDestroy () {
    console.debug('FileObjUploader.vue beforeDestory method executes.....')
    EVENT_CONFIG.EventBus.$off(EVENT_CONFIG.FILE_UPLOAD_EVENT)
    EVENT_CONFIG.EventBus.$off(EVENT_CONFIG.FILE_REMOVE_EVENT)
  },
  methods: {
    ...mapMutations({
      addNotification: 'NotificationModule/addNotification'
    }),
    uploadEventHandler (uploadParam) {
      if (this.enabled) {
        const formFieldName = uploadParam.fieldName
        if (this.relatedDataFormFieldName === formFieldName) {
          console.log('fieldName: %s', uploadParam.fieldName)
          this.fileTarget = uploadParam.target

          if (!this.fileTarget) {
            this.$message({
              message: 'please select one project',
              type: 'warning',
              showClose: true
            })

            return
          }

          if (uploadParam.uploadType === 'file') {
            if (this.$refs.uploadFileBtn) {
              this.$refs.uploadFileBtn.$el.click()
            }
          }

          if (uploadParam.uploadType === 'folder') {
            if (this.$refs.uploadFolderBtn) {
              this.$refs.uploadFolderBtn.$el.click()
            }
          }
        }
      }
    },
    /**
     * this is an async method but this method cannot be invoked more than one time at the same time.
     * because the choosing file behaviour cannot happen more than one time at the same time
     */
    async onFilesAdded (subFileList, parentFile) {
      this.panelShow = true
      this.collapse = true

      if (parentFile.length === 0) {
        this.$alert('<font color="red">You select the folder that has already been uploaded!<br>You can change the name of the folder you just selected</font>', {
          dangerouslyUseHTMLString: true,
          confirmButtonText: 'OK'
        })
        return
      }

      // parentFile[0].pause()
      // parentFile[0].resume()
      console.log('on file added files: ', subFileList)
      console.log('on file added parent folder: ', parentFile)

      if (parentFile[0].isFolder && parentFile.length === 1) {
        parentFile[0].pause()

        const folderIdSha = new JsSHA('SHA-256', 'TEXT')
        folderIdSha.update(parentFile[0].path.toLowerCase())
        const folderUniID = folderIdSha.getHash('HEX')

        parentFile[0].uniqueIdentifier = folderUniID

        const folderOBJ = {}
        folderOBJ.fileTarget = this.fileTarget
        folderOBJ.operator = this.currentLoginUser
        folderOBJ.fileUniqueID = folderUniID

        const { data: duplicatedFileResp } = await this.$http.post(this.serverAPI.FILE_DUPLICATION_URL, JSON.stringify(folderOBJ), { headers: { 'content-type': 'application/json' } })
        console.log('duplicated file check response: ', duplicatedFileResp)
        if (duplicatedFileResp.data && duplicatedFileResp.data.length > 0) {
          parentFile[0].cancel()
          this.$alert('You select the folder <font color="red"><b>"' + parentFile[0].path + '"</b></font> that has already been uploaded!<br>You can change the name of the folder you just selected and try to re-upload.', {
            dangerouslyUseHTMLString: true,
            confirmButtonText: 'OK'
          })
          return
        }

        if (this.relatedDataFormFieldName) {
          this.filesProgressUpdateFlag[parentFile[0].name] = true
          this.synchronzeFileObjInfo(parentFile[0])
        }
        parentFile[0].fileTarget = this.fileTarget
        parentFile[0].operator = this.currentLoginUser

        for (const file of subFileList) {
          file.fileTarget = this.fileTarget
          file.operator = this.currentLoginUser
        }
        parentFile[0].resume()
      } else {
        const noDuplicatedFiles = []

        let messageContent
        for (const file of parentFile) {
          file.pause()

          console.log('selected file: ', file)
          file.fileTarget = this.fileTarget
          file.operator = this.currentLoginUser

          const fileOBJ = {}
          fileOBJ.fileTarget = this.fileTarget
          fileOBJ.operator = this.currentLoginUser
          fileOBJ.fileUniqueID = file.uniqueIdentifier

          const { data: duplicatedFileResp } = await this.$http.post(this.serverAPI.FILE_DUPLICATION_URL, JSON.stringify(fileOBJ), { headers: { 'content-type': 'application/json' } })
          console.log('duplicated file check response: ', duplicatedFileResp)
          if (duplicatedFileResp.data && duplicatedFileResp.data.length > 0) {
            file.cancel()
            if (!messageContent) {
              messageContent = ''
            } else {
              messageContent += '<br>'
            }
            messageContent += '<p>You select the folder <font color="red"><b>"' + file.name + '"</b></font> that has already been uploaded!<br>You can change the name of the folder you just selected and try to re-upload.</p>'
          } else {
            noDuplicatedFiles.push(file)
          }
        }

        if (messageContent) {
          this.$msgbox({
            title: 'Duplicated Uploaded Files',
            message: messageContent,
            showCancelButton: true,
            dangerouslyUseHTMLString: true,
            confirmButtonText: 'OK',
            cancelButtonText: 'Cancel'
          }).then(_ => {
            console.log('confirm the reset of the file list')
            noDuplicatedFiles.forEach(noDuplicatedFile => {
              if (this.relatedDataFormFieldName) {
                this.filesProgressUpdateFlag[noDuplicatedFile.name] = true
                this.synchronzeFileObjInfo(noDuplicatedFile)
              }
              noDuplicatedFile.resume()
            })
          }).catch(_ => {
            console.log('cancel the reset of the file list')
            noDuplicatedFiles.forEach(noDuplicatedFile => {
              noDuplicatedFile.cancel()
            })
          })
        } else {
          noDuplicatedFiles.forEach(noDuplicatedFile => {
            if (this.relatedDataFormFieldName) {
              this.filesProgressUpdateFlag[noDuplicatedFile.name] = true
              this.synchronzeFileObjInfo(noDuplicatedFile)
            }
            noDuplicatedFile.resume()
          })
        }
      }
    },
    synchronzeFileObjInfo (fileObj) {
      fileObj.relatedFieldName = this.relatedDataFormFieldName
      const fieldFileRelation = this.generateFieldFileRelation(fileObj)
      EVENT_CONFIG.EventBus.$emit(EVENT_CONFIG.FILE_PROGRESS_UPDATE_EVENT, fieldFileRelation)
    },
    generateFieldFileRelation (uploadedFile) {
      const fieldFileRelation = {
        fieldName: uploadedFile.relatedFieldName,
        fileUniqueID: uploadedFile.uniqueIdentifier,
        fileName: uploadedFile.name,
        fileProgress: uploadedFile.progress(),
        isFolder: uploadedFile.isFolder,
        fileProgressFlag: this.filesProgressUpdateFlag[uploadedFile.name]
      }

      return fieldFileRelation
    },
    fileProgress (rootFile, file, chunk) {
      console.log('root file name=%s file progress=%s', rootFile.name, rootFile.progress())
      if (rootFile.relatedFieldName) {
        if (this.filesProgressUpdateFlag[rootFile.name]) {
          console.log('update file uploading progress ', rootFile.name)
          this.synchronzeFileObjInfo(rootFile)
        } else {
          rootFile.cancel()
        }
      }
    },
    // display or hidde the file list
    fileListShow () {
      this.collapse = !this.collapse
    },
    // finish file uploading
    complete () {
      console.log('complete', arguments)
    },
    fileSuccessHandler (rootFile, file, response) {
      console.log('file success handler')
    },
    fileRemoved (file) {
      console.log('removed file information:', file)
      const removeFieldFileRelation = this.generateFieldFileRelation(file)
      console.log('remove the file uploading pregress flag')
      delete this.filesProgressUpdateFlag[file.name]
      EVENT_CONFIG.EventBus.$emit(EVENT_CONFIG.FILE_PROGRESS_REMOVE_EVENT, removeFieldFileRelation)
    },
    close () {
      // close file upload panel and cancel file uploading
      this.panelShow = false
      this.$refs.uploaderRef.uploader.cancel()
    },
    //  A root file(Folder) was completed.
    async fileComplete (rootFile) {
      console.log('file complete', arguments)
      const isFolder = rootFile.isFolder

      let pathInfo
      if (isFolder) {
        console.log('uploading folder')
        /* const fileList = arguments[0].fileList
        filePromise = this.mergeFolder(fileList, arguments[0].name) */
        pathInfo = await this.mergeFolder(rootFile)
      } else {
        pathInfo = await this.mergeFile(rootFile)
      }

      console.log('add file to list', arguments)

      if (rootFile.relatedFieldName) {
        // to notify the FileFormField.vue component to add the file to the VueX instance
        const fileCompletion = this.generateFieldFileRelation(rootFile)
        EVENT_CONFIG.EventBus.$emit(EVENT_CONFIG.FILE_COMPLETE_EVENT, fileCompletion)
        // TODO: 记录文件状态，未合并的时候要标识出来，合并成功将状态删除， 使用异步的方式进行合并，不阻塞其他文件的上传过程
      } else {
        // emit the cutom event handler function to refresh
        EVENT_CONFIG.EventBus.$emit(EVENT_CONFIG.RELOAD_FILE_LIST_EVENT, pathInfo)
      }

      if (this.skipedFilePathes.length) {
        // show message alert window
        const createMethod = this.$createElement // create new element method: param1 is 'tag name', param2 is 'style content', param3 is 'text content'

        const messageArray = []
        this.skipedFilePathes.forEach(filePath => {
          let originFileRelativePathContentEle = createMethod('i', { style: 'color: red; font-weight:bold' }, filePath.fileRelativePath)
          if (filePath.isSkip) {
            originFileRelativePathContentEle = createMethod('i', { style: 'color: red; font-weight:bold' }, filePath.originFilePath.fileRelativePath)
          }
          const newFileRelativePathEle = createMethod('i', { style: 'color: red; font-weight:bold' }, filePath.fileRelativePath)

          const newFileRelativePathLi = createMethod('li', null, ['upload to path: ', newFileRelativePathEle, ' exist in path: ', originFileRelativePathContentEle])
          // create only one li row the li add fail
          const newFileRelativePathLi1 = createMethod('li', { attrs: { hidden: true } }, [''])

          const subULEle = createMethod('ul', { class: 'exist_path_name' }, [newFileRelativePathLi, newFileRelativePathLi1])
          // messageArray.push(subULEle)

          const newFilePathEle = createMethod('i', { style: 'color: red; font-weight:bold' }, filePath.fileRelativePath)
          const newFilePathUl = createMethod('li', null, ['upload file: ', newFilePathEle, subULEle])
          messageArray.push(newFilePathUl)
        })

        this.$msgbox({
          title: 'upload file existing',
          // message: createMethod('ul', { class: 'exist_path_root', attrs: { id: 'fileMsgbox' } }, messageArray),
          message: createMethod('ul', { class: 'exist_path_root' }, messageArray),
          showCancelButton: false,
          confirmButtonText: 'OK',
          customClass: 'msgbox_file',
          callback: (action, instance) => {
            console.log('message box closed')
            this.skipedFilePathes = []
          }
        })
      }
    },
    generateFolderPathObj (folderObj) {
      const folderIdSha = new JsSHA('SHA-256', 'TEXT')
      folderIdSha.update(folderObj.path.toLowerCase())
      const folderUniID = folderIdSha.getHash('HEX')

      const currentFolder = {
        fileName: folderObj.name,
        isFolder: true,
        filePath: folderObj.path,
        fileUniqueID: folderUniID,
        fileTarget: folderObj.fileTarget,
        operator: this.currentLoginUser
      }
      const currentFolderPath = {
        parentFile: folderObj.parentFile,
        currentFile: currentFolder,
        isFolder: true
      }

      return currentFolderPath
    },
    async mergeFolder (folderObj) {
      console.log('parent level folder info: ', folderObj)
      const currentFolderPath = this.generateFolderPathObj(folderObj)

      const { data: respData } = await this.$http.post(this.serverAPI.FILE_UPLOAD_FOLDER_URL, JSON.stringify(currentFolderPath), { headers: { 'content-type': 'application/json' } })
      console.log('folder save response', respData)
      if (respData.code === 1) {
        const folderList = []
        for (const fileItem of folderObj.fileList) {
          console.log('merge subfolder and subfile list', fileItem)
          // fileItem.pfid = respData.data.fileID
          fileItem.pfid = respData.data.currentFile.id
          fileItem.parentFile = respData.data.currentFile
          fileItem.fileTarget = folderObj.fileTarget
          if (fileItem.isFolder) {
            console.log('sub folder :', fileItem)
            folderList.push(fileItem)
          } else {
            console.log('filepath=%s', fileItem.path)
            await this.mergeFile(fileItem)
          }
        }

        console.log('subfolder list', folderList)
        while (folderList.length !== 0) {
          const subFolder = folderList.shift()
          console.log('sub folder list iteration', subFolder)
          const currentSubFolderPath = this.generateFolderPathObj(subFolder)
          const { data: subRespData } = await this.$http.post(this.serverAPI.FILE_UPLOAD_FOLDER_URL, JSON.stringify(currentSubFolderPath), { headers: { 'content-type': 'application/json' } })

          if (subRespData.code === 1) {
            const subFileList = subFolder.fileList
            for (const subFileItem of subFileList) {
              // subFileItem.pfid = subRespData.data.fileID
              subFileItem.pfid = subRespData.data.currentFile.id
              subFileItem.parentFile = subRespData.data.currentFile
              subFileItem.fileTarget = subFolder.fileTarget
              if (subFileItem.isFolder) {
                folderList.push(subFileItem)
              } else {
                console.log('pfid=%s, filepath=%s', subFolder.pfid, subFileItem.name)
                await this.mergeFile(subFileItem)
              }
            }
          }
        }
      }

      return respData.data
    },
    async mergeFile (fileObj) {
      console.log('merge file info', fileObj)
      const uploadFileName = fileObj.file.name
      const suffixIndex = uploadFileName.lastIndexOf('.')
      const uploadFileSuffix = uploadFileName.substring(suffixIndex, uploadFileName.length)

      const currentFile = {
        fileName: fileObj.file.name,
        filePath: fileObj.relativePath,
        fileUniqueID: fileObj.uniqueIdentifier,
        fileSize: fileObj.file.size,
        fileSuffix: uploadFileSuffix.toLowerCase(),
        isFolder: fileObj.isFolder,
        totalChunks: fileObj.totalChunks,
        fileTarget: fileObj.fileTarget,
        operator: this.currentLoginUser,
        modifiedStamp: fileObj.file.lastModified
      }
      const currentFilePath = {
        parentFile: fileObj.parentFile,
        currentFile: currentFile,
        isFolder: fileObj.isFolder
      }
      const { data: newMergeFileResp } = await this.$http.post(this.serverAPI.FILE_UPLOAD_INFO_URL, JSON.stringify(currentFilePath), { headers: { 'content-type': 'application/json' } })
      console.log('new merge file response: ', newMergeFileResp)

      if (newMergeFileResp.code === 1 && newMergeFileResp.data) {
        if (newMergeFileResp.data.isSkip) {
          this.skipedFilePathes.push(newMergeFileResp.data)
        }
      }

      currentFilePath.currentFile = newMergeFileResp.data.currentFile
      // request the server to merge file
      this.$http.post(this.serverAPI.FILE_UPLOAD_MERGE_URL, JSON.stringify(currentFilePath), { headers: { 'content-type': 'application/json' } })
        .then(mergeResp => {
          console.log('merge file response', mergeResp)
          const fileStatusCode = mergeResp.data.data.currentFile.fileStatus.statusCode
          console.log('file status code=', fileStatusCode)
          if (fileStatusCode === 2) {
            // send Notifications of the file is under the file merging process
            const notifObj = {
              type: 'file',
              fileUid: currentFilePath.currentFile.fileUniqueID,
              msgContent: 'File \'' + currentFilePath.currentFile.fileName + '\' is under merging.'
            }
            this.addNotification(notifObj)

            const fileNameContainer = this.$createElement('span', { style: 'color: red' }, currentFilePath.currentFile.fileName)
            const messageContainer = this.$createElement('div', {}, ['File ', fileNameContainer, ' is under merging, please wait'])
            this.$notify.info({
              title: 'Notification',
              message: messageContainer
            })
          }
        })

      return newMergeFileResp.data
    },
    async fileRemoveHandler (fieldFileRelation) {
      if (this.enabled && this.relatedDataFormFieldName === fieldFileRelation.fieldName) {
        console.log('remove file field relation: ', fieldFileRelation)

        let removedFile
        if (this.$refs.uploaderListRef) {
          const uploadedFileList = this.$refs.uploaderListRef.fileList
          console.log(this.$refs.uploaderListRef.fileList)
          const uploadedFileIndex = uploadedFileList.findIndex(uploadedFile => {
            return uploadedFile.uniqueIdentifier === fieldFileRelation.fileUniqueID && uploadedFile.relatedFieldName === fieldFileRelation.fieldName
          })

          removedFile = uploadedFileList.splice(uploadedFileIndex, 1)
          console.log('removed file from fileListRef Object:', removedFile)
        } else {
          console.log('this.$refs.uploaderRef', this.$refs.uploaderRef)
          const uploaderFileList = this.$refs.uploaderRef.files
          const uploaderFileIndex = uploaderFileList.findIndex(uploaderFile => {
            return uploaderFile.uniqueIdentifier === fieldFileRelation.fileUniqueID && uploaderFile.relatedFieldName === fieldFileRelation.fieldName
          })

          removedFile = uploaderFileList.splice(uploaderFileIndex, 1)
          console.log('removed file object from uploaderRef', removedFile)
        }

        this.filesProgressUpdateFlag[fieldFileRelation.fileName] = false
        if (!removedFile[0]) {
          console.log('this condition is maybe file is under checking the file upload progress not the really uploading')
          return
        }
        await removedFile[0].pause()
        removedFile[0].cancel()
        const fileUploaderOBJ = this.$refs.uploaderRef.uploader
        let removedFiles = []
        if (fieldFileRelation.isFolder) {
          delete fileUploaderOBJ.filePaths[fieldFileRelation.fileName + '/']
          removedFiles = removedFile[0].files
        } else {
          removedFiles = removedFile
        }
        console.log('removed file list:', removedFiles)
        /** remove file from this.$refs.uploaderRef.uploader
            to reduce the memory space **/
        const removedFileCount = removedFiles.length
        for (let i = 0; i < removedFileCount; i++) {
          const removedF = removedFiles[i]
          for (let j = 0; j < fileUploaderOBJ.files.length; j++) {
            const file = fileUploaderOBJ.files[j]
            if (removedF.uniqueIdentifier === file.uniqueIdentifier && removedF.relatedFieldName === file.relatedFieldName) {
              fileUploaderOBJ.files.splice(j, 1)
            }
          }
        }
        console.log('remove file from file list: ', this.$refs, fileUploaderOBJ)
      }
    }
  }
}
</script>

<style lang="scss">
  #app ~ div.el-message-box__wrapper {
    .msgbox_file {
        width: 50%;
    }
  }

  @media screen and (max-width: 1366px) {
    #app ~ div.el-message-box__wrapper {
      .msgbox_file {
        width: 500px;
      }
    }
  }
</style>

<style scoped>
/** custom css style to change the FileObjUploader to file uplaoding element style */
div >>> div.uploader-file-meta {
  width: 0%;
}
div >>> div.uploader-file-status {
  width: 32%;
}
</style>

<style lang="scss" scoped>
.exist_path_root {
  display: block;
  list-style-type: disc;
  padding-inline-start: 20px;
  margin-top: 10px;
}

.exist_path_root:first-child {
  margin-top: 0;
}

.exist_path_name {
  // list-style-type: circle;
  padding-inline-start: 10px;
}
#global-uploader {
  position: fixed;
  z-index: 20;
  right: 15px;
  bottom: 80px;

  .uploader-app {
      width: calc(50% - 160px);
      float: right;
  }

  .file-panel {
      background-color: #fff;
      border: 1px solid #e2e2e2;
      border-radius: 7px 7px 0 0;
      box-shadow: 0 0 10px rgba(0, 0, 0, .2);

      .file-title {
          display: flex;
          height: 40px;
          line-height: 40px;
          padding: 0 15px;
          border-bottom: 1px solid #ddd;

          .operate {
              flex: 1;
              text-align: right;
          }
      }

      .file-list {
          position: relative;
          height: 240px;
          overflow-x: hidden;
          overflow-y: auto;
          background-color: #fff;

          > li {
              background-color: #fff;
          }
      }

      &.collapse {
          .file-title {
              background-color: #E7ECF2;
          }
      }
  }

  .no-file {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 16px;
  }

  ::v-deep.uploader-file-icon {
      &:before {
          content: '' !important;
      }

      &[icon=image] {
        background: url(../../assets/file/images/file_img.png);
      }
      &[icon=video] {
        background: url(../../assets/file/images/file_video.png);
      }
      &[icon=audio] {
        background: url(../../assets/file/images/file_mp3.png);
      }
      &[icon=excel] {
        background: url(../../assets/file/images/file_excel.png);
      }
      &[icon=ppt] {
        background: url(../../assets/file/images/file_ppt.png);
      }
      &[icon=word] {
        background: url(../../assets/file/images/file_docx.png);
      }
      &[icon=pdf] {
        background: url(../../assets/file/images/file_pdf.png);
      }
      &[icon=zip] {
        background: url(../../assets/file/images/file_zip.png);
      }
      &[icon=text] {
        background: url(../../assets/file/images/file_txt.png);
      }
      &[icon=folder] {
        background: url(../../assets/file/images/folder.png);
      }
      &[icon=unknown] {
        background: url(../../assets/file/images/file_none.png);
      }
  }

  ::v-deep.uploader-file-actions > span {
      margin-right: 6px;
  }
}
 /* hidde file upload button */
 .uploader-btn {
   display: none;
 }
</style>
