<template>
  <section>
    <el-row type="flex" justify="center">
      <el-col :span="18">
        <el-image align="center" style="width: 372px; height: 240px; float:right; clear: both; margin-left: 3px;" :src="require('@/assets/help/user-module.gif')" :preview-src-list="[require('@/assets/help/user-module.gif')]" />
        <div style="font-size: 16px;">
          <h1>User List Help</h1>
          <p>In the 'User Module', there are more than one tab in the 'User List' page. The first tab is 'All Users' that is used to show all the user table list. There is a 'Detail' <el-button class="el-icon-view" type="primary" size="mini" /> button on the rightest column of each table row.
          A dialog will appear after clicking the button. There is the detail information of the corresponding user in the dialog.</p>
          <br>
          <p>
            You can use the input box
            <el-input style="width: 260px;" placeholder="Please input the user real name to search" size="mini"></el-input>
            on the upper-right corner of the user list page to enter the username keyword for fuzzy search.
            There is a button named <el-button type="primary" size="mini" readonly>Search Criteria</el-button> to edit the more search criteria. The 'Search Criteria' dialog will appear after clicking 'Search Criteria' button.
            In the dialog, there are three fields: 'Project', 'User Role' and 'User Status'. You can choose one or more fields for the user list query.
          </p>
          <br>
          <p>
            There is one or more project named tabs corresponding to the project relations on the right of the 'All Users' tab. For the project tab, if you are the PI of the corresponding project,
            you can manage the access right to the Data Management System (DMS) of the project members.
            Only the users with the 'PI' role and manage the status of the project members, the other roles of user can only view the detail of the users.
            And if there are some new user applications for the access right to the DMS, there will be a red dot on the upper-right corner of the tab displaying the numbers of the application count.
            The red dot can only disappear when there is no 'Unapproved' status user data row in the project tab.
          </p>
          <br>
          <p>
            You can click the image on the right to zoom out and see the detailed operations
          </p>
          <br>
        </div>
      </el-col>
    </el-row>
    <el-row type="flex" justify="center">
      <el-col :span="18">
        <el-image style="width: 372px; height: 240px; float:right; clear: both; margin-left: 3px;" :src="require('@/assets/help/user-notificaion.gif')" :preview-src-list="[require('@/assets/help/user-notificaion.gif')]"></el-image>
        <div style="font-size: 16px;">
          <h1>User Notification Help</h1>
          <p>
            If you have a user with 'PI' role. When you log in to the DMS, the DMS automatically check the 'Unapproved' status user applications of the corresponding projects.
            The red dot will appear if there is one or more 'Unapproved' status user applications of the corresponding projects.
            You can click the notification item to jump to the 'User List' page.
          </p>
          <br>
          <p>
            In the 'User List' page, there will be a red dot on the upper-right corner of the corresponding project tab displaying the numbers of the application count.
            There is a red dot on the user table rows of 'Unapproved' status.
            The number of the project tab decrease when you approve the 'Unapproved' data row. The red dot will disappear until there is no 'Unapproved' data row.
          </p>
        </div>
      </el-col>
    </el-row>
  </section>
</template>
<script>
export default {
}
</script>
<style lang="less" scoped>
h1 {
  font-size: 26px;
  font-weight: bold;
}
</style>
