<template>
  <div>
    <el-row>
      <el-col>
        <el-form-item label="Project" class="data-form-label" prop="project.id">
          <el-input :value="commonFormData.project.projectName" readonly></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-form-item label="Responsiboe PI" class="data-form-label" prop="responsiblePI.id">
        <el-input :value="commonFormData.responsiblePI.realName" readonly :class="commonFormData.responsiblePI.realName !== compareFormData.responsiblePI.realName? 'compareHighlight': ''"></el-input>
      </el-form-item>
    </el-row>
    <el-row>
      <el-form-item label="Experiment Title" class="data-form-label" prop="title">
        <el-input v-model="commonFormData.title" name="title" clearable readonly :class="commonFormData.title !== compareFormData.title? 'compareHighlight': ''"></el-input>
      </el-form-item>
    </el-row>
    <el-row>
      <el-form-item label="Experimenter" class="data-form-label" prop="author.id">
        <el-input :value="commonFormData.author.realName" readonly :class="commonFormData.author.realName !== compareFormData.author.realName? 'compareHighlight': ''"></el-input>
      </el-form-item>
    </el-row>
    <el-row>
      <el-form-item label="Experiment Time" class="data-form-label" prop="timePeriod">
        <el-date-picker v-model="commonFormData.timePeriod"
          name="timePeriod" type="daterange" align="right" unlink-panels range-separator="-"
          start-placeholder="startTime" end-placeholder="endTime" style="width: 100%;"
          value-format="timestamp" readonly
          :class="[commonFormData.timePeriod[0] !== compareFormData.timePeriod[0]? 'compareDate1Highlight': '', commonFormData.timePeriod[1] !== compareFormData.timePeriod[1]? 'compareDate2Highlight': '']"></el-date-picker>
      </el-form-item>
    <el-row>
    </el-row>
      <el-form-item label="Uploader" class="data-form-label" prop="creator.id">
        <el-input name="creator" :value="commonFormData.creator.realName" readonly :class="commonFormData.creator.realName !== compareFormData.creator.realName? 'compareHighlight': ''"></el-input>
      </el-form-item>
    </el-row>
    <el-row>
      <el-form-item label="Sharing Mode" class="data-form-label" prop="shareStatus.shareCode">
        <el-input :value="commonFormData.shareStatus.shareDesc" readonly :class="commonFormData.shareStatus.shareDesc !== compareFormData.shareStatus.shareDesc? 'compareHighlight': ''"></el-input>
      </el-form-item>
    </el-row>
    <el-row>
      <el-form-item label="Sharing License Type" class="data-form-label" prop="licencesType.desc">
        <el-input :value="commonFormData.licencesType? commonFormData.licencesType.desc: ''" readonly
          :class="(commonFormData.licencesType && compareFormData.licencesType)? commonFormData.licencesType.desc !== compareFormData.licencesType.desc? 'compareHighlight': '': commonFormData.licencesType != compareFormData.licencesType? 'compareHighlight': ''"
        ></el-input>
      </el-form-item>
    </el-row>
    <el-row>
      <el-form-item label="Experiment Method" class="data-form-label" prop="experimentMethod">
        <el-input v-model="commonFormData.experimentMethod" name="experimentMethod" clearable readonly :class="commonFormData.experimentMethod !== compareFormData.experimentMethod? 'compareHighlight': ''"></el-input>
      </el-form-item>
    </el-row>
    <el-row>
      <el-form-item label="Animal Experiment Permit Type" class="data-form-label" prop="animalPermitType.permitCode">
        <el-input :value="commonFormData.animalPermitType.permitDesc" readonly :class="commonFormData.animalPermitType.permitDesc !== compareFormData.animalPermitType.permitDesc? 'compareHighlight': ''"></el-input>
      </el-form-item>
    </el-row>

    <HistoryFilePropery formProperty="animalPermitFile" formLabel="Animal Experiment Permit File" :fileList="commonFormData['animalPermitFile']" :compareFileList="compareFormData['animalPermitFile']"></HistoryFilePropery>

    <el-row>
      <el-form-item label="Notes" class="data-form-label" prop="description">
        <el-input v-model="commonFormData.description" type="textarea" resize="vertical" name="description" clearable readonly :autosize="{minRows: 5}"
          :class="commonFormData.description !== compareFormData.description? 'compareHighlight': ''"
        ></el-input>
      </el-form-item>
    </el-row>
  </div>
</template>
<script>
import HistoryFilePropery from '../history_component/HistoryFilePropery'

export default {
  props: ['dataHistoryDetail', 'compareDataDetail'],
  components: {
    HistoryFilePropery
  },
  data () {
    return {
      commonFormData: {
        project: {
          projectName: ''
        },
        responsiblePI: {
          realName: ''
        },
        creator: {
          realName: ''
        },
        author: {
          realName: ''
        },
        shareStatus: {
          shareDesc: ''
        },
        licencesType: {
          desc: ''
        },
        animalPermitType: {
          permitDesc: ''
        },
        timePeriod: [
          0, 0
        ]
      },
      compareFormData: {
        project: {
          projectName: ''
        },
        responsiblePI: {
          realName: ''
        },
        creator: {
          realName: ''
        },
        author: {
          realName: ''
        },
        shareStatus: {
          shareDesc: ''
        },
        licencesType: {
          desc: ''
        },
        animalPermitType: {
          permitDesc: ''
        },
        timePeriod: [
          0, 0
        ]
      }
    }
  },
  watch: {
    dataHistoryDetail (newDetail) {
      this.commonFormData = newDetail
      if (this.commonFormData.timePeriod) {
        if (this.commonFormData.timePeriod.length === 2) {
          if (!this.commonFormData.timePeriod[0]) {
            this.commonFormData.timePeriod[0] = '-'
          }

          if (!this.commonFormData.timePeriod[1]) {
            this.commonFormData.timePeriod[1] = '-'
          }
        }
      }
    },
    compareDataDetail (newDetail) {
      this.compareFormData = newDetail
      if (this.compareFormData.timePeriod) {
        if (this.compareFormData.timePeriod.length === 2) {
          if (!this.compareFormData.timePeriod[0]) {
            this.compareFormData.timePeriod[0] = '-'
          }

          if (!this.compareFormData.timePeriod[1]) {
            this.compareFormData.timePeriod[1] = '-'
          }
        }
      }
    }
  },
  methods: {
  }
}
</script>
<style scoped>
div /deep/ .compareHighlight .el-input__inner {
  background-color:yellow;
}
div /deep/ .compareHighlight .el-textarea__inner {
  background-color:yellow;
}
div /deep/ .compareDate1Highlight .el-range-input:first-of-type {
  background-color:yellow;
}
div /deep/ .compareDate2Highlight .el-range-input:nth-of-type(2) {
  background-color:yellow;
}
</style>
