<template>
  <el-card>
    <el-form>
      <el-row>
        <el-col>
          <el-form-item label="Publication Type" class="data-form-label" prop="paperType.typeCode">
            <el-input :value="paperFormData.paperType.typeName" readonly></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row v-if="paperFormData.paperType.typeCode === 1 || paperFormData.paperType.typeCode === 2 || paperFormData.paperType.typeCode === 3">
        <el-col>
          <el-form-item label="Publication Status" class="data-form-label" prop="paperStatus.statusCode">
            <el-input :value="paperFormData.paperStatus.statusName" readonly :class="paperFormData.paperStatus.statusName !== compareFormData.paperStatus.statusName? 'compareHighlight': ''"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <el-form-item label="Publication Title" class="data-form-label" prop="paperTitle">
            <el-input :value="paperFormData.paperTitle" readonly :class="paperFormData.paperTitle !== compareFormData.paperTitle? 'compareHighlight': ''"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row v-if="paperFormData.paperType.typeCode !== 5">
        <el-col>
          <el-form-item label="Publication DOI" class="data-form-label" prop="paperDOI">
            <el-input :value="paperFormData.paperDOI" readonly :class="paperFormData.paperDOI !== compareFormData.paperDOI? 'compareHighlight': ''"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row v-if="paperFormData.paperType.typeCode !== 5">
        <el-col>
          <el-form-item label="Publication URL" class="data-form-label" prop="paperURL">
            <el-input :value="paperFormData.paperURL" readonly :class="paperFormData.paperURL !== compareFormData.paperURL? 'compareHighlight': ''"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row v-if="paperFormData.paperType.typeCode !== 5">
        <el-col>
          <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="Year" class="data-form-label" prop="paperYear">
                  <el-input :value="paperFormData.paperYear" readonly :class="paperFormData.paperYear !== compareFormData.paperYear? 'compareHighlight': ''"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Month" class="data-form-label" prop="paperMonth">
                  <el-input :value="paperFormData.paperMonth" readonly :class="paperFormData.paperMonth !== compareFormData.paperMonth? 'compareHighlight': ''"></el-input>
                </el-form-item>
              </el-col>
          </el-row>
        </el-col>
      </el-row>
      <el-row v-if="paperFormData.paperType.typeCode === 4">
        <el-col>
          <el-form-item label="University" class="data-form-label" prop="paperUniversity">
            <el-input :value="paperFormData.paperUniversity" readonly :class="paperFormData.paperUniversity !== compareFormData.paperUniversity? 'compareHighlight': ''"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row v-if="paperFormData.paperType.typeCode === 1">
        <el-col>
          <el-form-item label="Journal" class="data-form-label" prop="paperJournal">
            <el-input :value="paperFormData.paperJournal" readonly :class="paperFormData.paperJournal !== compareFormData.paperJournal? 'compareHighlight': ''"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row v-if="paperFormData.paperType.typeCode === 3">
        <el-col>
          <el-form-item label="Book Title" class="data-form-label" prop="paperBookTitle">
            <el-input :value="paperFormData.paperBookTitle" readonly :class="paperFormData.paperBookTitle !== compareFormData.paperBookTitle? 'compareHighlight': ''"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row v-if="paperFormData.paperType.typeCode === 2">
        <el-col>
          <el-form-item label="Book ISBN" class="data-form-label" prop="paperBookISBN">
            <el-input :value="paperFormData.paperBookISBN" readonly :class="paperFormData.paperBookISBN !== compareFormData.paperBookISBN? 'compareHighlight': ''"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row v-if="paperFormData.paperType.typeCode === 1 || paperFormData.paperType.typeCode === 2 || paperFormData.paperType.typeCode === 3">
        <el-col>
          <el-form-item label="Volume" class="data-form-label" prop="paperVolume">
            <el-input :value="paperFormData.paperVolume" readonly :class="paperFormData.paperVolume !== compareFormData.paperVolume? 'compareHighlight': ''"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row v-if="paperFormData.paperType.typeCode === 2 || paperFormData.paperType.typeCode === 3">
        <el-col>
          <el-form-item label="Editor" class="data-form-label" prop="paperBookEditor">
            <el-input :value="paperFormData.paperBookEditor" readonly :class="paperFormData.paperBookEditor !== compareFormData.paperBookEditor? 'compareHighlight': ''"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row v-if="paperFormData.paperType.typeCode === 2 || paperFormData.paperType.typeCode === 3">
        <el-col>
          <el-form-item label="Publisher" class="data-form-label" prop="paperBookPublisher">
            <el-input :value="paperFormData.paperBookPublisher" readonly :class="paperFormData.paperBookPublisher !== compareFormData.paperBookPublisher? 'compareHighlight': ''"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row type="flex" justify="space-between" align="middle">
        <el-col :span="10">
          <el-form-item label="Related Projects" class="data-form-label" prop="paperRelatedProject">
          </el-form-item>
        </el-col>
        <el-col :sm="3" :lg="2">
          <el-button type="text" style="padding-left: 9px; padding-right: 5px; font-weight: bold;" v-if="paperProjectFieldMoreBtnFlag" @click="shiftMorePaperProjectField">
            {{ paperProjectFieldActualHeight > paperProjectFieldOriginHeight ? 'More' : 'Less'}}
            <i :class="paperProjectFieldActualHeight > paperProjectFieldOriginHeight ? 'el-icon-arrow-right' : 'el-icon-arrow-down'"/>
          </el-button>
        </el-col>
      </el-row>
      <el-row>
        <el-scrollbar v-fieldcontent="{set: setPaperProjectFieldMoreBtnVisible}" v-if="paperFormData.paperRelatedProject && paperFormData.paperRelatedProject.length && paperFormData.paperRelatedProject.length > 0" :style="{'height': paperProjectFieldOriginHeight+'px', 'background': '#fafafa', 'padding-left': '10px'}">
          <el-tag :type="compareProjectItem(relatedProject.id)" v-for="relatedProject in paperFormData.paperRelatedProject" :key="relatedProject.id" style="margin-right: 3px; margin-bottom: 3px;">
            {{ relatedProject.projectName }}
          </el-tag>
        </el-scrollbar>
        <el-col v-if="!paperFormData.paperRelatedProject || !paperFormData.paperRelatedProject.length || paperFormData.paperRelatedProject.length === 0">
          <div :style="{'height': paperProjectFieldOriginHeight + 'px'}"></div>
        </el-col>
      </el-row>
      <el-row type="flex" justify="space-between" align="middle">
        <el-col :span="10">
          <el-form-item label="Related Data" class="data-form-label" prop="paperRelatedData">
          </el-form-item>
        </el-col>
        <el-col :sm="3" :lg="2">
          <el-button type="text" style="padding-left: 9px; padding-right: 5px; font-weight: bold;" v-if="paperDataFieldMoreBtnFlag" @click="shiftMoreDataProjectField">
            {{ paperDataFieldActualHeight > paperDataFieldOriginHeight ? 'More' : 'Less'}}
            <i :class="paperDataFieldActualHeight > paperDataFieldOriginHeight ? 'el-icon-arrow-right' : 'el-icon-arrow-down'"/>
          </el-button>
        </el-col>
      </el-row>
      <el-row>
        <el-scrollbar v-fieldcontent="{set: setPaperDateFieldMoreBtnVisible}" v-if="paperFormData.paperRelatedData && paperFormData.paperRelatedData.length && paperFormData.paperRelatedData.length > 0" :style="{'height': paperDataFieldOriginHeight+'px', 'background': '#fafafa', 'padding-left': '10px'}">
          <el-tooltip v-for="relatedData in paperFormData.paperRelatedData" :key="relatedData.id" :content="relatedData.title">
            <el-tag class="data-name" :type="compareDataItem(relatedData.dataID)" style="margin-right: 3px; margin-bottom: 3px;">
              {{ relatedData.title }}
            </el-tag>
          </el-tooltip>
        </el-scrollbar>
        <el-col v-if="!paperFormData.paperRelatedData || !paperFormData.paperRelatedData.length || paperFormData.paperRelatedData.length === 0">
          <div :style="{'height': paperDataFieldOriginHeight + 'px'}"></div>
        </el-col>
      </el-row>

      <HistoryFilePropery formProperty="paperFile" formLabel="Publication File" :fileList="paperFormData['paperFile']" :compareFileList="compareFormData['paperFile']"></HistoryFilePropery>

      <el-row v-if="paperFormData.paperType.typeCode === 1 || paperFormData.paperType.typeCode === 2 || paperFormData.paperType.typeCode === 3">
        <el-col>
          <el-form-item label="File Download Permission" class="data-form-label" prop="downloadSwitch">
            <el-input :value="paperFormData.downloadSwitch? 'Opem access' : 'Only internal access'" readonly :class="paperFormData.downloadSwitch !== compareFormData.downloadSwitch? 'compareHighlight': ''"></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row v-if="paperFormData.paperType.typeCode === 1 || paperFormData.paperType.typeCode === 3">
        <el-col>
          <el-form-item label="Page Range" class="data-form-label" prop="paperPageRange">
            <el-input v-model="paperFormData.paperPageRange" readonly :class="paperFormData.paperPageRange !== compareFormData.paperPageRange? 'compareHighlight': ''"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <el-form-item label="Related Authors" class="data-form-label" prop="paperRelatedProjectAuthors">
            <el-input type="textarea" :rows="6" resize="none" :value="paperFormData.paperRelatedProjectAuthors" readonly :class="paperFormData.paperRelatedProjectAuthors !== compareFormData.paperRelatedProjectAuthors? 'compareHighlight': ''"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </el-card>
</template>
<script>
import { EVENT_CONFIG } from '@/event_bus'
import HistoryFilePropery from '@/components/history_component/HistoryFilePropery'

export default {
  props: ['paperHistoryDetail', 'comparePaperDetail'],
  components: {
    HistoryFilePropery
  },
  created () {
    EVENT_CONFIG.EventBus.$on(EVENT_CONFIG.HISTORY_PAPER_FIELD_UPDATE_EVENT, this.updatePaperFieldHeight)
    EVENT_CONFIG.EventBus.$on(EVENT_CONFIG.HISTORY_PAPER_FIELD_INIT_EVENT, this.initPaperFieldHeight)
  },
  beforeDestroy () {
    EVENT_CONFIG.EventBus.$off(EVENT_CONFIG.HISTORY_PAPER_FIELD_UPDATE_EVENT)
    EVENT_CONFIG.EventBus.$off(EVENT_CONFIG.HISTORY_PAPER_FIELD_INIT_EVENT)
  },
  directives: {
    fieldcontent: {
      inserted (el, binding, vnode) {
        if (el.querySelector('.el-scrollbar__wrap').clientHeight < el.querySelector('.el-scrollbar__wrap').scrollHeight) {
          binding.value.set(el.querySelector('.el-scrollbar__wrap').scrollHeight)
        }
      }
    }
  },
  watch: {
    paperHistoryDetail (newDetail) {
      this.paperFormData = newDetail
    },
    comparePaperDetail (newDetail) {
      this.compareFormData = newDetail
    }
  },
  computed: {
    compareProjectItem () {
      return function (projectID) {
        if (!this.compareFormData.paperRelatedProject) {
          return 'warning'
        }

        const projectIndex = this.compareFormData.paperRelatedProject.findIndex(compareProject => {
          return projectID === compareProject.id
        })

        if (projectIndex === -1) {
          return 'warning'
        }
      }
    },
    compareDataItem () {
      return function (dataID) {
        if (!this.compareFormData.paperRelatedData) {
          return 'warning'
        }

        const dataIndex = this.compareFormData.paperRelatedData.findIndex(compareData => {
          return dataID === compareData.dataID
        })

        if (dataIndex === -1) {
          return 'warning'
        }
      }
    }
  },
  data () {
    return {
      paperProjectFieldActualHeight: 60,
      paperProjectFieldOriginHeight: 60,
      paperProjectFieldMoreBtnFlag: false,
      paperDataFieldActualHeight: 60,
      paperDataFieldOriginHeight: 60,
      paperDataFieldMoreBtnFlag: false,
      paperFormData: {
        paperType: {
          typeName: ''
        },
        paperStatus: {
          statusName: ''
        }
      },
      compareFormData: {
        paperType: {
          typeName: ''
        },
        paperStatus: {
          statusName: ''
        }
      }
    }
  },
  methods: {
    setPaperProjectFieldMoreBtnVisible (actualHeight) {
      this.paperProjectFieldMoreBtnFlag = true
      EVENT_CONFIG.EventBus.$emit(EVENT_CONFIG.HISTORY_PAPER_FIELD_INIT_EVENT, actualHeight, 'project')
    },
    setPaperDateFieldMoreBtnVisible (actualHeight) {
      this.paperDataFieldMoreBtnFlag = true
      EVENT_CONFIG.EventBus.$emit(EVENT_CONFIG.HISTORY_PAPER_FIELD_INIT_EVENT, actualHeight, 'data')
    },
    shiftMorePaperProjectField () {
      if (this.paperProjectFieldActualHeight > this.paperProjectFieldOriginHeight) {
        EVENT_CONFIG.EventBus.$emit(EVENT_CONFIG.HISTORY_PAPER_FIELD_UPDATE_EVENT, this.paperProjectFieldActualHeight, 'project')
      } else {
        EVENT_CONFIG.EventBus.$emit(EVENT_CONFIG.HISTORY_PAPER_FIELD_UPDATE_EVENT, 60, 'project')
      }
    },
    shiftMoreDataProjectField () {
      if (this.paperDataFieldActualHeight > this.paperDataFieldOriginHeight) {
        EVENT_CONFIG.EventBus.$emit(EVENT_CONFIG.HISTORY_PAPER_FIELD_UPDATE_EVENT, this.paperDataFieldActualHeight, 'data')
      } else {
        EVENT_CONFIG.EventBus.$emit(EVENT_CONFIG.HISTORY_PAPER_FIELD_UPDATE_EVENT, 60, 'data')
      }
    },
    initPaperFieldHeight (actualHeight, fieldName) {
      if (fieldName === 'project') {
        if (actualHeight > this.paperProjectFieldActualHeight) {
          this.paperProjectFieldActualHeight = actualHeight
        }
      }

      if (fieldName === 'data') {
        if (actualHeight > this.paperDataFieldActualHeight) {
          this.paperDataFieldActualHeight = actualHeight
        }
      }
    },
    updatePaperFieldHeight (actualHeight, fieldName) {
      if (fieldName === 'project') {
        this.paperProjectFieldOriginHeight = actualHeight
      }

      if (fieldName === 'data') {
        this.paperDataFieldOriginHeight = actualHeight
      }
    }
  }
}
</script>
<style scoped>
.data-name {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
  width: 150px;
  height: 32px;
  vertical-align: middle;
}

div /deep/ .compareHighlight .el-input__inner {
  background-color:yellow;
}

div /deep/ .compareHighlight .el-textarea__inner {
  background-color:yellow;
}
</style>
