import JsSHA from 'jssha'
function formDataChangeValidate (formData, digest) {
  const startDigest = new Date().getTime()
  const jsSHA = new JsSHA('SHA-256', 'TEXT')
  const formDataStr = JSON.stringify(formData)
  console.debug('form data string: ', formDataStr)
  jsSHA.update(formDataStr)
  const newDigest = jsSHA.getHash('HEX')
  console.debug('new digest of the form data: ', newDigest)
  console.log('validate form data digest time cost: ', new Date().getTime() - startDigest)
  return newDigest !== digest
}
/**
 * validate the specified field of the specified form reference object
 * @param {*} formRefObj
 * @param {*} fieldName
 */
function formFieldValidate (formRefObj, fieldName) {
  formRefObj.validateField(fieldName)
}

async function formValidate (formRefObj) {
  return await formRefObj.validate().catch(err => { return err })
}
/**
 * this method is used for both
 * 'commonDataForm' and 'nonCommonDataForm'
 */
// eslint-disable-next-line no-unused-vars
function formSyncValidate (formRefObj, eleCreator, msgTitle) {
  const messageContents = []

  formRefObj.validate((result, fieldInfo) => {
    console.log('check form result', result)
    console.log('fieldInfo: ', fieldInfo)
    if (!result) {
      for (const fieldKey in fieldInfo) {
        const msgContent = eleCreator('div', { style: 'color: red' }, fieldInfo[fieldKey][0].message)
        messageContents.push(msgContent)
      }
    }
  })

  if (messageContents.length > 0) {
    const formMsgContents = []

    if (msgTitle) {
      const formMsgTitle = eleCreator('h1', { style: 'font-size: 15px; font-weight: bold;' }, msgTitle)
      formMsgContents.push(formMsgTitle)
    }

    const formMsgDIV = eleCreator('div', { style: 'margin: 0 15px;' }, messageContents)
    formMsgContents.push(formMsgDIV)

    console.log('check formMsgContents: ', formMsgContents)
    return formMsgContents
  }
}

/**
 *
 * @param {*} formRefObj
 * @param {*} eleCreator
 * @param {*} callback
 */
// eslint-disable-next-line no-unused-vars
function formAsyncValidate (formRefObj, eleCreator, callback) {
  formRefObj.validate((result, fieldInfo) => {
    console.log('check form result', result)
    console.log('fieldInfo: ', fieldInfo)
    if (!result) {
      const messageContents = []
      for (const fieldKey in fieldInfo) {
        const msgContent = eleCreator('div', { style: 'color: red' }, fieldInfo[fieldKey][0].message)
        messageContents.push(msgContent)
      }

      if (messageContents.length > 0) {
        callback(messageContents)
      }

      return
    }
    callback()
  })
}

export const formValidator = {
  formValidate,
  formFieldValidate,
  formDataChangeValidate
}
