<template>
  <section>
    <el-row :gutter="layoutConf.row.gutter" :style="layoutConf.row.styleContent">
      <el-col :lg="layoutConf.col.lgSpan" :md="layoutConf.col.mdSpan">
        <el-form-item label="Project" class="data-form-label" prop="project.id">
          <el-input v-if="commonFormData.dataID > 0" :value="commonFormData.project.projectName" readonly></el-input>
          <el-select :disabled="!writeSwitch" v-else @change="changeProject($event, 'project')" v-model="commonFormData.project.id" style="width: 100%;" name="project" clearable>
            <el-option v-for="project in projectOptions" :key="project.id" :value="project.id" :label="project.projectName"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :lg="layoutConf.col.lgSpan" :md="layoutConf.col.mdSpan">
        <el-form-item label="Responsible PI" class="data-form-label" prop="responsiblePI.id">
          <el-select :disabled="!writeSwitch" v-if="commonFormData.creator.id === currentUser.id" @change="userChange($event, 'responsiblePI')" v-model="commonFormData.responsiblePI.id" style="width: 100%;" name="responsiblePI" clearable>
            <el-option v-for="supervisor in supervisors" :key="supervisor.id" :value="supervisor.id" :label="supervisor.realName"></el-option>
          </el-select>
          <el-input v-else :value="commonFormData.responsiblePI.realName" readonly></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="layoutConf.row.gutter" :style="layoutConf.row.styleContent">
      <el-col :lg="layoutConf.col.lgSpan" :md="layoutConf.col.mdSpan">
        <el-form-item label="Experiment Title" class="data-form-label" prop="title">
          <el-input :disabled="!writeSwitch" v-model="commonFormData.title" name="title" clearable :readonly="!(commonFormData.creator.id === currentUser.id)"></el-input>
        </el-form-item>
      </el-col>
      <el-col :lg="layoutConf.col.lgSpan" :md="layoutConf.col.mdSpan">
        <el-form-item label="Experimenter" class="data-form-label" prop="author.id">
          <el-select :disabled="!writeSwitch" v-if="commonFormData.creator.id === currentUser.id" @change="userChange($event, 'author')" v-model="commonFormData.author.id" style="width: 100%;" name="author" clearable>
            <el-option v-for="projectMember in memberList" :key="projectMember.id" :value="projectMember.id" :label="projectMember.realName"></el-option>
          </el-select>
          <el-input v-else :value="commonFormData.author.realName" readonly></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="layoutConf.row.gutter" :style="layoutConf.row.styleContent">
      <el-col :lg="layoutConf.col.lgSpan" :md="layoutConf.col.mdSpan">
        <el-form-item label="Experiment Time" class="data-form-label" prop="timePeriod">
          <el-date-picker :disabled="!writeSwitch" v-model="commonFormData.timePeriod"
            name="timePeriod" type="daterange" align="right" unlink-panels range-separator="-"
            start-placeholder="startTime" end-placeholder="endTime" style="width: 100%;"
            value-format="timestamp" :picker-options="pickerBeginDateBefore"
            :default-value="timeDefaultShow" :readonly="!(commonFormData.creator.id === currentUser.id)"></el-date-picker>
        </el-form-item>
      </el-col>
      <el-col :lg="layoutConf.col.lgSpan" :md="layoutConf.col.mdSpan">
        <el-form-item label="Uploader" class="data-form-label" prop="creator.id">
          <!-- <el-select @change="userChange($event, 'creator')" v-model="commonFormData.creator.userName" style="width: 100%;" name="creator" clearable>
            <el-option value="1" label="User1"></el-option>
          </el-select> -->
          <el-input :disabled="!writeSwitch" name="creator" :value="commonFormData.creator.realName? commonFormData.creator.realName : currentUser.realName" readonly></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="layoutConf.row.gutter" :style="layoutConf.row.styleContent">
      <el-col :lg="layoutConf.col.lgSpan" :md="layoutConf.col.mdSpan">
        <el-form-item label="Sharing Mode" class="data-form-label" prop="shareStatus.shareCode">
          <span v-if="commonFormData.creator.id === currentUser.id">
            <el-radio :disabled="!writeSwitch" v-for="(shareMode, index) in shareModeOptions" :key="index" :value="commonFormData.shareStatus.shareCode" name="shareStatus" :label="shareMode.shareCode" @change="togglesLicenseStatus(shareMode)"> {{shareMode.shareDesc}} </el-radio>
          </span>
          <el-input v-else :value="commonFormData.shareStatus.shareDesc" readonly></el-input>
        </el-form-item>
      </el-col>
      <el-col :lg="layoutConf.col.lgSpan" :md="layoutConf.col.mdSpan">
        <el-form-item label="Sharing License Type" class="data-form-label" :prop="licencesTypeName">
          <el-select v-if="commonFormData.creator.id === currentUser.id" :disabled="licenseStatusDisable" :placeholder="licenseStatusDisable ? 'Disabled with \'Interal Share\' Mode' : 'Please select a lincense type'" v-model="commonFormData.licencesType.desc" style="width: 100%;" :name="licencesTypeName" clearable>
            <el-option v-for="(shareLicenseType, index) in shareLicenceTypes" :key="index" :label="shareLicenseType.desc" :value="shareLicenseType.desc"></el-option>
          </el-select>
          <el-input v-else :value="commonFormData.licencesType.desc" readonly></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="layoutConf.row.gutter" :style="layoutConf.row.styleContent">
      <el-col :lg="layoutConf.col.lgSpan" :md="layoutConf.col.mdSpan">
        <el-form-item label="Experiment Method" class="data-form-label" prop="experimentMethod">
          <el-input :disabled="!writeSwitch" v-model="commonFormData.experimentMethod" name="experimentMethod" clearable :readonly="!(commonFormData.creator.id === currentUser.id)"></el-input>
        </el-form-item>
        <el-form-item label="Animal Experiment Permit Type" class="data-form-label" prop="animalPermitType.permitCode">
          <span v-if="commonFormData.creator.id === currentUser.id">
            <el-radio @change="togglePermitFile(permitType)" v-for="(permitType, index) in permitTypeOptions" :key="index" :value="commonFormData.animalPermitType.permitCode" name="animalPermitType" :label="permitType.permitCode"> {{permitType.permitDesc}} </el-radio>
          </span>
          <el-input v-else :value="commonFormData.animalPermitType.permitDesc"></el-input>
        </el-form-item>
        <el-form-item v-show="permitFileVisible" label="Animal Experiment Permit File" class="data-form-label" :prop="permitFileName">
          <FileFormField :fieldName="permitFileName" :formData="commonFormData" :isCommon="true" :creator="commonFormData.creator"></FileFormField>
        </el-form-item>
      </el-col>
      <el-col :lg="layoutConf.col.lgSpan" :md="layoutConf.col.mdSpan">
        <el-form-item label="Notes" class="data-form-label" prop="description">
          <el-input :disabled="!writeSwitch" v-model="commonFormData.description" type="textarea" resize="none" name="description" clearable :readonly="!(commonFormData.creator.id === currentUser.id)"
          placeholder="e.g., the co-experimenters, the objective of this experiment or any relevant reference information. "></el-input>
          <div class="field-prompt">e.g., the co-experimenters, the objective of this experiment or any relevant reference information.</div>
        </el-form-item>
      </el-col>
    </el-row>
  </section>
</template>
<script>
import { mapState, mapMutations } from 'vuex'
import FileFormField from '@/components/data_component/FileFormField.vue'
import { EVENT_CONFIG } from '@/event_bus'

export default {
  data () {
    return {
      pickerBeginDateBefore: { // the configuration for the DataPicker
        disabledDate (time) {
          return time.getTime() > Date.now()
        }
      },
      timeDefaultShow: '', // set the available data for the Datapicker component
      licenseStatusDisable: true,
      permitFileVisible: true,
      licencesTypeName: 'licencesType.desc',
      permitFileName: 'animalPermitFile',
      layoutConf: {
        row: {
          gutter: 20,
          styleContent: 'margin-bottom: 5px;'
        },
        col: {
          lgSpan: 12,
          mdSpan: 24
        }
      },
      currentUser: {}
    }
  },
  components: {
    FileFormField
  },
  async mounted () {
    await this.initCommonPage()
    // initiate the start date and end date for the Datepicker component
    this.timeDefaultShow = new Date()
    this.timeDefaultShow.setMonth(new Date().getMonth() - 1)
  },
  computed: {
    ...mapState({
      writeSwitch: (state) => state.writableStatus,
      commonFormData: (state) => state.dataModule.commonFormData,
      commonFormRules: (state) => state.dataModule.commonFormRules,
      projectOptions: (state) => state.dataPageModule.projectOptions,
      shareLicenceTypes: (state) => state.dataPageModule.shareLicenceTypes,
      shareModeOptions: (state) => state.dataPageModule.shareModeOptions,
      permitTypeOptions: (state) => state.dataPageModule.permitTypeOptions,
      supervisors: (state) => state.dataPageModule.supervisors,
      memberList: (state) => state.dataPageModule.memberList
    })
  },
  watch: {
    commonFormData (newCommonFormData) {
      console.log('new common form data content: ', newCommonFormData)
      this.togglesLicenseStatus(newCommonFormData.shareStatus)
      this.togglePermitFile(newCommonFormData.animalPermitType)
    }
  },
  methods: {
    ...mapMutations({
      newFormRule: 'dataModule/newFormRule',
      addRelatedProject: 'dataModule/addRelatedProject',
      changeNonCommonFormRule: 'dataModule/changeNonCommonFormRule',
      setProjectRelatedFields: 'dataModule/setProjectRelatedFields',
      clearRelatedFields: 'dataModule/clearRelatedFields',
      resetNonCommonFormRule: 'dataModule/resetNonCommonFormRule',
      changeLicenseStatus: 'dataModule/changeLicenseStatus',
      changeAnimalPermitType: 'dataModule/changeAnimalPermitType',
      addDataAddPageUserList: 'dataPageModule/addDataAddPageUserList'
    }),
    async initCommonPage () {
      this.togglesLicenseStatus(this.commonFormData.shareStatus)
      this.togglePermitFile(this.commonFormData.animalPermitType)
      this.currentUser = await this.$userStorage.getCurrentUser()
    },
    togglesLicenseStatus (shareMode) { // to implement the linkage of the 'Share Mode' and the 'Share License Type'
      if (shareMode.shareCode === 1) {
        this.licenseStatusDisable = false
      } else {
        this.licenseStatusDisable = true
      }

      this.commonFormData.shareStatus.shareCode = shareMode.shareCode

      this.changeLicenseStatus(shareMode)

      const ruleContent = {}
      ruleContent.fieldName = this.licencesTypeName
      ruleContent.required = !this.licenseStatusDisable
      this.newFormRule(ruleContent)
      EVENT_CONFIG.EventBus.$emit(EVENT_CONFIG.CHECK_FORM_FIELD_EVENT, true, 'shareStatus.shareCode')
      EVENT_CONFIG.EventBus.$emit(EVENT_CONFIG.CHECK_FORM_FIELD_EVENT, true, this.licencesTypeName)
    },
    togglePermitFile (permitType) { // to implement the linkage of the 'Animal Experiment Permit Type' and the 'Animal Experiment Permit File'
      if (permitType.permitCode === 1) { // enable the permit file field
        this.permitFileVisible = true
      } else {
        this.permitFileVisible = false
      }

      this.commonFormData.animalPermitType.permitCode = permitType.permitCode

      this.changeAnimalPermitType(permitType)

      const ruleContent = {}
      ruleContent.fieldName = this.permitFileName
      ruleContent.required = this.permitFileVisible
      this.newFormRule(ruleContent)
      EVENT_CONFIG.EventBus.$emit(EVENT_CONFIG.CHECK_FORM_FIELD_EVENT, true, 'animalPermitType.permitCode')
    },
    async changeProject (projectID, fieldName) { // the event method of dealing with the project changing
      // when project changed, to reset the form fields
      this.commonFormData.responsiblePI = { id: null }
      this.commonFormData.author = { id: null }

      // get project related fields
      this.resetNonCommonFormRule()
      this.clearRelatedFields()

      if (projectID) {
        console.log('projectID=%s fieldName=%s', projectID, fieldName)
        this.commonFormData[fieldName].id = projectID
        // const { data: relatedProjects } = await this.$http.get('/data/project/' + projectID)
        const { data: relatedProjects } = await this.$http.get(this.serverAPI.DATA_PROJECTS_RELATIONS_URL + projectID)
        console.log('related projects', relatedProjects)
        if (relatedProjects.data.project.relatedProjects !== null && relatedProjects.data.project.relatedProjects !== undefined) {
          this.addRelatedProject(relatedProjects.data.project.relatedProjects)
        }
        this.addDataAddPageUserList(relatedProjects.data)

        // const { data: relatedFields } = await this.$http.get('/field/relatedNonCommonList/' + projectID)
        const { data: relatedFields } = await this.$http.get(this.serverAPI.DATA_PROJECT_FIELD_RELATION_URL + projectID)
        console.log('related fields', relatedFields)
        relatedFields.data.forEach(field => {
          const ruleContent = {}
          ruleContent.fieldName = field.fieldName
          ruleContent.required = field.required
          this.changeNonCommonFormRule(ruleContent)
          this.setProjectRelatedFields(field.fieldName)
        })
      } else {
        this.commonFormData[fieldName].id = null
        const addPageProjectUser = {}
        addPageProjectUser.supervisors = []
        addPageProjectUser.memberList = []
        this.addDataAddPageUserList(addPageProjectUser)
      }

      // when the value of the project field changes, invoke the method to verify the common form data
      EVENT_CONFIG.EventBus.$emit(EVENT_CONFIG.CLEAR_DATAFORM_VALIDATE)
      EVENT_CONFIG.EventBus.$emit(EVENT_CONFIG.CHECK_FORM_FIELD_EVENT, true, fieldName + '.id')
      EVENT_CONFIG.EventBus.$emit(EVENT_CONFIG.DATA_PROJECT_CHANGE_EVENT, projectID)
    },
    userChange (event, fieldName) {
      console.log('user change value: ', event)
      if (event) {
        this.commonFormData[fieldName].id = event
      } else {
        this.commonFormData[fieldName].id = null
      }
      // when the value of the project field changes, invoke the method to verify the common form data
      EVENT_CONFIG.EventBus.$emit(EVENT_CONFIG.CHECK_FORM_FIELD_EVENT, true, fieldName + '.id')
    }
  }
}
</script>
<style scoped>
div >>> textarea.el-textarea__inner {
  height: 200px;
  overflow-y: auto;
  margin-bottom: 5px;
}
div.el-form-item {
  margin-bottom: 0;
  /* margin-top: 10px; */
}
form label {
    line-height: 30px !important;
    padding: 0 !important;
  }

.clearfix:before, .clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both
}
div >>> div.el-form-item {
  margin-bottom: 0px !important;
}
div >>> label.el-form-item__label {
  padding-bottom: 0 !important;
}
</style>
