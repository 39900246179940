<template>
  <section>
    <el-row type="flex" justify="space-between" align="middle">
      <el-col :span="10">
        <el-form-item :label="formLabel" class="data-form-label" :prop="formProperty">
        </el-form-item>
      </el-col>
      <el-col :sm="3" :lg="2">
        <el-button type="text" style="padding-left: 9px; padding-right: 5px; font-weight: bold;" v-if="fileFieldMoreBtnFlag" @click="shiftMoreFileField">
          {{ fileFieldActualHeight > fileFieldOriginHeight ? 'More' : 'Less'}}
          <i :class="fileFieldActualHeight > fileFieldOriginHeight ? 'el-icon-arrow-right' : 'el-icon-arrow-down'"/>
        </el-button>
      </el-col>
    </el-row>
    <el-row>
      <el-scrollbar v-fileconent="{set: setFileFieldMoreBtnVisible}" v-if="fileList && fileList.length && fileList.length > 0" :style="{'height': fileFieldOriginHeight+'px', 'background': '#fafafa', 'padding-left': '10px'}">
        <el-tooltip v-for="file in fileList" :key="file.fileUniqueID" :content="file.fileName">
          <el-tag class="file-name" style="margin-right: 3px; margin-bottom: 3px;" :type="compareFileItem(file.fileName)">
            {{file.fileName}}
          </el-tag>
        </el-tooltip>
      </el-scrollbar>
      <el-col v-if="!fileList || !fileList.length || fileList.length === 0">
        <div :style="{'height': fileFieldOriginHeight + 'px'}"></div>
      </el-col>
    </el-row>
  </section>
</template>
<script>
import { EVENT_CONFIG } from '@/event_bus'

export default {
  props: ['formProperty', 'formLabel', 'fileList', 'compareFileList'],
  created () {
    EVENT_CONFIG.EventBus.$on(EVENT_CONFIG.HISTORY_DATA_FILE_HEIGHT_UPDATE_EVENT, this.updateFileFieldHeight)
    EVENT_CONFIG.EventBus.$on(EVENT_CONFIG.HISTORY_DATA_FILE_HEIGHT_INIT_EVENT, this.initFileFieldHeight)
  },
  beforeDestroy () {
    EVENT_CONFIG.EventBus.$off(EVENT_CONFIG.HISTORY_DATA_FILE_HEIGHT_UPDATE_EVENT)
    EVENT_CONFIG.EventBus.$off(EVENT_CONFIG.HISTORY_DATA_FILE_HEIGHT_INIT_EVENT)
  },
  computed: {
    compareFileItem () {
      return function (fileName) {
        // console.log(this.compareFileList)
        if (!this.compareFileList) {
          return 'warning'
        }

        const fileIndex = this.compareFileList.findIndex(compareFile => {
          return compareFile.fileName === fileName
        })

        if (fileIndex === -1) {
          return 'warning'
        }
      }
    }
  },
  directives: {
    fileconent: {
      inserted (el, binding, vnode) {
        if (el.querySelector('.el-scrollbar__wrap').clientHeight < el.querySelector('.el-scrollbar__wrap').scrollHeight) {
          binding.value.set(el.querySelector('.el-scrollbar__wrap').scrollHeight)
        }
      }
    }
  },
  data () {
    return {
      fileFieldMoreBtnFlag: false,
      fileFieldActualHeight: 60,
      fileFieldOriginHeight: 60
    }
  },
  methods: {
    setFileFieldMoreBtnVisible (actualHeight) {
      this.fileFieldMoreBtnFlag = true
      EVENT_CONFIG.EventBus.$emit(EVENT_CONFIG.HISTORY_DATA_FILE_HEIGHT_INIT_EVENT, actualHeight, this.formProperty)
    },
    shiftMoreFileField () {
      console.log('assdfd')
      if (this.fileFieldActualHeight > this.fileFieldOriginHeight) {
        EVENT_CONFIG.EventBus.$emit(EVENT_CONFIG.HISTORY_DATA_FILE_HEIGHT_UPDATE_EVENT, this.fileFieldActualHeight, this.formProperty)
      } else {
        EVENT_CONFIG.EventBus.$emit(EVENT_CONFIG.HISTORY_DATA_FILE_HEIGHT_UPDATE_EVENT, 60, this.formProperty)
      }
    },
    initFileFieldHeight (actualHeight, formFieldName) {
      if (formFieldName === this.formProperty) {
        if (actualHeight > this.fileFieldActualHeight) {
          this.fileFieldActualHeight = actualHeight
        }
      }
    },
    updateFileFieldHeight (actualHeight, formFieldName) {
      if (formFieldName === this.formProperty) {
        this.fileFieldOriginHeight = actualHeight
      }
    }
  }
}
</script>
<style scoped>
div /deep/ div.el-scrollbar__wrap {
  overflow-x: hidden;
}
div /deep/ .el-scrollbar__thumb {
  background: #86909c;
}
/* div /deep/ .el-scrollbar__bar.is-vertical {
  opacity: 1;
} */
.file-name {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
  width: 150px;
  height: 32px;
  vertical-align: middle;
}
</style>
