<template>
  <div>
    <!--Breadcrumb navigation area-->
    <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/home' }">Home</el-breadcrumb-item>
        <el-breadcrumb-item>Data Module</el-breadcrumb-item>
        <el-breadcrumb-item>Add Data</el-breadcrumb-item>
    </el-breadcrumb>
    <h1 style="margin-top: 15px;margin-bottom: 10px; font-size: 20px;">Data Storage</h1>
    <el-form id="commonDataForm" :model="commonFormData" class="data-form" :rules="commonFormRules" ref="commonFormRef" label-position="top" label-width="80px" @validate="afterCommonFormFieldValidate">
      <el-card>
        <div slot="header" class="clearfix">
          <span> General Information </span>
          <!-- <el-button style="float: right; padding: 3px 0" type="text">操作按钮</el-button> -->
        </div>
        <!-- 1 <DataFields fieldSetName="Common Fields" :normalFieldInfos="commonNormalFieldInfos" :textareaFieldInfos="commonTextareaFieldInfos" :commonOptions="commonOptionInfos" :formData="addDataForm"></DataFields> -->
        <CommonFields></CommonFields>
      </el-card>
    </el-form>
    <el-form id="nonCommonDataForm" :model="nonCommonFormData" class="data-form" :rules="nonCommonFormRules" ref="nonCommonFormRef" label-position="top" label-width="80px" @validate="afterNoncommonFirmFieldValidate">
      <el-card v-show="commonFormData.project && showRelatedField">
        <div slot="header" class="clearfix">
          <span> Project Specified Information </span>
          <!-- <el-button style="float: right; padding: 3px 0" type="text">操作按钮</el-button> -->
        </div>
        <NonCommonFields :isRelated="true" :creator="commonFormData.creator"></NonCommonFields>
      </el-card>
      <el-card>
        <div slot="header" class="clearfix">
          <span> Additional Information </span>
          <!-- <el-button style="float: right; padding: 3px 0" type="text">操作按钮</el-button> -->
        </div>
        <NonCommonFields :isRelated="false" :creator="commonFormData.creator"></NonCommonFields>
        <el-row style="margin-top: 15px;" type="flex" justify="end" v-if="writeSwitch">
          <el-col :span="2" :offset="22" style="padding-right: 15px">
            <el-button style="float: right;" @click="resetFormData" :disabled="!commonFormChange && !nonCommonFormChange">Reset</el-button>
          </el-col>
          <el-col :span="2">
            <el-button type="primary" @click="submitData" :disabled="!commonFormChange && !nonCommonFormChange">Submit</el-button>
          </el-col>
        </el-row>
      </el-card>
    </el-form>
  </div>
</template>

<script>
import JsSHA from 'jssha'
import CommonFields from '@/components/data_component/CommonFields'
import NonCommonFields from '@/components/data_component/NonCommonFields'
import { mapState, mapMutations } from 'vuex'
import { EVENT_CONFIG } from '@/event_bus'
import { formValidator } from '@/plugins/formValidator'
import alertCountdown from '@/plugins/alertCountdown.js'
import { DRAFT_GENERATOR } from '@/local_storage/DraftGenerator'

export default {
  async created () {
    const loadingInstance = this.$loading.service({
      lock: true,
      text: 'Initing data adding page...'
    })

    this.initNewCommonFormData()
    await this.initCommonFields()

    window.onbeforeunload = (event) => {
      this.commonFormChange = formValidator.formDataChangeValidate(this.commonFormData, this.commonFormDigest)
      this.nonCommonFormChange = this.nonCommonFormChange = formValidator.formDataChangeValidate(this.nonCommonFormData, this.nonCommonFormDigest)

      if (this.commonFormChange || this.nonCommonFormChange) {
        // add the code to store the draft of adding
        const dataObj = {}
        dataObj.commonFormData = this.commonFormData
        dataObj.nonCommonFormData = this.nonCommonFormData
        DRAFT_GENERATOR.storeDataDraft(dataObj)

        return 'confirm to refresh?'
      }
    }

    const startCommonDigest = new Date().getTime()
    const jsSHACommon = new JsSHA('SHA-256', 'TEXT')
    jsSHACommon.update(JSON.stringify(this.commonFormData))
    this.commonFormDigest = jsSHACommon.getHash('HEX')
    console.log('data add page digest common form data time cost: ', new Date().getTime() - startCommonDigest)

    const startNoncommonDigest = new Date().getTime()
    const jsSHANonCommon = new JsSHA('SHA-256', 'TEXT')
    jsSHANonCommon.update(JSON.stringify(this.nonCommonFormData))
    this.nonCommonFormDigest = jsSHANonCommon.getHash('HEX')
    console.log('data add page digest non-common form data time cost: ', new Date().getTime() - startNoncommonDigest)

    EVENT_CONFIG.EventBus.$on(EVENT_CONFIG.CHECK_FORM_FIELD_EVENT, this.checkFormDataField)
    EVENT_CONFIG.EventBus.$on(EVENT_CONFIG.CLEAR_DATAFORM_VALIDATE, this.clearFormValidate)

    const dataDraft = await DRAFT_GENERATOR.getDataDraft(this.commonFormData.dataID)
    console.log('load data draft from the local storage: ', dataDraft)
    if (dataDraft) {
      const confirmLoadDraftResult = await this.$confirm('Load the experimental data draft?', {
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        closeOnClickModal: false,
        closeOnPressEscape: false
      }).then(async _ => {
        console.log('to load experiemntal data draft from the local storage')
        this.initNewDataFromBasedDraft(dataDraft)

        const projectID = dataDraft.commonFormData.project.id
        EVENT_CONFIG.EventBus.$emit(EVENT_CONFIG.DATA_PROJECT_CHANGE_EVENT, projectID)
        const { data: relatedProjects } = await this.$http.get(this.serverAPI.DATA_PROJECTS_RELATIONS_URL + projectID)
        console.log('related projects', relatedProjects)
        if (relatedProjects.data.project.relatedProjects !== null && relatedProjects.data.project.relatedProjects !== undefined) {
          this.addRelatedProject(relatedProjects.data.project.relatedProjects)
        }
        this.addDataAddPageUserList(relatedProjects.data)

        // const { data: relatedFields } = await this.$http.get('/field/relatedNonCommonList/' + projectID)
        const { data: relatedFields } = await this.$http.get(this.serverAPI.DATA_PROJECT_FIELD_RELATION_URL + projectID)
        console.log('related fields', relatedFields)
        relatedFields.data.forEach(field => {
          const ruleContent = {}
          ruleContent.fieldName = field.fieldName
          ruleContent.required = field.required
          this.changeNonCommonFormRule(ruleContent)
          this.setProjectRelatedFields(field.fieldName)
        })

        return true
      }).catch((error) => {
        console.log('cancel to load the experimental data from the loacal storage error: ', error)
      }).finally(_ => {
        DRAFT_GENERATOR.removeDataDraft(this.commonFormData.dataID)
      })

      if (confirmLoadDraftResult) {
        const commonFormRef = this.$refs.commonFormRef
        const validateCommonFormResult = await formValidator.formValidate(commonFormRef)
        console.log('validate reloaded common form data result: ', validateCommonFormResult)

        const nonCommonFormRef = this.$refs.nonCommonFormRef
        console.log('nonFirmForm: ', nonCommonFormRef)
        const validateNonCommonFormResult = await formValidator.formValidate(nonCommonFormRef)
        console.log('validate reloaded non-common form data result: ', validateNonCommonFormResult)
      }
    }

    this.$nextTick(function () {
      loadingInstance.close()
    })
  },
  beforeRouteLeave (to, from, next) {
    console.log('before leaving the DataAdd.vue page', this.commonFormChange, this.nonCommonFormChange)
    if (this.commonFormChange || this.nonCommonFormChange) {
      this.$confirm('Leave the experimental data add page and save the unsubmitted data as local draft?', {
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        closeOnClickModal: false,
        closeOnPressEscape: false
      }).then(_ => {
        console.log('confirm to leave DataAdd.vue')
        const dataObj = {}
        dataObj.commonFormData = this.commonFormData
        dataObj.nonCommonFormData = this.nonCommonFormData
        DRAFT_GENERATOR.storeDataDraft(dataObj)
        next()
      }).catch(_ => {
        console.log('cancel to leave')
        this.setRouterActivePath('/data/add')
      })
    } else {
      next()
    }
  },
  beforeDestroy () {
    console.debug('DataAdd.vue beforeDestory method executes.....')
    window.onbeforeunload = null
    EVENT_CONFIG.EventBus.$off(EVENT_CONFIG.CHECK_FORM_FIELD_EVENT)
    EVENT_CONFIG.EventBus.$off(EVENT_CONFIG.CLEAR_DATAFORM_VALIDATE)
  },
  components: {
    CommonFields,
    NonCommonFields
  },
  data () {
    return {
      commonFormChange: false,
      nonCommonFormChange: false,
      commonFormDigest: '',
      nonCommonFormDigest: '',
      relatedFieldInfoList: [],
      showRelatedField: false,
      formToken: '' // formToken to prevent the repeated submission
    }
  },
  computed: {
    ...mapState({
      writeSwitch: (state) => state.writableStatus,
      commonFormData: (state) => state.dataModule.commonFormData,
      commonFormRules: (state) => state.dataModule.commonFormRules,
      nonCommonFormData: (state) => state.dataModule.nonCommonFormData,
      nonCommonFormRules: (state) => state.dataModule.nonCommonFormRules,
      projectRelatedFields: (state) => state.dataModule.projectRelatedFields
    })
  },
  watch: {
    projectRelatedFields: function (newValue) {
      if (!newValue || (newValue.length === 1 && !newValue[0]) || newValue.length === 0) {
        this.showRelatedField = false
      } else {
        this.showRelatedField = true
      }
    }
  },
  methods: {
    ...mapMutations({
      setRouterActivePath: 'setRouterActivePath',
      initDataAddPage: 'dataPageModule/initDataAddPage',
      initNewCommonFormData: 'dataModule/initNewCommonFormData',
      // init the data form based on the data draft from the local storage
      initNewDataFromBasedDraft: 'dataModule/initNewDataFromBasedDraft',
      addRelatedProject: 'dataModule/addRelatedProject',
      addDataAddPageUserList: 'dataPageModule/addDataAddPageUserList',
      changeNonCommonFormRule: 'dataModule/changeNonCommonFormRule',
      setProjectRelatedFields: 'dataModule/setProjectRelatedFields'
    }),
    async initCommonFields () {
      // const { data: dataAddConfigResp } = await this.$http.get('/data/addConfig')
      const dataAddConfigResp = await this.$http.get(this.serverAPI.DATA_ADDCONFIG_URL)
      console.log('data add page config: ', dataAddConfigResp)
      this.initDataAddPage(dataAddConfigResp.data.data)
      console.log('data add config header: ', dataAddConfigResp.headers)
      this.formToken = dataAddConfigResp.headers['form-token']
    },
    checkFormDataField (isCommon, fieldName) {
      console.log('check the specified field: ', fieldName)
      console.log('is common form: ', isCommon)

      const commonFormRef = this.$refs.commonFormRef
      const nonCommonFormRef = this.$refs.nonCommonFormRef
      formValidator.formFieldValidate(isCommon ? commonFormRef : nonCommonFormRef, fieldName)
    },
    async submitData () {
      // verify the data form field
      const commonFormRef = this.$refs.commonFormRef
      const validateCommonFormResult = await formValidator.formValidate(commonFormRef)
      console.log('check add experimental data commonForm: ', validateCommonFormResult)
      if (validateCommonFormResult !== true) {
        let msgContent = ''
        const validateValues = Object.values(validateCommonFormResult)
        validateValues.forEach(validateVal => {
          msgContent += validateVal[0].message + '<br>'
        })
        this.$alert('<span style="font-size: 15px; font-weight: bold;"> Exceptions in the common form data: </span><br> <span style="color: red;">' + msgContent + '</span>', 'Notification of Experimental Data', {
          dangerouslyUseHTMLString: true
        })

        return
      }

      const nonCommonFormRef = this.$refs.nonCommonFormRef
      let validateNonCommonFormResult = await formValidator.formValidate(nonCommonFormRef)
      console.log('check add experimental data nonCommonForm: ', validateNonCommonFormResult)
      if (validateNonCommonFormResult !== true) {
        let noncommonMsgConent = ''
        const noncommonValidateValues = Object.values(validateNonCommonFormResult)
        noncommonValidateValues.forEach(nonValidateVal => {
          noncommonMsgConent += nonValidateVal[0].message + '<br>'
        })
        validateNonCommonFormResult = await this.$confirm('<span style="font-size: 15px; font-weight: bold;"> Exceptions in the optional form data: </span><br> <span style="color: red;">' +
                     noncommonMsgConent + '</span>', 'Notification of Experimental Data', {
          confirmButtonText: 'Submit Anyway',
          dangerouslyUseHTMLString: true
        }).then(_ => {
          return true
        }).catch(_ => {
          console.warn('skip to submit form data')
        })
      }

      if (validateCommonFormResult === true && validateNonCommonFormResult === true) {
        this.commonFormChange = false
        this.nonCommonFormChange = false
        console.log('commonData', this.commonFormData)
        console.log('noncommonData', this.nonCommonFormData)
        // TODO: add the function of preventing repeat submit
        console.log('send the request to the server to add data')
        let experimentData = {}
        experimentData = JSON.parse(JSON.stringify(this.commonFormData))

        if (this.commonFormData.timePeriod) {
          experimentData.startDate = this.commonFormData.timePeriod[0]
          experimentData.endDate = this.commonFormData.timePeriod[1]
        }

        experimentData.optionalContent = JSON.parse(JSON.stringify(this.nonCommonFormData))

        // const { data: addResp } = await this.$http.post('/data/new', JSON.stringify(experimentData), { headers: { 'content-type': 'application/json' } })
        const { data: addResp } = await this.$http.post(this.serverAPI.DATA_ADD_URL, JSON.stringify(experimentData), { headers: { 'content-type': 'application/json', 'form-token': this.formToken } })
        console.log('add data response content: ', addResp)

        if (addResp.code === 1) {
          this.$message({
            showClose: true,
            message: 'add data successfully',
            center: true,
            type: 'success'
          })
          // to the data list page automatically with time countdown
          this.$router.push({ path: `/data/detail/${addResp.data}` })
          alertCountdown(this, 'To Data List', () => {
            this.$router.push({ path: '/data/list' })
          }, 5)

          return
        } else {
          this.$alert('<font color="red">' + addResp.msg + '</font>', 'Notification of Experimental Data', {
            dangerouslyUseHTMLString: true
          })
        }
      }
      this.commonFormChange = formValidator.formDataChangeValidate(this.commonFormData, this.commonFormDigest)
      this.nonCommonFormChange = formValidator.formDataChangeValidate(this.nonCommonFormData, this.nonCommonFormDigest)
    },
    resetFormData () {
      // TODO: add the confirm to save the filled data as a draft
      if (this.commonFormChange || this.nonCommonFormChange) {
        this.$confirm('Reset the data addition page, all data will be lost! Confirm?', {
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
          closeOnClickModal: false,
          closeOnPressEscape: false
        }).then(async _ => {
          window.onbeforeunload = null
          const resetLoadingInstance = this.$loading.service({
            lock: true,
            text: 'Resetting data addition page...'
          })
          await this.$router.go(this.$router.currentRoute)
          resetLoadingInstance.close()
        }).catch(_ => {
          console.log('cancel resetting the data form')
        })
        return
      }
      this.$router.go(this.$router.currentRoute)
    },
    afterCommonFormFieldValidate (props, callback) {
      console.log('validate common form field: ', props)
      this.commonFormChange = formValidator.formDataChangeValidate(this.commonFormData, this.commonFormDigest)
      console.log('common form data change: ', this.commonFormChange)
    },
    afterNoncommonFirmFieldValidate (props, callback) {
      console.log('validate non-common form field: ', props)
      this.nonCommonFormChange = formValidator.formDataChangeValidate(this.nonCommonFormData, this.nonCommonFormDigest)
      console.log('non-common form data change: ', this.commonFormChange)
    },
    clearFormValidate () {
      console.log('common form reference: ', this.$refs.commonFormRef)
      this.$refs.commonFormRef.clearValidate()
      this.$refs.nonCommonFormRef.clearValidate()
    },
    // TODO: generate the unsaved data into the localstorage
    generateDraft () {
    }
  }
}
</script>
<style lang="less" scope>
div.clearfix span {
  font-size: 16px;
  font-weight: bold;
}
div.el-card__header {
  padding-top: 15px;
  padding-bottom: 5px;
  background-color: #F5F7FA;
}
div.el-message-box {
  width: 25%;
  min-width: 500px;
}
</style>
