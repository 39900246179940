<template>
  <section>
    <!--Breadcrumb navigation area-->
    <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>Home</el-breadcrumb-item>
        <el-breadcrumb-item>Data Module</el-breadcrumb-item>
        <el-breadcrumb-item>File View</el-breadcrumb-item>
    </el-breadcrumb>

    <el-row v-if="currentLoginUser.adminFlag && writeSwitch" style="margin-bottom: 3px;">
      <el-select v-model="selectedProject" filterable clearable placeholder="select project and then upload file" style="margin-right: 10px;">
        <el-option value="0" label="Publication"></el-option>
        <el-option v-for="project in projectList" :key="project.id" :label="project.projectName" :value="project.id"></el-option>
      </el-select>
      <el-button type="primary" size="medium" icon="el-icon-document-add" @click="fileUpload"></el-button>
      <el-button type="primary" size="medium" icon="el-icon-folder-add" @click="folderUpload"></el-button>
    </el-row>

    <el-tabs type="border-card" v-model="activeTabValue" @tab-click="fileListTabClick" @tab-remove="fileListTabRemove">
      <el-tab-pane label="Data">
        <el-table v-loading="projectTableLoading" :data="projectList" border stripe>
          <el-table-column type="index" align="center"></el-table-column>
          <el-table-column label="Project" align="left">
            <template v-slot="projectProps">
              <el-link :underline="false" @click="projectItemClick(projectProps.row)">
                <img :src="projectProps.row | folderIcon" style="width: 20px; height: 20px; margin-right: 3px; vertical-align:middle"/>
                {{ projectProps.row.projectName }}
              </el-link>
            </template>
          </el-table-column>
          <el-table-column label="Type"> folder </el-table-column>
        </el-table>
      </el-tab-pane>
      <el-tab-pane label="Publications">
        <PaperFileTable></PaperFileTable>
      </el-tab-pane>
      <el-tab-pane v-for="closableTab in closableTabs" :key="closableTab.id" :label="closableTab.projectName" closable :name="closableTab.id">
        <ProjectFileTable :project="closableTab"></ProjectFileTable>
      </el-tab-pane>
    </el-tabs>

    <FileObjUploader ref="uploaderRef" :displayFileList="uploadFileListFlag" style="width: 100%; min-width: 1260px;" :enabled="true"></FileObjUploader>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import { FILE_TYPE } from '@/components/file_component/fileconfig'
import PaperFileTable from '@/components/file_component/PaperFileTable'
import ProjectFileTable from '../components/file_component/ProjectFileTable'
import { EVENT_CONFIG } from '@/event_bus'
import FileObjUploader from '../components/file_component/FileObjUploader'

export default {
  data () {
    return {
      selectedProject: '',
      currentLoginUser: {},
      projectTableLoading: true,
      activeTabValue: '',
      projectList: [],
      closableTabs: [],
      uploadFileListFlag: false
    }
  },
  components: {
    PaperFileTable,
    ProjectFileTable,
    FileObjUploader
  },
  async mounted () {
    await this.getProjectList()
    this.currentLoginUser = await this.$userStorage.getCurrentUser()
    if (this.currentLoginUser.adminFlag) {
      this.uploadFileListFlag = true
    }
  },
  filters: {
    folderIcon () {
      return require('@/' + FILE_TYPE.FOLDER.typeIcon)
    }
  },
  computed: {
    ...mapState({
      writeSwitch: (state) => state.writableStatus
    })
  },
  methods: {
    async fileListTabClick (activeTab) {
      if (activeTab.index === '0') {
        await this.getProjectList()
      } else if (activeTab.index === '1') {
        EVENT_CONFIG.EventBus.$emit(EVENT_CONFIG.FILE_PAPER_LIST_EVENT)
      } else {
        EVENT_CONFIG.EventBus.$emit(EVENT_CONFIG.FILE_PROJECT_LIST_EVENT, activeTab.name)
      }
    },
    fileListTabRemove (targetTab) {
      console.log('close the targe tab: ', targetTab)
      const tabs = this.closableTabs
      let activeTabID = this.activeTabValue

      if (activeTabID === targetTab) {
        tabs.forEach((tab, index) => {
          if (tab.id === targetTab) {
            const nextTab = tabs[index + 1] || tabs[index - 1]
            if (nextTab) {
              activeTabID = nextTab.id
            }
          }
        })
      }

      this.activeTabValue = activeTabID
      this.closableTabs = tabs.filter(tab => tab.id !== targetTab)

      if (this.closableTabs.length === 0) {
        this.activeTabValue = '0'
      }
    },
    async getProjectList () {
      this.projectTableLoading = true
      const { data: fileProjectsResp } = await this.$http.get(this.serverAPI.FILE_VIEW_PROJECT_URL, { headers: { 'content-type': 'application/json' } })
      console.log('file list project response: ', fileProjectsResp)
      this.projectList = fileProjectsResp.data
      this.projectTableLoading = false
    },
    projectItemClick (projectItem) {
      console.log('project ID: ', projectItem)
      const tabIndex = this.closableTabs.findIndex(closableTab => {
        return closableTab.id === projectItem.id
      })
      if (tabIndex === -1) {
        this.closableTabs.push(projectItem)
      }

      this.activeTabValue = projectItem.id
    },
    fileUpload () {
      if (this.selectedProject === '' || this.selectedProject === undefined || this.selectedProject === null) {
        this.$message({
          message: 'please select one project',
          type: 'warning',
          showClose: true
        })
        return
      }

      const fileUploadParam = {
        uploadType: 'file',
        target: this.selectedProject
      }
      EVENT_CONFIG.EventBus.$emit(EVENT_CONFIG.FILE_UPLOAD_EVENT, fileUploadParam)
    },
    folderUpload () {
      if (this.selectedProject === '' || this.selectedProject === undefined || this.selectedProject === null) {
        this.$message({
          message: 'please select one project',
          type: 'warning',
          showClose: true
        })
        return
      }

      const fileUploadParam = {
        uploadType: 'folder',
        target: this.selectedProject
      }
      EVENT_CONFIG.EventBus.$emit(EVENT_CONFIG.FILE_UPLOAD_EVENT, fileUploadParam)
    }
  }
}
</script>
<style scoped>
div /deep/ th{
  line-height: 28px;
  font-weight: bold;
}
</style>
