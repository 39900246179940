import { render, staticRenderFns } from "./UserHelp.vue?vue&type=template&id=729b6310&scoped=true&"
import script from "./UserHelp.vue?vue&type=script&lang=js&"
export * from "./UserHelp.vue?vue&type=script&lang=js&"
import style0 from "./UserHelp.vue?vue&type=style&index=0&id=729b6310&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "729b6310",
  null
  
)

export default component.exports