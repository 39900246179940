<template>
  <div>
    <el-row class="bdbottom bdtop vcenter">
      <el-col :lg="{span:3}">
        <el-tag>Common Fields:</el-tag>
      </el-col>
      <el-col :lg="{span:21}">
        <div v-for="(field, index) in commonFieldList" :key="field.fieldID">
          <el-checkbox-group name="commonField" v-model="projectRow.commonFields">
            <el-row v-if="index%4==0" :class="[ index >= 4? 'bdtop':'', 'vcenter']">
              <el-col :lg="{span: 1}">
                <el-tooltip content="related field required" placement="left">
                  <el-switch :disabled="!writeSwitch" :value="projectRow['requirement'] == null ? false : projectRow['requirement'][commonFieldList[index].fieldName]" @change="changeFieldRelationRequirement($event, projectRow, commonFieldList[index])"></el-switch>
                </el-tooltip>
              </el-col>
              <el-col :lg="{span: 5}" style="margin:10px 0;">
                <el-checkbox :disabled="!writeSwitch" @change="checked => changeProjectFieldRelation(checked, commonFieldList[index], projectRow)" border :label="commonFieldList[index].fieldName"></el-checkbox>
              </el-col>

              <el-col :lg="{span: 1}" v-if="( index + 1 ) < commonFieldList.length">
                <el-tooltip content="related field required" placement="left">
                  <el-switch :disabled="!writeSwitch" :value="projectRow['requirement'] == null ? false : projectRow['requirement'][commonFieldList[index + 1].fieldName]" @change="changeFieldRelationRequirement($event, projectRow, commonFieldList[index + 1])"></el-switch>
                </el-tooltip>
              </el-col>
              <el-col :lg="{span: 5}" style="margin:10px 0;">
                <el-checkbox :disabled="!writeSwitch" @change="checked => changeProjectFieldRelation(checked, commonFieldList[index + 1], projectRow)" border v-if="( index + 1 ) < commonFieldList.length" :label="commonFieldList[index + 1].fieldName"></el-checkbox>
              </el-col>

              <el-col v-if="( index + 2 ) < commonFieldList.length" :lg="{span: 1}">
                <el-tooltip content="related field required" placement="left">
                  <el-switch :disabled="!writeSwitch" :value="projectRow['requirement'] == null ? false : projectRow['requirement'][commonFieldList[index + 2].fieldName]" @change="changeFieldRelationRequirement($event, projectRow, commonFieldList[index + 2])"></el-switch>
                </el-tooltip>
              </el-col>
              <el-col :lg="{span: 5}" style="margin:10px 0;">
                <el-checkbox :disabled="!writeSwitch" @change="checked => changeProjectFieldRelation(checked, commonFieldList[index + 2], projectRow)" border v-if="( index + 2 ) < commonFieldList.length" :label="commonFieldList[index + 2].fieldName"></el-checkbox>
              </el-col>

              <el-col :lg="{span: 1}" v-if="( index + 3 ) < commonFieldList.length">
                <el-tooltip content="related field required" placement="left">
                  <el-switch :disabled="!writeSwitch" :value="projectRow['requirement'] == null ? false : projectRow['requirement'][commonFieldList[index + 3].fieldName]" @change="changeFieldRelationRequirement($event, projectRow, commonFieldList[index + 3])"></el-switch>
                </el-tooltip>
              </el-col>
              <el-col :lg="{span: 5}" style="margin:10px 0;">
                <el-checkbox :disabled="!writeSwitch" @change="checked => changeProjectFieldRelation(checked, commonFieldList[index + 3], projectRow)" border v-if="( index + 3 ) < commonFieldList.length" :label="commonFieldList[index + 3].fieldName"></el-checkbox>
              </el-col>
              <!-- <el-col :lg="{span: 4}" style="margin:10px 0;">
                <el-checkbox @change="checked => changeProjectFieldRelation(checked, commonFieldList[index + 4], projectRow)" border v-if="( index + 4 ) < commonFieldList.length" :label="commonFieldList[index + 4].fieldName"></el-checkbox>
              </el-col>
              <el-col :lg="{span: 4}" style="margin:10px 0;">
                <el-checkbox @change="checked => changeProjectFieldRelation(checked, commonFieldList[index + 5], projectRow)" border v-if="( index + 5 ) < commonFieldList.length" :label="commonFieldList[index + 5].fieldName"></el-checkbox>
              </el-col> -->
            </el-row>
          </el-checkbox-group>
        </div>
      </el-col>
    </el-row>
    <el-row class="bdbottom vcenter" style="margin-top: 15px;">
      <el-col :lg="{span:3}">
        <el-tag>NonCommon Fields:</el-tag>
      </el-col>
      <el-col :lg="{span:21}">
        <div v-for="(field, index) in nonCommonFieldList" :key="field.fieldID">
          <el-checkbox-group name="nonCommon" v-model="projectRow.nonCommonFields">
            <el-row v-if="index%4==0" :class="[ index >= 4? 'bdtop':'', 'vcenter']">
              <el-col :lg="{span: 1}">
                <el-tooltip content="related field required" placement="left">
                  <el-switch :disabled="!writeSwitch" :value="projectRow['requirement'] == null ? false : projectRow['requirement'][nonCommonFieldList[index].fieldName]" @change="changeFieldRelationRequirement($event, projectRow, nonCommonFieldList[index])"></el-switch>
                </el-tooltip>
              </el-col>
              <el-col :lg="{span: 5}" style="margin:10px 0;">
                <el-checkbox :disabled="!writeSwitch" @change="checked => changeProjectFieldRelation(checked, nonCommonFieldList[index], projectRow)" border :label="nonCommonFieldList[index].fieldName"></el-checkbox>
              </el-col>

              <el-col v-if="( index + 1 ) < nonCommonFieldList.length" :lg="{span: 1}">
                <el-tooltip content="related field required" placement="left">
                  <el-switch :disabled="!writeSwitch" :value="projectRow['requirement'] == null ? false : projectRow['requirement'][nonCommonFieldList[index + 1].fieldName]" @change="changeFieldRelationRequirement($event, projectRow, nonCommonFieldList[index + 1])"></el-switch>
                </el-tooltip>
              </el-col>
              <el-col :lg="{span: 5}" style="margin:10px 0;">
                <el-checkbox :disabled="!writeSwitch" @change="checked => changeProjectFieldRelation(checked, nonCommonFieldList[index + 1], projectRow)" border v-if="( index + 1 ) < nonCommonFieldList.length" :label="nonCommonFieldList[index + 1].fieldName"></el-checkbox>
              </el-col>

              <el-col v-if="( index + 2 ) < nonCommonFieldList.length" :lg="{span: 1}">
                <el-tooltip content="related field required" placement="left">
                  <el-switch :disabled="!writeSwitch" :value="projectRow['requirement'] == null ? false : projectRow['requirement'][nonCommonFieldList[index + 2].fieldName]" @change="changeFieldRelationRequirement($event, projectRow, nonCommonFieldList[index + 2])"></el-switch>
                </el-tooltip>
              </el-col>
              <el-col :lg="{span: 5}" style="margin:10px 0;">
                <el-checkbox :disabled="!writeSwitch" @change="checked => changeProjectFieldRelation(checked, nonCommonFieldList[index + 2], projectRow)" border v-if="( index + 2 ) < nonCommonFieldList.length" :label="nonCommonFieldList[index + 2].fieldName"></el-checkbox>
              </el-col>

              <el-col v-if="( index + 3 ) < nonCommonFieldList.length" :lg="{span: 1}">
                <el-tooltip content="related field required" placement="left">
                  <el-switch :disabled="!writeSwitch" :value="projectRow['requirement'] == null ? false : projectRow['requirement'][nonCommonFieldList[index + 3].fieldName]" @change="changeFieldRelationRequirement($event, projectRow, nonCommonFieldList[index + 3])"></el-switch>
                </el-tooltip>
              </el-col>
              <el-col :lg="{span: 5}" style="margin:10px 0;">
                <el-checkbox :disabled="!writeSwitch" @change="checked => changeProjectFieldRelation(checked, nonCommonFieldList[index + 3], projectRow)" border v-if="( index + 3 ) < nonCommonFieldList.length" :label="nonCommonFieldList[index + 3].fieldName"></el-checkbox>
              </el-col>
              <!-- <el-col :lg="{span: 4}" style="margin:10px 0;">
                <el-checkbox @change="checked => changeProjectFieldRelation(checked, nonCommonFieldList[index + 4], projectRow)" border v-if="( index + 4 ) < nonCommonFieldList.length" :label="nonCommonFieldList[index + 4].fieldName"></el-checkbox>
              </el-col>
              <el-col :lg="{span: 4}" style="margin:10px 0;">
                <el-checkbox @change="checked => changeProjectFieldRelation(checked, nonCommonFieldList[index + 5], projectRow)" border v-if="( index + 5 ) < nonCommonFieldList.length" :label="nonCommonFieldList[index + 5].fieldName"></el-checkbox>
              </el-col> -->
            </el-row>
          </el-checkbox-group>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import { mapState } from 'vuex'

export default {
  props: ['projectRow', 'commonFieldList', 'nonCommonFieldList'],
  data () {
    return {
      relatedCommonFieldList: [],
      relatedNonCommonFieldList: []
    }
  },
  computed: {
    ...mapState({
      writeSwitch: (state) => state.writableStatus
    })
  },
  mounted () {
  },
  methods: {
    async changeProjectFieldRelation (checkStatus, field, project) {
      const relatedField = {}
      relatedField.fieldName = field.fieldName
      relatedField.fieldID = field.id
      relatedField.projectName = project.projectName
      relatedField.id = project.id

      // let requestURL = '/project/removeFieldRelation'
      let requestURL = this.serverAPI.PROJECT_FIELD_RELATION_REMOVE_URL
      if (checkStatus === true) {
        // requestURL = '/project/addFieldRelation'
        requestURL = this.serverAPI.PROJECT_FIELD_RELATION_ADD_URL
      }
      const { data: manageRelatedFieldsResp } = await this.$http.post(requestURL, JSON.stringify(relatedField), { headers: { 'content-type': 'application/json' } })
      console.log('manage project related fields', manageRelatedFieldsResp)
      if (manageRelatedFieldsResp.code === 1) {
        this.getProjectRelatedFields(project)
        this.$message({
          showClose: true,
          message: 'modify the relationship of the ' + manageRelatedFieldsResp.data.projectName + ' project and ' + field.fieldName + ' field sucessfully!',
          center: true,
          type: 'success'
        })
      } else {
        this.$message({
          showClose: true,
          message: 'modify the relationship of the ' + manageRelatedFieldsResp.data.projectName + ' project and ' + field.fieldName + ' field failed!',
          center: true,
          type: 'error'
        })
      }
    },
    async changeFieldRelationRequirement (event, project, field) {
      console.log('change the relationship requirement', event, project, field)
      const editProjectFieldRequirement = {}
      editProjectFieldRequirement.fieldName = field.fieldName
      editProjectFieldRequirement.fieldID = field.id
      editProjectFieldRequirement.projectName = project.projectName
      editProjectFieldRequirement.id = project.id
      editProjectFieldRequirement.required = event
      // const { data: requirementResp } = await this.$http.post('/project/editRequirement', JSON.stringify(editProjectFieldRequirement), { headers: { 'content-type': 'application/json' } })
      const { data: requirementResp } = await this.$http.post(this.serverAPI.PROJECT_FIELD_RELATION_REQUIREMENT_EDIT_URL, JSON.stringify(editProjectFieldRequirement), { headers: { 'content-type': 'application/json' } })
      console.log('edit the requirement of the project and the field response', requirementResp)

      if (requirementResp.code === 1) {
        project.requirement[field.fieldName] = !project.requirement[field.fieldName]
        this.$message({
          showClose: true,
          message: 'modify the requirement of the ' + editProjectFieldRequirement.projectName + ' project and ' + editProjectFieldRequirement.fieldName + ' field sucessfully!',
          center: true,
          type: 'success'
        })
      } else {
        if (requirementResp.msg) {
          this.$message({
            showClose: true,
            message: requirementResp.msg,
            center: true,
            type: 'error'
          })
        } else {
          this.$message({
            showClose: true,
            message: 'modify the requirement of the ' + editProjectFieldRequirement.projectName + ' project and ' + editProjectFieldRequirement.fieldName + ' field failed!',
            center: true,
            type: 'error'
          })
        }
      }
    },
    async getProjectRelatedFields (project) {
      // const { data: relatedFieldResp } = await this.$http.get('/field/relatedList/' + project.id)
      const { data: relatedFieldResp } = await this.$http.get(this.serverAPI.PROJECT_RELATED_FIELDS_URL + project.id)
      console.log('get relatedFieldResp:', relatedFieldResp)
      if (relatedFieldResp.data) {
        const commonRelatedFields = []
        const nonCommonRelatedFields = []
        const fieldRequirement = {}
        if (relatedFieldResp.data.length && relatedFieldResp.data[0]) {
          relatedFieldResp.data.forEach(relation => {
            fieldRequirement[relation.fieldName] = relation.required
            if (relation.isCommon.fieldTypeCode === 1) {
              commonRelatedFields.push(relation.fieldName)
            } else {
              nonCommonRelatedFields.push(relation.fieldName)
            }
          })
        }

        project.commonFields = commonRelatedFields
        project.nonCommonFields = nonCommonRelatedFields

        if (!project.requirement) {
          project.requirement = {}
        }
        project.requirement = fieldRequirement
      }
    }
  }
}
</script>
<style lang="less" scoped>
.bdtop {
  border-top: 1px solid #eee;
}
.bdbottom {
  border-bottom: 1px solid #eee;
}
.vcenter {
  display: flex;
  align-items: center;
}
</style>
