<template>
  <div>
    <!--Breadcrumb navigation area-->
    <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>Home</el-breadcrumb-item>
        <el-breadcrumb-item>Project Module</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card>
      <el-row>
        <el-col>
          <el-button type="primary" @click="displayAddDialog" v-if="writeSwitch">Add Project</el-button>
          <!-- <el-button type="primary" @click="exportExcel">Export Relationship</el-button> -->
        </el-col>
      </el-row>

      <el-table ref="projectTableRef" :data="projectList" border stripe @expand-change="expanTableHandler">
        <el-table-column type="expand" align="center">
          <template v-slot:="slotProps">
            <ProjectFieldRelation :projectRow="slotProps.row" :commonFieldList="commonFieldList" :nonCommonFieldList="nonCommonFieldList"></ProjectFieldRelation>
          </template>
        </el-table-column>
        <el-table-column type="index" label="#" align="center"></el-table-column>
        <el-table-column prop="projectName" label="Project Name" align="center"></el-table-column>
        <el-table-column prop="projectDescription" label="Description" align="center"></el-table-column>
        <el-table-column prop="relatedProjects" label="Related Projects" align="center">
          <template v-slot:="slotProps">
            <el-tag type="success" v-for="relatedProject in slotProps.row.relatedProjects" :key="relatedProject.id"> {{relatedProject.projectName}} </el-tag>
          </template>
        </el-table-column>
        <el-table-column label="Create Time" align="center">
          <template v-slot="projectSlot">
            {{ projectSlot.row.createTime | formatDate}}
          </template>
        </el-table-column>
        <el-table-column label="Actions" align="center" width="300px">
          <template v-slot:="slotProps">
            <el-tooltip effect="dark" content="edit project" placement="top" :enterable="false">
              <el-button type="primary" icon="el-icon-edit" size="mini" @click="displayEditDialog(slotProps.row.id)"></el-button>
            </el-tooltip>
            <el-tooltip effect="dark" content="change the project status" placement="top" :enterable="false"  v-if="writeSwitch">
              <el-button v-if="slotProps.row.projectStatus.statusCode" type="success" icon="el-icon-open" size="mini" @click="enableProject(slotProps.row.id)"></el-button>
              <el-button v-else type="danger" icon="el-icon-turn-off" size="mini" @click="disableProject(slotProps.row.id)"></el-button>
            </el-tooltip>
            <el-tooltip effect="dark" content="manage related fields" placement="top" :enterable="false">
              <el-button type="warning" icon="el-icon-setting" size="mini" @click="expanTableBtn(slotProps.row)"></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>

      <!--add project-->
      <el-dialog title="New Project" :visible.sync="addProjectDialogVisible" width="50%" @close="addDialogClosed" @mousedown.native="dialogClick">
        <el-form :model="addProjectForm" :rules="projectFormRules" ref="addFormRef" label-width="130px" label-position="top">
          <el-form-item label="Project Name" prop="projectName">
            <el-input ref="addProjectNameRef" v-model="addProjectForm.projectName"></el-input>
          </el-form-item>
          <el-form-item label="Description" prop="projectDescription">
            <el-input v-model="addProjectForm.projectDescription"></el-input>
          </el-form-item>
          <el-form-item label="Project Group" prop="projectGroup">
            <el-autocomplete v-model="addProjectForm.projectGroup" :fetch-suggestions="fetchProjectGroup" clearable style="width: 100%;" placeholder="Please inout a new or select an existing project group"></el-autocomplete>
          </el-form-item>
          <el-form-item label="Related Projects">
            <el-tag
              :key="project.id"
              v-for="project in addProjectForm.relatedProjects"
              closable
              :disable-transitions="false"
              @close="handleClose(project, addProjectForm)">
              {{project.projectName}}
            </el-tag>
            <el-select clearable :automatic-dropdown="true" class="input-new-tag" v-if="inputVisible" v-model="selectSearchValue" filterable ref="saveTagInput" size="small"
               @change="handleInputConfirm($event, addProjectForm)">
              <el-option v-for="project in relatedProjectOptionList" :key="project.projectName" :label="project.projectName" :value="project"></el-option>
            </el-select>
            <el-button v-else class="button-new-tag" size="small" @click="showInput(addProjectForm)">+ New Related Project</el-button>
          </el-form-item>
        </el-form>

        <span slot="footer" class="dialog-footer">
          <el-button @click="addProjectDialogVisible = false">Cancel</el-button>
          <el-button type="primary" @click="addProject">Submit</el-button>
        </span>
      </el-dialog>

      <!--edit project-->
      <el-dialog title="Edit Project" :visible.sync="editProjectDialogVisible" width="50%" @close="editDialogClosed" @mousedown.native="dialogClick">
        <el-form :model="editProjectForm" :rules="projectFormRules" ref="editFormRef" label-width="130px" label-position="top">
          <el-form-item label="Project Name" prop="projectName">
            <el-input ref="editProjectNameRef" v-model="editProjectForm.projectName" :disabled="!writeSwitch"></el-input>
          </el-form-item>
          <el-form-item label="Description">
            <el-input v-model="editProjectForm.projectDescription" :disabled="!writeSwitch"></el-input>
          </el-form-item>
          <el-form-item label="Project Group" prop="projectGroup">
            <el-autocomplete v-model="editProjectForm.projectGroup" :fetch-suggestions="fetchProjectGroup" clearable style="width: 100%;" placeholder="Please inout a new or select an existing project group" :disabled="!writeSwitch"></el-autocomplete>
          </el-form-item>
          <el-form-item label="Related Projects">
            <el-tag
              :key="relatedProject.id"
              v-for="relatedProject in editProjectForm.relatedProjects"
              :closable="writeSwitch"
              :disable-transitions="false"
              @close="handleClose(relatedProject, editProjectForm)">
              {{relatedProject.projectName}}
            </el-tag>
            <span v-if="writeSwitch">
              <el-select clearable :automatic-dropdown="true" class="input-new-tag" v-if="inputVisible" v-model="selectSearchValue" filterable ref="saveTagInput" size="small"
                @change="handleInputConfirm($event, editProjectForm)">
                <el-option v-for="project in relatedProjectOptionList" :key="project.projectName" :label="project.projectName" :value="project"></el-option>
              </el-select>
              <el-button v-else class="button-new-tag" size="small" @click="showInput(editProjectForm)">+ New Related Project</el-button>
            </span>
          </el-form-item>
        </el-form>

        <span slot="footer" class="dialog-footer">
          <el-button @click="editProjectDialogVisible = false">Cancel</el-button>
          <el-button type="primary" @click="updateProject" v-if="writeSwitch">Update</el-button>
        </span>
      </el-dialog>
    </el-card>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import ProjectFieldRelation from '../components/project_component/ProjectFieldRelation'
import { export2Excel } from '../../src/assets/excelexport'

export default {
  components: {
    ProjectFieldRelation
  },
  data () {
    const checkProjectName = (rule, value, callback) => {
      const currentProjectName = this.addProjectForm.projectName
      const checkResult = this.projectList.filter(project => {
        return project.projectName === currentProjectName
      })
      if (checkResult !== undefined && checkResult != null && checkResult.length > 0) {
        return callback(new Error(value + ' already exist in the project list'))
      }

      return callback()
    }
    return {
      inputVisible: false,
      projectList: [],
      relatedProjectOptionList: [],
      selectSearchValue: '',
      addProjectDialogVisible: false,
      editProjectDialogVisible: false,
      addProjectForm: {
        projectName: '',
        projectDescription: '',
        relatedProjects: [],
        projectGroup: ''
      },
      editProjectForm: {
        projectName: '',
        projectDescription: '',
        relatedProjects: [],
        projectGroup: ''
      },
      projectFormRules: {
        projectName: [
          { required: true, message: 'Please input project name', trigger: 'blur' },
          { min: 3, max: 100, message: 'The length of the project name is limit from 3 to 10', trigger: 'blur' },
          { validator: checkProjectName, trigger: 'blur' }
        ],
        projectGroup: [
          { required: true, message: 'Please input or select a project group', trigger: 'change' }
        ]
      },
      commonFieldList: [],
      nonCommonFieldList: [],
      projectGroup: []
    }
  },
  created () {
    this.getProjects()
    this.getAllFields()
  },
  computed: {
    ...mapState({
      writeSwitch: (state) => state.writableStatus
    })
  },
  mounted () {
  },
  methods: {
    expanTableHandler (row, expandedRow) {
      if (expandedRow.length > 0) {
        const matchResult = expandedRow.filter(item => { // return the matched item
          return item.id === row.id
        })

        if (matchResult.length === 0) {
          row.isExpand = false
        } else {
          row.isExpand = true
          this.getProjectRelatedFields(row)
        }
      } else {
        row.isExpand = false
      }
      console.log('get related fields', row, expandedRow)
    },
    expanTableBtn (row) { // use the button to expand the table row
      console.log('button row', row)
      if (row.isExpand !== null && row.isExpand !== 'undefined' && row.isExpand !== undefined && row.isExpand === true) {
        row.isExpand = false
      } else {
        row.isExpand = true
      }
      this.$refs.projectTableRef.toggleRowExpansion(row, row.isExpand)
    },
    dialogClick (event) {
      console.log('click element:', event.srcElement)
      if (this.inputVisible) {
        this.inputVisible = !this.inputVisible
      }
    },
    handleClose (tag, formData) {
      if (formData.relatedProjects) {
        formData.relatedProjects.splice(formData.relatedProjects.indexOf(tag), 1)
      }
    },
    showInput (formData) {
      const currentProjectName = formData.projectName
      this.relatedProjectOptionList = this.getSubtraction(this.projectList, Array.of(currentProjectName))
      if (!formData.relatedProjects) {
        formData.relatedProjects = []
      }
      const selectedProjectNames = formData.relatedProjects.map(selectedProject => selectedProject.projectName)
      this.relatedProjectOptionList = this.getSubtraction(this.relatedProjectOptionList, selectedProjectNames)

      this.inputVisible = true
      this.$nextTick(_ => {
        this.$refs.saveTagInput.focus()
      })
    },
    handleInputConfirm (event, formData) {
      if (event && formData) {
        if (!formData.relatedProjects) {
          formData.relatedProjects = []
        }
        formData.relatedProjects.push(event)
      }
      this.inputVisible = false
      this.selectSearchValue = ''
    },
    getSubtraction (allProjects, subProjects) {
      const subStraction = allProjects.filter(project => {
        return subProjects.indexOf(project.projectName) === -1
      })

      return subStraction
    },
    addDialogClosed () {
      this.addProjectForm.relatedProjects = []
      this.$refs.addFormRef.resetFields()
    },
    editDialogClosed () {
      this.editProjectForm.relatedProjects = []
      this.$refs.editFormRef.resetFields()
    },
    async getProjects () {
      // const { data: projectData } = await this.$http.get('/project/list')
      const { data: projectData } = await this.$http.get(this.serverAPI.PROJECT_LIST_URL)
      console.log('project data', projectData)
      this.projectList = projectData.data
    },
    addProject () {
      this.$refs.addFormRef.validate(async result => {
        if (!result) return
        // const { data: res } = await this.$http.post('/project/save', JSON.stringify(this.addProjectForm), { headers: { 'content-type': 'application/json' } })
        const { data: res } = await this.$http.post(this.serverAPI.PROJECT_SAVE_URL, JSON.stringify(this.addProjectForm), { headers: { 'content-type': 'application/json' } })
        console.log('add project response', res)
        this.addProjectDialogVisible = false
        this.getProjects()
      })
    },
    async displayAddDialog () {
      this.getProjectGroup()
      this.addProjectDialogVisible = true
      this.$nextTick(_ => {
        console.log(this.$refs.addProjectNameRef)
        this.$refs.addProjectNameRef.focus()
      })
    },
    async getProjectGroup () {
      const { data: projectGroupResp } = await this.$http.get(this.serverAPI.PROJECT_GROUP_URL)
      console.log('project group: ', projectGroupResp)

      const projectGroups = []
      projectGroupResp.data.forEach(group => {
        const groupObj = {}
        groupObj.value = group
        projectGroups.push(groupObj)
      })

      this.projectGroup = projectGroups
    },
    async displayEditDialog (id) {
      // const { data: projectResp } = await this.$http.get('/project/' + id)
      await this.getProjectGroup()
      const { data: projectResp } = await this.$http.get(this.serverAPI.PROJECT_DETAIL_URL + id)
      console.log('get project info by name', projectResp)
      this.editProjectDialogVisible = true
      this.editProjectForm = projectResp.data
      this.$nextTick(_ => {
        this.$refs.editProjectNameRef.focus()
      })
    },
    updateProject () {
      console.log(this.$refs.editFormRef)
      this.$refs.editFormRef.validate(async result => {
        if (!result) return
        // const { data: updateResp } = await this.$http.post('/project/update', JSON.stringify(this.editProjectForm), { headers: { 'content-type': 'application/json' } })
        const { data: updateResp } = await this.$http.post(this.serverAPI.PROJECT_UPDATE_URL, JSON.stringify(this.editProjectForm), { headers: { 'content-type': 'application/json' } })
        console.log('update project response', updateResp)
        this.editProjectDialogVisible = false
        this.getProjects()
      })
    },
    async disableProject (id) {
      console.log(id)
      // const { data: disResp } = await this.$http.delete('/project/disable/' + id)
      const { data: disResp } = await this.$http.delete(this.serverAPI.PROJECT_DISABLE_URL + id)
      console.log('disable projectName=%s', id, disResp)
      this.getProjects()
      // window.location.reload()
    },
    async enableProject (id) {
      // const { data: enableResp } = await this.$http.get('/project/enable/' + id)
      const { data: enableResp } = await this.$http.get(this.serverAPI.PROJECT_ENABLE_URL + id)
      console.log('enable projectName=%s', id, enableResp)
      this.getProjects()
      // window.location.reload()
    },
    async getAllFields () {
      // const { data: groupFieldResp } = await this.$http.get('/field/groupFields')
      const { data: groupFieldResp } = await this.$http.get(this.serverAPI.FIELD_GROUP_URL)
      console.log('common field list reponse', groupFieldResp)
      this.commonFieldList = groupFieldResp.data.commonField
      this.nonCommonFieldList = groupFieldResp.data.nonCommonField
    },
    async getProjectRelatedFields (project) {
      // const { data: relatedFieldResp } = await this.$http.get('/field/relatedList/' + project.id)
      const { data: relatedFieldResp } = await this.$http.get(this.serverAPI.PROJECT_RELATED_FIELDS_URL + project.id)
      console.log('get relatedFieldResp:', relatedFieldResp)
      if (relatedFieldResp.data) {
        const commonRelatedFields = []
        const nonCommonRelatedFields = []
        const fieldRequirement = {}
        if (relatedFieldResp.data.length && relatedFieldResp.data[0]) {
          relatedFieldResp.data.forEach(relation => {
            fieldRequirement[relation.fieldName] = relation.required
            if (relation.isCommon.fieldTypeCode === 1) {
              commonRelatedFields.push(relation.fieldName)
            } else {
              nonCommonRelatedFields.push(relation.fieldName)
            }
          })
        }

        project.commonFields = commonRelatedFields
        project.nonCommonFields = nonCommonRelatedFields

        if (!project.requirement) {
          project.requirement = {}
        }
        project.requirement = fieldRequirement
      }
    },
    fetchProjectGroup (queryStr, cb) {
      cb(this.projectGroup)
    },
    async exportExcel () {
      /* const data = {
        tableTitle: ['第一季度持仓', '第二季度持仓'],
        list: [[['代码', '持仓', '收益率', '收益'], [1, 2, 3, 4], [1, 2, 3, 4]], [['代码', '持仓', '收益率', '收益'], [5, 6, 5, 6], [5, 6, 5, 6]]],
        excelFileName: '养鸡报告'
      }

      export2Excel(data.tableTitle, data.list, data.excelFileName) */

      // console.log('project list: ', this.projectList)
      for (let i = 0; i < this.projectList.length; i++) {
        const project = this.projectList[i]

        const { data: relatedFieldResp } = await this.$http.get(this.serverAPI.PROJECT_RELATED_FIELDS_URL + project.id)
        // console.log('get relatedFieldResp:', relatedFieldResp)
        if (relatedFieldResp.data) {
          const commonRelatedFields = []
          const nonCommonRelatedFields = []
          const fieldRequirement = {}
          if (relatedFieldResp.data.length && relatedFieldResp.data[0]) {
            relatedFieldResp.data.forEach(relation => {
              fieldRequirement[relation.fieldName] = relation.required
              if (relation.isCommon.fieldTypeCode === 1) {
                commonRelatedFields.push(relation.fieldName)
              } else {
                nonCommonRelatedFields.push(relation.fieldName)
              }
            })
          }

          project.commonFields = commonRelatedFields
          project.nonCommonFields = nonCommonRelatedFields

          if (!project.requirement) {
            project.requirement = {}
          }
          project.requirement = fieldRequirement
        }
      }

      const projectNameList = this.projectList.map(project => project.projectName)
      console.log('project name list: ', projectNameList)

      const projectRelationDataList = []
      for (let pi = 0; pi < this.projectList.length; pi++) {
        const project = this.projectList[pi]
        console.log('project detail: ', project)
        const projectFieldList = []
        projectFieldList.push(['Gerneral Fields', '', ''])
        projectFieldList.push(['Field Label', 'Field Name', 'Requirement'])

        for (let i = 0; i < project.commonFields.length; i++) {
          const commonFieldName = project.commonFields[i]
          console.log('project common field name: ', commonFieldName)
          const requirement = project.requirement[commonFieldName]
          console.log('project common field requirement: ', requirement)
          const commonField = this.commonFieldList.find(item => {
            if (item.fieldName === commonFieldName) {
              return true
            }
          })

          if (commonField) {
            projectFieldList.push([commonField.fieldLabel, commonFieldName, requirement])
          }
        }

        projectFieldList.push(['ProjectRelated Fields', '', ''])
        for (let i = 0; i < project.nonCommonFields.length; i++) {
          const nonCommonRelatedFieldName = project.nonCommonFields[i]
          const requirement = project.requirement[nonCommonRelatedFieldName]
          const nonCommonField = this.nonCommonFieldList.find(item => {
            if (item.fieldName === nonCommonRelatedFieldName) {
              return true
            }
          })

          if (nonCommonField) {
            projectFieldList.push([nonCommonField.fieldLabel, nonCommonRelatedFieldName, requirement])
          }
        }

        projectRelationDataList.push(projectFieldList)
      }
      console.log('project field list: ', projectRelationDataList)

      export2Excel(projectNameList, projectRelationDataList, 'Relationship of the SFB Projects and Fields')
    }
  }
}
</script>
<style lang="less" scoped>
.el-tag {
  margin-right: 10px;
  margin-bottom: 3px;
}
.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
  width: 155px;
}
.input-new-tag {
  width: 155px;
  margin-left: 10px;
  vertical-align: bottom;
}
</style>
