const UNKNOWN = {
  typeCode: 0,
  typeName: 'unknown',
  typeIcon: 'assets/file/images/file_none@3x.png'
}

const FOLDER = {
  typeCode: 1,
  typeName: 'folder',
  typeIcon: 'assets/file/images/folder@3x.png'
}

const IMAGE = {
  typeCode: 2,
  typeName: 'image',
  categories: ['png', 'jpg', 'jpeg', 'gif', 'bmp'],
  typeIcon: 'assets/file/images/file_img@3x.png',
  suffix: function () {
    const suffix = []
    this.categories.forEach(fileCategory => {
      suffix.push('.' + fileCategory)
    })
    return suffix
  }
}

const VIDEO = {
  typeCode: 3,
  typeName: 'video',
  categories: ['avi', 'mp4', 'rmvb', 'flv', 'mov', 'mkv', 'wmv', 'm2v'],
  typeIcon: 'assets/file/images/file_video@3x.png',
  suffix: function () {
    const suffix = []
    this.categories.forEach(fileCategory => {
      suffix.push('.' + fileCategory)
    })
    return suffix
  }
}

const AUDIO = {
  typeCode: 4,
  typeName: 'audio',
  categories: ['aac', 'mp3', 'oga', 'wav', 'weba', 'wma', 'm4a'],
  typeIcon: 'assets/file/images/file_mp3@3x.png',
  suffix: function () {
    const suffix = []
    this.categories.forEach(fileCategory => {
      suffix.push('.' + fileCategory)
    })
    return suffix
  }
}

const EXCEL = {
  typeCode: 5,
  typeName: 'excel',
  categories: ['xls', 'xlsx'],
  typeIcon: 'assets/file/images/file_excel@3x.png',
  suffix: function () {
    const suffix = []
    this.categories.forEach(fileCategory => {
      suffix.push('.' + fileCategory)
    })
    return suffix
  }
}

const PPT = {
  typeCode: 6,
  typeName: 'ppt',
  categories: ['ppt', 'pptx'],
  typeIcon: 'assets/file/images/file_ppt@3x.png',
  suffix: function () {
    const suffix = []
    this.categories.forEach(fileCategory => {
      suffix.push('.' + fileCategory)
    })
    return suffix
  }
}

const WORD = {
  typeCode: 7,
  typeName: 'word',
  categories: ['doc', 'docx'],
  typeIcon: 'assets/file/images/file_docx@3x.png',
  suffix: function () {
    const suffix = []
    this.categories.forEach(fileCategory => {
      suffix.push('.' + fileCategory)
    })
    return suffix
  }
}

const PDF = {
  typeCode: 8,
  typeName: 'pdf',
  categories: ['pdf'],
  typeIcon: 'assets/file/images/file_pdf@3x.png',
  suffix: function () {
    const suffix = []
    this.categories.forEach(fileCategory => {
      suffix.push('.' + fileCategory)
    })
    return suffix
  }
}

const COMPRESS = {
  typeCode: 9,
  typeName: 'zip',
  categories: ['arc', 'bz', 'bz2', 'rar', 'tar', 'zip', '7z'],
  typeIcon: 'assets/file/images/file_zip@3x.png',
  suffix: function () {
    const suffix = []
    this.categories.forEach(fileCategory => {
      suffix.push('.' + fileCategory)
    })
    return suffix
  }
}

const TEXT = {
  typeCode: 10,
  typeName: 'text',
  categories: ['txt', 'html', 'htm', 'xml', 'sql', 'css', 'less', 'scss', 'js'],
  typeIcon: 'assets/file/images/file_txt@3x.png',
  suffix: function () {
    const suffix = []
    this.categories.forEach(fileCategory => {
      suffix.push('.' + fileCategory)
    })
    return suffix
  }
}

const getFileTypes = () => {
  const fileTypes = []
  console.debug(Object.entries(FILE_TYPE))
  for (const [key, value] of Object.entries(FILE_TYPE)) {
    try {
      if (value.typeCode !== FILE_TYPE.UNKNOWN.typeCode &&
          value.typeCode !== FILE_TYPE.FOLDER.typeCode &&
          value.typeCode !== null &&
          value.typeCode !== 'undefined' &&
          value.typeCode !== undefined &&
          value.typeCode !== '') {
        fileTypes.push(value)
      }
    } catch (e) {
      console.log('keyName=%s has no typeCode property', key)
      continue
    }
  }
  return fileTypes
}

// determine file type
const getFileType = (fileName) => {
  const suffixIndex = fileName.lastIndexOf('.')
  const fileSuffix = fileName.substring(suffixIndex + 1, fileName.length)
  console.debug('fileName = %s, suffix = %s', fileName, fileSuffix)

  const fileTypes = getFileTypes()
  console.debug('get the validate fileTypes', fileTypes)
  for (const fileType of fileTypes) {
    const fileResult = fileType.categories.some((suffixItem) => {
      return suffixItem === fileSuffix.toLowerCase()
    })

    if (fileResult) {
      return fileType
    }
  }

  return FILE_TYPE.UNKNOWN
}

const getCategoryMap = () => {
  const categoryMap = {}
  categoryMap[FILE_TYPE.IMAGE.typeName] = FILE_TYPE.IMAGE.categories
  categoryMap[FILE_TYPE.VIDEO.typeName] = FILE_TYPE.VIDEO.categories
  categoryMap[FILE_TYPE.AUDIO.typeName] = FILE_TYPE.AUDIO.categories
  categoryMap[FILE_TYPE.EXCEL.typeName] = FILE_TYPE.EXCEL.categories
  categoryMap[FILE_TYPE.PPT.typeName] = FILE_TYPE.PPT.categories
  categoryMap[FILE_TYPE.WORD.typeName] = FILE_TYPE.WORD.categories
  categoryMap[FILE_TYPE.PDF.typeName] = FILE_TYPE.PDF.categories
  categoryMap[FILE_TYPE.COMPRESS.typeName] = FILE_TYPE.COMPRESS.categories
  categoryMap[FILE_TYPE.TEXT.typeName] = FILE_TYPE.TEXT.categories

  return categoryMap
}

// basic file type configuration
export const FILE_TYPE = {
  UNKNOWN,
  FOLDER,
  IMAGE,
  VIDEO,
  AUDIO,
  EXCEL,
  PPT,
  WORD,
  PDF,
  COMPRESS,
  TEXT,
  getFileType,
  getCategoryMap
}

// used to config simple-uploader
export const ACCEPT_CONFIG = {
  getImageSuffix: function () {
    return FILE_TYPE.IMAGE.suffix()
  },

  getVideoSuffix: function () {
    return FILE_TYPE.VIDEO.suffix()
  },

  getAudioSuffix: function () {
    return FILE_TYPE.AUDIO.suffix()
  },

  getExcelSuffix: function () {
    return FILE_TYPE.EXCEL.suffix()
  },

  getPptSuffix: function () {
    return FILE_TYPE.PPT.suffix()
  },

  getWordSuffix: function () {
    return FILE_TYPE.WORD.suffix()
  },

  getPdfSuffix: function () {
    return FILE_TYPE.PDF.suffix()
  },

  getCompressSuffix: function () {
    return FILE_TYPE.COMPRESS.suffix()
  },

  getTextSuffix: function () {
    return FILE_TYPE.TEXT.suffix()
  },

  getDocument () {
    return [...this.getExcelSuffix(), ...this.getPptSuffix(), ...this.getWordSuffix(), ...this.getPdfSuffix(), ...this.getTextSuffix(), '.tif', '.tiff']
  },

  getAll () {
    return [...this.getImageSuffix(), ...this.getVideoSuffix(), ...this.getAudioSuffix(), ...this.getCompressSuffix(), ...this.getDocument()]
  }
}
