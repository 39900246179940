<template>
  <section>
    <el-row>
      <el-col :span="24" v-if="writeSwitch">
        <el-tag v-for="relatedProject in paperForm.paperRelatedProject" :key="relatedProject.id" style="margin-right: 5px;"
          :closable="btnsVisible" :disable-transitions="false"
          @close="handleCloseProjectTag(relatedProject)">
          {{relatedProject.projectName}}
        </el-tag>
        <span v-if="btnsVisible" v-cloak>
          <section v-show="selectVisible">
            <el-select ref="paperRelatedProjectRef" size="medium" clearable filterable multiple class="select-new-tag" :reserve-keyword="true"
              v-model="projectSearchSelectOptions" placeholder="Please input the project name">
              <el-option v-for="project in projectOptions" :key="project.id" :label="project.projectName" :value="project.id"></el-option>
            </el-select>
            <el-button size="medium" type="primary" circle icon="el-icon-plus" @click="handleProjectSelectConfirm"></el-button>
            <el-button size="medium" type="danger" circle icon="el-icon-close" @click="selectVisible=false"></el-button>
          </section>
          <el-button v-show="!selectVisible" class="button-new-tag" size="small" type="primary" @click="selectVisible=true">+ New Related Project</el-button>
        </span>
      </el-col>
    </el-row>
  </section>
</template>
<script>
import { mapState } from 'vuex'
import { EVENT_CONFIG } from '@/event_bus'

export default {
  props: ['paperForm', 'btnsVisible'],
  async mounted () {
    await this.setProjectOptions()
  },
  watch: {
    /* paper update page when this component mounted,
     * the 'paperForm' doesn't have the detail data
     * so use the watch the 'paperForm' to be updated and re-render the 'projectOptions'
     */
    paperForm: {
      handler () {
        this.setProjectOptions()
      }
    }
  },
  computed: {
    ...mapState({
      writeSwitch: (state) => state.writableStatus
    })
  },
  data () {
    return {
      currentUser: { id: '' },
      selectVisible: false,
      projectOptions: [],
      projectSearchSelectOptions: []
    }
  },
  methods: {
    async getProjectOptions () {
      // const { data: projectOptResp } = await this.$http.get('/project/enableList')
      const { data: projectOptResp } = await this.$http.get(this.serverAPI.PROJECT_ENABLE_LIST_URL)
      console.debug('get project options response: ', projectOptResp)
      if (projectOptResp.code === 1) {
        this.projectOptions = projectOptResp.data
      }
    },
    async setProjectOptions () {
      await this.getProjectOptions()

      const unselectedProjects = this.projectOptions.filter(projectOptionItem => {
        const existProjectPos = this.paperForm.paperRelatedProject.findIndex(relatedProject => {
          return relatedProject.id === projectOptionItem.id
        })
        return !(existProjectPos > -1)
      })
      console.log('unselected projects: ', unselectedProjects)
      this.projectOptions = unselectedProjects
    },
    handleCloseProjectTag (selectedProject) {
      const selectedProjetPos = this.paperForm.paperRelatedProject.findIndex(relatedProject => {
        return selectedProject.id === relatedProject.id
      })

      this.paperForm.paperRelatedProject.splice(selectedProjetPos, 1)

      this.setProjectOptions()

      EVENT_CONFIG.EventBus.$emit(EVENT_CONFIG.CHECK_FORM_FIELD_EVENT, null, 'paperRelatedProject')
    },
    handleProjectSelectConfirm () {
      console.log('selected project', this.projectSearchSelectOptions)
      if (this.projectSearchSelectOptions.length && this.paperForm) {
        const selectedProjects = this.projectOptions.filter(projectOptionItem => {
          return this.projectSearchSelectOptions.indexOf(projectOptionItem.id) > -1
        })
        this.paperForm.paperRelatedProject.push(...selectedProjects)

        const unselectedProjects = this.projectOptions.filter(projectOptionItem => {
          return !(this.projectSearchSelectOptions.indexOf(projectOptionItem.id) > -1)
        })
        this.projectOptions = unselectedProjects

        this.projectSearchSelectOptions = []
        this.selectVisible = false
      } else {
        this.$message({
          showClose: true,
          message: 'no selected project',
          type: 'error'
        })
      }
    }
  }
}
</script>
<style scoped>
@import '../paper_res/PaperStyle.css';
</style>
