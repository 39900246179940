import axios from 'axios'
import { SERVER_API } from '@/server_api'

const USER_STORAGE_KEY = 'current_user'

const setCurrentUser = (currentUser) => {
  window.localStorage.setItem(USER_STORAGE_KEY, JSON.stringify(currentUser))
}

const getCurrentUser = async () => {
  const userStrContent = window.localStorage.getItem(USER_STORAGE_KEY)
  if (userStrContent === null || userStrContent === undefined) {
    // const { data: currentUserResp } = await this.$http.get('user/current')
    const { data: currentUserResp } = await axios.get(SERVER_API.CURRENT_USER_URL)
    console.log('request current login user from server: ', currentUserResp)

    if (currentUserResp.code === 1) {
      window.localStorage.setItem(USER_STORAGE_KEY, JSON.stringify(currentUserResp.data))
      return currentUserResp.data
    }
  }
  return JSON.parse(userStrContent)
}

const getCurrentUserStr = () => {
  return window.localStorage.getItem(USER_STORAGE_KEY)
}

const removeCurrentUser = () => {
  window.localStorage.removeItem(USER_STORAGE_KEY)
}

export const USER_LOCAL_STORAGE = {
  USER_STORAGE_KEY,
  setCurrentUser,
  getCurrentUser,
  getCurrentUserStr,
  removeCurrentUser
}
