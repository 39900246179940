<template>
  <section>
    <el-form-item>
      <el-row>
        <span v-if="writeSwitch">
          <el-button v-if="creator.id === currentUser.id" size="small" type="primary" icon="el-icon-plus" @click="showAddFileDialog" style="margin-right: 3px;">Add Files</el-button>
        </span>
        <span v-if="(creator.id === currentUser.id) || (formData[fieldName] && formData[fieldName].length && formData[fieldName].length > 0)">
          <el-tooltip v-for="(file, index) in formData[fieldName]" :key="file.fileUniqueID" :content="file.fileName" @click.native="checkFileStatusBeforeDownload(file)">
            <el-tag class="file-name" :style="index > 0 ? 'margin-left: 3px;' : ''">
              {{file.fileName}}
            </el-tag>
            <!-- <el-tag class="file-tag" :style="index > 0 ? 'margin-left: 3px;' : ''">
              {{file.fileName}}
            </el-tag> -->
          </el-tooltip>
        </span>
        <section v-for="fileProgress in fileProgressList" :key="fileProgress.fieldName + fileProgress.fileUniqueID">
          <span v-if="fileProgress.fieldName === fieldName">
            <el-row>
              <img :src="getFileIcon(fileProgress)" style="width: 20px; height: 20px; vertical-align: middle; margin-right: 5px;">{{ fileProgress.fileName }}
              <el-progress type="circle" style="vertical-align: middle;" :width="80" :percentage="(fileProgress.fileProgress * 100)" :format="formattFileProgressText">{{ fileProgress.fileName }}</el-progress>
            </el-row>
          </span>
        </section>
      </el-row>

      <el-dialog title="Add Files" :visible.sync="showAddFileFlag" width="50%" @close="closeAddFileDialog" :show-close="false" :before-close="beforeCloseAddFileDialog" style="min-width: 1260px;">
        <el-row v-show="!searchFileFlag">
          <el-button size="small" type="primary" icon="el-icon-document-add" @click="fileUploadHandler(fieldName)">Upload File</el-button>
          <el-button size="small" type="primary" icon="el-icon-folder-add" @click="folderUploadHandler(fieldName)">Upload Folder</el-button>
          <el-button size="small" type="primary" icon="el-icon-search" @click="fileSearchHandler">Search Files</el-button>
        </el-row>

        <section v-for="fieldFileRelation in addedFieldFielRelationList" :key="fieldFileRelation.fieldName + fieldFileRelation.fileUniqueID" style="display: inline; margin-right: 5px;">
          <span v-if="fieldFileRelation.fieldName === fieldName">
            <el-tag size="medium" effect="dark" type="success" closable @close="searchedFileRemoveHandler(fieldFileRelation)"><img :src="getFileIcon(fieldFileRelation)" style="width: 20px; height: 20px; vertical-align: middle; margin-right: 5px;">{{ fieldFileRelation.fileName }}</el-tag>
          </span>
        </section>

        <section v-for="fileProgress in fileProgressList" :key="fileProgress.fieldName + fileProgress.fileUniqueID">
          <span v-if="fileProgress.fieldName === fieldName">
            <el-row>
              <img :src="getFileIcon(fileProgress)" style="width: 20px; height: 20px; vertical-align: middle; margin-right: 5px;">{{ fileProgress.fileName }}
            </el-row>
            <el-row>
              <el-col :span="23" style="margin-top: 10px;">
                <el-progress :text-inside="true" :stroke-width="20" :percentage="(fileProgress.fileProgress * 100)" :format="formattFileProgressText">{{ fileProgress.fileName }}</el-progress>
              </el-col>
              <el-col :span="1">
                <el-button type="danger" icon="el-icon-delete" circle size="mini" plain @click="invokeFileRemoveHandler(fileProgress)"></el-button>
              </el-col>
            </el-row>
          </span>
        </section>

        <el-row v-show="searchFileFlag" type="flex" style="flex-wrap: nowrap;" align="middle">
          <el-radio v-model="searchType" label="false" @change="fileTypeChangeHandler">File</el-radio>
          <el-radio v-model="searchType" label="true" @change="fileTypeChangeHandler">Folder</el-radio>
        </el-row>
        <div v-show="searchFileFlag" style="display: flex; flex-wrap: nowrap;">
          <el-input name="fileSearchKeyword" size="small" placeholder="Please input file name to search" clearable v-model="fileSearchKeyword" @keyup.enter.native="searchFileListHandler" @clear="searchFileListHandler"></el-input>
          <el-button slot="append" size="small" clearable style="height: 32px; margin-top: 4px; background-color: #F5F7FA;" icon="el-icon-search" @click="searchFileListHandler"></el-button>
        </div>

        <el-row v-show="searchFileFlag">
          <el-table v-loading="fileLoading" size="mini" ref="fileTable" :row-key="row => row.rowkey" border stripe
            :data="fileTableList" :row-class-name="selectedRowClass" @selection-change="selectionChangeHandler"
            lazy :load="load" :tree-props="{hasChildren: 'isFolder'}">
            <el-table-column type="selection" :reserve-selection="true" width="55" align="center"></el-table-column>
            <el-table-column label="File Path" :show-overflow-tooltip="true" prop="fileName"></el-table-column>
            <el-table-column label="File Type">
              <template v-slot="fileProps">
                {{ fileProps.row | showFileType }}
                <img :src="getFileIcon(fileProps.row)" class="name-col-icon" alt="file type icon" style="width: 20px; height: 20px; vertical-align:middle"/>
              </template>
            </el-table-column>
            <el-table-column label="Status" align="center">
            <template v-slot="fileProps">
              <span v-show="fileProps.row.fileStatus.statusCode === 1"><i class="el-icon-question" style="font-size: 12px; color: #e6a23c; margin-left: 3px;"></i>Unmerged</span>
              <span v-show="fileProps.row.fileStatus.statusCode === 2"><i class="el-icon-loading" style="font-size: 12px; color: #409EFF; margin-left: 3px;"></i>Merging</span>
              <span v-show="fileProps.row.fileStatus.statusCode === 3"><i class="el-icon-info" style="font-size: 12px; color: #909399; margin-left: 3px;"></i>Merged</span>
              <span v-show="fileProps.row.fileStatus.statusCode === 4"><i class="el-icon-error" style="font-size: 12px; color: #f56c6c; margin-left: 3px;"></i>Failed</span>
              <span v-show="fileProps.row.fileStatus.statusCode === 5"><i class="el-icon-success" style="font-size: 12px; color: #67c23a; margin-left: 3px;"></i>Success</span>
            </template>
          </el-table-column>
            <el-table-column align="center" label="Size">
              <template v-slot="fileProps">
                {{ fileProps.row | fileSizeFormat }}
              </template>
            </el-table-column>
            <el-table-column label="Operator" prop="operator.realName"></el-table-column>
          </el-table>

          <el-pagination style="float: right; margin-bottom: 10px;"
            @current-change="handleCurrentChange"
            :current-page="paginationInfo.currentPage"
            :page-sizes="[5]"
            :page-size="paginationInfo.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="paginationInfo.totalCount">
          </el-pagination>
        </el-row>

        <div slot="footer" class="dialog-footer">
          <!-- <el-button size="small" type="primary" style="height: 32px; margin-top: 4px; margin-left: 15px;">Search</el-button> -->
          <el-button v-show="searchFileFlag" size="small" type="primary" @click="toFileUplaodView" style="height: 32px; margin-top: 4px;">to Upload Files</el-button>
        </div>

        <div v-show="!searchFileFlag" slot="footer" class="dialog-footer">
          <el-button size="small" @click="closeAddFileDialog">Close</el-button>
          <el-button size="small" type="primary" @click="confirmToAddSelectedFiles">Confirm</el-button>
        </div>
      </el-dialog>
    </el-form-item>

    <el-dialog title="Subfile List" width="50%" :visible.sync="fileListFlag">
      <el-row style="padding-bottom: 20px;">
        <el-table v-loading="subfileLoading" size="mini" ref="subfileTable"
          :data="subFileList" :row-key="row => row.rowkey" border stripe
          style="margin-top: 0;" :tree-props="{hasChildren: 'isFolder'}" lazy :load="load">
          <el-table-column type="selection" :reserve-selection="true" width="55" align="center"></el-table-column>
          <el-table-column label="File Path" :show-overflow-tooltip="true">
            <template v-slot="fileProps">
              {{ fileProps.row.fileName }}
            </template>
          </el-table-column>
          <el-table-column label="File Type" align="center">
            <template v-slot="fileProps">
              {{ fileProps.row | showFileType }}
              <img :src="getFileIcon(fileProps.row)" class="name-col-icon" alt="file type icon" style="width: 20px; height: 20px; vertical-align:middle"/>
            </template>
          </el-table-column>
          <el-table-column label="Status" align="center">
            <template v-slot="fileProps">
              <span v-show="fileProps.row.fileStatus.statusCode === 1"><i class="el-icon-question" style="font-size: 12px; color: #e6a23c; margin-left: 3px;"></i>Unmerged</span>
              <span v-show="fileProps.row.fileStatus.statusCode === 2"><i class="el-icon-loading" style="font-size: 12px; color: #409EFF; margin-left: 3px;"></i>Merging</span>
              <span v-show="fileProps.row.fileStatus.statusCode === 3"><i class="el-icon-info" style="font-size: 12px; color: #909399; margin-left: 3px;"></i>Merged</span>
              <span v-show="fileProps.row.fileStatus.statusCode === 4"><i class="el-icon-error" style="font-size: 12px; color: #f56c6c; margin-left: 3px;"></i>Failed</span>
              <span v-show="fileProps.row.fileStatus.statusCode === 5"><i class="el-icon-success" style="font-size: 12px; color: #67c23a; margin-left: 3px;"></i>Success</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="Size">
            <template v-slot="fileProps">
              {{ fileProps.row | fileSizeFormat }}
            </template>
          </el-table-column>
          <el-table-column align="center" width="100">
            <template v-slot="fileProps">
              <el-button v-if="!fileProps.row.isFolder" type="text" style="font-weight: bold;" @click="checkFileStatusBeforeDownload(fileProps.row)">Download</el-button>
              <span v-else> - </span>
            </template>
          </el-table-column>
        </el-table>
      </el-row>
    </el-dialog>

    <FileObjUploader ref="uploaderRef" :displayFileList="false" :relatedDataFormFieldName="fieldName" :enabled="enaleUploader"></FileObjUploader>
  </section>
</template>
<script>
import { mapState, mapMutations } from 'vuex'
import { EVENT_CONFIG } from '@/event_bus'
import { FILE_TYPE } from '@/components/file_component/fileconfig'
import FileObjUploader from '@/components/file_component/FileObjUploader'

export default {
  props: { fieldName: String, formData: Object, isCommon: Boolean, creator: Object, targetValue: { type: [Number, String] }, enaleUploader: { type: Boolean, default: true } },
  data () {
    return {
      fileListFlag: false,
      currentUser: {},
      fileProgressList: [],
      addedFieldFielRelationList: [],
      fileLoading: false,
      fileSearchKeyword: '',
      showAddFileFlag: false,
      searchFileFlag: false,
      searchLoading: false,
      showFileProgressFlag: false,
      fileOptions: [],
      fileTableList: [],
      selectedFileTableRows: [],
      paginationInfo: {
        currentPage: 1,
        pageSize: 5,
        totalCount: 0
      },
      subFileList: [],
      subfileLoading: false,
      projectTargetVal: null,
      searchType: 'false'
    }
  },
  components: {
    FileObjUploader
  },
  filters: {
    fileSizeFormat (file) {
      console.debug('file name: %s and file size %s', file.fileName, file.fileSize)
      if (file.fileSize === null) return '-'
      if (file.fileSize < 1024) {
        // lower than 1024, the unit is 'Bytes'
        return file.fileSize + ' Bytes'
      }
      if (file.fileSize < (1024 * 1024)) {
        // bigger than 1024 and smaller than 1024*1024, the unit is 'KB'
        const _kb = (file.fileSize / 1024).toFixed(2)
        return parseFloat(_kb) + ' KB'
      }

      if (file.fileSize < (1024 * 1024 * 1024)) {
        // bigger than 1024*1024 and smaller than 1024*1024*1024, the unit is 'MB'
        const _mb = (file.fileSize / (1024 * 1024)).toFixed(2)
        return parseFloat(_mb) + ' MB'
      }

      const _gb = (file.fileSize / (1024 * 1024 * 1024)).toFixed(2)
      return parseFloat(_gb) + ' GB'
    },
    showFileType (file) {
      if (file.isFolder) {
        return FILE_TYPE.FOLDER.typeName
      }

      const fileType = FILE_TYPE.getFileType(file.fileName)
      return fileType.typeName
    }
  },
  computed: {
    ...mapState({
      writeSwitch: (state) => state.writableStatus,
      dataFormFieldFileRelationMap: (state) => state.FileUploadModule.fieldFileMap
    })
  },
  async mounted () {
    EVENT_CONFIG.EventBus.$on(EVENT_CONFIG.FILE_PROGRESS_UPDATE_EVENT, this.updateFileProgress)
    EVENT_CONFIG.EventBus.$on(EVENT_CONFIG.FILE_PROGRESS_REMOVE_EVENT, this.removeFileProgress)
    EVENT_CONFIG.EventBus.$on(EVENT_CONFIG.FILE_COMPLETE_EVENT, this.fileCompleteHandler)
    EVENT_CONFIG.EventBus.$on(EVENT_CONFIG.DATA_PROJECT_CHANGE_EVENT, this.dataProjectChangeHandler)
    this.currentUser = await this.$userStorage.getCurrentUser()

    this.projectTargetVal = this.targetValue
  },
  beforeDestroy () {
    console.debug('FileFormField.vue beforeDestory method executes.....')
    EVENT_CONFIG.EventBus.$off(EVENT_CONFIG.FILE_PROGRESS_UPDATE_EVENT)
    EVENT_CONFIG.EventBus.$off(EVENT_CONFIG.FILE_PROGRESS_REMOVE_EVENT)
    EVENT_CONFIG.EventBus.$off(EVENT_CONFIG.FILE_COMPLETE_EVENT)
    EVENT_CONFIG.EventBus.$off(EVENT_CONFIG.DATA_PROJECT_CHANGE_EVENT)
  },
  watch: {
    selectedFileTableRows (newSelectedFiles, oldSelectedFiles) {
      if (this.searchFileFlag) {
        console.log('old selected files', oldSelectedFiles)
        console.log('new selected files', newSelectedFiles)
        for (const oldSelectedFile of oldSelectedFiles) {
          const selectedFileIndex = newSelectedFiles.findIndex(newSelectedFile => {
            if (oldSelectedFile.fileUniqueID === newSelectedFile.fileUniqueID) {
              return true
            }
          })

          // the 'selectedFileIndex' is -1 means the file is not selected, so we need to remove the relation
          if (selectedFileIndex === -1) {
            const fieldFileRelation = {
              fieldName: this.fieldName,
              fileUniqueID: oldSelectedFile.fileUniqueID
            }

            this.removeFieldFileRelation(fieldFileRelation)
          }
        }

        for (const newSelectedFile of newSelectedFiles) {
          const fieldFileRelation = {
            fieldName: this.fieldName,
            fileUniqueID: newSelectedFile.fileUniqueID,
            fileName: newSelectedFile.fileName,
            fileProgress: 1,
            isFolder: newSelectedFile.isFolder,
            fileProgressFlag: false
          }
          this.addFieldFileRelation(fieldFileRelation)
        }

        this.addedFieldFielRelationList = this.dataFormFieldFileRelationMap.get(this.fieldName)
      }
    }
  },
  destroyed () {
    this.emptyFieldFileRelation(this.fieldName)
    this.formData[this.fieldName] = []
  },
  methods: {
    ...mapMutations({
      addFieldFileRelation: 'FileUploadModule/addFieldFileRelation',
      removeFieldFileRelation: 'FileUploadModule/removeFieldFileRelation',
      emptyFieldFileRelation: 'FileUploadModule/emptyFieldFileRelation'
    }),
    formattFileProgressText (percentage) {
      if (percentage === 0) return 'Initializing, please wait...'

      if (percentage === 100) return 'Merging file chunks, please wait...'

      percentage = percentage.toFixed(2)
      return percentage + '%'
    },
    toggleFileRowSelection (fileUniqueID) {
      this.fileTableList.forEach(fileRow => {
        if (fileRow.fileUniqueID === fileUniqueID) {
          console.log('file uniqueID:', fileUniqueID)
          console.log('file info:', fileRow)
          this.$refs.fileTable.toggleRowSelection(fileRow)
        }
      })
    },
    showAddFileDialog () {
      if (this.projectTargetVal === null || this.projectTargetVal === undefined || this.projectTargetVal.trim === '') {
        this.$message({
          message: 'please select one project',
          type: 'warning',
          showClose: true
        })

        return
      }

      this.showAddFileFlag = true
      const uploadedFileList = this.formData[this.fieldName]
      if (uploadedFileList) {
        for (const existedFile of uploadedFileList) {
          const fieldFileRelation = {
            fieldName: this.fieldName,
            fileUniqueID: existedFile.fileUniqueID,
            fileName: existedFile.fileName,
            fileProgress: 1,
            isFolder: existedFile.isFolder,
            fileProgressFlag: false
          }
          console.log('add new field file relation: ', fieldFileRelation)
          this.addFieldFileRelation(fieldFileRelation)
        }
      }

      this.addedFieldFielRelationList = this.dataFormFieldFileRelationMap.get(this.fieldName)
    },
    beforeCloseAddFileDialog (done) {
      this.$confirm('Quit adding files?', {
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        closeOnClickModal: false,
        closeOnPressEscape: false
      }).then(_ => {
        done()
      }).catch(_ => {
        console.log('cancel to close the adding related files dialog')
      })
    },
    closeAddFileDialog () {
      this.showAddFileFlag = false
      this.searchFileFlag = false
      this.showFileProgressFlag = false
    },
    selectionChangeHandler (rows) {
      console.log('selection handler method selected rows:', rows)
      this.selectedFileTableRows = rows
    },
    handleCurrentChange (newCurrentPage) {
      this.paginationInfo.currentPage = newCurrentPage
      this.searchFileListHandler()
    },
    selectedRowClass (tableRow) {
      const selectedRow = this.selectedFileTableRows.find(selectedRow => selectedRow.id === tableRow.row.id)
      if (selectedRow) {
        return 'selected-row'
      }
      return ''
    },
    toFileUplaodView () {
      this.searchFileFlag = false
      this.fileTableList = []
      this.$refs.fileTable.clearSelection()
    },
    async searchFileListHandler () {
      // this.searchLoading = true
      this.fileLoading = true
      console.log('search file list keyword: ', this.fileSearchKeyword)
      let urlParams = this.$qs.stringify(this.paginationInfo)
      if (urlParams === '') {
        urlParams = 'projectID=' + this.projectTargetVal + '&isFolder=' + this.searchType
      } else {
        urlParams += '&projectID=' + this.projectTargetVal + '&isFolder=' + this.searchType
      }

      const { data: searchResp } = await this.$http.post(this.serverAPI.FILE_SEARCH_URL + this.fileSearchKeyword + '?' + urlParams)
      console.log('search file list response content: ', searchResp)
      // this.fileOptions = searchResp.data
      // this.searchLoading = false
      console.log('selected rows', this.selectedFileTableRows)
      // set the select to the local sessin storage

      this.fileTableList = searchResp.data.records
      for (const file of this.fileTableList) {
        file.rowkey = file.id + '-' + new Date().getTime()
      }
      this.paginationInfo.totalCount = searchResp.data.total
      this.fileLoading = false

      const fieldFileRelations = this.dataFormFieldFileRelationMap.get(this.fieldName)
      if (fieldFileRelations) {
        const selectedFileUniqueIDs = fieldFileRelations.map(fieldFielRelation => {
          return fieldFielRelation.fileUniqueID
        })

        console.log('file search handler selectedFileUuniqueIDs', selectedFileUniqueIDs)

        selectedFileUniqueIDs.forEach(selectedFileUniqueID => {
          this.toggleFileRowSelection(selectedFileUniqueID)
        })
      }
    },
    getFileIcon (fieldFileRelation) {
      let fileType
      if (fieldFileRelation.isFolder) {
        fileType = FILE_TYPE.FOLDER
      } else {
        fileType = FILE_TYPE.getFileType(fieldFileRelation.fileName)
      }

      const iconPath = require('@/' + `${fileType.typeIcon}`)
      return iconPath
    },
    fileUploadHandler () {
      console.log('click file field name=%s', this.fieldName)
      const fileUploadParam = {
        fieldName: this.fieldName,
        uploadType: 'file',
        target: this.projectTargetVal
      }
      EVENT_CONFIG.EventBus.$emit(EVENT_CONFIG.FILE_UPLOAD_EVENT, fileUploadParam)
    },
    folderUploadHandler () {
      console.log('click folder field name=%s', this.fieldName)
      const fileUploadParam = {
        fieldName: this.fieldName,
        uploadType: 'folder',
        target: this.projectTargetVal
      }
      EVENT_CONFIG.EventBus.$emit(EVENT_CONFIG.FILE_UPLOAD_EVENT, fileUploadParam)
    },
    async fileTypeChangeHandler () {
      this.searchFileFlag = true

      this.paginationInfo.currentPage = 1
      await this.searchFileListHandler()
    },
    async fileSearchHandler () {
      this.searchFileFlag = true

      this.paginationInfo.currentPage = 1
      await this.searchFileListHandler()
    },
    searchedFileRemoveHandler (searchedFileFieldRelation) {
      console.log('remove searched file field relation:', searchedFileFieldRelation)
      const fileUniqueID = searchedFileFieldRelation.fileUniqueID
      const deleteFileIndex = this.selectedFileTableRows.findIndex(selectedFile => {
        if (fileUniqueID === selectedFile.fileUniqueID) {
          return true
        }
      })

      if (deleteFileIndex >= 0) {
        this.toggleFileRowSelection(fileUniqueID)
      } else {
        this.removeFieldFileRelation(searchedFileFieldRelation)
      }
    },
    invokeFileRemoveHandler (fieldFileRelation) {
      EVENT_CONFIG.EventBus.$emit(EVENT_CONFIG.FILE_REMOVE_EVENT, fieldFileRelation)
    },
    removeFileProgress (progressInfo) {
      if (progressInfo && this.enaleUploader) {
        const pregressFieldName = progressInfo.fieldName
        if (pregressFieldName !== this.fieldName) {
          return
        }
        console.log('remove the file progress information')

        const progressInfoIndex = this.fileProgressList.findIndex(fileProgress => {
          if (fileProgress.fileUniqueID === progressInfo.fileUniqueID &&
              fileProgress.fileName === progressInfo.fileName) {
            return true
          }
        })

        if (progressInfoIndex >= 0) {
          this.showFileProgressFlag = false
          this.fileProgressList.splice(progressInfoIndex, 1)
        }
      }
    },
    /**
     * progressInfo should have:
     *    'fieldName' is String type
     *    'fileUniqueID' is String type
     *    'fileName' is String type
     *    'fileProgress' is Numberic type
     *    'isFolder' is Boolean type
     *    'fileProgressFlag' is Boolean type
     */
    updateFileProgress (progressInfo) {
      if (progressInfo && this.enaleUploader) {
        const pregressFieldName = progressInfo.fieldName
        if (pregressFieldName !== this.fieldName) {
          return
        }

        const progressInfoIndex = this.fileProgressList.findIndex(fileProgress => {
          if (fileProgress.fileUniqueID === progressInfo.fileUniqueID &&
              fileProgress.fileName === progressInfo.fileName) {
            return true
          }
        })

        if (progressInfoIndex >= 0) {
          this.fileProgressList.splice(progressInfoIndex, 1)
        }

        const showProgressFlag = progressInfo.fileProgressFlag
        if (showProgressFlag) {
          this.fileProgressList.push(progressInfo)
        }
      }
    },
    fileCompleteHandler (fileComplete) {
      if (this.enaleUploader) {
        const completeFieldName = fileComplete.fieldName
        if (completeFieldName === this.fieldName) {
          console.log('handle the file completion:', fileComplete)
          const fileProgressIndex = this.fileProgressList.findIndex(fileProgress => {
            if (fileProgress.fileUniqueID === fileComplete.fileUniqueID) {
              return true
            }
          })

          if (fileProgressIndex >= 0) {
            this.fileProgressList.splice(fileProgressIndex, 1)
          }

          this.addFieldFileRelation(fileComplete)
          this.addedFieldFielRelationList = this.dataFormFieldFileRelationMap.get(this.fieldName)
          if (!this.showAddFileFlag) {
            this.formData[this.fieldName] = this.addedFieldFielRelationList
            EVENT_CONFIG.EventBus.$emit(EVENT_CONFIG.CHECK_FORM_FIELD_EVENT, this.isCommon, this.fieldName)
          }
        }
      }
    },
    dataProjectChangeHandler (targetProjectID) {
      if (this.projectTargetVal && this.projectTargetVal !== targetProjectID) {
        this.formData[this.fieldName] = []
      }

      this.projectTargetVal = targetProjectID
    },
    confirmToAddSelectedFiles () {
      console.log('confirm to add the selected file to the data form object')
      this.showAddFileFlag = false
      console.log('selected file list: ', this.addedFieldFielRelationList)

      console.log('data form object: ', this.formData)
      this.formData[this.fieldName] = this.addedFieldFielRelationList

      if (this.fileProgressList.length > 0) {
        return
      }

      EVENT_CONFIG.EventBus.$emit(EVENT_CONFIG.CHECK_FORM_FIELD_EVENT, this.isCommon, this.fieldName)
    },
    async checkFileStatusBeforeDownload (fileObj) {
      console.log('before download file, check the file status fileUniqueID=%s fileID=%s', fileObj.fileUniqueID, fileObj.id)
      let checkFileStatusURL = ''
      if (this.creator.id === this.currentUser.id) {
        checkFileStatusURL = this.serverAPI.FILE_STATUS_URL + fileObj.fileUniqueID + '/' + this.projectTargetVal + '/' + this.currentUser.id
      } else {
        checkFileStatusURL = this.serverAPI.FILE_STATUS_URL + fileObj.fileUniqueID + '/' + this.projectTargetVal + '/' + this.creator.id
      }
      const { data: checkResp } = await this.$http.get(checkFileStatusURL)
      console.log('check file status response: ', checkResp)
      if (checkResp.code === 1) {
        if (!checkResp.data.isFolder) {
          const beforeunloadFunc = window.onbeforeunload
          console.debug('FileFormField.vue beforeunload function:', beforeunloadFunc)
          window.onbeforeunload = null
          window.location.href = this.serverAPI.FILE_DOWNLOAD_URL + checkResp.data.id
          window.onbeforeunload = beforeunloadFunc
          return
        }
        console.log('this is a folder')
        this.fileListFlag = true
        // TODO: 显示文件列表
        this.subfileLoading = true
        const subFileList = await this.getSubFile(checkResp.data.id)
        this.subFileList = subFileList
        this.subfileLoading = false
      } else {
        this.$alert('<font color="red">' + checkResp.msg + '</font>', 'File Download Notification', {
          dangerouslyUseHTMLString: true
        })
      }
    },
    async getSubFile (fileID) {
      const { data: subFileResp } = await this.$http.get(this.serverAPI.FILE_SUBLIST_URL + fileID)
      console.log('sub file list response: ', subFileResp)
      const subFileList = subFileResp.data
      for (const subFile of subFileList) {
        subFile.rowkey = subFile.id + '-' + fileID + '-' + new Date().getTime()
      }
      return subFileList
    },
    async load (file, tableTreeCof, resolve) {
      const subFileList = await this.getSubFile(file.id)
      resolve(subFileList)
    }
  }
}
</script>
<style scoped>
.no-file {
  padding-left: 15px;
  border: 1px solid #DCDFE6;
  border-radius: 4px;
  height: 40px;
  line-height: 40px;
  color: #FFC4CC;
}
.file-name {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
  max-width: 150px;
  height: 32px;
  vertical-align: middle;
}
.file-name:hover {
  cursor: pointer;
  text-decoration:underline;
  font-weight: bold;
}
div >>> div.el-dialog__body {
  padding-top: 0px;
  padding-bottom: 0px;
}
div >>> .el-table .selected-row td {
  background-color: #f0f9eb !important;
}
div >>> th{
  line-height: 28px;
  font-weight: bold;
}

div >>> .el-progress-bar__innerText {
  color:black;
}
</style>
