<template>
  <section>
    <el-card>
      <div slot="header" class="clearfix">
        <span>Book Information</span>
      </div>
      <el-form label-position="top" id="bookDataForm" ref="bookDataFormRef" :model="paperDataForm" :rules="bookDataFormRules" @validate="afterBookFormValidate">
        <el-row :gutter="20" style="margin-bottom: 5px;">
          <el-col :lg="12" :md="24">
            <el-form-item label="Publication Type" class="data-form-label" prop="paperType.typeCode">
              <el-select :disabled="!writeSwitch" v-if="isAdd" v-model="paperDataForm.paperType.typeCode" @change="paperTypeChangeHandler" style="width: 100%;" name="paperType" clearable>
                <el-option v-for="(paperType, index) in paperTypeOptions" :key="index" :value="paperType.value" :label="paperType.label"></el-option>
              </el-select>
              <el-input v-else :value="paperDataForm.paperType.typeName" readonly></el-input>
            </el-form-item>
          </el-col>
          <el-col :lg="12" :md="24">
            <el-form-item label="Publication Status" class="data-form-label" prop="paperStatus.statusCode">
              <el-select :disabled="!writeSwitch" v-if="paperDataForm.uploader.id === currentUser.id" v-model="paperDataForm.paperStatus.statusCode" style="width: 100%;" name="paperStatus" clearable>
                <el-option v-for="(paperStatus, index) in paperStatusOptions" :key="index" :value="paperStatus.value" :label="paperStatus.label"></el-option>
              </el-select>
              <el-input v-else :value="paperDataForm.paperStatus.statusName" readonly></el-input>
            </el-form-item>
          </el-col>
          <el-col :lg="12" :md="24">
            <el-form-item label="Publication Title" class="data-form-label" prop="paperTitle">
              <el-input :disabled="!writeSwitch" v-model="paperDataForm.paperTitle" style="width: 100%;" name="paperTitle" clearable :readonly="!(paperDataForm.uploader.id === currentUser.id)"></el-input>
            </el-form-item>
          </el-col>
          <el-col :lg="12" :md="24">
            <el-form-item label="Publication DOI" class="data-form-label" prop="paperDOI">
              <el-input :disabled="!writeSwitch" v-model="paperDataForm.paperDOI" style="width: 100%;" name="paperDOI" clearable :readonly="!(paperDataForm.uploader.id === currentUser.id)"></el-input>
            </el-form-item>
          </el-col>
          <el-col :lg="12" :md="24">
            <el-form-item label="Publication URL" class="data-form-label" prop="paperURL">
              <el-input :disabled="!writeSwitch" v-model="paperDataForm.paperURL" style="width: 100%;" name="paperURL" clearable :readonly="!(paperDataForm.uploader.id === currentUser.id)"></el-input>
            </el-form-item>
          </el-col>
          <el-col :lg="12" :md="24">
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="Year" class="data-form-label" prop="paperYear">
                  <el-select :disabled="!writeSwitch" v-if="paperDataForm.uploader.id === currentUser.id" v-model="paperDataForm.paperYear" style="width: 100%;" name="paperYear" clearable>
                    <el-option v-for="(i, index) in (yearOptions.endYear - yearOptions.startYear + 1)" :key="index" :value="yearOptions.endYear - index" :label="yearOptions.endYear - index"></el-option>
                  </el-select>
                  <el-input v-else :value="paperDataForm.paperYear" readonly></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Month" class="data-form-label" prop="paperMonth">
                  <el-select :disabled="!writeSwitch" v-if="paperDataForm.uploader.id === currentUser.id" v-model="paperDataForm.paperMonth" style="width: 100%;" name="paperMonth" clearable>
                    <el-option v-for="(month, index) in monthOptions" :key="index" :value="month.value" :label="month.label"></el-option>
                  </el-select>
                  <el-input v-else :value="paperDataForm.paperMonth" readonly></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
          <el-col :lg="12" :md="24">
            <el-form-item label="Book ISBN" class="data-form-label" prop="paperBookISBN">
              <el-input :disabled="!writeSwitch" v-model="paperDataForm.paperBookISBN" style="width: 100%;" name="paperBookISBN" clearable :readonly="!(paperDataForm.uploader.id === currentUser.id)"></el-input>
            </el-form-item>
          </el-col>
          <el-col :lg="12" :md="24">
            <el-form-item label="Volume" class="data-form-label" prop="paperVolume">
              <el-input :disabled="!writeSwitch" v-model="paperDataForm.paperVolume" style="width: 100%;" name="paperVolume" clearable :readonly="!(paperDataForm.uploader.id === currentUser.id)"></el-input>
            </el-form-item>
          </el-col>
          <el-col :lg="12" :md="24">
            <el-form-item label="Editor" class="data-form-label" prop="paperBookEditor">
              <el-input :disabled="!writeSwitch" v-model="paperDataForm.paperBookEditor" style="width: 100%;" name="paperBookEditor" clearable :readonly="!(paperDataForm.uploader.id === currentUser.id)"></el-input>
            </el-form-item>
          </el-col>
          <el-col :lg="12" :md="24">
            <el-form-item label="Publisher" class="data-form-label" prop="paperBookPublisher">
              <el-input :disabled="!writeSwitch" v-model="paperDataForm.paperBookPublisher" style="width: 100%;" name="paperBookPublisher" clearable :readonly="!(paperDataForm.uploader.id === currentUser.id)"></el-input>
            </el-form-item>
          </el-col>
          <el-col :lg="12" :md="24" v-if="(paperDataForm.paperRelatedProject!==null&&paperDataForm.paperRelatedProject.length>0) || (paperDataForm.uploader.id === currentUser.id)">
            <el-form-item label="Related Projects" class="data-form-label" prop="paperRelatedProject">
              <PaperRelatedProject :paperForm="paperDataForm" :btnsVisible="paperDataForm.uploader.id === currentUser.id"></PaperRelatedProject>
            </el-form-item>
          </el-col>
          <el-col :lg="12" :md="24" v-if="(paperDataForm.paperRelatedData!==null&&paperDataForm.paperRelatedData.length>0) || (paperDataForm.uploader.id === currentUser.id)">
            <el-form-item label="Related Data" class="data-form-label" prop="paperRelatedData">
              <PaperRelatedData :paperForm="paperDataForm" :btnsVisible="paperDataForm.uploader.id === currentUser.id"></PaperRelatedData>
            </el-form-item>
          </el-col>
          <el-col :lg="12" :md="24">
            <el-form-item label="Publication File" class="data-form-label" prop="paperFile">
              <FileFormField fieldName="paperFile" :formData="paperDataForm" :creator="paperDataForm.uploader" :targetValue="'0'"></FileFormField>
            </el-form-item>
          </el-col>
          <el-col :lg="12" :md="24">
            <el-form-item label="File Download Permission" prop="downloadSwitch">
              <el-radio-group :disabled="!writeSwitch" v-if="paperDataForm.uploader.id === currentUser.id" v-model="paperDataForm.downloadSwitch">
                <el-radio :label="true">Open Access</el-radio>
                <el-radio :label="false">Only Internal Access</el-radio>
              </el-radio-group>
              <el-tag v-else>
                {{paperDataForm.downloadSwitch==="true" || paperDataForm.downloadSwitch===true? 'Open Access': 'Only Internal Access'}}
              </el-tag>
            </el-form-item>
          </el-col>
          <el-col :lg="12" :md="24">
            <el-form-item label="Related Authors" class="data-form-label" prop="paperRelatedProjectAuthors">
              <el-input :disabled="!writeSwitch" type="textarea" :rows="6" resize="none" v-model="paperDataForm.paperRelatedProjectAuthors" name="paperRelatedProjectAuthors" clearable :readonly="!(paperDataForm.uploader.id === currentUser.id)"></el-input>
              <div class="field-prompt">Please use commas(", ") to separate different names e.g: name1, name2</div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="paperDataForm.uploader.id === currentUser.id && writeSwitch" style="margin-top: 15px;" type="flex" justify="end">
          <el-col :span="4" :offset="22" style="padding-right: 15px">
            <el-button style="float: right;" @click="resetPaperFormData" :disabled="!bookFormChange"> Reset </el-button>
          </el-col>
          <el-col :span="2">
            <el-button v-if="paperID!==null && paperID!==undefined" type="primary" @click="updateBookFormData" :disabled="!bookFormChange">Update</el-button>
            <el-button v-else type="primary" @click="submitBookFormData" :disabled="!bookFormChange">Submit</el-button>
          </el-col>
        </el-row>
      </el-form>
    </el-card>

    <PaperDetailHistoryList v-if="paperID !== null && paperID !== undefined" :relatedHistory="paperRelatedHistory"></PaperDetailHistoryList>
  </section>
</template>
<script>
import { mapState } from 'vuex'
import { PAPER_FUNCS } from './paper_res/PaperFormFunctions'
import { EVENT_CONFIG } from '@/event_bus'
import PaperRelatedProject from '@/components/paper_component/paper_fields/PaperRelatedProject.vue'
import PaperRelatedData from '@/components/paper_component/paper_fields/PaperRelatedData.vue'
import JsSHA from 'jssha'
import { formValidator } from '@/plugins/formValidator'
import alertCountdown from '@/plugins/alertCountdown.js'
import FileFormField from '@/components/data_component/FileFormField.vue'
import { DRAFT_GENERATOR } from '@/local_storage/DraftGenerator'
import PaperDetailHistoryList from '@/components/paper_component/PaperDetailHistoryList.vue'

export default {
  props: ['isAdd', 'paperID', 'monthOptions', 'yearOptions', 'paperTypeOptions', 'paperStatusOptions'],
  components: {
    PaperRelatedProject,
    PaperRelatedData,
    FileFormField,
    PaperDetailHistoryList
  },
  async mounted () {
    console.log('init book type paper detail page')
    const loadingInstance = this.$loading.service({
      lock: true,
      text: 'Paper information is loading...'
    })

    window.onbeforeunload = (event) => {
      this.bookFormChange = formValidator.formDataChangeValidate(this.paperDataForm, this.bookFormDigest)
      if (this.bookFormChange) {
        DRAFT_GENERATOR.storePublicationDraft(this.paperDataForm)
        return 'confirm to refresh?'
      }
    }

    this.currentUser = await this.$userStorage.getCurrentUser()
    if (this.isAdd) {
      this.paperDataForm.uploader = this.currentUser
    }
    await this.getBookPaperDetail()

    const bookPaperDraft = await DRAFT_GENERATOR.getPublicationDraft(this.paperDataForm.id)
    if (bookPaperDraft && bookPaperDraft.paperType.typeCode === 2) {
      const toValidateForm = await this.$confirm('Load the publication draft?', {
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        closeOnClickModal: false,
        closeOnPressEscape: false
      }).then(_ => {
        console.log('to load book type paper draft')
        this.paperDataForm = bookPaperDraft
        return true
      }).catch(_ => {
        console.log('not to load book type paper draft')
      }).finally(_ => {
        DRAFT_GENERATOR.removePublicationDraft(this.paperDataForm.id)
      })

      if (toValidateForm) {
        const bookFormRef = this.$refs.bookDataFormRef
        const validateBookFormResult = await formValidator.formValidate(bookFormRef)
        console.log('after reload from draft vlidate result: ', validateBookFormResult)
      }
    }

    EVENT_CONFIG.EventBus.$on(EVENT_CONFIG.CHECK_FORM_FIELD_EVENT, this.checkFormDataField)
    loadingInstance.close()
  },
  computed: {
    ...mapState({
      writeSwitch: (state) => state.writableStatus
    })
  },
  watch: {
    bookFormChange (newValue) {
      console.log('newvalue of the bookFormChange:', newValue)
      EVENT_CONFIG.EventBus.$emit(EVENT_CONFIG.PAPER_FORM_CHANGE_EVENT, newValue)
    }
  },
  beforeDestroy () {
    console.log('PaperBook.vue beforeDestroy method executes...')
    window.onbeforeunload = null
    EVENT_CONFIG.EventBus.$off(EVENT_CONFIG.CHECK_FORM_FIELD_EVENT)
  },
  data () {
    const checkPaperTitle = async (rule, value, callback) => {
      console.log('check book paper title')
      if (!value) {
        callback(new Error('paper title is required'))
        return
      }
      const checkPaperTitleResp = await PAPER_FUNCS.checkPaperTitle(value, this.paperID)
      console.log('check paper title response content: ', checkPaperTitleResp)
      if (checkPaperTitleResp.code === 1) {
        if (!checkPaperTitleResp.data) {
          return callback()
        } else {
          callback(new Error('Paper title exist, please try another one'))
        }
      } else {
        callback(new Error('Checking paper title existing error, please try later'))
      }
    }

    return {
      formToken: '', // formToken to prevent the repeated submission
      bookFormChange: false, // the flag of the data form change
      bookFormDigest: '',
      currentUser: { id: '' },
      paperRelatedHistory: [],
      paperDataForm: {
        paperType: {
          typeCode: 2
        },
        paperStatus: {
          statusCode: ''
        },
        paperTitle: '',
        paperDOI: '',
        paperURL: '',
        paperYear: '',
        paperMonth: '',
        paperBookISBN: '',
        paperVolume: '',
        paperFile: [],
        paperBookEditor: '',
        paperBookPublisher: '',
        paperRelatedProjectAuthors: '',
        paperRelatedProject: [],
        paperRelatedData: [],
        downloadSwitch: false,
        uploader: { id: '' }
      },
      bookDataFormRules: {
        'paperType.typeCode': [
          { required: true, message: 'paper type is required', trigger: 'change' }
        ],
        'paperStatus.statusCode': [
          { required: true, message: 'paper status is required', trigger: 'change' }
        ],
        paperTitle: [
          { required: true, message: 'paper title is required', trigger: 'change' },
          { validator: checkPaperTitle, trigger: 'blur' }
        ],
        paperDOI: [
          { required: false, message: 'paper doi', trigger: 'change' },
          { validator: PAPER_FUNCS.checkPaperDOI, trigger: 'blur' }
        ],
        paperURL: [
          { required: false, message: 'paper url', trigger: 'change' },
          { validator: PAPER_FUNCS.checkPaperURL, trigger: 'blur' }
        ],
        paperYear: [
          { required: true, message: 'paper published year is required', trigger: 'change' }
        ],
        paperMonth: [
          { required: false, message: 'paper month', trigger: 'change' }
        ],
        paperBookISBN: [
          { required: false, message: 'paper book ISBN', trigger: 'change' }
        ],
        paperVolume: [
          { required: false, message: 'paper volume', trigger: 'change' }
        ],
        paperBookEditor: [
          { required: true, message: 'paper journal editor name is required', trigger: 'change' }
        ],
        paperBookPublisher: [
          { required: true, message: 'paper journal publisher is required', trigger: 'change' }
        ],
        paperRelatedProject: [
          { required: false, message: 'publication related projects is null', trigger: 'change' }
        ],
        paperRelatedData: [
          { required: false, message: 'publication related data is null', trigger: 'change' }
        ],
        paperRelatedProjectAuthors: [
          { required: true, message: 'paper related author is required', trigger: 'change' }/* ,
          { validator: PAPER_FUNCS.changePaperAuthor, trigger: 'blur' } */
        ],
        paperFile: [
          { required: false, message: 'publication related files is null', trigger: 'change' }
        ],
        downloadSwitch: [
          { required: true, message: 'paper file download switch is required', trigger: 'change' }
        ]
      }
    }
  },
  methods: {
    async getBookPaperDetail () {
      if (this.paperID !== null && this.paperID !== undefined) {
        const paperDetailResp = await PAPER_FUNCS.getPaperDetail(this.paperID)
        console.log('paper detail response content: ', paperDetailResp)
        this.formToken = paperDetailResp.headers['form-token']
        const paperDetail = paperDetailResp.data
        if (paperDetail.code === 1) {
          this.paperDataForm = paperDetail.data
          this.paperRelatedHistory = paperDetail.data.relatedHistories

          if (this.paperDataForm.paperDOI === null) {
            this.paperDataForm.paperDOI = ''
          }

          if (this.paperDataForm.paperURL === null) {
            this.paperDataForm.paperURL = ''
          }

          if (this.paperDataForm.paperMonth === null) {
            this.paperDataForm.paperMonth = ''
          }

          if (this.paperDataForm.paperVolume === null) {
            this.paperDataForm.paperVolume = ''
          }

          if (this.paperDataForm.paperBookISBN === null) {
            this.paperDataForm.paperBookISBN = ''
          }

          if (this.paperDataForm.downloadSwitch === null) {
            this.paperDataForm.downloadSwitch = false
          }
        } else {
          this.$alert(paperDetailResp.msg, 'book paper detail page error', {
            confirmButtonText: 'OK',
            center: true
          })
        }
      } else {
        const paperTokenResp = await this.$http.get(this.serverAPI.PAPER_SUBMITTOKEN_URL)
        console.log('paper article add token response hrader: ', paperTokenResp)
        this.formToken = paperTokenResp.headers['form-token']
      }

      const startBookDigest = new Date().getTime()
      const jsSHABook = new JsSHA('SHA-256', 'TEXT')
      const bookDataFormStr = JSON.stringify(this.paperDataForm)
      console.debug('book form string: ', bookDataFormStr)
      jsSHABook.update(bookDataFormStr)
      this.bookFormDigest = jsSHABook.getHash('HEX')
      console.log('publication book form digest time cost: ', new Date().getTime() - startBookDigest)
      this.bookFormChange = false
    },
    async paperTypeChangeHandler (selectedType) {
      console.log('book paper selected paper type:', selectedType)

      if (this.bookFormChange) {
        const confirmChangePaperTYpeResult = await PAPER_FUNCS.confirmChangePaperType(this)
        if (!confirmChangePaperTYpeResult) {
          this.paperDataForm.paperType.typeCode = 2
          return
        }
      }

      this.resetPaperFormData()
      EVENT_CONFIG.EventBus.$emit(EVENT_CONFIG.PAPER_FORM_CHANGE_EVENT, false)
      EVENT_CONFIG.EventBus.$emit(EVENT_CONFIG.PAPER_TYPE_CHANGE_EVENT, selectedType)
    },
    async submitBookFormData () {
      const bookFormRef = this.$refs.bookDataFormRef
      const validateBookFormResult = await formValidator.formValidate(bookFormRef)
      console.log('validate book type publication result: ', validateBookFormResult)
      this.bookFormChange = false

      if (validateBookFormResult !== true) {
        let msgContent = ''
        const validateValues = Object.values(validateBookFormResult)
        validateValues.forEach(validateVal => {
          msgContent += validateVal[0].message + '<br>'
        })

        this.$alert('<span style="font-size: 15px; font-weight: bold;"> Exceptions in the publication form data: </span><br> <span style="color: red;">' + msgContent + '</span>', 'Notification of Publication Data', {
          dangerouslyUseHTMLString: true
        })

        return
      }

      if (validateBookFormResult === true) {
        console.log('submit the book publication:', this.bookFormChange)

        try {
          // const { data: bookResponse } = await this.$http.post('/paper/newBook', JSON.stringify(this.paperDataForm), { headers: { 'content-type': 'application/json' } })
          const { data: bookResponse } = await this.$http.post(this.serverAPI.PAPER_NEWBOOK_URL, JSON.stringify(this.paperDataForm), { headers: { 'content-type': 'application/json', 'form-token': this.formToken } })
          console.log('save book type paper response', bookResponse)

          if (bookResponse.code === 1) {
            this.$message({
              showClose: true,
              message: 'Add publication successfully!',
              center: true,
              type: 'success'
            })

            // to the data list page automatically with time countdown
            this.$router.push({ path: `/paper/detail/${bookResponse.data}/2` })
            alertCountdown(this, 'To Publication Detail', this.backToPublicationList, 5)
          } else {
            this.$alert('<font color="red">' + bookResponse.msg + '</font>', 'Publication Add Error', {
              dangerouslyUseHTMLString: true
            })

            this.bookFormChange = true
          }
        } catch (error) {
          console.log('save book type paper response error: ', error)
          this.$alert(error, 'save book paper error', {
            confirmButtonText: 'OK',
            center: true
          })
          this.bookFormChange = true
        }

        return
      }
      this.bookFormChange = true
    },
    async updateBookFormData () {
      const bookFormRef = this.$refs.bookDataFormRef
      const validateBookFormResult = await formValidator.formValidate(bookFormRef)
      console.log('update book publication page vlidate result: ', validateBookFormResult)

      if (validateBookFormResult !== true) {
        let msgContent = ''
        const validateValues = Object.values(validateBookFormResult)
        validateValues.forEach(validateVal => {
          msgContent += validateVal[0].message + '<br>'
        })

        this.$alert('<span style="font-size: 15px; font-weight: bold;"> Exceptions in the publication form data: </span><br> <span style="color: red;">' + msgContent + '</span>', 'Notification of Publication Data', {
          dangerouslyUseHTMLString: true
        })

        return
      }

      this.bookFormChange = false
      if (validateBookFormResult === true) {
        console.log('update the book publication: ', this.bookFormChange)

        try {
          // const { data: updateBookResp } = await this.$http.post('/paper/updateBook', JSON.stringify(this.bookUpdateDataForm), { headers: { 'content-type': 'application/json' } })
          const { data: updateBookResp } = await this.$http.post(this.serverAPI.PAPER_UPDATEBOOK_URL, JSON.stringify(this.paperDataForm), { headers: { 'content-type': 'application/json', 'form-token': this.formToken } })
          console.log('update book type paper response', updateBookResp)

          if (updateBookResp.code === 1) {
            this.$message({
              showClose: true,
              message: 'Update publication successfully!',
              center: true,
              type: 'success'
            })
            // to the data list page automatically with time countdown
            alertCountdown(this, 'To Publication List', this.backToPublicationList, 5)
            await this.getBookPaperDetail()
          } else {
            this.$alert('<font color="red">' + updateBookResp.msg + '</font>', 'Publication Update Error', {
              dangerouslyUseHTMLString: true
            })

            this.bookFormChange = true
          }
        } catch (error) {
          console.log('update book type paper response error: ', error)
          this.$alert(error, 'update book paper error', {
            confirmButtonText: 'OK',
            center: true
          })
        }

        return
      }
      this.bookFormChange = true
    },
    async resetPaperFormData () {
      const paperFormData = this.$refs.bookDataFormRef
      if (this.paperID === null || this.paperID === undefined) {
        console.log('reset the book type publication add page', paperFormData)
        paperFormData.resetFields()

        this.bookFormChange = formValidator.formDataChangeValidate(this.paperDataForm, this.bookFormDigest)
        return
      }

      console.log('reset the book type publication update page')
      await this.getBookPaperDetail()
      paperFormData.clearValidate()
    },
    backToPublicationList () {
      this.$router.push({
        path: '/paper/list'
      })
    },
    afterBookFormValidate (props, callback) {
      console.log('validate book form field:', props)
      console.log('book form data digest ', this.bookFormDigest)
      this.bookFormChange = formValidator.formDataChangeValidate(this.paperDataForm, this.bookFormDigest)
      console.log('book publication change: ', this.bookFormChange)
    },
    checkFormDataField (isComon, fieldName) {
      console.log('check the book form data specified field: ', fieldName)
      const bookDataFormRef = this.$refs.bookDataFormRef
      formValidator.formFieldValidate(bookDataFormRef, fieldName)
    }
  }
}
</script>
<style scoped>
form /deep/ label {
  line-height: 30px !important;
  padding: 0 !important;
}
</style>
<style scoped>
@import './paper_res/PaperStyle.css';
</style>
