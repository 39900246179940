const NOTIFICATION_KEY = 'notification'
const DOTDISPLAY_KEY = 'notificationDot'

const getNotifications = () => {
  const notificationContent = window.sessionStorage.getItem(NOTIFICATION_KEY)
  console.debug('get the notification list from session storage: ', notificationContent)
  if (notificationContent) {
    return JSON.parse(notificationContent)
  }
  return []
}

const addNotications = (newNotifs) => {
  let oldNotifs = getNotifications()
  if (oldNotifs === null || oldNotifs === undefined) {
    oldNotifs = []
  }

  const totalNotifs = oldNotifs.concat(newNotifs)
  window.sessionStorage.setItem(NOTIFICATION_KEY, JSON.stringify(totalNotifs))
}

const removeNotification = (fileUID) => {
  const notifications = getNotifications()
  const notifIndex = notifications.findIndex(notification => {
    return notification.fileUid === fileUID
  })
  console.log('remove file notification index:', notifIndex)

  notifications.splice(notifIndex, 1)
  console.log('remove file notification: ', notifications)
  window.sessionStorage.setItem(NOTIFICATION_KEY, JSON.stringify(notifications))
}

const setDot = () => {
  window.sessionStorage.setItem(DOTDISPLAY_KEY, 'true')
}

const getDotFlat = () => {
  return window.sessionStorage.getItem(DOTDISPLAY_KEY)
}

const hideDot = () => {
  window.sessionStorage.removeItem(DOTDISPLAY_KEY)
}

export const NOTIFICATION_LOCAL_STORAGE = {
  getNotifications,
  addNotications,
  removeNotification,
  setDot,
  getDotFlat,
  hideDot
}
