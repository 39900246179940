<template>
  <section>
    <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>Home</el-breadcrumb-item>
        <el-breadcrumb-item>Publication Module</el-breadcrumb-item>
        <el-breadcrumb-item>Publication Detail</el-breadcrumb-item>
    </el-breadcrumb>
    <div style="margin-top: 15px; margin-bottom: 10px;">
      <el-button type="primary" plain style="margin-right: 10px;" @click="backToList">Back</el-button>
      <font style="font-size: 20px;">Publication Detail</font>
    </div>

    <!--article form start-->
    <PaperArticleForm ref="paperFormRef" v-if="articleUpdateFormVisible" :isAdd="false" :paperID="paperID"
      :monthOptions="months" :yearOptions="years" :paperStatusOptions="paperStatuses"></PaperArticleForm>
    <!--article form end-->

    <!--book form start-->
    <PaperBookForm ref="paperFormRef" v-if="bookUpdateFormVisible" :isAdd="false" :paperID="paperID"
      :monthOptions="months" :yearOptions="years" :paperStatusOptions="paperStatuses"></PaperBookForm>
    <!--book form end-->

    <!--inBook form start-->
    <PaperInBookForm ref="paperFormRef" v-if="inbookUpdateFormVisible" :isAdd="false" :paperID="paperID"
      :monthOptions="months" :yearOptions="years" :paperStatusOptions="paperStatuses"></PaperInBookForm>
    <!--inBook form end-->

    <!--thesis form start-->
    <PaperThesisForm ref="paperFormRef" v-if="thesisUpdateFormVisible" :isAdd="false" :paperID="paperID"
      :monthOptions="months" :yearOptions="years" :paperStatusOptions="paperStatuses"></PaperThesisForm>
    <!--thesis form end-->

    <!--draft form start-->
    <PaperManuscriptForm ref="paperFormRef" v-if="unpublishedUpdateFormVisible" :isAdd="false" :paperID="paperID"
      :monthOptions="months" :yearOptions="years" :paperStatusOptions="paperStatuses"></PaperManuscriptForm>
    <!--draft form end-->
  </section>
</template>
<script>
import { mapMutations } from 'vuex'
import { EVENT_CONFIG } from '@/event_bus'
import PaperArticleForm from '@/components/paper_component/PaperArticleForm.vue'
import PaperBookForm from '@/components/paper_component/PaperBookForm.vue'
import PaperInBookForm from '@/components/paper_component/PaperInBookForm.vue'
import PaperThesisForm from '@/components/paper_component/PaperThesisForm.vue'
import PaperManuscriptForm from '@/components/paper_component/PaperManuscriptForm.vue'
import { DRAFT_GENERATOR } from '@/local_storage/DraftGenerator'

export default {
  props: ['paperID', 'paperType'],
  async created () {
    await this.showPaperForm()

    // TODO: add the code to store the draft of editing
    EVENT_CONFIG.EventBus.$on(EVENT_CONFIG.PAPER_FORM_CHANGE_EVENT, this.paperFormChangeHandler)
    window.onunload = () => {
      this.setRouterActivePath(`/paper/detail/${this.paperID}/${this.paperType}`)
    }

    this.setRouterActivePath('/paper/list')
  },
  beforeRouteLeave (to, from, next) {
    console.log('before leaving the PaperUpdate.vue page changeFlag: ', this.paperFormChange)
    if (this.paperFormChange) {
      this.$confirm('Leave the publication update page and save the unsubmitted data as local draft?', {
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        closeOnClickModal: false,
        closeOnPressEscape: false
      }).then(_ => {
        const paperFormRef = this.$refs.paperFormRef
        console.log('confirm to leave PaperUpdate.vue: ', paperFormRef.paperDataForm)
        DRAFT_GENERATOR.storePublicationDraft(paperFormRef.paperDataForm)
        next()
      }).catch((error) => {
        console.log('cancel to leave PaperUpdate.vue', error)
        this.setRouterActivePath('/paper/list')
      })
    } else {
      next()
    }
  },
  beforeDestroy () {
    window.onunload = null
  },
  components: {
    PaperArticleForm,
    PaperBookForm,
    PaperInBookForm,
    PaperThesisForm,
    PaperManuscriptForm
  },
  data () {
    return {
      paperFormChange: false,
      articleUpdateFormVisible: false,
      bookUpdateFormVisible: false,
      inbookUpdateFormVisible: false,
      thesisUpdateFormVisible: false,
      unpublishedUpdateFormVisible: false,
      projectOptions: [],
      projectSearchSelectOptions: [],
      searchDataLoading: false,
      dataSelectOptions: [],
      dataSearchSelectOptions: [],
      diableSubmitPaperBtn: true,
      paperStatuses: [
        // { value: 1, label: 'unpublished' },
        { value: 2, label: 'submitted' },
        { value: 3, label: 'accepted' },
        { value: 4, label: 'published' }
      ],
      months: [
        { label: 'January', value: 1 },
        { label: 'Feburary', value: 2 },
        { label: 'March', value: 3 },
        { label: 'April', value: 4 },
        { label: 'May', value: 5 },
        { label: 'June', value: 6 },
        { label: 'July', value: 7 },
        { label: 'August', value: 8 },
        { label: 'September', value: 9 },
        { label: 'October', value: 10 },
        { label: 'November', value: 11 },
        { label: 'December', value: 12 }
      ],
      years: {
        startYear: 2017,
        endYear: new Date().getFullYear()
      }
    }
  },
  methods: {
    ...mapMutations({
      setRouterActivePath: 'setRouterActivePath'
    }),
    async showPaperForm () {
      if (this.paperType === '1') {
        this.articleUpdateFormVisible = true
      }

      if (this.paperType === '2') {
        this.bookUpdateFormVisible = true
      }

      if (this.paperType === '3') {
        this.inbookUpdateFormVisible = true
      }

      if (this.paperType === '4') {
        this.thesisUpdateFormVisible = true
      }

      if (this.paperType === '5') {
        this.unpublishedUpdateFormVisible = true
      }
    },
    backToList () {
      this.$router.push({
        path: '/paper/list'
      })
    },
    paperFormChangeHandler (formChange) {
      console.log('from the publication form change: ', formChange)
      this.paperFormChange = formChange
    }
  }
}
</script>
<style scoped>
div.clearfix span {
  font-size: 16px;
  font-weight: bold;
}
div.el-card__header {
  padding-top: 15px;
  padding-bottom: 5px;
  background-color: #F5F7FA;
}
div.el-form-item {
  margin-bottom: 5px;
  /* margin-top: 10px; */
}
form label {
    line-height: 30px !important;
    padding: 0 !important;
  }

.clearfix:before, .clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both
}
.input-new-tag {
  width: 30%;
  vertical-align: bottom;
}
</style>
