import { USER_LOCAL_STORAGE } from '@/local_storage/UserLocalStorage'
import axios from 'axios'
import { SERVER_API } from '@/server_api'
import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'
import Index from '@/views/Index.vue'
import DataList from '@/views/data_views/DataList.vue'
import DataAdd from '@/views/data_views/DataAdd.vue'
import DataUpdate from '@/views/data_views/DataUpdate'
import PaperList from '@/views/paper_views/PaperList.vue'
import PaperAdd from '@/views/paper_views/PaperAdd.vue'
import PaperUpdate from '@/views/paper_views/PaperUpdate.vue'
import Menu from '@/views/Menu.vue'
import UserList from '@/views/user_views/UserList.vue'
import UserRoleList from '@/views/user_views/UserRoleList.vue'
import UserRegister from '../views/UserRegister.vue'
import Project from '@/views/Project.vue'
import Field from '@/views/Field.vue'
import FileExplorer from '@/views/FileExplorer.vue'
import FileView from '@/views/FileView.vue'
import DataHistoryDetail from '@/components/history_component/DataHistoryDetail.vue'
import PaperHistoryDetail from '@/components/history_component/PaperHistoryDetail.vue'
import Publications from '@/views/Publications.vue'

Vue.use(VueRouter)

const routes = [
  // add the redirect strategy to 'Home' Component
  {
    path: '/',
    // component: Index
    component: Home
  },
  {
    path: '/index',
    name: 'Index',
    component: Index
  },
  {
    path: '/publications',
    name: 'Publications',
    component: Publications
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
    redirect: '/data/list',
    children: [
      { path: '/data/list', component: DataList },
      { path: '/data/add', component: DataAdd },
      { path: '/data/detail/:dataID', component: DataUpdate, props: true },
      { path: '/data/history/:dataID/:historyID', component: DataHistoryDetail, props: true },
      { path: '/paper/history/:paperID/:historyID', component: PaperHistoryDetail, props: true },
      { path: '/file/list', component: FileExplorer },
      { path: '/file/view', component: FileView },
      { path: '/paper/list', component: PaperList },
      { path: '/paper/add', component: PaperAdd },
      { path: '/paper/detail/:paperID/:paperType', component: PaperUpdate, props: true },
      { path: '/user/list', component: UserList },
      { path: '/user/role/list', component: UserRoleList },
      { path: '/admin/menu', component: Menu },
      { path: '/admin/project', component: Project },
      { path: '/admin/field', component: Field }
    ]
  },
  {
    path: '/user/register', name: 'userRegister', component: UserRegister
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    name: 'maintain',
    path: '/maintain',
    component: () => import('@/views/Maintain.vue')
  },
  {
    name: '404',
    path: '/404',
    component: () => import('@/views/404.vue')
  },
  {
    path: '*', // 此处需特别注意至于最底部
    redirect: '/404'
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

// router guards
router.beforeEach(async (to, from, next) => {
  console.debug('user is from path: %s to path: %s ', from.path, to.path)
  if (to.path === '/') {
    return next()
  }

  if (to.path === '/index') {
    return next()
  }

  if (to.path === '/publications' || to.path === '/publications/') {
    return next()
  }

  if (to.path === '/user/register') {
    return next()
  }

  const currentUserObj = await USER_LOCAL_STORAGE.getCurrentUser()
  if (currentUserObj != null) {
    if (currentUserObj.forbidden) {
      return next('/user/register')
    }

    return next()
  }

  if (to.path === '/home') {
    return next()
  }

  const { data: logoutResp } = await axios.get(SERVER_API.USER_LOGOUT_URL)
  USER_LOCAL_STORAGE.removeCurrentUser()
  this.$loading.service({
    lock: true,
    text: 'Loading',
    spinner: 'el-icon-loading',
    background: 'rgba(0, 0, 0, 0.7)'
  })
  console.log('logout resp', logoutResp)
  window.location.replace(logoutResp.data)
})

export default router
