const dataPageModule = {
  namespaced: true,
  state: {
    projectOptions: [],
    shareLicenceTypes: [],
    permitTypeOptions: [],
    shareModeOptions: [],
    supervisors: [],
    memberList: []
  },
  mutations: {
    initDataAddPage (state, addPageConfig) {
      state.projectOptions = addPageConfig.projectList
      state.shareLicenceTypes = addPageConfig.shareLicenseOptions
      state.permitTypeOptions = addPageConfig.permitTypeOptions
      state.shareModeOptions = addPageConfig.shareModeOptions
      state.supervisors = []
      state.memberList = []
    },
    addDataAddPageUserList (state, addPageProjectUser) {
      state.supervisors = addPageProjectUser.supervisors
      state.memberList = addPageProjectUser.memberList
    },
    initDataUpdatePage (state, updatePageConfig) {
      state.shareLicenceTypes = updatePageConfig.shareLicenseOptions
      state.permitTypeOptions = updatePageConfig.permitTypeOptions
      state.shareModeOptions = updatePageConfig.shareModeOptions
      state.supervisors = updatePageConfig.supervisors
      state.memberList = updatePageConfig.memberList
    }
  },
  actions: {}
}

export default dataPageModule
