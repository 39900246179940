<template>
  <section>
    <!--Breadcrumb navigation area-->
    <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>Home</el-breadcrumb-item>
        <el-breadcrumb-item>User Module</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card>
      <el-row v-if="currentLoginUser.adminFlag">
        <el-col>
          <el-button type="primary" @click="displayAddDialog" v-if="writeSwitch">Add UserRole</el-button>
        </el-col>
      </el-row>
      <el-table :data="roleList" :stripe="true" :border="true" :highlight-current-row="true">
        <el-table-column type="index" label="#" align="center"></el-table-column>
        <el-table-column prop="roleName" label="Role Name" align="center"></el-table-column>
        <el-table-column prop="roleAuthLevel.authName" label="Auth Level" align="center"></el-table-column>
        <el-table-column prop="roleDesc" label="Role Info" align="center" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column label="Create Time" align="center">
           <template v-slot="dataSlot">
            {{dataSlot.row.createTime | formatDate}}
          </template>
        </el-table-column>
        <el-table-column label="Actions" align="center" :width="currentLoginUser.adminFlag? 200 : 150">
          <template v-slot:="slotProps">
            <section v-if="currentLoginUser.adminFlag">
              <el-tooltip effect="dark" content="edit role" placement="top" :enterable="false">
                <el-button type="primary" icon="el-icon-edit" size="mini" @click="displayEditDialog(slotProps.row.id)"></el-button>
              </el-tooltip>
              <el-tooltip v-if="writeSwitch" effect="dark" content="delete role" placement="top" :enterable="false">
                <el-button type="danger" icon="el-icon-delete" size="mini" @click="removedUserRole(slotProps.row.id)"></el-button>
              </el-tooltip>
              <el-tooltip effect="dark" content="menu management" placement="top" :enterable="false">
                <el-button type="warning" icon="el-icon-view" size="mini" @click="showAllotMenuDialog(slotProps.row)"></el-button>
              </el-tooltip>
            </section>
            <section v-else>
              <el-tooltip effect="dark" content="view detail" placement="top" :enterable="false">
                <el-button type="primary" icon="el-icon-view" size="mini" @click="displayDetailDialog(slotProps.row.id)"></el-button>
              </el-tooltip>
            </section>
          </template>
        </el-table-column>
      </el-table>

      <!--manage the menus of the user role-->
      <el-dialog :title="'Manage ' + selectedUserRole.roleName + ' Menus'" :visible.sync="menuDialogVisible" width="50%" @close="menuDialogVisibleClose">
        <el-tree :data="menuTree" ref="menuTreeRef" :props="menuTreeProps" default-expand-all show-checkbox node-key="menuID" :default-checked-keys="checkedMenuIDs"></el-tree>
        <span slot="footer" class="dialog-footer">
          <el-button @click="menuDialogVisible = false">Cancel</el-button>
          <el-button type="primary" @click="allocateMenu" v-if="writeSwitch">Submit</el-button>
        </span>
      </el-dialog>

      <!--add role-->
      <el-dialog title="New User Role" :visible.sync="addUserRoleDialogVisible" width="50%" @close="addDialogClosed">
        <el-form :model="addUserRoleForm" :rules="userRoleFormRules" ref="addFormRef" label-width="130px" label-position="top">
          <el-form-item label="Role Name" prop="roleName">
            <el-input ref="addRoleNameRef" v-model="addUserRoleForm.roleName"></el-input>
          </el-form-item>
          <el-form-item label="Auth Scope" prop="roleAuthLevel">
            <el-tooltip v-for="roleAuth in roleAuthList" :key="roleAuth.authLevel" effect="dark" :content="roleAuth.authDesc" placement="top" :enterable="false">
              <el-radio v-model="addUserRoleForm.roleAuthLevel.authLevel" :label="roleAuth.authLevel"> {{roleAuth.authName}} </el-radio>
            </el-tooltip>
          </el-form-item>
          <el-form-item label="Role Info" prop="roleDesc">
            <el-input v-model="addUserRoleForm.roleDesc"></el-input>
          </el-form-item>
        </el-form>

        <span slot="footer" class="dialog-footer">
          <el-button @click="addUserRoleDialogVisible = false">Cancel</el-button>
          <el-button type="primary" @click="addUserRole">Submit</el-button>
        </span>
      </el-dialog>

       <!--edit role-->
      <el-dialog title="Edit User Role" :visible.sync="editUserRoleDialogVisible" width="50%" @close="editDialogClosed">
        <el-form :model="editUserRoleForm" :rules="editRoleFormRules" ref="editFormRef" label-width="130px" label-position="top">
          <el-form-item label="Role Name" prop="roleName">
            <el-input ref="editRoleNameRef" v-model="editUserRoleForm.roleName" :disabled="!writeSwitch"></el-input>
          </el-form-item>
          <el-form-item label="Auth Scope" prop="roleAuthLevel">
            <el-tooltip v-for="roleAuth in roleAuthList" :key="roleAuth.authLevel" effect="dark" :content="roleAuth.authDesc" placement="top" :enterable="false">
              <el-radio v-model="editUserRoleForm.roleAuthLevel.authLevel" :label="roleAuth.authLevel" :disabled="!writeSwitch"> {{roleAuth.authName}} </el-radio>
            </el-tooltip>
          </el-form-item>
          <el-form-item label="Role Info" prop="roleDesc">
            <el-input v-model="editUserRoleForm.roleDesc" :disabled="!writeSwitch"></el-input>
          </el-form-item>
        </el-form>

        <span slot="footer" class="dialog-footer">
          <el-button @click="editUserRoleDialogVisible = false">Cancel</el-button>
          <el-button type="primary" @click="updateUserRole" v-if="writeSwitch">Update</el-button>
        </span>
      </el-dialog>

       <!--role detail dialog-->
      <el-dialog title="User Role Detail" :visible.sync="UserRoleDetailDialogVisible" width="50%">
        <el-form :model="editUserRoleForm" :rules="editRoleFormRules" ref="editFormRef" label-width="130px" label-position="top">
          <el-form-item label="Role Name" prop="roleName">
            <el-input ref="editRoleNameRef" v-model="editUserRoleForm.roleName" readonly></el-input>
            <font v-if="editUserRoleForm.isSystemReserved" color="red">{{editUserRoleForm.roleName}} is reserved by the system</font>
          </el-form-item>
          <el-form-item label="Auth Scope" prop="roleAuthLevel">
            <el-tooltip effect="dark" :content="editUserRoleForm.roleAuthLevel.authDesc" placement="top-start" :enterable="false">
              <el-input v-model="editUserRoleForm.roleAuthLevel.authName" readonly></el-input>
            </el-tooltip>
          </el-form-item>
          <el-form-item label="Role Info" prop="roleDesc">
            <el-input v-model="editUserRoleForm.roleDesc" readonly></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="UserRoleDetailDialogVisible = false">Close</el-button>
        </span>
      </el-dialog>
    </el-card>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import { EVENT_CONFIG } from '@/event_bus'

export default {
  async created () {
    this.currentLoginUser = await this.$userStorage.getCurrentUser()
    this.getRoleAuthScope()
    this.getUserRoleList()
  },
  data () {
    const checkRoleName = async (rule, value, callback) => {
      console.log('check role name rule:', rule)
      console.log('check role name value:', value)
      // const { data: checkRoleResp } = await this.$http.get('user/role/search/' + value)
      const { data: checkRoleResp } = await this.$http.get(this.serverAPI.USERROLE_SEARCHE_URL + value)
      console.log('check user role response: ', checkRoleResp)
      if (checkRoleResp.data) {
        callback(new Error('Role name \'' + value + '\' is already existing'))
      }
      return callback()
    }

    const checkEditRoleName = async (rule, value, callback) => {
      console.log('check edit role name rule:', rule)
      console.log('check edit role name value:', value)
      // const { data: checkRoleResp } = await this.$http.get('user/role/search/' + value + '/' + this.editUserRoleForm.id)
      const { data: checkRoleResp } = await this.$http.get(this.serverAPI.USERROLE_SEARCHE_URL + value + '/' + this.editUserRoleForm.id)
      console.log('check user role response: ', checkRoleResp)
      if (checkRoleResp.data) {
        callback(new Error('Role name \'' + value + '\' is already existing'))
      }
      return callback()
    }

    return {
      menuDialogVisible: false,
      menuTree: [],
      menuTreeProps: {
        label: 'menuName',
        children: 'subMenus'
      },
      checkedMenuIDs: [],
      selectedUserRole: {},
      roleAuthList: [],
      currentLoginUser: {},
      addUserRoleDialogVisible: false,
      addUserRoleForm: {
        roleName: '',
        roleAuthLevel: {
          authLevel: 3
        },
        roleDesc: ''
      },
      userRoleFormRules: {
        roleName: [
          { required: true, message: 'Please input user role name', trigger: 'blur' },
          { min: 2, max: 20, message: 'The length of the user role name is limit from 2 to 20', trigger: 'blur' },
          { validator: checkRoleName, trigger: 'blur' }
        ],
        roleAuthLevel: [
          { required: true, message: 'Please assign user role auth level', trigger: 'change' }
        ],
        roleDesc: [
          { required: true, message: 'Please input user role info', trigger: 'blur' }
        ]
      },
      editUserRoleDialogVisible: false,
      editUserRoleForm: {
        roleAuthLevel: {
          authLevel: 0
        }
      },
      editRoleFormRules: {
        roleName: [
          { required: true, message: 'Please input user role name', trigger: 'blur' },
          { min: 2, max: 20, message: 'The length of the user role name is limit from 2 to 20', trigger: 'blur' },
          { validator: checkEditRoleName, trigger: 'blur' }
        ],
        roleAuthLevel: [
          { required: true, message: 'Please assign user role auth level', trigger: 'change' }
        ],
        roleDesc: [
          { required: true, message: 'Please input user role info', trigger: 'blur' }
        ]
      },
      roleList: [],
      UserRoleDetailDialogVisible: false
    }
  },
  computed: {
    ...mapState({
      writeSwitch: (state) => state.writableStatus
    })
  },
  methods: {
    async getMenuTree () {
      const { data: menuData } = await this.$http.get(this.serverAPI.MENU_LIST_URL)
      console.log('get the menu tree for the user role allocation:', menuData)
      if (menuData.data !== null && menuData.data !== undefined) {
        this.menuTree = menuData.data
      }
    },
    async showAllotMenuDialog (roleItem) {
      console.log('show the dialog role item:', roleItem)
      await this.getMenuTree()
      this.menuDialogVisible = true
      this.checkedMenuIDs = roleItem.relatedMenuIDs
      this.selectedUserRole = roleItem
    },
    async allocateMenu () {
      console.log('allot the menu to user role')

      const userRole = this.selectedUserRole
      this.selectedUserRole = {}

      const checkedMenuKeys = this.$refs.menuTreeRef.getCheckedKeys()
      // const halfCheckedMenuKeys = this.$refs.menuTreeRef.getHalfCheckedKeys()

      userRole.relatedMenuIDs = []
      userRole.relatedMenuIDs = checkedMenuKeys
      console.log('checked menu IDs:', userRole)

      const { data: allocateResp } = await this.$http.post(this.serverAPI.USERROLE_MENUS_URL, JSON.stringify(userRole), { headers: { 'content-type': 'application/json' } })
      console.log('allocated response content:', allocateResp)
      this.menuDialogVisibleClose()

      this.getUserRoleList()
      EVENT_CONFIG.EventBus.$emit(EVENT_CONFIG.RELOAD_NAVI_MENU_EVENT)
    },
    menuDialogVisibleClose () {
      this.menuDialogVisible = false
      this.checkedMenuIDs = []
      this.selectedUserRole = {}
    },
    async getRoleAuthScope () {
      const { data: resp } = await this.$http.get(this.serverAPI.USERROLE_AUTH_SCOPE_URL)
      this.roleAuthList = resp.data
    },
    // display the dialog of adding user role
    displayAddDialog () {
      this.addUserRoleDialogVisible = true
      this.$nextTick(_ => {
        console.log(this.$refs.addRoleNameRef)
        this.$refs.addRoleNameRef.focus()
      })
    },
    // close the dialog
    addDialogClosed () {
      this.$refs.addFormRef.resetFields()
    },
    addUserRole () {
      this.$refs.addFormRef.validate(async result => {
        if (!result) return
        // const { data: res } = await this.$http.post('/user/role/add', JSON.stringify(this.addUserRoleForm), { headers: { 'content-type': 'application/json' } })
        const { data: res } = await this.$http.post(this.serverAPI.USERROLE_ADD_URL, JSON.stringify(this.addUserRoleForm), { headers: { 'content-type': 'application/json' } })
        console.log('add user role response', res)
        this.addUserRoleDialogVisible = false
        // get all user role list
        this.getUserRoleList()
      })
    },
    async getUserRoleList () {
      console.log('get user role list')
      // const { data: roleListResp } = await this.$http.get('/user/role/list')
      const { data: roleListResp } = await this.$http.get(this.serverAPI.USERROLE_LIST_URL)
      console.log('user role list response content: ', roleListResp)
      this.roleList = roleListResp.data
    },
    async displayEditDialog (roleID) {
      console.log('edit role id:', roleID)
      // const { data: roleResp } = await this.$http.get('/user/role/detail/' + roleID)
      const { data: roleResp } = await this.$http.get(this.serverAPI.USERROLE_DETAIL_URL + roleID)
      console.log('user role detail:', roleResp)
      this.editUserRoleForm = roleResp.data
      this.editUserRoleDialogVisible = true
      this.$nextTick(_ => {
        console.log(this.$refs.editRoleNameRef)
        this.$refs.editRoleNameRef.focus()
      })
    },
    async removedUserRole (roleID) {
      const { data: deleteRoleResp } = await this.$http.delete(this.serverAPI.USERROLE_REMOVE_URL + roleID)
      console.log('remove user role response: ', deleteRoleResp)
      this.getUserRoleList()
    },
    editDialogClosed () {
      this.$refs.editFormRef.resetFields()
    },
    async displayDetailDialog (roleID) {
      console.log('edit role id:', roleID)
      // const { data: roleResp } = await this.$http.get('/user/role/detail/' + roleID)
      const { data: roleResp } = await this.$http.get(this.serverAPI.USERROLE_DETAIL_URL + roleID)
      console.log('user role detail:', roleResp)
      this.editUserRoleForm = roleResp.data
      this.UserRoleDetailDialogVisible = true
    },
    updateUserRole () {
      this.$refs.editFormRef.validate(async result => {
        if (!result) return
        // const { data: updateResp } = await this.$http.put('/user/role/update', JSON.stringify(this.editUserRoleForm), { headers: { 'content-type': 'application/json' } })
        const { data: updateResp } = await this.$http.put(this.serverAPI.USERROLE_UPDATE_URL, JSON.stringify(this.editUserRoleForm), { headers: { 'content-type': 'application/json' } })
        console.log('update role response: ', updateResp)
        this.editUserRoleDialogVisible = false
        this.getUserRoleList()
      })
    }
  }
}
</script>
<style lang="less" scoped>

</style>
