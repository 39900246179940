<template>
  <div>
    <el-card>
      <div slot="header" class="clearfix">
        <span> {{fieldSetName}} </span>
        <!-- <el-button style="float: right; padding: 3px 0" type="text">操作按钮</el-button> -->
      </div>
      <el-row v-for="(fieldInfo, index) in normalFieldInfos" :key="fieldInfo.fieldName" :gutter="20" style="margin-bottom: 5px;">
          <template v-if="index%2==0">
            <FormField :fieldInfo="normalFieldInfos[index]" :formData="formData" :commonOptionInfos="commonOptions"></FormField>
            <FormField v-if="( index + 1 ) < normalFieldInfos.length" :fieldInfo="normalFieldInfos[index + 1]" :formData="formData" :commonOptionInfos="commonOptions"></FormField>
          </template>
        </el-row>
        <el-row v-for="(fieldInfo, index) in textareaFieldInfos" :key="fieldInfo.fieldName" :gutter="20" style="margin-bottom: 5px;">
         <FormField :fieldInfo="textareaFieldInfos[index]" :formData="formData" :commonOptionInfos="commonOptions"></FormField>
        </el-row>
    </el-card>
  </div>
</template>
<script>
import FormField from '@/components/data_component/FormField'
export default {
  props: ['fieldSetName', 'normalFieldInfos', 'textareaFieldInfos', 'commonOptions', 'formData'],
  data () {
    return {}
  },
  components: {
    FormField
  }
}
</script>
<style lang="less" scoped>
textarea.form-field-textarea {
  height: 120px;
  overflow-y: auto;
  margin-bottom: 5px;
}
div.el-form-item {
  margin-bottom: 0;
  // margin-top: 10px;
}
form {
  label {
    line-height: 30px !important;
    padding: 0 !important;
  }
}
.clearfix:before, .clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both
}
</style>
