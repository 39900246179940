import Vue from 'vue'
import Vuex from 'vuex'
import dataFormModule from './DataFormModule'
import dataPageModule from './DataPageModule'
import FileUploadModule from './FileUploadModule'
import NotificationModule from './NotificationModule'

Vue.use(Vuex)

const currentPathKey = 'activePath'

export default new Vuex.Store({
  state: {
    writableStatus: false,
    routerActivePath: window.sessionStorage.getItem(currentPathKey),
    paperSearchKeyword: '',
    currentPaperPage: 1,
    paperPageSize: 10
  },
  mutations: {
    switchWritableStatus (state, newStaus) {
      state.writableStatus = newStaus
    },
    setRouterActivePath (state, currentPath) {
      window.sessionStorage.setItem(currentPathKey, currentPath)
      state.routerActivePath = currentPath
    },
    removeRouterActivePath (state) {
      window.sessionStorage.removeItem(currentPathKey)
      state.routerActivePath = ''
    },
    // Paper Related VUEX states
    setPaperSearchKeyword (state, newSearchKeyword) {
      state.paperSearchKeyword = newSearchKeyword
    },
    setCurrentPaperPage (state, newCurrentPaperPage) {
      state.currentPaperPage = newCurrentPaperPage
    },
    setPaperPageSize (state, newPaperPageSize) {
      state.paperPageSize = newPaperPageSize
    }
  },
  actions: {
  },
  modules: {
    dataModule: dataFormModule,
    dataPageModule,
    FileUploadModule,
    NotificationModule
  }
})
