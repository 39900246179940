<template>
  <!--On data detail view page, there should be some fields that cannot be altered. like: Project ResponsiblePI Uploader-->
  <div>
    <!--Breadcrumb navigation area-->
    <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/home' }">Home</el-breadcrumb-item>
        <el-breadcrumb-item>Data Module</el-breadcrumb-item>
        <el-breadcrumb-item>Data Detail</el-breadcrumb-item>
    </el-breadcrumb>
    <div style="margin-top: 15px; margin-bottom: 10px;">
      <el-button type="primary" size="mini" plain style="font-size: 16px;" @click="backToDataList">Back</el-button>
      <el-button type="success" size="mini" plain style="font-size: 16px;" :disabled ="!commonFormChange && !nonCommonFormChange" @click="reloadBtnHandler">Reset</el-button>
      <el-button type="primary" size="mini" plain style="font-size: 16px;" @click="shiftAttachedFilesFlag">Attached File List</el-button>
    </div>
    <el-form id="commonDataForm" :model="commonFormData" class="data-form" :rules="commonFormRules" ref="commonFormRef" label-position="top" label-width="80px" @validate="afterCommonFormFieldValidate">
      <el-card>
        <div slot="header" class="clearfix">
          <span> General Information </span>
        </div>
        <!-- 1 <DataFields fieldSetName="Common Fields" :normalFieldInfos="commonNormalFieldInfos" :textareaFieldInfos="commonTextareaFieldInfos" :commonOptions="commonOptionInfos" :formData="addDataForm"></DataFields> -->
        <common-fields></common-fields>
      </el-card>
    </el-form>

    <el-form id="nonCommonDataForm" :model="nonCommonFormData" class="data-form" :rules="nonCommonFormRules" ref="nonCommonFormRef" label-position="top" label-width="80px" @validate="afterNoncommonFirmFieldValidate">
      <section v-if="projectRelatedFieldFlag">
        <el-row type="flex" class="field-title-container" @click.native="shiftRelatedFiledsFlag" justify="start" align="middle">
          <el-col :span="12" class="field-title">Project Specified Information</el-col>
          <el-col class="field-title-button">
            <el-button type="text" style="padding-left: 9px; padding-right: 5px; font-weight: bold;" @click.stop="shiftRelatedFiledsFlag">{{ relatedFiledsFlag? 'Hide': 'More ' }}<i :class="relatedFiledsFlag? 'el-icon-arrow-down' : 'el-icon-arrow-right'"/></el-button>
          </el-col>
        </el-row>
        <el-collapse-transition>
          <div v-show="relatedFiledsFlag">
            <el-card v-show="commonFormData.project && commonFormData.project" style="border-top-left-radius: 0; border-top-right-radius: 0;">
              <NonCommonFields :isRelated="true" :creator="commonFormData.creator"></NonCommonFields>
            </el-card>
          </div>
        </el-collapse-transition>
      </section>

      <el-row type="flex" class="field-title-container" @click.native="shiftOptionalFiledsFlag" justify="start" align="middle">
        <el-col :span="12" class="field-title">Additional Information</el-col>
        <el-col class="field-title-button">
          <el-button type="text" style="padding-left: 9px; padding-right: 5px; font-weight: bold;" @click.stop="shiftOptionalFiledsFlag">{{ optionalFiledsFlag? 'Hide': 'More ' }}<i :class="optionalFiledsFlag? 'el-icon-arrow-down' : 'el-icon-arrow-right'"/></el-button>
        </el-col>
      </el-row>
      <el-collapse-transition>
        <div v-show="optionalFiledsFlag">
          <el-card v-show="commonFormData.project && commonFormData.project" style="border-top-left-radius: 0; border-top-right-radius: 0;">
            <NonCommonFields :isRelated="false" :creator="commonFormData.creator"></NonCommonFields>
          </el-card>
        </div>
      </el-collapse-transition>

      <el-row v-if="commonFormData.creator.id === currentUser.id && writeSwitch" style="margin-top: 10px; margin-bottom: 10px; margin-right: 10px;" type="flex" justify="end">
        <el-col :span="2" :offset="22" style="padding-right: 15px">
          <el-button style="float: right;" v-if="commonFormChange || nonCommonFormChange" :disabled="!commonFormChange && !nonCommonFormChange" @click="reloadBtnHandler">Reset</el-button>
          <el-button style="float: right;" v-else :disabled="commonFormChange || nonCommonFormChange" @click="backToDataList">Cancel</el-button>
        </el-col>
        <el-col :span="2">
           <el-button type="primary" @click="updateData" :disabled="!commonFormChange && !nonCommonFormChange">Update</el-button>
        </el-col>
      </el-row>
    </el-form>

    <el-row type="flex" class="field-title-container" @click.native="shiftRelatedPapersFlag" justify="start" align="middle">
      <el-col :span="7" class="field-title">Related Publication List</el-col>
      <el-col class="field-title-button">
        <el-button type="text" style="padding-left: 9px; padding-right: 5px; font-weight: bold;" @click.stop="shiftRelatedPapersFlag">{{ relatedPapersFlag? 'Hide': 'More ' }}<i :class="relatedPapersFlag? 'el-icon-arrow-down' : 'el-icon-arrow-right'"/></el-button>
      </el-col>
    </el-row>
    <el-collapse-transition>
      <div v-show="relatedPapersFlag">
        <el-card style="border-top-left-radius: 0; border-top-right-radius: 0;">
          <el-table :data="relatedPapers" stripe>
            <el-table-column type="index" label="#" align="center"></el-table-column>
            <el-table-column prop="paperTitle" label="Title" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column prop="paperType.typeName" label="Type" align="center"></el-table-column>
            <el-table-column label="Status" align="center">
              <template v-slot="paperSlot">
                {{ paperSlot.row.paperStatus !== null? paperSlot.row.paperStatus.statusName : '-'}}
              </template>
            </el-table-column>
            <el-table-column label="Year" align="center">
              <template v-slot="paperSlot">
                {{ paperSlot.row.paperYear !== null && paperSlot.row.paperYear !== ''? paperSlot.row.paperYear : '-'}}
              </template>
            </el-table-column>
            <el-table-column label="URL" align="center" :show-overflow-tooltip="true">
              <template v-slot="paperSlot">
                {{ paperSlot.row.paperURL !== null && paperSlot.row.paperURL !== ''? paperSlot.row.paperURL : '-'}}
              </template>
            </el-table-column>
            <el-table-column prop="uploader.realName" label="Uploader" align="center"></el-table-column>
            <el-table-column label="Update Time" align="center" width="160">
              <template v-slot="paperSlot">
                {{paperSlot.row.updateTime | formatDate}}
              </template>
            </el-table-column>
            <el-table-column label="Action" align="center" width="150">
              <template v-slot:="paperSlot">
                <el-button type="primary" icon="el-icon-view" size="mini" @click="toEditDetail(paperSlot.row)"></el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-card>
      </div>
    </el-collapse-transition>

    <el-row type="flex" class="field-title-container" @click.native="shiftRelatedHistoryFlag" justify="start" align="middle">
      <el-col :span="7" class="field-title">Data History List</el-col>
      <el-col class="field-title-button">
        <el-button type="text" style="padding-left: 9px; padding-right: 5px; font-weight: bold;" @click.stop="shiftRelatedHistoryFlag"> {{relatedHistoryFlag? 'Hide': 'More '}}<i :class="relatedHistoryFlag? 'el-icon-arrow-down' : 'el-icon-arrow-right'"/></el-button>
      </el-col>
    </el-row>
    <el-collapse-transition>
      <div v-show="relatedHistoryFlag">
        <el-card style="border-top-left-radius: 0; border-top-right-radius: 0;">
          <el-tag class="history-name" v-for="(history, index) in relatedHistory" :key="index" @click="dataHistoryDetail(dataID, history.id)">
            Version: {{history.version}} CreateTime: {{history.createTime | formatDate}}
          </el-tag>
        </el-card>
      </div>
    </el-collapse-transition>

    <el-dialog title="Attached File List" :visible.sync="attachedFielsFlag" width="50%" style="min-width: 1260px;">
      <el-row style="padding-bottom: 20px;">
        <el-table :data="dataRelatedFiles" stripe border size="mini" ref="attachedFileTable" :row-key="row => row.rowkey"
          style="margin-top: 0;" :tree-props="{hasChildren: 'isFolder'}" lazy :load="loadSubFile">
            <el-table-column prop="fileName" label="File Name" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column label="Type" align="center">
              <template v-slot="fileProps">
              <img :src="fileProps.row | getFileIcon" style="width: 20px; height: 20px; vertical-align: middle; margin-right: 5px;">
              {{ fileProps.row | showFileType }}
              </template>
            </el-table-column>
            <el-table-column label="Status" align="center">
              <template v-slot="fileProps">
                <span v-show="fileProps.row.fileStatus.statusCode === 1"><i class="el-icon-question" style="font-size: 12px; color: #e6a23c; margin-left: 3px;"></i>Unmerged</span>
                <span v-show="fileProps.row.fileStatus.statusCode === 2"><i class="el-icon-loading" style="font-size: 12px; color: #409EFF; margin-left: 3px;"></i>Merging</span>
                <span v-show="fileProps.row.fileStatus.statusCode === 3"><i class="el-icon-info" style="font-size: 12px; color: #909399; margin-left: 3px;"></i>Merged</span>
                <span v-show="fileProps.row.fileStatus.statusCode === 4"><i class="el-icon-error" style="font-size: 12px; color: #f56c6c; margin-left: 3px;"></i>Failed</span>
                <span v-show="fileProps.row.fileStatus.statusCode === 5"><i class="el-icon-success" style="font-size: 12px; color: #67c23a; margin-left: 3px;"></i>Success</span>
                </template>
            </el-table-column>
            <el-table-column label="Related Fields" align="center">
              <template v-slot="fileProps">
              <el-tag v-for="(relatedField, index) in fileProps.row.relatedFields" :key="index" :style="index > 0? 'margin-left: 5px;' : ''"> {{relatedField}} </el-tag>
              </template>
            </el-table-column>
            <el-table-column align="center" label="Size">
              <template v-slot="fileProps">
              {{ fileProps.row | fileSizeFormat }}
              </template>
            </el-table-column>
            <el-table-column label="Update Time" align="center" width="160">
              <template v-slot="fileProps">
              {{fileProps.row.updateTime | formatDate}}
              </template>
            </el-table-column>
            <el-table-column align="center" width="100">
              <template v-slot="fileProps">
                <el-button v-if="!fileProps.row.isFolder" type="text" style="font-weight: bold;" @click="checkFileStatusBeforeDownload(fileProps.row)">Download</el-button>
                <span v-else> - </span>
              </template>
            </el-table-column>
        </el-table>
      </el-row>
    </el-dialog>
  </div>
</template>
<script>
import JsSHA from 'jssha'
import CommonFields from '@/components/data_component/CommonFields'
import { mapState, mapMutations } from 'vuex'
import NonCommonFields from '@/components/data_component/NonCommonFields.vue'
import { EVENT_CONFIG } from '@/event_bus'
import { formValidator } from '@/plugins/formValidator'
import alertCountdown from '@/plugins/alertCountdown.js'
import { FILE_TYPE } from '@/components/file_component/fileconfig'
import { DRAFT_GENERATOR } from '../../local_storage/DraftGenerator'

export default {
  props: ['dataID'],
  components: {
    CommonFields,
    NonCommonFields
  },
  computed: {
    ...mapState({
      writeSwitch: (state) => state.writableStatus,
      commonFormData: (state) => state.dataModule.commonFormData,
      commonFormRules: (state) => state.dataModule.commonFormRules,
      nonCommonFormData: (state) => state.dataModule.nonCommonFormData,
      nonCommonFormRules: (state) => state.dataModule.nonCommonFormRules,
      projectRelatedFields: (state) => state.dataModule.projectRelatedFields,
      nonCommonFieldRelated: (state) => state.dataModule.nonCommonFieldRelated
    })
  },
  data () {
    return {
      currentUser: {},
      projectRelatedFieldFlag: false,
      commonFormChange: false,
      nonCommonFormChange: false,
      commonFormDigest: '',
      nonCommonFormDigest: '',
      formToken: '',
      relatedPapers: [],
      relatedHistory: [],
      dataRelatedFiles: [],
      relatedFiledsFlag: false, // this is the display flag for the 'Project Related Fields'
      optionalFiledsFlag: false, // this is the display flag for the 'Optional Related Fields'
      relatedPapersFlag: true, // this is the display flag for the 'Related Pulication List'
      relatedHistoryFlag: true, // this is the display flag for the 'Data History List'
      attachedFielsFlag: false // this is the display flag for the 'Attached Files List'
    }
  },
  async created () {
    const loadingInstance = this.$loading.service({
      lock: true,
      text: 'Initing data detail page...'
    })

    window.onbeforeunload = (event) => {
      console.log('form change flag commonFormChange=%o nonCommonFormChange=%o', this.commonFormChange, this.nonCommonFormChange)

      this.commonFormChange = formValidator.formDataChangeValidate(this.commonFormData, this.commonFormDigest)
      this.nonCommonFormChange = this.nonCommonFormChange = formValidator.formDataChangeValidate(this.nonCommonFormData, this.nonCommonFormDigest)

      if (this.commonFormChange || this.nonCommonFormChange) {
        // add the code to store the draft of editing
        const dataObj = {}
        dataObj.commonFormData = this.commonFormData
        dataObj.nonCommonFormData = this.nonCommonFormData
        DRAFT_GENERATOR.storeDataDraft(dataObj)

        return 'confirm to refresh?'
      }
    }

    window.onunload = () => {
      this.setRouterActivePath(`/data/detail/${this.dataID}`)
    }

    EVENT_CONFIG.EventBus.$on(EVENT_CONFIG.CHECK_FORM_FIELD_EVENT, this.checkFormDataField)
    await this.getDataDetail()
    this.setRouterActivePath('/data/list')

    const startCheckNoncommonField = new Date().getTime()
    this.projectRelatedFieldFlag = Object.values(this.nonCommonFieldRelated).find(nonCommonFieldValue => {
      if (nonCommonFieldValue) return nonCommonFieldValue
    })
    console.log('check project related field list time cost: ', new Date().getTime() - startCheckNoncommonField)

    this.currentUser = await this.$userStorage.getCurrentUser()

    const dataDraft = await DRAFT_GENERATOR.getDataDraft(this.commonFormData.dataID)
    console.log('load update data draft from the local storage: ', dataDraft)
    if (dataDraft) {
      const confirmLoadDraftResult = await this.$confirm('Load the experimental data draft?', {
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        closeOnClickModal: false,
        closeOnPressEscape: false
      }).then(_ => {
        console.log('to load experimental data draft from the loacal storage')
        this.initNewDataFromBasedDraft(dataDraft)
      }).catch((error) => {
        console.log('cancel to load the experimental data from the local storage error: ', error)
      }).finally(_ => {
        DRAFT_GENERATOR.removeDataDraft(this.commonFormData.dataID)
      })

      if (confirmLoadDraftResult) {
        const commonFormRef = this.$refs.commonFormRef
        const validateCommonFormResult = await formValidator.formValidate(commonFormRef)
        console.log('validate reloaded common form data result: ', validateCommonFormResult)

        const nonCommonFormRef = this.$refs.nonCommonFormRef
        console.log('nonFirmForm: ', nonCommonFormRef)
        const validateNonCommonFormResult = await formValidator.formValidate(nonCommonFormRef)
        console.log('validate reloaded non-common form data result: ', validateNonCommonFormResult)
      }
    }

    const projectID = this.commonFormData.project.id
    EVENT_CONFIG.EventBus.$emit(EVENT_CONFIG.DATA_PROJECT_CHANGE_EVENT, projectID)

    this.$nextTick(function () {
      loadingInstance.close()
    })
  },
  beforeRouteLeave (to, from, next) {
    console.log('before leaving the DataUpdate.vue page', this.commonFormChange, this.nonCommonFormChange)
    if (this.commonFormChange || this.nonCommonFormChange) {
      this.$confirm('Leave the experimental data detail page and save the unsubmitted data as local draft?', {
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        closeOnClickModal: false,
        closeOnPressEscape: false
      }).then(_ => {
        console.log('confirm to leave DataUpdate.vue')
        const dataObj = {}
        dataObj.commonFormData = this.commonFormData
        dataObj.nonCommonFormData = this.nonCommonFormData
        DRAFT_GENERATOR.storeDataDraft(dataObj)
        next()
      }).catch(_ => {
        console.log('cancel to leave')
        this.setRouterActivePath('/data/list')
      })
    } else {
      next()
    }
  },
  async beforeDestroy () {
    console.debug('DataUpdate.vue beforeDestory method executes.....')
    window.onbeforeunload = null
    window.onunload = null
    EVENT_CONFIG.EventBus.$off(EVENT_CONFIG.CHECK_FORM_FIELD_EVENT)
  },
  filters: {
    fileSizeFormat (file) {
      console.debug('file name: %s and file size %s', file.fileName, file.fileSize)
      if (file.fileSize === null) return '-'
      if (file.fileSize < 1024) {
        // lower than 1024, the unit is 'Bytes'
        return file.fileSize + ' Bytes'
      }
      if (file.fileSize < (1024 * 1024)) {
        // bigger than 1024 and smaller than 1024*1024, the unit is 'KB'
        const _kb = (file.fileSize / 1024).toFixed(2)
        return parseFloat(_kb) + ' KB'
      }

      if (file.fileSize < (1024 * 1024 * 1024)) {
        // bigger than 1024*1024 and smaller than 1024*1024*1024, the unit is 'MB'
        const _mb = (file.fileSize / (1024 * 1024)).toFixed(2)
        return parseFloat(_mb) + ' MB'
      }

      const _gb = (file.fileSize / (1024 * 1024 * 1024)).toFixed(2)
      return parseFloat(_gb) + ' GB'
    },
    showFileType (file) {
      if (file.isFolder) {
        return FILE_TYPE.FOLDER.typeName
      }

      const fileType = FILE_TYPE.getFileType(file.fileName)
      return fileType.typeName
    },
    getFileIcon (fieldFileRelation) {
      let fileType
      if (fieldFileRelation.isFolder) {
        fileType = FILE_TYPE.FOLDER
      } else {
        fileType = FILE_TYPE.getFileType(fieldFileRelation.fileName)
      }

      const iconPath = require('@/' + `${fileType.typeIcon}`)
      return iconPath
    }
  },
  methods: {
    ...mapMutations({
      initDataUpdatePage: 'dataPageModule/initDataUpdatePage',
      setRouterActivePath: 'setRouterActivePath',
      initUpdatePageFormData: 'dataModule/initUpdatePageFormData',
      changeNonCommonFormRule: 'dataModule/changeNonCommonFormRule',
      setProjectRelatedFields: 'dataModule/setProjectRelatedFields',
      // init the data form based on the data draft from the local storage
      initNewDataFromBasedDraft: 'dataModule/initNewDataFromBasedDraft'
    }),
    dataHistoryDetail (dataID, historyID) {
      this.$router.push({
        path: `/data/history/${dataID}/${historyID}`
      })
    },
    reloadBtnHandler () {
      console.log('click reload button')
      if (this.commonFormChange || this.nonCommonFormChange) {
        this.$confirm('Reload the data detail, all changes will be lost. Confirm?', {
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
          closeOnClickModal: false,
          closeOnPressEscape: false
        }).then(_ => {
          this.getDataDetail()
        }).catch(() => {
          console.log('cancel to reload the paper detail id:', this.dataID)
        })
        return
      }
      this.getDataDetail()
    },
    async getDataDetail () {
      console.log('projectID=%s, dataID=%s', this.dataID)
      // const { data: dataDetaiilResp } = await this.$http.get(`/data/detail/${this.dataID}`)
      const dataDetailResponse = await this.$http.get(`${this.serverAPI.DATA_DETAIL_URL}${this.dataID}`)
      console.log('get data detail response content: ', dataDetailResponse)
      this.formToken = dataDetailResponse.headers['form-token']

      const dataDetaiilResp = dataDetailResponse.data

      if (dataDetaiilResp.data.dataDetail.timePeriod) {
        if (dataDetaiilResp.data.dataDetail.timePeriod.length === 2) {
          if (!dataDetaiilResp.data.dataDetail.timePeriod[0]) {
            dataDetaiilResp.data.dataDetail.timePeriod[0] = '-'
          }

          if (!dataDetaiilResp.data.dataDetail.timePeriod[1]) {
            dataDetaiilResp.data.dataDetail.timePeriod[1] = '-'
          }
        }
      }

      this.initDataUpdatePage(dataDetaiilResp.data)

      this.initUpdatePageFormData(dataDetaiilResp.data)

      this.relatedPapers = dataDetaiilResp.data.relatedPapers // data related paper list
      if (!this.relatedPapers || !this.relatedPapers.length) {
        this.relatedPapersFlag = false
      }

      this.relatedHistory = dataDetaiilResp.data.relatedHistories
      console.log('related history: ', this.relatedHistory)
      if (!this.relatedHistory || !this.relatedHistory.length) {
        this.relatedHistoryFlag = false
      }

      const relatedFields = dataDetaiilResp.data.relatedFields
      console.log('get data detail related fields: ', relatedFields)
      relatedFields.forEach(field => {
        const ruleContent = {}
        ruleContent.fieldName = field.fieldName
        ruleContent.required = field.required
        this.changeNonCommonFormRule(ruleContent)
        this.setProjectRelatedFields(field.fieldName)
      })

      const relatedFileDTOs = dataDetaiilResp.data.relatedFiles
      console.log('get data detail related files: ', relatedFileDTOs)

      if (relatedFileDTOs && relatedFileDTOs.length) {
        const commonKeys = Object.keys(this.commonFormData)
        const noncommonKeys = Object.keys(this.nonCommonFormData)
        for (const relatedFileDTO of relatedFileDTOs) {
          relatedFileDTO.rowkey = relatedFileDTO.id + '-' + new Date().getTime()
          const relatedFields = []
          commonKeys.forEach(commonKey => {
            const commonFormValue = this.commonFormData[commonKey]
            if (commonFormValue instanceof Array) {
              const fileIndex = commonFormValue.findIndex(fileValue => {
                if (fileValue) {
                  return fileValue.fileUniqueID === relatedFileDTO.fileUniqueID
                }
                return false
              })
              if (fileIndex >= 0) {
                relatedFields.push(commonKey)
              }
            }
          })

          noncommonKeys.forEach(noncommonKey => {
            const noncommonFormValue = this.nonCommonFormData[noncommonKey]
            if (noncommonFormValue instanceof Array) {
              const noncommonFileIndex = noncommonFormValue.findIndex(noncommonFileValue => {
                if (noncommonFileValue) {
                  return noncommonFileValue.fileUniqueID === relatedFileDTO.fileUniqueID
                }
                return false
              })
              if (noncommonFileIndex >= 0) {
                relatedFields.push(noncommonKey)
              }
            }
          })
          relatedFileDTO.relatedFields = relatedFields
        }
        this.dataRelatedFiles = relatedFileDTOs
      } else {
        this.attachedFielsFlag = false
      }

      const startCommonDigest = new Date().getTime()
      const jsSHACommon = new JsSHA('SHA-256', 'TEXT')
      jsSHACommon.update(JSON.stringify(this.commonFormData))
      this.commonFormDigest = jsSHACommon.getHash('HEX')
      console.log('data update detail digest common form data time cost: ', new Date().getTime() - startCommonDigest)
      this.commonFormChange = false

      const startNoncommonDigest = new Date().getTime()
      const jsSHANonCommon = new JsSHA('SHA-256', 'TEXT')
      jsSHANonCommon.update(JSON.stringify(this.nonCommonFormData))
      this.nonCommonFormDigest = jsSHANonCommon.getHash('HEX')
      console.log('data update detail digest non-common form data time cost: ', new Date().getTime() - startNoncommonDigest)
      this.nonCommonFormChange = false
    },
    checkFormDataField (isCommon, fieldName) {
      console.log('check the specified field: ', fieldName)
      console.log('is common form: ', isCommon)

      const commonFormRef = this.$refs.commonFormRef
      const nonCommonFormRef = this.$refs.nonCommonFormRef
      formValidator.formFieldValidate(isCommon ? commonFormRef : nonCommonFormRef, fieldName)
    },
    async updateData () {
      // verify the data form field while updating data detail
      const commonFormRef = this.$refs.commonFormRef
      const validateCommonFormResult = await formValidator.formValidate(commonFormRef)
      console.log('DataUpdate.vue check add experimental data commonForm: ', validateCommonFormResult)
      if (validateCommonFormResult !== true) {
        let msgContent = ''
        const validateValues = Object.values(validateCommonFormResult)
        validateValues.forEach(validateVal => {
          msgContent += validateVal[0].message + '<br>'
        })
        this.$alert('<span style="font-size: 15px; font-weight: bold;"> Exceptions in the common form data: </span><br> <span style="color: red;">' + msgContent + '</span>', 'Notification of Experimental Data', {
          dangerouslyUseHTMLString: true
        })

        return
      }

      const nonCommonFormRef = this.$refs.nonCommonFormRef
      let validateNonCommonFormResult = await formValidator.formValidate(nonCommonFormRef)
      console.log('DataUpdate.vue check add experimental data nonCommonForm: ', validateNonCommonFormResult)
      if (validateNonCommonFormResult !== true) {
        let noncommonMsgConent = ''
        const noncommonValidateValues = Object.values(validateNonCommonFormResult)
        noncommonValidateValues.forEach(nonValidateVal => {
          noncommonMsgConent += nonValidateVal[0].message + '<br>'
        })
        validateNonCommonFormResult = await this.$confirm('<span style="font-size: 15px; font-weight: bold;"> Exceptions in the optional form data: </span><br> <span style="color: red;">' +
                     noncommonMsgConent + '</span>', 'Notification of Experimental Data', {
          confirmButtonText: 'Update Anyway',
          dangerouslyUseHTMLString: true
        }).then(_ => {
          return true
        }).catch(_ => {
          console.warn('skip to update form data')
        })
      }

      if (validateCommonFormResult === true && validateNonCommonFormResult === true) {
        this.commonFormChange = false
        this.nonCommonFormChange = false
        console.log('commonData', this.commonFormData)
        console.log('noncommonData', this.nonCommonFormData)
        // TODO: add the function of preventing repeat submit
        console.log('send the request to the server to update data detail')
        let updateExperimentData = {}
        updateExperimentData = JSON.parse(JSON.stringify(this.commonFormData))

        if (updateExperimentData.timePeriod) {
          if (updateExperimentData.timePeriod[0] === '-') {
            updateExperimentData.startDate = null
            updateExperimentData.timePeriod[0] = null
          }

          if (updateExperimentData.timePeriod[1] === '-') {
            updateExperimentData.endDate = null
            updateExperimentData.timePeriod[1] = null
          }
        } else {
          updateExperimentData.timePeriod = [null, null]
        }

        updateExperimentData.startDate = updateExperimentData.timePeriod[0]
        updateExperimentData.endDate = updateExperimentData.timePeriod[1]

        console.log('update commonData: ', updateExperimentData)

        updateExperimentData.optionalContent = JSON.parse(JSON.stringify(this.nonCommonFormData))

        const { data: dataUpdateResp } = await this.$http.post(this.serverAPI.DATA_UPDATE_URL, JSON.stringify(updateExperimentData), { headers: { 'content-type': 'application/json', 'form-token': this.formToken } })
        console.log('update experimental data response content: {}', dataUpdateResp)

        if (dataUpdateResp.code === 1) {
          this.$message({
            showClose: true,
            message: 'update data successfully',
            center: true,
            type: 'success'
          })
          // to the data list page automatically with time countdown
          await this.getDataDetail()
          alertCountdown(this, 'To Data List', this.backToDataList, 5)
          return
        } else {
          this.$alert('<font color="red">' + dataUpdateResp.data.msg + '</font>', 'Data Update Error', {
            dangerouslyUseHTMLString: true
          })
        }
      }
      this.commonFormChange = formValidator.formDataChangeValidate(this.commonFormData, this.commonFormDigest)
      this.nonCommonFormChange = formValidator.formDataChangeValidate(this.nonCommonFormData, this.nonCommonFormDigest)
    },
    backToDataList () {
      this.$router.push({
        path: '/data/list'
      })
    },
    async toEditDetail (paper) {
      console.debug('paper id is', paper)
      await this.$router.push({ path: `/paper/detail/${paper.id}/${paper.paperType.typeCode}` })
    },
    shiftRelatedFiledsFlag () {
      this.relatedFiledsFlag = !this.relatedFiledsFlag
    },
    shiftOptionalFiledsFlag () {
      this.optionalFiledsFlag = !this.optionalFiledsFlag
    },
    shiftRelatedPapersFlag () {
      this.relatedPapersFlag = !this.relatedPapersFlag
    },
    shiftAttachedFilesFlag () {
      this.attachedFielsFlag = !this.attachedFielsFlag
    },
    shiftRelatedHistoryFlag () {
      this.relatedHistoryFlag = !this.relatedHistoryFlag
    },
    afterCommonFormFieldValidate (props, callback) {
      console.log('validate common form field: ', props)
      this.commonFormChange = formValidator.formDataChangeValidate(this.commonFormData, this.commonFormDigest)
      console.log('common form data change: ', this.commonFormChange)
    },
    afterNoncommonFirmFieldValidate (props, callback) {
      console.log('validate non-common form field: ', props)
      this.nonCommonFormChange = formValidator.formDataChangeValidate(this.nonCommonFormData, this.nonCommonFormDigest)
      console.log('non-common form data change: ', this.commonFormChange)
    },
    async loadSubFile (file, tableTreeCof, resolve) {
      const { data: subFileResp } = await this.$http.get(this.serverAPI.FILE_SUBLIST_URL + file.id)
      console.log('attached sub file list response: ', subFileResp)
      const subFiles = subFileResp.data
      for (const subFile of subFiles) {
        subFile.rowkey = subFile.id + '-' + new Date().getTime()
      }

      resolve(subFiles)
    },
    async checkFileStatusBeforeDownload (fileObj) {
      console.log('before download file, check the file status fileObj=', fileObj)

      let checkFileStatusURL = ''
      if (this.commonFormData.creator.id === this.currentUser.id) {
        checkFileStatusURL = this.serverAPI.FILE_STATUS_URL + fileObj.fileUniqueID + '/' + this.commonFormData.project.id + '/' + this.currentUser.id
      } else {
        checkFileStatusURL = this.serverAPI.FILE_STATUS_URL + fileObj.id
      }
      const { data: checkResp } = await this.$http.get(checkFileStatusURL)
      console.log('check file status response: ', checkResp)
      if (checkResp.code === 1) {
        if (!checkResp.data.isFolder) {
          window.location.href = this.serverAPI.FILE_DOWNLOAD_URL + checkResp.data.id
          return
        }
        console.log('this is a folder')
      } else {
        this.$alert('<font color="red">' + checkResp.msg + '</font>', 'File Download Noticaition', {
          dangerouslyUseHTMLString: true
        })
      }
    },
    // TODO: generate the unsaved data into the localstorage
    generateDraft () {
    }
  }
}
</script>
<style scoped>
.history-name {
  width: 270px;
  margin-right: 5px;
  margin-bottom: 3px;
}
.history-name:hover {
  cursor: pointer;
  text-decoration: underline;
}
.field-title-container {
  border: solid 1px #d7dae2;
  border-bottom-width: 0;
  border-style:solid none;
  background-color: #F5F7FA;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.field-title-container {
  cursor: pointer;
}
.field-title {
  font-weight: bold;
  font-size: 16px;
  color: #303133;
  line-height: 48px;
  padding-left: 20px;
}
.field-title-button {
  display: flex;
  justify-content: flex-end;
  padding-right: 15px;
}
div /deep/ .el-collapse-item__header {
  font-size: 16px;
  font-weight: bold;
  padding-left: 20px;
  background-color: #F5F7FA;
}
div /deep/ .clearfix span {
  font-size: 16px;
  font-weight: bold;
}
div /deep/ .el-card__header {
  padding-top: 15px;
  padding-bottom: 5px;
  background-color: #F5F7FA;
}
div /deep/ .el-collapse-item__content {
  padding-bottom: 0;
}

div >>> div.el-dialog__body {
  padding-top: 0px;
  padding-bottom: 0px;
}
</style>
