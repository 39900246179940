import { DataFiedConfig } from '@/components/data_component/field_config/DataFiedConfig'
import { USER_LOCAL_STORAGE } from '@/local_storage/UserLocalStorage'

const dataFormModule = {
  namespaced: true,
  state: {
    commonFormData: JSON.parse(JSON.stringify(DataFiedConfig.CommonDataForm)),
    commonFormRules: JSON.parse(JSON.stringify(DataFiedConfig.CommonFormRules)),
    nonCommonFormData: JSON.parse(JSON.stringify(DataFiedConfig.NonCommonDataForm)),
    nonCommonFormRules: JSON.parse(JSON.stringify(DataFiedConfig.NonCommonFormRules)),
    nonCommonFieldRelated: JSON.parse(JSON.stringify(DataFiedConfig.NonCommonFieldRelated)),
    projectRelatedProjects: [],
    projectRelatedFields: [],

    // Experimental data related VUEX states
    dataSearchKeyword: '',
    currentDataPage: 1,
    dataPageSize: 10
  },
  mutations: {
    newFormRule (state, ruleContent) {
      const fieldName = ruleContent.fieldName
      const required = ruleContent.required
      console.log(ruleContent, fieldName, required)
      state.commonFormRules[fieldName][0].required = required
    },
    async initNewCommonFormData (state) {
      state.commonFormData = JSON.parse(JSON.stringify(DataFiedConfig.CommonDataForm))
      state.commonFormRules = JSON.parse(JSON.stringify(DataFiedConfig.CommonFormRules))
      state.projectRelatedProjects = []
      state.nonCommonFormData = JSON.parse(JSON.stringify(DataFiedConfig.NonCommonDataForm))
      state.nonCommonFormRules = JSON.parse(JSON.stringify(DataFiedConfig.NonCommonFormRules))
      state.projectRelatedFields = []
      const currentUser = await USER_LOCAL_STORAGE.getCurrentUser()
      state.commonFormData.creator = currentUser
    },
    initUpdatePageFormData (state, dataDeatil) {
      state.commonFormData = JSON.parse(JSON.stringify(DataFiedConfig.CommonDataForm))
      state.commonFormRules = JSON.parse(JSON.stringify(DataFiedConfig.CommonFormRules))
      state.projectRelatedProjects = []
      state.nonCommonFormData = JSON.parse(JSON.stringify(DataFiedConfig.NonCommonDataForm))
      state.nonCommonFormRules = JSON.parse(JSON.stringify(DataFiedConfig.NonCommonFormRules))
      state.projectRelatedFields = []

      // initiate the commonFormData
      const updateCommonData = dataDeatil.dataDetail
      console.log('inite the commonFormData from DataUpdate page', updateCommonData)
      if (!updateCommonData.licencesType) {
        updateCommonData.licencesType = {}
        updateCommonData.licencesType.desc = ''
      }

      state.commonFormData = updateCommonData

      // init the project of data related projects
      state.projectRelatedProjects = dataDeatil.dataDetail.project.relatedProjects

      // init the nonCommonDataForm
      state.nonCommonFormData = dataDeatil.dataDetail.optionalContent

      // init related fields
      dataDeatil.dataDetail.project.nonCommonFields.forEach(field => {
        state.nonCommonFormRules[field.fieldName][0].required = field.required
        state.projectRelatedFields.push(field.fieldName)
      })
    },
    initNewDataFromBasedDraft (state, dataDraft) {
      state.commonFormData = dataDraft.commonFormData
      state.nonCommonFormData = dataDraft.nonCommonFormData
    },
    changeNonCommonFormRule (state, ruleContent) {
      const fieldName = ruleContent.fieldName
      const required = ruleContent.required
      state.nonCommonFormRules[fieldName][0].required = required
    },
    resetNonCommonFormRule (state) {
      state.projectRelatedFields.forEach(relatedField => {
        state.nonCommonFormRules[relatedField][0].required = false
      })
    },
    changeNonCommonFieldRelated (state, fieldList) {
      console.log('change the nonCommonFieldRelated', state.nonCommonFieldRelated)
      const keyArray = Object.keys(state.nonCommonFieldRelated)
      keyArray.forEach(key => {
        state.nonCommonFieldRelated[key] = false
      })
      fieldList.forEach(field => {
        state.nonCommonFieldRelated[field] = true
      })
    },
    changeLicenseStatus (state, shareMode) {
      console.log('change license status')
      state.commonFormData.shareStatus.shareCode = shareMode.shareCode
      if (shareMode.shareCode === 0) {
        if (state.commonFormData.licencesType === null ||
            state.commonFormData.licencesType === undefined ||
            state.commonFormData.licencesType === 'undefined') {
          state.commonFormData.licencesType = {}
        }
        state.commonFormData.licencesType.desc = ''
      }
    },
    changeAnimalPermitType (state, permitType) {
      state.commonFormData.animalPermitType.permitCode = permitType.permitCode
      if (state.commonFormData.animalPermitFile === null ||
          state.commonFormData.animalPermitFile === undefined ||
          state.commonFormData.animalPermitFile === 'undefined') {
        state.commonFormData.animalPermitFile = {}
      }
      state.commonFormData.animalPermitFile.fileID = ''
    },
    addRelatedProject (state, relatedProjects) {
      state.projectRelatedProjects = relatedProjects
    },
    clearRelatedFields (state) {
      state.projectRelatedFields = []
    },
    setProjectRelatedFields (state, relatedFieldName) {
      state.projectRelatedFields.push(relatedFieldName)
    },
    // Data related VUE states
    setDataSearchKeyword (state, newDataSearchKeyword) {
      state.dataSearchKeyword = newDataSearchKeyword
    },
    setCurrentDataPage (state, newCurrentDataPage) {
      state.currentDataPage = newCurrentDataPage
    },
    setDataPageSize (state, newDataPageSize) {
      state.dataPageSize = newDataPageSize
    }
  },
  actions: {}
}

export default dataFormModule
