<template>
  <section>
    <el-row>
      <el-col :span="24" v-if="writeSwitch">
        <el-tooltip v-for="(relatedData, index) in paperForm.paperRelatedData" :key="relatedData.dataID" :content="relatedData.title">
          <el-tag :style="index > 0? 'margin-left: 5px;' : ''" @click="toDataDetail(relatedData.dataID)">
            <span class="data-title">
              {{relatedData.title}}
            </span>
            <el-popconfirm v-if="btnsVisible"
              confirmButtonText="Yes" title="Confirm to remove?" @confirm="handleCloseDataTag(relatedData)">
              <i slot="reference" class="el-icon-delete data-delete" @click.stop></i>
            </el-popconfirm>
          </el-tag>
        </el-tooltip>
        <span v-if="btnsVisible">
          <el-button class="button-new-tag" size="small" type="primary" @click="showAddRelatedProjectDialog">+ New Related Data Dialog</el-button>
        </span>
      </el-col>
    </el-row>

    <el-dialog title="Add Related Data" :visible.sync="showAddDataFlag" @close="closeAddRelatedProjectDialog" :before-close="beforeCloseAddRelatedProjectDialog" width="80%">
      <section v-show="selectedDataTableRows !== null && selectedDataTableRows !== undefined && selectedDataTableRows.length > 0">
        <el-row style="height: 15px; line-height: 15px;">
          Selected Related Data
        </el-row>
        <el-row>
          <el-tag v-for="(selectedDataRow, index) in selectedDataTableRows" :key="selectedDataRow.id" :style="index > 0? 'margin-left: 3px;' : ''"> {{selectedDataRow.title}} </el-tag>
        </el-row>
      </section>

      <el-row type="flex" justify="start" align="middle" style="border: solid 1px #d7dae2; border-style:solid none;">
        <el-col :span="6" style="font-weight: bold; font-size: 15px;">Data Search Criteria</el-col>
        <el-col style="display: flex; justify-content: flex-end;">
          <el-button v-show="showDataTableFlag" size="small" type="primary" style="padding-left: 9px; padding-right: 5px;" @click="shiftCriteriaShow">More Criteria<i :class="searchCriteriaFlag? 'el-icon-arrow-down' : 'el-icon-arrow-right'"/></el-button>
        </el-col>
      </el-row>

      <el-collapse-transition>
        <div v-show="searchCriteriaFlag">
          <el-form :model="criteriaForm" ref="criteriaFormRef" label-width="130px" label-position="top">
            <el-row>
              <el-form-item label="Project" prop="dataProject">
                <el-checkbox :value="criteriaForm.dataProject.map(item => item.projectName)" v-for="dataProject in dataProjectCriteria" :key="dataProject.id" :label="dataProject.projectName" @change="checkedDataProjectChangeHandler($event, dataProject)" :checked="checkedDataProject(dataProject.id)"></el-checkbox>
              </el-form-item>
            </el-row>
            <el-row type="flex" justify="start" align="middle">
              <el-col :span="12" style="margin-right: 8px;">
                <el-form-item label="PI" prop="dataPI">
                  <el-select size="small" :value="criteriaForm.projectPI.id" style="width: 100%;" filterable clearable placeholder="principal investigator" @change="selectedPIChangeHandler">
                    <el-option v-for="projectRelatedPI in projectRelatedPICriteria" :key="projectRelatedPI.id" :label="projectRelatedPI.realName" :value="projectRelatedPI.id"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12" style="margin-left: 8px;">
                <el-form-item label="Experimenter" prop="experimenter">
                  <el-select size="small" :value="criteriaForm.experimenter.id" style="width: 100%;" filterable clearable placeholder="the person of doing the experiment corresponding to the data" @change="selectedUserChangeHandler">
                    <el-option v-for="projectRelatedUser in projectRelatedUserCriteria" :key="projectRelatedUser.id" :label="projectRelatedUser.realName" :value="projectRelatedUser.id"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row type="flex" justify="start" align="middle">
              <el-col :span="12" style="padding-right: 8px;">
                <el-form-item label="Uploader" prop="uploader">
                  <el-select size="small" :value="criteriaForm.uploader.id" style="width: 100%;" filterable clearable placeholder="the person of uploading the experimental data" @change="selectedUploaderChangeHandler">
                    <el-option v-for="projectRelatedUploader in projectRelatedUserCriteria" :key="projectRelatedUploader.id" :label="projectRelatedUploader.realName" :value="projectRelatedUploader.id"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12" style="padding-left: 8px;">
                <el-form-item label="Data Title" prop="dataTitle">
                  <el-input size="small" v-model="criteriaForm.dataTitle" clearable placeholder="the keywords for searching data title"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>

          <el-row type="flex" width="45%" justify="center" align="middle">
            <el-col style="display: flex; justify-content: flex-end; margin-top: 10px;">
              <el-button size="mini" @click="clearSearchCriteriaDialog">Clear</el-button>
              <el-button size="mini" @click="resetSearchCriteria">Reset</el-button>
              <el-button size="mini" type="primary" @click="submitSearchCriteria">Search</el-button>
            </el-col>
          </el-row>
        </div>
      </el-collapse-transition>

      <el-row el-row style="margin-top: 3px;">
        <span v-show="searchForm.dataProject.length > 0? true:false" style="margin-right: 10px;">
          <b>Project:</b>
          <el-tag v-for="projectItem in searchForm.dataProject" :key="projectItem.id" closable style="margin-left: 3px;" @close="criteriaTagClose('dataProject', 'id', projectItem.id)">{{projectItem.projectName}}</el-tag>
        </span>
        <span v-show="(searchForm.projectPI !== null && searchForm.projectPI !== undefined && Object.keys(searchForm.projectPI).length > 0)? true: false" style="margin-right: 10px;">
          <b>ProjectPI:</b>
          <el-tag closable style="margin-left: 3px;" @close="criteriaTagClose('projectPI', 'id', searchForm.projectPI.id)">{{searchForm.projectPI.realName}}</el-tag>
        </span>
        <span v-show="(searchForm.experimenter !== null && searchForm.experimenter !== undefined && Object.keys(searchForm.experimenter).length > 0)? true: false" style="margin-right: 10px;">
          <b>Experimenter:</b>
          <el-tag closable style="margin-left: 3px;" @close="criteriaTagClose('experimenter', 'id', searchForm.experimenter.id)">{{searchForm.experimenter.realName}}</el-tag>
        </span>
        <span v-show="(searchForm.uploader !== null && searchForm.uploader !== undefined && Object.keys(searchForm.uploader).length > 0)? true: false" style="margin-right: 10px;">
          <b>Uploader:</b>
          <el-tag closable style="margin-left: 3px;" @close="criteriaTagClose('uploader', 'id', searchForm.uploader.id)">{{searchForm.uploader.realName}}</el-tag>
        </span>
        <span v-show="(searchForm.dataTitle !== null && searchForm.dataTitle !== undefined && searchForm.dataTitle !== '')? true: false" style="margin-right: 10px;">
          <b>DataTitle:</b>
          <el-tag closable style="margin-left: 3px;" @close="criteriaTagClose('dataTitle', '', searchForm.dataTitle)">{{searchForm.dataTitle}}</el-tag>
        </span>
      </el-row>

      <section v-show="showDataTableFlag">
        <el-row>
          <el-table ref="relatedDataTableRef" :data="dataList" :stripe="true" :border="true" :row-key="row => row.dataID" :highlight-current-row="true" @selection-change="selectionChangeHandler">
            <el-table-column type="selection" :reserve-selection="true" width="55" align="center" :selectable="checkTableRowSelectable"></el-table-column>
            <el-table-column prop="title" label="Data Title" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column prop="project.projectName" label="Project" align="center"></el-table-column>
            <el-table-column prop="author.realName" label="Experimenter" align="center"></el-table-column>
            <el-table-column prop="responsiblePI.realName" label="PI" align="center"></el-table-column>
            <el-table-column prop="creator.realName" label="Uploader" align="center"></el-table-column>
            <el-table-column label="Update Time" align="center">
              <template v-slot="dataSlot">
                {{dataSlot.row.updateTime | formatDate}}
              </template>
            </el-table-column>
          </el-table>

          <el-pagination style="float: right; margin-bottom: 10px;"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="paginationInfo.currentPage"
            :page-sizes="[5, 10]"
            :page-size="paginationInfo.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="paginationInfo.totalCount">
          </el-pagination>
        </el-row>

        <el-row type="flex" width="45%" justify="center" align="middle">
          <el-col style="display: flex; justify-content: flex-end; margin-top: 10px;">
            <el-button @click="cancelBtnHanlder">Cancel</el-button>
            <el-button type="primary" @click="addSelectedData">OK</el-button>
          </el-col>
        </el-row>
      </section>
    </el-dialog>
  </section>
</template>
<script>
import { mapState } from 'vuex'
import JsSHA from 'jssha'
import { EVENT_CONFIG } from '@/event_bus'

export default {
  props: ['paperForm', 'btnsVisible'],
  async mounted () {
  },
  data () {
    return {
      searchCriteriaFlag: true,
      currentUser: { id: '' },
      showAddDataFlag: false,
      criteriaForm: {
        dataProject: [],
        projectPI: {},
        uploader: {},
        experimenter: {},
        dataTitle: ''
      },
      searchForm: {
        dataSearchKey: null,
        dataProject: [],
        projectPI: {},
        uploader: {},
        experimenter: {},
        dataTitle: ''
      },
      dataProjectCriteria: [],
      projectRelatedPICriteria: [],
      dataPICriteria: [],
      projectRelatedUserCriteria: [],
      dataUserCriteria: [],
      paginationInfo: {
        currentPage: 1,
        pageSize: 10,
        totalCount: 0
      },
      dataList: [],
      selectedDataTableRows: [],
      showDataTableFlag: false
    }
  },
  computed: {
    ...mapState({
      writeSwitch: (state) => state.writableStatus
    })
  },
  methods: {
    /**
     * this method is used for the datatable the 'selection' type to determin the row is selectable or not
     * based on the 'paperRelatedData' property of the paperForm object
     */
    checkTableRowSelectable (row, index) {
      if (this.paperForm.paperRelatedData.length === 0) { return true }
      const dataIndex = this.paperForm.paperRelatedData.findIndex(relatedDataItem => {
        return relatedDataItem.dataID === row.dataID
      })
      if (dataIndex === -1) {
        return true
      }
      return false
    },
    /**
     * add the selected data to the paperForm
     */
    addSelectedData () {
      console.log('click the selected data to the paper form object')
      console.log('the selected data:', this.selectedDataTableRows)
      if (!this.selectedDataTableRows.length) {
        this.$message({
          showClose: true,
          message: 'No selected data!',
          type: 'error'
        })
        return
      }

      if (!this.paperForm && !Object.keys(this.paperForm).length) {
        this.$message({
          showClose: true,
          message: 'Unvalidated paper form object!',
          type: 'error'
        })
        return
      }

      console.log('paper form object:', this.paperForm)
      const duplicatedDataList = []
      let duplicatedAlertContent = ''
      this.selectedDataTableRows.forEach(selectedDataTableRow => {
        const duplicatedDataItem = this.paperForm.paperRelatedData.find(dataItem => {
          return dataItem.dataID === selectedDataTableRow.dataID
        })

        if (duplicatedDataItem) {
          duplicatedDataList.push(duplicatedDataItem)

          const contentItem = '<font color="red"><b>' + duplicatedDataItem.title + '</b></font>'
          if (duplicatedAlertContent !== '') {
            duplicatedAlertContent += ','
          }
          duplicatedAlertContent += contentItem
        }
      })

      if (duplicatedDataList.length > 0) {
        const resetSelectedDataRows = this.selectedDataTableRows.filter(selectedData => {
          const duplicatedDataIndex = duplicatedDataList.findIndex(dataItem => {
            return dataItem.dataID === selectedData.dataID
          })

          return duplicatedDataIndex === -1
        })
        console.log('the resetSelectedDataRows:', resetSelectedDataRows)

        this.$alert('duplicated data existing:' + duplicatedAlertContent, 'Duplicated Selected Data', {
          confirmButtonText: 'OK',
          dangerouslyUseHTMLString: true,
          callback: action => {
            console.log('remove the duplicated data:', duplicatedDataList)
            this.paperForm.paperRelatedData.push(...resetSelectedDataRows)
          }
        })
      } else {
        this.paperForm.paperRelatedData.push(...this.selectedDataTableRows)
      }

      this.closeAddRelatedProjectDialog()
    },
    handleCloseDataTag (selectedData) {
      const selectedDataPos = this.paperForm.paperRelatedData.findIndex(relatedData => {
        return relatedData.dataID === selectedData.dataID
      })
      console.log('handle the event of closing the related data tag')

      this.paperForm.paperRelatedData.splice(selectedDataPos, 1)

      EVENT_CONFIG.EventBus.$emit(EVENT_CONFIG.CHECK_FORM_FIELD_EVENT, null, 'paperRelatedData')
    },
    async showAddRelatedProjectDialog () {
      const { data: criteriaResp } = await this.$http.get(this.serverAPI.DATA_LIST_CRITERIA_URL)
      console.log('data list criteria response content: ', criteriaResp)

      this.dataProjectCriteria = criteriaResp.data.projectCriteria
      this.dataPICriteria = criteriaResp.data.piCriteria
      this.dataUserCriteria = criteriaResp.data.userCriteria

      this.initiatePrejectRelatedSelection()

      this.searchCriteriaFlag = true

      this.showAddDataFlag = true
    },
    closeAddRelatedProjectDialog () {
      this.showAddDataFlag = false
      this.showDataTableFlag = false

      this.emptySearchForm()
      this.emptyCriteriaForm()
      this.clearSelectedDataRows()
    },
    cancelBtnHanlder () {
      this.beforeCloseAddRelatedProjectDialog(this.closeAddRelatedProjectDialog)
    },
    beforeCloseAddRelatedProjectDialog (done) {
      this.$confirm('Close the dialog and clear the selected data?', {
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        closeOnClickModal: false,
        closeOnPressEscape: false
      }).then(async _ => {
        done()
      }).catch(_ => {
        console.log('cancel close the dialog to add related projects of the paper')
      })
    },
    /**
     * event handler to deal with event of the value of the project checkbox is change
     */
    checkedDataProjectChangeHandler (checkedFlag, selectedDataProject) {
      if (checkedFlag) {
        this.criteriaForm.dataProject.push(selectedDataProject)
      } else {
        const removeIndex = this.criteriaForm.dataProject.findIndex(projectItem => {
          return projectItem.id === selectedDataProject.id
        })

        this.criteriaForm.dataProject.splice(removeIndex, 1)
      }

      this.initiatePrejectRelatedSelection()
    },
    /**
     * used to play back the selected project to be selected
     */
    checkedDataProject (projectID) {
      const projectIndex = this.criteriaForm.dataProject.findIndex(projectItem => {
        return projectItem.id === projectID
      })

      return projectIndex >= 0
    },
    /**
     * generate the selection option list for the 'projectRelatedPICriteria'
     */
    genreateProjecrPICriteria () {
      this.dataPICriteria.forEach(piItem => {
        if (this.projectRelatedPICriteria.length === 0) {
          this.projectRelatedPICriteria = this.projectRelatedPICriteria.concat(piItem.piList)
        } else {
          if (piItem.piList) {
            piItem.piList.forEach(pi => {
              const relatedPIIndex = this.projectRelatedPICriteria.findIndex(projectRelatedPI => {
                return projectRelatedPI.id === pi.id
              })

              if (relatedPIIndex === -1) {
                this.projectRelatedPICriteria.push(pi)
              }
            })
          }
        }
      })
    },
    /**
     * generate the selection option list for the 'projectRelatedUserCriteria'
     */
    generateProjectUserCriteria () {
      this.dataUserCriteria.forEach(userItem => {
        if (this.projectRelatedUserCriteria.length === 0) {
          this.projectRelatedUserCriteria = this.projectRelatedUserCriteria.concat(userItem.userList)
        } else {
          if (userItem.userList) {
            userItem.userList.forEach(user => {
              const relatedUserIndex = this.projectRelatedUserCriteria.findIndex(projectRelatedUser => {
                return projectRelatedUser.id === user.id
              })

              if (relatedUserIndex === -1) {
                this.projectRelatedUserCriteria.push(user)
              }
            })
          }
        }
      })
    },
    /**
     * initiate the selection option list based on the 'criteriaForm' data
     */
    initiatePrejectRelatedSelection () {
      if (this.criteriaForm.dataProject.length === 0) {
        this.genreateProjecrPICriteria()
        this.generateProjectUserCriteria()
        return
      }

      this.projectRelatedPICriteria = []
      this.projectRelatedUserCriteria = []

      this.criteriaForm.dataProject.forEach(projectItem => {
        const projectPI = this.dataPICriteria.find(projectPI => {
          return projectPI.project.id === projectItem.id
        })

        if (projectPI !== null && projectPI !== undefined && projectPI.piList !== null) {
          if (this.projectRelatedPICriteria.length === 0) {
            this.projectRelatedPICriteria = this.projectRelatedPICriteria.concat(projectPI.piList)
          } else {
            projectPI.piList.forEach(projectPIItem => {
              const relatedPIIndex = this.projectRelatedPICriteria.findIndex(projectRelatedPI => {
                return projectRelatedPI.id === projectPIItem.id
              })

              if (relatedPIIndex === -1) {
                this.projectRelatedPICriteria.push(projectPIItem)
              }
            })
          }
        }

        const projectUser = this.dataUserCriteria.find(projectUser => {
          return projectUser.project.id === projectItem.id
        })
        if (projectUser !== null && projectUser !== undefined && projectUser.userList !== null) {
          if (this.projectRelatedUserCriteria.length === 0) {
            this.projectRelatedUserCriteria = this.projectRelatedUserCriteria.concat(projectUser.userList)
          } else {
            projectUser.userList.forEach(projectUserItem => {
              const relatedUserIndex = this.projectRelatedUserCriteria.findIndex(projectRelatedUser => {
                return projectRelatedUser.id === projectUserItem.id
              })

              if (relatedUserIndex === -1) {
                this.projectRelatedUserCriteria.push(projectUserItem)
              }
            })
          }
        }
      })
    },
    /**
     * assign the projectPI based on the selected project pi id
     */
    selectedPIChangeHandler (selectedPIID) {
      console.log('selected project PI ID:', selectedPIID)
      this.criteriaForm.projectPI = {}
      if (selectedPIID !== null && selectedPIID !== '' && selectedPIID !== undefined) {
        const selectedProjectPI = this.projectRelatedPICriteria.find(projectRelatedPI => {
          if (projectRelatedPI.id === selectedPIID) {
            return projectRelatedPI
          }
        })
        this.criteriaForm.projectPI = selectedProjectPI
      }
    },
    /**
     * assign the experimenter based on the selected project user ID
     */
    selectedUserChangeHandler (selectedUserID) {
      console.log('selected project user ID:', selectedUserID)
      this.criteriaForm.experimenter = {}
      if (selectedUserID !== null && selectedUserID !== '' && selectedUserID !== undefined) {
        const selectedProjectUser = this.projectRelatedUserCriteria.find(projectRelatedUser => {
          if (projectRelatedUser.id === selectedUserID) {
            return projectRelatedUser
          }
        })
        this.criteriaForm.experimenter = selectedProjectUser
      }
    },
    selectedUploaderChangeHandler (selectedUploaderID) {
      console.log('selected experimental data uploader ID:', selectedUploaderID)
      this.criteriaForm.uploader = {}
      if (selectedUploaderID !== null && selectedUploaderID !== '' && selectedUploaderID !== undefined) {
        const selectedProjectUploader = this.projectRelatedUserCriteria.find(projectRelatedUser => {
          if (projectRelatedUser.id === selectedUploaderID) {
            return projectRelatedUser
          }
        })
        this.criteriaForm.uploader = selectedProjectUploader
      }
    },
    /**
     * reset the criteriaForm based on the searchForm
     */
    resetSearchCriteria () {
      this.$confirm('Reset search data criteria?', {
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        closeOnClickModal: false,
        closeOnPressEscape: false
      }).then(async _ => {
        const searchStr = JSON.stringify(this.searchForm)
        this.criteriaForm = JSON.parse(searchStr)

        this.initiatePrejectRelatedSelection()
        // call the search data list
        await this.searchDataList()
      }).catch(_ => {
        console.log('cancel reset data search criteria')
      })
    },
    /**
     * clear the criteriaForm
     */
    clearSearchCriteriaDialog () {
      this.$confirm('Clear search data criteria?', {
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        closeOnClickModal: false,
        closeOnPressEscape: false
      }).then(async _ => {
        this.emptyCriteriaForm()
        this.initiatePrejectRelatedSelection()
        this.emptySearchForm()
        this.paginationInfo.currentPage = 1
        this.paginationInfo.pageSize = 5

        // to call search data list
        await this.searchDataList()
      }).catch(_ => {
        console.log('cancel to clear the data search criteria data')
      })
    },
    emptyCriteriaForm () {
      this.criteriaForm = {
        dataProject: [],
        projectPI: {},
        uploader: {},
        experimenter: {},
        dataTitle: ''
      }
    },
    emptySearchForm () {
      this.searchForm = {
        dataProject: [],
        projectPI: {},
        uploader: {},
        experimenter: {},
        dataTitle: ''
      }
    },
    // assing the search criteria parameter from the criteriaForm to searchFrom
    async submitSearchCriteria () {
      const criteriaStr = JSON.stringify(this.criteriaForm)
      this.searchForm = JSON.parse(criteriaStr)

      this.searchCriteriaFlag = false

      // clear the selected data list
      this.clearSelectedDataRows()

      // call the search data list
      this.paginationInfo.currentPage = 1
      this.paginationInfo.pageSize = 10
      await this.searchDataList()
    },
    async searchDataList () {
      const urlParams = this.$qs.stringify(this.paginationInfo)
      // const { data: dataListResp } = await this.$http.get('/data/list/' + this.dataSearchKey + '?' + urlParams)
      this.searchForm.dataSearchKey = this.criteriaForm.dataTitle
      const { data: dataListResp } = await this.$http.post(this.serverAPI.DATA_LIST_URL + '?' + urlParams, JSON.stringify(this.searchForm), { headers: { 'content-type': 'application/json' } })
      console.log('data search list response content: ', dataListResp)
      this.dataList = dataListResp.data.records
      this.paginationInfo.currentPage = dataListResp.data.current
      this.paginationInfo.pageSize = dataListResp.data.size
      this.paginationInfo.totalCount = dataListResp.data.total

      this.showDataTableFlag = true
    },
    async shiftCriteriaShow () {
      this.paginationInfo.currentPage = 1

      if (!this.searchCriteriaFlag) {
        await this.handleSizeChange(5)
        this.searchCriteriaFlag = !this.searchCriteriaFlag
      } else {
        const searchFormStr = JSON.stringify(this.searchForm)
        const criteriaFormStr = JSON.stringify(this.criteriaForm)

        const searchEncrypter = new JsSHA('SHA-256', 'TEXT')
        searchEncrypter.update(searchFormStr)
        const searchDig = searchEncrypter.getHash('HEX')
        console.log(searchDig)

        const criteriaEncrypter = new JsSHA('SHA-256', 'TEXT')
        criteriaEncrypter.update(criteriaFormStr)
        const criteriaDig = criteriaEncrypter.getHash('HEX')
        console.log(criteriaDig)
        if (searchDig === criteriaDig) {
          await this.handleSizeChange(10)
          this.searchCriteriaFlag = !this.searchCriteriaFlag
        } else {
          this.$confirm('Unsubmitted search criteria, submit?', {
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            closeOnClickModal: false,
            closeOnPressEscape: false
          }).then(async _ => {
            console.log('confirm to submit')
            this.searchForm = JSON.parse(criteriaFormStr)

            await this.submitSearchCriteria()
          }).catch(_ => {
            console.log('cancel to submit paper search criteria')
          })
        }
      }
    },
    /**
     * this is used for pagination, change the page size
     */
    async handleSizeChange (newPageSize) {
      console.log('page size change', newPageSize)
      this.paginationInfo.pageSize = newPageSize
      await this.searchDataList()
    },
    /**
     * this is used for pagination, change the current page
     */
    async handleCurrentChange (newCurrentPage) {
      console.log('current page', newCurrentPage)
      this.paginationInfo.currentPage = newCurrentPage
      await this.searchDataList()
    },
    /**
     * get the current selected data rows of the data table
     */
    selectionChangeHandler (rows) {
      this.selectedDataTableRows = rows
    },
    // clear the selected data rows of the data table list
    clearSelectedDataRows () {
      this.$refs.relatedDataTableRef.clearSelection()
    },
    /**
     * the method for the tag to close the tag for the search criteria
     * to remove the correspongding search criteria
     */
    criteriaTagClose (formFieldName, itemFieldName, criteriaContent) {
      console.log('close the tag formFieldName:', formFieldName)
      console.log('close the tag criteria:', criteriaContent)

      if (this.searchForm[formFieldName] instanceof Array) {
        const arrayContent = this.searchForm[formFieldName]
        const criteriaIndex = arrayContent.findIndex(item => {
          return item[itemFieldName] === criteriaContent
        })
        arrayContent.splice(criteriaIndex, 1)
      } else if (this.searchForm[formFieldName] instanceof Object) {
        console.log('remove the form object field value from search form:', this.searchForm[formFieldName])
        this.searchForm[formFieldName] = {}
      } else {
        console.log('remove the form other types field from search form:', this.searchForm[formFieldName])
        this.searchForm[formFieldName] = ''
      }

      const searchFormStr = JSON.stringify(this.searchForm)
      this.criteriaForm = JSON.parse(searchFormStr)
      this.initiatePrejectRelatedSelection()

      this.searchDataList()
    },
    toDataDetail (dataID) {
      console.log('data id is', dataID)
      this.$router.push({ path: `/data/detail/${dataID}` })
    }
  }
}
</script>
<style scoped>
 @import '../paper_res/PaperStyle.css';
 div /deep/ .el-form-item__label {
  padding-bottom: 0px;
}
div /deep/ .el-dialog__body {
  padding-bottom: 10px;
  padding-top: 0;
}

div /deep/ .el-collapse-item__header {
  font-weight: bold;
}

div /deep/ .el-table {
  margin-top: 0;
}

div /deep/ .el-table__cell {
  line-height: 1;
  padding: 5px 0;
}

.data-title {
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
  max-width: 150px;
  height: 32px;
  vertical-align: middle;
}

.data-title:hover {
  cursor: pointer;
  text-decoration:underline;
  font-weight: bold;
}

.data-delete {
  height: 16px;
  width: 16px;
  font-size: 12px;
  text-align: center;
  line-height: 16px;
}
.data-delete:hover {
  cursor: pointer;
  background-color: #409EFF;
  color: #FFF;
  border-radius: 50%;
}
</style>
