<template>
  <section>
    <el-row type="flex" justify="end" align="middle">
      <el-col v-if="currentLoginUser.adminFlag" style="display: flex; justify-content: flex-start; margin-right: 5px;">
        <el-button type="primary" @click="showAddDialogCaller" v-if="writeSwitch">Add User</el-button>
      </el-col>
      <el-col v-if="projectID === ''" :span="2" style="display: flex; justify-content: flex-end; margin-right: 5px;">
        <el-button type="primary" @click="criteriaDialog">Search Criteria</el-button>
      </el-col>
      <el-col :span="6" style="display: flex; justify-content: flex-end;">
        <el-input v-model="userSearchKey" clearable @change="searchUserHandler" placeholder="Please input user real name to search">
          <el-button slot="append" icon="el-icon-search" @click="searchUserHandler"></el-button>
        </el-input>
      </el-col>
    </el-row>
    <el-row>
      <el-table ref="userTableRef" :data="userList" border stripe row-key="id">
        <el-table-column type="index" label="#" align="center" :index="indexUserRow"></el-table-column>
        <el-table-column prop="userName" label="User Name" align="center" width="100px"></el-table-column>
        <el-table-column prop="realName" label="Name" align="center"></el-table-column>
        <el-table-column prop="userEmail" label="Email" align="center" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column label="Project" align="center">
          <template v-slot:="slotProps">
            <span v-if="slotProps.row.projectRoles != null && slotProps.row.projectRoles.length > 0">
              <el-tag type="success" style="margin-right:5px;" v-for="projectRole in slotProps.row.projectRoles" :key="projectRole.project.id">
                {{projectRole.project.projectName}}
                <span v-if="projectRole.role">
                  ({{projectRole.role.roleName}})
                </span>
              </el-tag>
            </span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column v-if="projectID !== ''" label="Status" align="center">
          <template v-slot:="slotProps">
            <span v-if="slotProps.row.projectRoles != null && slotProps.row.projectRoles.length > 0">
              <el-tag type="success" style="margin-right:5px;" v-for="projectRole in slotProps.row.projectRoles" :key="projectRole.project.id">
                {{projectRole.status.statusName}}
              </el-tag>
            </span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column label="Application Date" align="center" width="160px">
          <template v-slot="dataSlot">
            {{dataSlot.row.updateTime | formatDate}}
          </template>
        </el-table-column>
        <el-table-column label="Action" align="center">
          <template v-slot:="slotProps">
            <el-tooltip effect="dark" :content="currentLoginUser.adminFlag? 'edit user' : 'view user detail'" placement="top" :enterable="false">
              <el-button type="primary" :icon="currentLoginUser.adminFlag? 'el-icon-edit': 'el-icon-view'" size="mini" @click="displayEditDialog(slotProps.row.id)"></el-button>
            </el-tooltip>
            <span v-if="writeSwitch">
              <span v-if="projectID !== '' && isProjectPI && slotProps.row.id !== currentLoginUser.id && slotProps.row.projectRoles[0].hasSupervisor" style="margin-left: 5px;">
                <el-tooltip v-if="slotProps.row.projectRoles[0].status.statusCode === 0" effect="dark" content="remove from the project" placement="top" :enterable="false">
                  <el-button type="danger" icon="el-icon-turn-off" size="mini" @click="disableUserProjectRole(slotProps.row)"></el-button>
                </el-tooltip>
                <el-tooltip v-else effect="dark" content="approve to the project" placement="top" :enterable="false">
                  <el-button type="success" icon="el-icon-open" size="mini" @click="enableUserProjectRole(slotProps.row)"></el-button>
                </el-tooltip>
                <el-badge :is-dot="slotProps.row.projectRoles[0].status.statusCode === 1? true: false" class="item"></el-badge>
              </span>
            </span>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination style="float: right; margin-bottom: 10px;"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="paginationInfo.currentPage"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="paginationInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="paginationInfo.totalCount">
      </el-pagination>
    </el-row>

    <el-dialog title="Search Criteria" :visible.sync="criteriaDialogVisible" width="45%" :before-close="confirmSearchUserDialogClose" @close="searchUserDialogClose">
      <el-form :model="criteriaForm" ref="criteriaFormRef" label-width="130px" label-position="top">
        <el-row>
          <el-form-item label="Project" prop="userProject">
            <el-checkbox :value="criteriaForm.userProject.map(item => item.projectName)" v-for="project in projectCriteria" :key="project.id" :label="project.projectName" @change="changeCheckedUserProjectHandler($event, project)" :checked="checkedUserProject(project.id)"></el-checkbox>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item label="User Role" prop="userRole">
            <el-checkbox :value="criteriaForm.userRole.map(item => item.roleName)" v-for="userRole in userRoleCriteria" :key="userRole.id" :label="userRole.roleName" @change="changeCheckedUserRoleHandler($event, userRole)" :checked="checkedUserRole(userRole.id)"></el-checkbox>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item label="User Status" prop="userStatus">
            <el-checkbox :value="criteriaForm.userStatus.map(item => item.statusName)" v-for="userStatus in userStatusCriteria" :key="userStatus.statusCode" :label="userStatus.statusName" @change="changeCheckedUserStatusHandler($event, userStatus)" :checked="checkedUserStatus(userStatus.statusCode)"></el-checkbox>
          </el-form-item>
        </el-row>
      </el-form>

      <el-row type="flex" justify="center" align="middle">
        <el-col style="display: flex; justify-content: flex-end; margin-top: 10px;">
          <el-button @click="clearSearchCriteriaDialog">Clear</el-button>
          <el-button @click="resetSearchCriteria">Reset</el-button>
          <el-button type="primary" @click="submitSearchCriteria">Search</el-button>
        </el-col>
      </el-row>
    </el-dialog>
  </section>
</template>
<script>
import { mapState } from 'vuex'
import { EVENT_CONFIG } from '@/event_bus'
import { CheckUserRole } from '@/components/user_component/CheckUserProjectRole'

export default {
  props: ['projectID'],
  async mounted () {
    this.currentLoginUser = await this.$userStorage.getCurrentUser()

    this.isProjectPI = CheckUserRole.isProjectPI(this.projectID, this.currentLoginUser.projectRoles)
    console.log('isProjectPI====', this.isProjectPI)

    EVENT_CONFIG.EventBus.$on(EVENT_CONFIG.USERLIST_LOAD_EVENT, this.loadUserListEventHandler)
    EVENT_CONFIG.EventBus.$on(EVENT_CONFIG.USERLIST_CLEAR_CRITERIA_EVENT, this.clearCriteriaHandler)

    this.changeOffsetFlag()
    window.onresize = () => {
      this.changeOffsetFlag()
    }
  },
  beforeDestroy () {
    console.debug('UserTable.vue beforeDestory method executes.....')
    EVENT_CONFIG.EventBus.$off(EVENT_CONFIG.USERLIST_LOAD_EVENT)
    EVENT_CONFIG.EventBus.$off(EVENT_CONFIG.USERLIST_CLEAR_CRITERIA_EVENT)
  },
  data () {
    return {
      currentLoginUser: {},
      isProjectPI: false,
      offsetFlag: true,
      userList: [],
      paginationInfo: {
        currentPage: 1,
        pageSize: 10,
        totalCount: 0
      },
      userSearchKey: '',
      criteriaDialogVisible: false,
      projectCriteria: [],
      userRoleCriteria: [],
      userStatusCriteria: [],
      criteriaForm: {
        userProject: [],
        userRole: [],
        userStatus: []
      },
      searchForm: {
        userProject: [],
        userRole: [],
        userStatus: []
      }
    }
  },
  computed: {
    ...mapState({
      writeSwitch: (state) => state.writableStatus
    })
  },
  methods: {
    /**
     * emit the event of adding user
     */
    showAddDialogCaller () {
      console.log('emit the event of displaying the user adding dialog')
      EVENT_CONFIG.EventBus.$emit(EVENT_CONFIG.USER_ADD_EVENT)
    },
    changeOffsetFlag () {
      console.log('window body width:', document.body.clientWidth)
      if (document.body.clientWidth >= 1920) {
        this.offsetFlag = true
      } else {
        this.offsetFlag = false
      }
    },
    /**
     * formFieldInfo structure:
     *            formField,
     *            itemKey,
     *            itemValue
     */
    async loadUserListEventHandler (activeProjectID, formFieldInfo) {
      if (activeProjectID === this.projectID && this.projectID !== '') {
        console.log('active tab project ID:', activeProjectID)
        await this.getUserList()
        // invoke the event of update the information of display the criteria information
        EVENT_CONFIG.EventBus.$emit(EVENT_CONFIG.USERLIST_CRITERIA_EVENT, this.searchForm)
        return
      }

      if (!activeProjectID && this.projectID === '') {
        console.log('reload user list event param is null')

        if (formFieldInfo) {
          console.log('form field information:', formFieldInfo)
          const formFieldName = formFieldInfo.formField
          const itemFieldName = formFieldInfo.itemKey
          const itemFieldValue = formFieldInfo.itemValue

          if (this.searchForm[formFieldName] instanceof Array) {
            const arrayContent = this.searchForm[formFieldName]
            const criteriaIndex = arrayContent.findIndex(item => {
              return item[itemFieldName] === itemFieldValue
            })
            arrayContent.splice(criteriaIndex, 1)
          } else if (this.searchForm[formFieldName] instanceof Object) {
            this.searchForm[formFieldName] = {}
          } else {
            this.searchForm[formFieldName] = ''
          }

          this.paginationInfo.currentPage = 1
          this.paginationInfo.pageSize = 10
        }
        await this.getUserList()

        // invoke the event of update the information of display the criteria information
        EVENT_CONFIG.EventBus.$emit(EVENT_CONFIG.USERLIST_CRITERIA_EVENT, this.searchForm)
      }
    },
    async getUserList () {
      console.log('get user list projectID:', this.projectID)
      let urlParams = this.$qs.stringify(this.paginationInfo)
      urlParams = urlParams + '&projectID=' + this.projectID
      // const { data: userListResp } = await this.$http.get('/user/search?' + urlParams)
      let searchURL = this.serverAPI.USER_SEARCH_URL
      if (this.userSearchKey !== null && this.userSearchKey !== undefined && this.userSearchKey.trim !== '') {
        searchURL += '/' + this.userSearchKey
      }
      const { data: userListResp } = await this.$http.post(searchURL + '?' + urlParams, JSON.stringify(this.searchForm), { headers: { 'content-type': 'application/json' } })
      console.log('user list response content: ', userListResp)
      this.userList = userListResp.data.records
      this.paginationInfo.currentPage = userListResp.data.current
      this.paginationInfo.pageSize = userListResp.data.size
      this.paginationInfo.totalCount = userListResp.data.total
    },
    handleSizeChange (newPageSize) {
      console.log('user list table size change', newPageSize)
      this.paginationInfo.pageSize = newPageSize
      this.getUserList()
    },
    handleCurrentChange (newCurrentPage) {
      console.log('user list current page', newCurrentPage)
      this.paginationInfo.currentPage = newCurrentPage
      this.getUserList()
    },
    displayEditDialog (userID) {
      console.log('emit the event handler to show user detail dialog. ', userID)
      EVENT_CONFIG.EventBus.$emit(EVENT_CONFIG.USER_DETAIL_EVENT, userID)
    },
    disableUserProjectRole (userRow) {
      console.log('relation content:', userRow.projectRoles[0])
      this.$confirm('Disable the relation of ' + userRow.realName + ' and ' + userRow.projectRoles[0].project.projectName + ' as \'' + userRow.projectRoles[0].role.roleName + '\'?', {
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        closeOnClickModal: false,
        closeOnPressEscape: false
      }).then(async _ => {
        console.log('disable relationID: ', userRow.projectRoles[0])
        const { data: response } = await this.$http.post(this.serverAPI.USER_RELATION_DISABLE_URL, JSON.stringify(userRow.projectRoles[0]), { headers: { 'content-type': 'application/json' } })
        console.log('disable user relation repsonse:', response)

        this.getUserList()
        if (response.code === 1) {
          this.$message({
            showClose: true,
            message: 'enable user project relation successfully',
            center: true,
            type: 'success'
          })
        } else {
          this.$message({
            showClose: true,
            message: response.msg,
            center: true,
            type: 'error'
          })
        }
      }).catch(_ => {
        console.log('cancel the disable opration')
      })
    },
    async enableUserProjectRole (userRow) {
      console.log('enable relationID: ', userRow.projectRoles[0])
      this.$confirm('Enable the relation of ' + userRow.realName + ' and ' + userRow.projectRoles[0].project.projectName + ' as \'' + userRow.projectRoles[0].role.roleName + '\'?', {
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        closeOnClickModal: false,
        closeOnPressEscape: false
      }).then(async _ => {
        const { data: response } = await this.$http.post(this.serverAPI.USER_RELATION_ENABLE_URL, JSON.stringify(userRow.projectRoles[0]), { headers: { 'content-type': 'application/json' } })
        console.log('enable user relation repsonse:', response)

        if (userRow.projectRoles[0].status.statusCode === 1) {
          console.log('invoke the "reload red dot" event')
          EVENT_CONFIG.EventBus.$emit(EVENT_CONFIG.USER_TAB_REDDOT_EVENT)
        }

        this.getUserList()
        if (response.code === 1) {
          this.$message({
            showClose: true,
            message: 'enable user project relation successfully',
            center: true,
            type: 'success'
          })
        } else {
          this.$message({
            showClose: true,
            message: response.msg,
            center: true,
            type: 'error'
          })
        }
      }).catch(_ => {
        console.log('cancel the enable opration')
      })
    },
    async searchUserHandler () {
      console.log('user search keyword', this.userSearchKey)
      this.paginationInfo.currentPage = 1
      this.paginationInfo.pageSize = 10

      await this.getUserList()
    },
    changeCheckedUserProjectHandler (checkFlag, selectedUserProject) {
      if (checkFlag) {
        this.criteriaForm.userProject.push(selectedUserProject)
      } else {
        const removeIndex = this.criteriaForm.userProject.findIndex(projectItem => {
          return projectItem.id === selectedUserProject.id
        })
        this.criteriaForm.userProject.splice(removeIndex, 1)
      }
    },
    checkedUserProject (checkedProjectID) {
      const checkedProjectIndex = this.criteriaForm.userProject.findIndex(projectItem => {
        return projectItem.id === checkedProjectID
      })
      return checkedProjectIndex >= 0
    },
    changeCheckedUserRoleHandler (checkFlag, selectedUserRole) {
      if (checkFlag) {
        this.criteriaForm.userRole.push(selectedUserRole)
      } else {
        const removeIndex = this.criteriaForm.userRole.findIndex(roleItem => {
          return roleItem.id === selectedUserRole.id
        })
        this.criteriaForm.userRole.splice(removeIndex, 1)
      }
    },
    checkedUserRole (checkedUserRoleID) {
      const checkedUserRoleIndex = this.criteriaForm.userRole.findIndex(roleItem => {
        return roleItem.id === checkedUserRoleID
      })
      return checkedUserRoleIndex >= 0
    },
    changeCheckedUserStatusHandler (checkFlag, selectedUserStatus) {
      console.log('checked user status flag', checkFlag)
      if (checkFlag) {
        this.criteriaForm.userStatus.push(selectedUserStatus)
      } else {
        const removeIndex = this.criteriaForm.userStatus.findIndex(statusItem => {
          return statusItem.statusCode === selectedUserStatus.statusCode
        })
        this.criteriaForm.userStatus.splice(removeIndex, 1)
      }
    },
    checkedUserStatus (checkedUserStatusCode) {
      const checkedUserStatusIndex = this.criteriaForm.userStatus.findIndex(statusItem => {
        return statusItem.id === checkedUserStatusCode
      })
      return checkedUserStatusIndex >= 0
    },
    async submitSearchCriteria () {
      const criteriaStr = JSON.stringify(this.criteriaForm)
      this.searchForm = JSON.parse(criteriaStr)

      await this.getUserList()
      this.searchUserDialogClose()

      // invoke the event of update the information of display the criteria information
      EVENT_CONFIG.EventBus.$emit(EVENT_CONFIG.USERLIST_CRITERIA_EVENT, this.searchForm)
    },
    resetSearchCriteria () {
      this.$confirm('Reset search criteria?', {
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        closeOnClickModal: false,
        closeOnPressEscape: false
      }).then(_ => {
        const searchStr = JSON.stringify(this.searchForm)
        this.criteriaForm = JSON.parse(searchStr)
      }).catch(_ => {
        console.log('cancel reset user search criteria')
      })
    },
    clearSearchCriteriaDialog () {
      this.$confirm('Clear search criteria?', {
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        closeOnClickModal: false,
        closeOnPressEscape: false
      }).then(async _ => {
        this.criteriaForm = {
          userProject: [],
          userRole: [],
          userStatus: []
        }

        await this.clearCriteria()
      }).catch(_ => {
        console.log('cancel clear user search criteria')
      })
    },
    async clearCriteriaHandler () {
      if (this.projectID === '') {
        await this.clearCriteria()
      }
    },
    async clearCriteria () {
      this.searchForm = {
        userProject: [],
        userRole: [],
        userStatus: []
      }

      this.paginationInfo.currentPage = 1
      this.paginationInfo.pageSize = 10

      await this.getUserList()
      // invoke the event of update the information of display the criteria information
      EVENT_CONFIG.EventBus.$emit(EVENT_CONFIG.USERLIST_CRITERIA_EVENT, this.searchForm)
    },
    async criteriaDialog () {
      console.log('open criteria dialog')

      const { data: userListResp } = await this.$http.get(this.serverAPI.USER_LIST_CRITERIA_URL)
      console.log('the user list page criteria response content: ', userListResp)

      this.projectCriteria = userListResp.data.projectCriteria
      this.userRoleCriteria = userListResp.data.userRoleCriteria
      this.userStatusCriteria = userListResp.data.statusCriteria

      const searchStr = JSON.stringify(this.searchForm)
      this.criteriaForm = JSON.parse(searchStr)

      this.criteriaDialogVisible = true
    },
    searchUserDialogClose () {
      this.criteriaDialogVisible = false
    },
    confirmSearchUserDialogClose (done) {
      this.$confirm('Close the dialog and reset search criteria?', {
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        closeOnClickModal: false,
        closeOnPressEscape: false
      }).then(_ => {
        done()
      }).catch(_ => {
        console.log('cancel close the user search dialog')
      })
    },
    indexUserRow (index) {
      const newIndex = (this.paginationInfo.currentPage - 1) * this.paginationInfo.pageSize + index + 1
      return newIndex
    }
  }
}
</script>
<style lang="less" scoped>

</style>
