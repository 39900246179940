<template>
  <el-container class="home-container">
    <el-header>
      <div>
        <img src="@/assets/sfb-logo.png">
        <span style="font-weight: bold; font-size: 32px;">SFB1372 Data Management System</span>
      </div>
    </el-header>
    <el-main>
      <div align="center" class="box">
        <div class="title">THIS WEBSITE IS UNDER CONSTRUCTION</div>
          <img src="@/assets/maintenance.png">
          <div class="text">
              We are currently updating the Data Management System (DMS), please check back in a few minutes. <br>
              In case the DMS is unavailable for an extended period of time please contact <a href="mailto:junxiao.qin@uol.de">junxiao.qin@uol.de</a>.
          </div>
      </div>
    </el-main>
  </el-container>
</template>
<script>
export default {
  data () {
    return {}
  }
}
</script>
<style scoped>
a:link,a:visited {
  color: #06c;
  text-decoration: none;
}

a:hover,a:active {
  text-decoration: underline;
}

body {
  background: #F9FAFD;
  color: black;
}

.box {
  /* width: 624px;
  height: 188px;
  padding: 99px 30px 0 184px;
  background: url(maintenance.png) no-repeat 50% 50%;
  position: absolute;
  margin: -144px 0 0 -419px; */
  margin-top: 80px;
  top: 50%;
  left: 50%;
  font-size: 14px;
  line-height: 24px;
}

.box .title {
  margin-bottom: 20px;
  font-size: 40px;
  font-weight: bold;
  line-height: 40px;
}

.box .text {
  padding-left: 29px;
  margin-top: 10px;
  font-size: 20px;
  font-weight: bold;
  padding-bottom: 50px;
}
</style>
<style lang="less" scoped>
.el-header {
  background-color: #373d41;
  display: flex;
  justify-content: space-between;
  padding-left: 0;
  align-items: center;
  color: #fff;
  font-size: 20px;
  > div {
    display: flex;
    align-items: center;
    span {
      margin-left: 15px;
      font-family: Arial, Helvetica, sans-serif;
    }
  }
}
</style>
