/**
 * this file is used to store the configuration of the Event Bus of the project
 */
import Vue from 'vue'

const EventBus = new Vue()
/**
 * 'FILE_UPLOAD_EVENT' is defined on the 'FileUploder.vue' component
 * It is used for invoking the upload button on the 'FileUploder.vue' component
*/
const FILE_UPLOAD_EVENT = 'invokeFileUpload'

/**
 * 'FILE_REMOVE_EVENT' is defined on the 'FileObjUploader.vue' component
 * It is used for invoking to delete the file from the file list of 'FileUploder.vue' component
 * when delete a selected file from a form field
 */
const FILE_REMOVE_EVENT = 'fileRemoveEvent'

/**
 * 'FILE_PROGRESS_UPDATE_EVENT' is defined on the 'FileFormField.vue' component
 * It is used for invoking to update the file uploading progress information from the 'FileObjUploader.vue' component
 * to the 'FileFormField.vue' component
 */
const FILE_PROGRESS_UPDATE_EVENT = 'fileProgressUpdateEvent'

/**
 * 'FILE_PROGRESS_REMOVE_EVENT' is defined on the 'FieldFormField.vue' component
 * It is used for 'invoking the remove the file uploading progress information from the 'FileObjUploader.vue' compoent
 */
const FILE_PROGRESS_REMOVE_EVENT = 'fileProgressRemoveEvent'

/**
 * 'FILE_COMPLETE_EVENT' is defined on the 'FileFormField.vue' component
 * It is used for invoking to notify the file uploading completation from the 'FileObjUploader.vue' component
 * to the 'FileFormField.vue' component
 */
const FILE_COMPLETE_EVENT = 'fileCompleteEvent'

/**
 * 'FILE_PAPER_LIST_EVENT' is defined on the PubFileTab.vue component
 * It is used for invoking to get the paper list to show the related files
 */
const FILE_PAPER_LIST_EVENT = 'filePaperListEvent'

/**
 * 'FILE_PROJECT_LIST_EVENT' is defined on the 'ProjectFileTable.vue' component
 * It is used for invoking to get the project data list to show the related files
 */
const FILE_PROJECT_LIST_EVENT = 'fileProjectListEvent'

/**
 * 'USER_DETAIL_EVENT' is defined on the 'UserList.vue' page
 * It is used for invoking the method of getting user detail information
 */
const USER_DETAIL_EVENT = 'loadUserDetailEvent'

/**
 * 'USERLIST_LOAD_EVENT' is defined on the 'UserTable.vue' page
 * It is used for invoking the method of getting user list
 * when the corresponding tab of the user list is actived on the 'UserList.vue' page
 */
const USERLIST_LOAD_EVENT = 'loadUserListEvent'

/**
 * 'USERLIST_CRITERIA_EVENT' is defined on the 'UserList.vue' page
 * It is used for invoking the method of update the user search criteria information,
 * 'userSearchCriteria' defined on the 'UserList.vue', which is used to show the search criteria information
 * under the crumb path on the top of the 'UserList.vue' page
 */
const USERLIST_CRITERIA_EVENT = 'updateUserCriteriaEvent'

/**
 * 'USERLIST_CLEAR_CRITERIA_EVENT' is defined on the 'UserTable.vue' page
 * It is used for invoking the method of clearing the 'searchForm' field and reload the user list
 */
const USERLIST_CLEAR_CRITERIA_EVENT = 'clearUserCriteriaEvent'

/**
 * 'USER_ADD_EVENT' is defined on 'UserList.vue' page
 * It is used for invoking the method of displaying the user add dialog on the 'UserList.vue' page
 * when the 'Add User' button on the 'UserTab.vue' is clcked
 */
const USER_ADD_EVENT = 'addUserEvent'

/**
 * 'USER_TAB_REDDOT_EVENT' is defined on 'UserList.vue' page
 * It is used for invoking the method of reloading the red dot information
 */
const USER_TAB_REDDOT_EVENT = 'userTabRedDotEvent'

/**
 * 'PAPER_TYPE_CHANGE_EVENT' is defined on the 'PaperAdd.vue' page
 * It is used for invoking the method of changing the differ paper type
 */
const PAPER_TYPE_CHANGE_EVENT = 'paperTypeChangeEvent'

/**
 * 'PAPER_FORM_CHANGE_EVENT' is defined on the 'PaperAdd.vue' and 'PaperUpdate.vue' page
 * It is used for invoking the method confirming the leaving the PaperAdd.vue or PaperUpdate.vue
 */
const PAPER_FORM_CHANGE_EVENT = 'paperFormChangeEvent'

/**
 * 'RELOAD_NAVI_MENU_EVENT' is defined on the 'Home.vue' page
 * It is used for invoking the method of reloading the navigation menus
 */
const RELOAD_NAVI_MENU_EVENT = 'reloadNaviMenuEvent'

/**
 * 'RELOAD_FILE_LIST_EVENT' is defined on the 'FileExplorer.vue' page
 * It is used for invoking the method of reloading the file list already uploaded into the system
 */
const RELOAD_FILE_LIST_EVENT = 'reloadFileListEvent'

/**
 * 'CHECK_FORM_FIELD_EVENT' is defined on the 'DataAdd.vue' and 'DataUpdate.vue' page
 * It is used for invoking the method of verifying the values of the specified field
 */
const CHECK_FORM_FIELD_EVENT = 'checkFormFieldEvent'

/**
 * 'CLEAR_DATAFORM_VALIDATE' is defined on the 'DataAdd.vue' page
 * It is used for invoking the method of clearing the validate information
 */
const CLEAR_DATAFORM_VALIDATE = 'clearDataFormValidate'

/**
 * 'DATA_PROJECT_CHANGE_EVENT' is defined on the 'CommonFields.vue' page
 * It is used for invoking the method of set the selected projectID to the FileFormField.vue
 */
const DATA_PROJECT_CHANGE_EVENT = 'dataProjectChangeEvent'

/**
 * 'HISTORY_DATA_FILE_HEIGHT_INIT_EVENT' is defined on the 'HistoryFileProperty.vue' page
 * It is used for invoking the method of synchronizing the largest height of the corresponding
 * file type field
 */
const HISTORY_DATA_FILE_HEIGHT_INIT_EVENT = 'historyDataFileHeightInitEvent'

/**
 * 'HISTORY_DATA_FILE_HEIGHT_UPDATE_EVENT' is defined on the 'HistoryFileProperty.vue' page
 * It is used for invoking the method of updating the largest height of the corresponding
 * file type field
 */
const HISTORY_DATA_FILE_HEIGHT_UPDATE_EVENT = 'historyDataFileHeightUpdateEvent'

/**
 * 'HISTORY_PAPER_FIELD_INIT_EVENT' is defined on the 'PaperPropertyCompare.vue' page
 * It is used for invoking the method of synchronizing the largest height of the corresponding
 * file type field
 */
const HISTORY_PAPER_FIELD_INIT_EVENT = 'historyPaperFieldInitEvent'

/**
 * 'HISTORY_PAPER_FIELD_UPDATE_EVENT' is defined on the 'PaperPropertyCompare.vue' page
 * It is used for invoking the method of updating the largest height of the corresponding
 * file type field
 */
const HISTORY_PAPER_FIELD_UPDATE_EVENT = 'historyPaperFieldUpdateEvent'

export const EVENT_CONFIG = {
  EventBus,
  FILE_UPLOAD_EVENT,
  FILE_REMOVE_EVENT,
  FILE_PROGRESS_UPDATE_EVENT,
  FILE_PROGRESS_REMOVE_EVENT,
  FILE_COMPLETE_EVENT,
  FILE_PAPER_LIST_EVENT,
  FILE_PROJECT_LIST_EVENT,
  USER_DETAIL_EVENT,
  USERLIST_LOAD_EVENT,
  USERLIST_CRITERIA_EVENT,
  USERLIST_CLEAR_CRITERIA_EVENT,
  USER_ADD_EVENT,
  USER_TAB_REDDOT_EVENT,
  PAPER_TYPE_CHANGE_EVENT,
  PAPER_FORM_CHANGE_EVENT,
  RELOAD_NAVI_MENU_EVENT,
  RELOAD_FILE_LIST_EVENT,
  CHECK_FORM_FIELD_EVENT,
  CLEAR_DATAFORM_VALIDATE,
  DATA_PROJECT_CHANGE_EVENT,
  HISTORY_DATA_FILE_HEIGHT_INIT_EVENT,
  HISTORY_DATA_FILE_HEIGHT_UPDATE_EVENT,
  HISTORY_PAPER_FIELD_INIT_EVENT,
  HISTORY_PAPER_FIELD_UPDATE_EVENT
}
