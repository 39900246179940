<template>
  <div>
    <el-row v-if="isRelated">
      <el-form-item label="Related Projects" class="data-form-label">
      </el-form-item>
    </el-row>
    <el-row v-if="isRelated" style="height: 80px;">
      <el-tag :type="compareProjectItem(relatedProject)" v-for="relatedProject in originOptionalData.relatedProject" :key="relatedProject" style="margin-right: 5px;">
        {{ projectList.find(project => project.id === relatedProject).projectName }}
      </el-tag>
    </el-row>
    <el-row v-if="isRelated? nonCommonFieldRelated['geneName']: !nonCommonFieldRelated['geneName']">
      <el-form-item label="Gene Name" class="data-form-label" prop="geneName">
        <el-input name="geneName" v-model="originOptionalData.geneName" readonly
          :class="originOptionalData.geneName !== compareOptionalData.geneName? 'compareHighlight': ''"></el-input>
      </el-form-item>
    </el-row>
    <el-row v-if="isRelated? nonCommonFieldRelated['proteinName']: !nonCommonFieldRelated['proteinName']">
      <el-form-item label="Protein Name" class="data-form-label" prop="proteinName">
        <el-input name="proteinName" v-model="originOptionalData.proteinName" clearable readonly
          :class="originOptionalData.proteinName !== compareOptionalData.proteinName? 'compareHighlight': ''"></el-input>
      </el-form-item>
    </el-row>
    <el-row v-if="isRelated? nonCommonFieldRelated['proteinStructure']: !nonCommonFieldRelated['proteinStructure']">
      <el-form-item label="Protein Structure" class="data-form-label" prop="proteinStructure">
        <el-input name="proteinStructure" v-model="originOptionalData.proteinStructure" clearable readonly
          :class="originOptionalData.proteinStructure !== compareOptionalData.proteinStructure? 'compareHighlight': ''"></el-input>
      </el-form-item>
    </el-row>
    <el-row v-if="isRelated? nonCommonFieldRelated['antibody']: !nonCommonFieldRelated['antibody']">
      <el-form-item label="Antibody" class="data-form-label" prop="antibody">
        <el-input name="antibody" v-model="originOptionalData.antibody" clearable readonly
          :class="originOptionalData.antibody !== compareOptionalData.antibody? 'compareHighlight': ''"></el-input>
      </el-form-item>
    </el-row>
    <el-row v-if="isRelated? nonCommonFieldRelated['cellType']: !nonCommonFieldRelated['cellType']">
      <el-form-item label="Cell Type" class="data-form-label" prop="cellType">
        <el-input name="cellType" v-model="originOptionalData.cellType" clearable readonly
          :class="originOptionalData.cellType !== compareOptionalData.cellType? 'compareHighlight': ''"></el-input>
      </el-form-item>
    </el-row>
    <el-row v-if="isRelated? nonCommonFieldRelated['nerveName']: !nonCommonFieldRelated['nerveName']">
      <el-form-item label="Nerve Name" class="data-form-label" prop="nerveName">
        <el-input name="nerveName" v-model="originOptionalData.nerveName" clearable readonly
          :class="originOptionalData.nerveName !== compareOptionalData.nerveName? 'compareHighlight': ''"></el-input>
      </el-form-item>
    </el-row>
    <el-row v-if="isRelated? nonCommonFieldRelated['brainRegion']: !nonCommonFieldRelated['brainRegion']">
      <el-form-item label="Brain Region" class="data-form-label" prop="brainRegion">
        <el-input name="brainRegion" v-model="originOptionalData.brainRegion" clearable readonly
          :class="originOptionalData.brainRegion !== compareOptionalData.brainRegion? 'compareHighlight': ''"></el-input>
      </el-form-item>
    </el-row>
    <el-row v-if="isRelated? nonCommonFieldRelated['sensorySystem']: !nonCommonFieldRelated['sensorySystem']">
      <el-form-item label="Sensory System" class="data-form-label" prop="sensorySystem">
        <el-input name="sensorySystem" v-model="originOptionalData.sensorySystem" clearable readonly
          :class="originOptionalData.sensorySystem !== compareOptionalData.sensorySystem? 'compareHighlight': ''"></el-input>
      </el-form-item>
    </el-row>
    <el-row v-if="isRelated? nonCommonFieldRelated['surgery']: !nonCommonFieldRelated['surgery']">
      <el-form-item label="Surgery" class="data-form-label" prop="surgery">
        <el-input name="surgery" v-model="originOptionalData.surgery" clearable readonly
          :class="originOptionalData.surgery !== compareOptionalData.surgery? 'compareHighlight': ''"></el-input>
      </el-form-item>
    </el-row>
    <el-row v-if="isRelated? nonCommonFieldRelated['experimentAnimalSpecies']: !nonCommonFieldRelated['experimentAnimalSpecies']">
      <el-form-item label="Experiment Animal Species" class="data-form-label" prop="experimentAnimalSpecies">
        <el-input name="experimentAnimalSpecies" v-model="originOptionalData.experimentAnimalSpecies" clearable readonly
          :class="originOptionalData.experimentAnimalSpecies !== compareOptionalData.experimentAnimalSpecies? 'compareHighlight': ''"></el-input>
      </el-form-item>
    </el-row>
    <el-row v-if="isRelated? nonCommonFieldRelated['experimentSample']: !nonCommonFieldRelated['experimentSample']">
      <el-form-item label="Experiment Sample" class="data-form-label" prop="experimentSample">
        <el-input name="experimentSample" v-model="originOptionalData.experimentSample" clearable readonly
          :class="originOptionalData.experimentSample !== compareOptionalData.experimentSample? 'compareHighlight': ''"></el-input>
      </el-form-item>
    </el-row>
    <el-row v-if="isRelated? nonCommonFieldRelated['sampleCarrier']: !nonCommonFieldRelated['sampleCarrier']">
      <el-form-item label="Sample Carrier" class="data-form-label" prop="sampleCarrier">
        <el-input name="sampleCarrier" v-model="originOptionalData.sampleCarrier" clearable readonly
          :class="originOptionalData.sampleCarrier !== compareOptionalData.sampleCarrier? 'compareHighlight': ''"></el-input>
      </el-form-item>
    </el-row>
    <el-row v-if="isRelated? nonCommonFieldRelated['rfpower']: !nonCommonFieldRelated['rfpower']">
      <el-form-item label="RF Power" class="data-form-label" prop="rfpower">
        <el-input name="rfpower" v-model="originOptionalData.rfpower" clearable readonly
          :class="originOptionalData.rfpower !== compareOptionalData.rfpower? 'compareHighlight': ''"></el-input>
      </el-form-item>
    </el-row>
    <el-row v-if="isRelated? nonCommonFieldRelated['rfprotocol']: !nonCommonFieldRelated['rfprotocol']">
      <el-form-item label="RF Protocol" class="data-form-label" prop="rfprotocol">
        <el-input name="rfprotocol" v-model="originOptionalData.rfprotocol" clearable readonly
          :class="originOptionalData.rfprotocol !== compareOptionalData.rfprotocol? 'compareHighlight': ''"></el-input>
      </el-form-item>
    </el-row>
    <el-row v-if="isRelated? nonCommonFieldRelated['diamond']: !nonCommonFieldRelated['diamond']">
      <el-form-item label="Diamond Plate" class="data-form-label" prop="diamond">
        <el-input name="diamond" v-model="originOptionalData.diamond" clearable readonly
          :class="originOptionalData.diamond !== compareOptionalData.diamond? 'compareHighlight': ''"></el-input>
      </el-form-item>
    </el-row>
    <el-row v-if="isRelated? nonCommonFieldRelated['opticalparam']: !nonCommonFieldRelated['opticalparam']">
      <el-form-item label="Optical Parameter" class="data-form-label" prop="opticalparam">
        <el-input name="opticalparam" v-model="originOptionalData.opticalparam" clearable readonly
          :class="originOptionalData.opticalparam !== compareOptionalData.opticalparam? 'compareHighlight': ''"></el-input>
      </el-form-item>
    </el-row>
    <el-row v-if="isRelated? nonCommonFieldRelated['laserpower']: !nonCommonFieldRelated['laserpower']">
      <el-form-item label="Laser Power" class="data-form-label" prop="laserpower">
        <el-input name="laserpower" v-model="originOptionalData.laserpower" clearable readonly
          :class="originOptionalData.laserpower !== compareOptionalData.laserpower? 'compareHighlight': ''"></el-input>
      </el-form-item>
    </el-row>
    <el-row v-if="isRelated? nonCommonFieldRelated['longitude']: !nonCommonFieldRelated['longitude']">
      <el-form-item label="Longitude" class="data-form-label" prop="longitude">
        <el-input name="longitude" v-model="originOptionalData.longitude" clearable readonly
          :class="originOptionalData.longitude !== compareOptionalData.longitude? 'compareHighlight': ''"></el-input>
      </el-form-item>
    </el-row>
    <el-row v-if="isRelated? nonCommonFieldRelated['latitude']: !nonCommonFieldRelated['latitude']">
      <el-form-item label="Latitude" class="data-form-label" prop="latitude">
        <el-input name="latitude" v-model="originOptionalData.latitude" clearable readonly
          :class="originOptionalData.latitude !== compareOptionalData.latitude? 'compareHighlight': ''"></el-input>
      </el-form-item>
    </el-row>
    <el-row v-if="isRelated? nonCommonFieldRelated['magneticDirection']: !nonCommonFieldRelated['magneticDirection']">
      <el-form-item label="Magnetic Direction" class="data-form-label" prop="magneticDirection">
        <el-input name="magneticDirection" v-model="originOptionalData.magneticDirection" clearable readonly
          :class="originOptionalData.magneticDirection !== compareOptionalData.magneticDirection? 'compareHighlight': ''"></el-input>
      </el-form-item>
    </el-row>
    <el-row v-if="isRelated? nonCommonFieldRelated['season']: !nonCommonFieldRelated['season']">
      <el-form-item label="Season" class="data-form-label" prop="season">
        <el-input name="season" v-model="originOptionalData.season" clearable readonly
          :class="originOptionalData.season !== compareOptionalData.season? 'compareHighlight': ''"></el-input>
      </el-form-item>
    </el-row>
    <!-- <el-row v-if="isRelated? nonCommonFieldRelated['radioEquipment']: !nonCommonFieldRelated['radioEquipment']">
      <el-form-item label="Radio Equipment" class="data-form-label" prop="radioEquipment">
        <el-input name="radioEquipment" v-model="originOptionalData.radioEquipment" clearable readonly
          :class="originOptionalData.radioEquipment !== compareOptionalData.radioEquipment? 'compareHighlight': ''"></el-input>
      </el-form-item>
    </el-row> -->

    <el-row v-if="isRelated? nonCommonFieldRelated['traceInjection']: !nonCommonFieldRelated['traceInjection']">
      <el-form-item label="Trace Injection" class="data-form-label" prop="traceInjection">
        <el-input name="traceInjection" v-model="originOptionalData.traceInjection" clearable readonly
          :class="originOptionalData.traceInjection !== compareOptionalData.traceInjection? 'compareHighlight': ''"></el-input>
      </el-form-item>
    </el-row>
    <el-row v-if="isRelated? nonCommonFieldRelated['behavioralCondition']: !nonCommonFieldRelated['behavioralCondition']">
      <el-form-item label="Behavioral Conditioning" class="data-form-label" prop="behavioralCondition">
        <el-input name="behavioralCondition" v-model="originOptionalData.behavioralCondition" clearable readonly
          :class="originOptionalData.behavioralCondition !== compareOptionalData.behavioralCondition? 'compareHighlight': ''"></el-input>
      </el-form-item>
    </el-row>

    <HistoryFilePropery v-if="isRelated? nonCommonFieldRelated['radioEquipment']: !nonCommonFieldRelated['radioEquipment']"
      formProperty="radioEquipment" formLabel="Radio Equipment"
      :fileList="originOptionalData['radioEquipment']"
      :compareFileList="compareOptionalData['radioEquipment']"></HistoryFilePropery>

    <HistoryFilePropery v-if="isRelated? nonCommonFieldRelated['geneSequenceFile']: !nonCommonFieldRelated['geneSequenceFile']"
      formProperty="geneSequenceFile" formLabel="Gene Sequence File"
      :fileList="originOptionalData['geneSequenceFile']"
      :compareFileList="compareOptionalData['geneSequenceFile']"></HistoryFilePropery>

    <HistoryFilePropery v-if="isRelated? nonCommonFieldRelated['aminoAcidSequenceFile']: !nonCommonFieldRelated['aminoAcidSequenceFile']"
      formProperty="aminoAcidSequenceFile" formLabel="Amino Acid Sequence File"
      :fileList="originOptionalData['aminoAcidSequenceFile']"
      :compareFileList="compareOptionalData['aminoAcidSequenceFile']"></HistoryFilePropery>

    <HistoryFilePropery v-if="isRelated? nonCommonFieldRelated['experimentAnimalTreatmentFile']: !nonCommonFieldRelated['experimentAnimalTreatmentFile']"
      formProperty="experimentAnimalTreatmentFile" formLabel="Experiment Animal Treatment File"
      :fileList="originOptionalData['experimentAnimalTreatmentFile']"
      :compareFileList="compareOptionalData['experimentAnimalTreatmentFile']"></HistoryFilePropery>

    <HistoryFilePropery v-if="isRelated? nonCommonFieldRelated['commentsFile']: !nonCommonFieldRelated['commentsFile']"
      formProperty="commentsFile" formLabel="Comments File"
      :fileList="originOptionalData['commentsFile']"
      :compareFileList="compareOptionalData['commentsFile']"></HistoryFilePropery>

    <HistoryFilePropery v-if="isRelated? nonCommonFieldRelated['lightStimulusFile']: !nonCommonFieldRelated['lightStimulusFile']"
      formProperty="lightStimulusFile" formLabel="Light Stimulus File"
      :fileList="originOptionalData['lightStimulusFile']"
      :compareFileList="compareOptionalData['lightStimulusFile']"></HistoryFilePropery>

    <HistoryFilePropery v-if="isRelated? nonCommonFieldRelated['functionsFile']: !nonCommonFieldRelated['functionsFile']"
      formProperty="functionsFile" formLabel="Functions File"
      :fileList="originOptionalData['functionsFile']"
      :compareFileList="compareOptionalData['functionsFile']"></HistoryFilePropery>

    <HistoryFilePropery v-if="isRelated? nonCommonFieldRelated['magneticInfoFile']: !nonCommonFieldRelated['magneticInfoFile']"
      formProperty="magneticInfoFile" formLabel="Magnetic Stimulus File"
      :fileList="originOptionalData['magneticInfoFile']"
      :compareFileList="compareOptionalData['magneticInfoFile']"></HistoryFilePropery>

    <HistoryFilePropery v-if="isRelated? nonCommonFieldRelated['weatherInfoFile']: !nonCommonFieldRelated['weatherInfoFile']"
      formProperty="weatherInfoFile" formLabel="Weather Information File"
      :fileList="originOptionalData['weatherInfoFile']"
      :compareFileList="compareOptionalData['weatherInfoFile']"></HistoryFilePropery>

    <HistoryFilePropery v-if="isRelated? nonCommonFieldRelated['departureFile']: !nonCommonFieldRelated['departureFile']"
      formProperty="departureFile" formLabel="Departure Behavior File"
      :fileList="originOptionalData['departureFile']"
      :compareFileList="compareOptionalData['departureFile']"></HistoryFilePropery>

    <HistoryFilePropery v-if="isRelated? nonCommonFieldRelated['radioFile']: !nonCommonFieldRelated['radioFile']"
      formProperty="radioFile" formLabel="Radio Frequency Information File"
      :fileList="originOptionalData['radioFile']"
      :compareFileList="compareOptionalData['radioFile']"></HistoryFilePropery>

    <HistoryFilePropery v-if="isRelated? nonCommonFieldRelated['analysisFile']: !nonCommonFieldRelated['analysisFile']"
      formProperty="analysisFile" formLabel="Data Analysis Protocol File"
      :fileList="originOptionalData['analysisFile']"
      :compareFileList="compareOptionalData['analysisFile']"></HistoryFilePropery>

    <HistoryFilePropery v-if="isRelated? nonCommonFieldRelated['relatedDataFile']: !nonCommonFieldRelated['relatedDataFile']"
      formProperty="relatedDataFile" formLabel="Experimental Result Data File"
      :fileList="originOptionalData['relatedDataFile']"
      :compareFileList="compareOptionalData['relatedDataFile']"></HistoryFilePropery>

    <HistoryFilePropery v-if="isRelated? nonCommonFieldRelated['labProtocolFile']: !nonCommonFieldRelated['labProtocolFile']"
      formProperty="labProtocolFile" formLabel="Lab Protocol File"
      :fileList="originOptionalData['labProtocolFile']"
      :compareFileList="compareOptionalData['labProtocolFile']"></HistoryFilePropery>
  </div>

</template>
<script>
import { mapState, mapMutations } from 'vuex'
import HistoryFilePropery from '@/components/history_component/HistoryFilePropery.vue'

export default {
  props: ['optionalDataDetail', 'compareDataDetail', 'projectList', 'isRelated'],
  components: {
    HistoryFilePropery
  },
  watch: {
    optionalDataDetail (newValue) {
      this.originOptionalData = newValue
    },
    compareDataDetail (newValue) {
      this.compareOptionalData = newValue
    }
  },
  computed: {
    ...mapState({
      nonCommonFieldRelated: (state) => state.dataModule.nonCommonFieldRelated
    }),
    compareProjectItem () {
      return function (projectID) {
        if (!this.compareDataDetail.relatedProject) {
          return 'warning'
        }

        const projectIndex = this.compareDataDetail.relatedProject.findIndex(compareProject => {
          return projectID === compareProject
        })

        if (projectIndex === -1) {
          return 'warning'
        }
      }
    }
  },
  data () {
    return {
      originOptionalData: {
        relatedProject: []
      },
      compareOptionalData: {
        relatedProject: []
      }
    }
  },
  methods: {
    ...mapMutations({
      changeNonCommonFieldRelated: 'dataModule/changeNonCommonFieldRelated'
    })
  }
}
</script>
<style scoped>
  div /deep/ .compareHighlight .el-input__inner {
    background-color:yellow;
  }
</style>
