<template>
  <div>
    <el-col v-if="fieldInfo.fieldType=='textarea'" :lg="24">
      <el-form-item :label="fieldInfo.fieldLabel" class="data-form-label" :prop="fieldInfo.fieldName">
        <el-input type="textarea" class="form-field-textarea" :rows="5" :placeholder="fieldInfo.placeholder"></el-input>
      </el-form-item>
    </el-col>
    <el-col v-else :lg="12" :md="24">
      <el-form-item :label="fieldInfo.fieldLabel" class="data-form-label" :prop="fieldInfo.fieldName">
        <el-select v-model="formData.fieldName" :name="formData.fieldName" v-if="fieldInfo.fieldType=='select'" style="width: 100%;" :placeholder="fieldInfo.placeholder">
          <template v-if="fieldInfo.fieldName == 'project'">
            <el-option v-for="project in commonOptionInfos.projectDTOList" :key="project.id" :label="project.projectName" :value="project.id"></el-option>
          </template>
          <template v-if="fieldInfo.fieldName == 'licencesType'">
            <el-option v-for="licencesType in commonOptionInfos.shareLicenseOptions" :key="licencesType.desc" :label="licencesType.desc" :value="licencesType.desc"></el-option>
          </template>
        </el-select>
        <div v-else-if="fieldInfo.fieldType=='radio'">
          <template v-if="fieldInfo.fieldName == 'shareStatus'">
            <el-radio @change="enableLicencesType" v-model="formData[fieldInfo.fieldName]" v-for="shareMode in commonOptionInfos.shareModeOptions" :key="shareMode.shareCode" :label="shareMode.shareCode + ''"> {{shareMode.shareDesc}} </el-radio>
          </template>
          <template v-if="fieldInfo.fieldName == 'animalPermitType'">
            <el-radio v-model="formData.fieldName" v-for="permitType in commonOptionInfos.permitTypeOptions" :key="permitType.permitCode" :label="permitType.permitDesc"> {{permitType.permitDesc}} </el-radio>
          </template>
        </div>
        <el-date-picker v-else-if="fieldInfo.fieldType=='date'" :type="fieldInfo.fieldType" style="width: 100%;" :placeholder="fieldInfo.placeholder"></el-date-picker>
        <el-upload v-else-if="fieldInfo.fieldType=='file'" action="https://jsonplaceholder.typicode.com/posts/">
          <el-button size="small" type="primary">Upload File</el-button>
        </el-upload>
        <el-input v-else :placeholder="fieldInfo.placeholder"></el-input>
      </el-form-item>
    </el-col>
  </div>
</template>
<script>
export default {
  props: ['fieldInfo', 'formData', 'commonOptionInfos'],
  methods: {
    enableLicencesType (event) {
      console.log(event)
    }
  }
}
</script>
<style scoped>
div >>> div.el-form-item {
  margin-bottom: 0px !important;
}
div >>> label.el-form-item__label {
  padding-bottom: 0 !important;
}
</style>
