<template>
  <section>
    <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/home' }">Home</el-breadcrumb-item>
        <el-breadcrumb-item>Publication Module</el-breadcrumb-item>
        <el-breadcrumb-item>Publication History</el-breadcrumb-item>
    </el-breadcrumb>

    <div style="margin-top: 15px; margin-bottom: 10px;">
      <el-button type="primary" size="mini" plain style="font-size: 16px;"  @click="backToPaperDetail">Publication Deatil</el-button>
    </div>

    <el-row type="flex">
      <el-col style="border-right: solid 1px #d7dae2;">
        <PaperPropertyCompare :paperHistoryDetail="originPaper" :comparePaperDetail="comparePaper"></PaperPropertyCompare>
      </el-col>

      <el-col style="border-left: solid 1px #d7dae2;">
        <PaperPropertyCompare :paperHistoryDetail="comparePaper" :comparePaperDetail="originPaper"></PaperPropertyCompare>
      </el-col>
    </el-row>
  </section>
</template>
<script>
import { mapMutations } from 'vuex'
import PaperPropertyCompare from '@/components/history_component/PaperPropertyCompare.vue'

export default {
  props: ['paperID', 'historyID'],
  beforeDestroy () {
    window.onunload = null
  },
  components: {
    PaperPropertyCompare
  },
  async mounted () {
    console.log('dataID=%s historyID=%s', this.paperID, this.historyID)

    window.onunload = () => {
      this.setRouterActivePath(`/paper/history/${this.paperID}/${this.historyID}`)
    }

    await this.loadPaperDetail()

    this.setRouterActivePath('/paper/list')
  },
  data () {
    return {
      originPaper: {},
      comparePaper: {},
      currentVersion: 0,
      paperHistories: []
    }
  },
  methods: {
    ...mapMutations({
      setRouterActivePath: 'setRouterActivePath'
    }),
    async loadPaperDetail () {
      const { data: paperHistoryResp } = await this.$http.get(`${this.serverAPI.PAPER_HISTORY_URL}${this.paperID}/${this.historyID}`)
      console.log('paper history response: ', paperHistoryResp)

      this.parsePaperHistoryDetail(paperHistoryResp)
    },
    parsePaperHistoryDetail (paperHistoryResp) {
      this.originPaper = paperHistoryResp.data.originPaper
      this.comparePaper = paperHistoryResp.data.comparePaper
      this.currentVersion = paperHistoryResp.data.currentVersion
      this.paperHistories = paperHistoryResp.data.paperHistoryList
    },
    backToPaperDetail () {
      this.$router.push({
        path: `/paper/detail/${this.paperID}/${this.originPaper.paperType.typeCode}`
      })
    }
  }
}
</script>
<style scoped>

</style>
