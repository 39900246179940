<template>
  <section>
    <el-card>
      <div slot="header" class="clearfix">
        <span>Manuscript Information</span>
      </div>
      <el-form id="unpublishedForm" ref="unpublishedFormRef" :model="paperDataForm" :rules="unpublishedFormDataRules" @validate="afterMenuscriptFormValidate">
        <el-row :gutter="20" style="margin-bottom: 5px;">
          <el-col :lg="12" :md="24">
            <el-form-item label="Publication Type" class="data-form-label" prop="paperType.typeCode">
              <el-select :disabled="!writeSwitch" v-if="isAdd" v-model="paperDataForm.paperType.typeCode" @change="paperTypeChangeHandler" style="width: 100%;" name="paperType" clearable>
                <el-option v-for="(paperType, index) in paperTypeOptions" :key="index" :value="paperType.value" :label="paperType.label"></el-option>
              </el-select>
              <el-input v-else :value="paperDataForm.paperType.typeName" readonly></el-input>
            </el-form-item>
          </el-col>
          <!-- <el-col :lg="12" :md="24">
            <el-form-item label="Publication Status" class="data-form-label" prop="paperStatus.statusCode">
              <el-select v-model="paperDataForm.paperStatus.statusCode" style="width: 100%;" name="paperStatus" clearable>
                <el-option v-for="(paperStatus, index) in paperStatuses" :key="index" :disabled="paperStatus.value !== 1" :value="paperStatus.value" :label="paperStatus.label"></el-option>
              </el-select>
            </el-form-item>
          </el-col> -->
          <el-col :lg="12" :md="24">
            <el-form-item label="Publication Title" class="data-form-label" prop="paperTitle">
              <el-input :disabled="!writeSwitch" v-model="paperDataForm.paperTitle" style="width: 100%;" name="paperTitle" clearable :readonly="!(paperDataForm.uploader.id === currentUser.id)"></el-input>
            </el-form-item>
          </el-col>
          <!-- <el-col :lg="12" :md="24">
            <el-form-item label="Publication DOI" class="data-form-label" prop="paperDOI">
              <el-input v-model="paperDataForm.paperDOI" style="width: 100%;" name="paperDOI" clearable></el-input>
            </el-form-item>
          </el-col> -->
          <!-- <el-col :lg="12" :md="24">
            <el-form-item label="Publication URL" class="data-form-label" prop="paperURL">
              <el-input v-model="paperDataForm.paperURL" style="width: 100%;" name="paperURL" clearable></el-input>
            </el-form-item>
          </el-col> -->
          <!-- <el-col :lg="12" :md="24">
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="Year" class="data-form-label" prop="paperYear">
                  <el-select v-model="paperDataForm.paperYear" style="width: 100%;" name="paperYear" clearable>
                    <el-option v-for="(i, index) in (years.endYear - years.startYear + 1)" :key="index" :value="years.endYear - index" :label="years.endYear - index"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Month" class="data-form-label" prop="paperMonth">
                  <el-select v-model="paperDataForm.paperMonth" style="width: 100%;" name="paperMonth" clearable>
                    <el-option v-for="(month, index) in months" :key="index" :value="month.value" :label="month.label"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </el-col> -->
          <el-col :lg="12" :md="24" v-if="(paperDataForm.paperRelatedProject!==null&&paperDataForm.paperRelatedProject.length>0) || (paperDataForm.uploader.id === currentUser.id)">
            <el-form-item label="Related Projects" class="data-form-label" prop="paperRelatedProject">
              <PaperRelatedProject :paperForm="paperDataForm" :btnsVisible="paperDataForm.uploader.id === currentUser.id"></PaperRelatedProject>
            </el-form-item>
          </el-col>
          <el-col :lg="12" :md="24" v-if="(paperDataForm.paperRelatedData!==null&&paperDataForm.paperRelatedData.length>0) || (paperDataForm.uploader.id === currentUser.id)">
            <el-form-item label="Related Data" class="data-form-label" prop="paperRelatedData">
              <PaperRelatedData :paperForm="paperDataForm" :btnsVisible="paperDataForm.uploader.id === currentUser.id"></PaperRelatedData>
            </el-form-item>
          </el-col>
          <el-col :lg="12" :md="24">
            <el-form-item label="Publication File" class="data-form-label" prop="paperFile">
              <el-row></el-row>
              <FileFormField fieldName="paperFile" :formData="paperDataForm" :creator="paperDataForm.uploader" :targetValue="'0'"></FileFormField>
            </el-form-item>
          </el-col>
          <el-col :lg="12" :md="24">
            <el-form-item label="Related Authors" class="data-form-label" prop="paperRelatedProjectAuthors">
              <el-row>
                <el-col :span="24">
                  <el-input :disabled="!writeSwitch" type="textarea" :rows="6" resize="none" v-model="paperDataForm.paperRelatedProjectAuthors" name="paperRelatedProjectAuthors" clearable :readonly="!(paperDataForm.uploader.id === currentUser.id)"></el-input>
                  <div class="field-prompt">Please use commas(",") to separate different names e.g: name1,name2</div>
                </el-col>
              </el-row>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="paperDataForm.uploader.id === currentUser.id && writeSwitch" style="margin-top: 15px;" type="flex" justify="end">
          <el-col :span="4" :offset="22" style="padding-right: 15px">
            <el-button style="float: right;" @click="resetPaperFormData" :disabled="!manuscriptFormChange"> Reset </el-button>
          </el-col>
          <el-col :span="2">
            <el-button v-if="paperID!==null && paperID!==undefined" type="primary" @click="updateUnpublishedFormData" :disabled="!manuscriptFormChange">Update</el-button>
            <el-button v-else type="primary" @click="submitUnpublishedFormData" :disabled="!manuscriptFormChange">Submit</el-button>
          </el-col>
        </el-row>
      </el-form>
    </el-card>

    <PaperDetailHistoryList v-if="paperID !== null && paperID !== undefined" :relatedHistory="paperRelatedHistory"></PaperDetailHistoryList>
  </section>
</template>
<script>
import { mapState } from 'vuex'
import { PAPER_FUNCS } from './paper_res/PaperFormFunctions'
import { EVENT_CONFIG } from '@/event_bus'
import PaperRelatedProject from '@/components/paper_component/paper_fields/PaperRelatedProject.vue'
import PaperRelatedData from '@/components/paper_component/paper_fields/PaperRelatedData.vue'
import JsSHA from 'jssha'
import { formValidator } from '@/plugins/formValidator'
import alertCountdown from '@/plugins/alertCountdown.js'
import FileFormField from '@/components/data_component/FileFormField.vue'
import { DRAFT_GENERATOR } from '@/local_storage/DraftGenerator'
import PaperDetailHistoryList from '@/components/paper_component/PaperDetailHistoryList.vue'

export default {
  props: ['isAdd', 'paperID', 'monthOptions', 'yearOptions', 'paperTypeOptions', 'paperStatusOptions'],
  components: {
    PaperRelatedProject,
    PaperRelatedData,
    FileFormField,
    PaperDetailHistoryList
  },
  async mounted () {
    console.log('init manuscript type paper detail page')
    const loadingInstance = this.$loading.service({
      lock: true,
      text: 'Paper information is loading...'
    })

    window.onbeforeunload = (event) => {
      this.manuscriptFormChange = formValidator.formDataChangeValidate(this.paperDataForm, this.manuscriptFormDigest)
      if (this.manuscriptFormChange) {
        DRAFT_GENERATOR.storePublicationDraft(this.paperDataForm)
        return 'confirm to refresh?'
      }
    }

    this.currentUser = await this.$userStorage.getCurrentUser()
    if (this.isAdd) {
      this.paperDataForm.uploader = this.currentUser
    }
    await this.getManuscriptPaperDetail()

    const manuscriptPaperDraft = await DRAFT_GENERATOR.getPublicationDraft(this.paperDataForm.id)
    if (manuscriptPaperDraft && manuscriptPaperDraft.paperType.typeCode === 5) {
      const toValidateForm = await this.$confirm('Load the publication draft?', {
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        closeOnClickModal: false,
        closeOnPressEscape: false
      }).then(_ => {
        console.log('to load manuscript type paper draft')
        this.paperDataForm = manuscriptPaperDraft
        return true
      }).catch(_ => {
        console.log('not to load manuscript type paper draft')
      }).finally(_ => {
        DRAFT_GENERATOR.removePublicationDraft(this.paperDataForm.id)
      })

      if (toValidateForm) {
        const unpublishedFormRef = this.$refs.unpublishedFormRef
        const validateManuscriptFormResult = await formValidator.formValidate(unpublishedFormRef)
        console.log('after reload from draft validate result: ', validateManuscriptFormResult)
      }
    }

    EVENT_CONFIG.EventBus.$on(EVENT_CONFIG.CHECK_FORM_FIELD_EVENT, this.checkFormDataField)
    loadingInstance.close()
  },
  computed: {
    ...mapState({
      writeSwitch: (state) => state.writableStatus
    })
  },
  watch: {
    manuscriptFormChange (newValue) {
      console.log('newvalue of the manuscriptFormChange:', newValue)
      EVENT_CONFIG.EventBus.$emit(EVENT_CONFIG.PAPER_FORM_CHANGE_EVENT, newValue)
    }
  },
  beforeDestroy () {
    console.log('PaperManuscript.vue beforeDestroy method executes...')
    window.onbeforeunload = null
    EVENT_CONFIG.EventBus.$off(EVENT_CONFIG.CHECK_FORM_FIELD_EVENT)
  },
  data () {
    const checkPaperTitle = async (rule, value, callback) => {
      console.log('check manuscript paper title')
      if (!value) {
        callback(new Error('paper title is required'))
        return
      }
      const checkPaperTitleResp = await PAPER_FUNCS.checkPaperTitle(value, this.paperID)
      console.log('check paper title response content: ', checkPaperTitleResp)
      if (checkPaperTitleResp.code === 1) {
        if (!checkPaperTitleResp.data) {
          return callback()
        } else {
          callback(new Error('Paper title exist, please try another one'))
        }
      } else {
        callback(new Error('Checking paper title existing error, please try later'))
      }
    }

    return {
      formToken: '', // formToken to prevent the repeated submission
      manuscriptFormChange: false, // the flag of the data form change
      manuscriptFormDigest: '',
      currentUser: { id: '' },
      paperRelatedHistory: [],
      paperDataForm: {
        paperType: {
          typeCode: 5
        },
        paperStatus: {
          statusCode: ''
        },
        paperTitle: '',
        paperDOI: '',
        paperURL: '',
        paperYear: '',
        paperMonth: '',
        paperFile: [],
        paperRelatedProjectAuthors: '',
        paperRelatedProject: [],
        paperRelatedData: [],
        uploader: { id: '' }
      },
      unpublishedFormDataRules: {
        'paperType.typeCode': [
          { required: true, message: 'paper type is required', trigger: 'change' }
        ],
        'paperStatus.statusCode': [
          { required: true, message: 'paper status is required', trigger: 'change' }
        ],
        paperTitle: [
          { required: true, message: 'paper title is required', trigger: 'change' },
          { validator: checkPaperTitle, trigger: 'blur' }
        ],
        paperDOI: [
          { required: false, message: 'paper doi', trigger: 'change' },
          { validator: PAPER_FUNCS.checkPaperDOI, trigger: 'blur' }
        ],
        paperURL: [
          { required: false, message: 'paper url', trigger: 'change' },
          { validator: PAPER_FUNCS.checkPaperURL, trigger: 'blur' }
        ],
        paperYear: [
          { required: true, message: 'paper published year is required', trigger: 'change' }
        ],
        paperMonth: [
          { required: false, message: 'paper month', trigger: 'change' }
        ],
        paperRelatedProject: [
          { required: false, message: 'publication related projects is null', trigger: 'change' }
        ],
        paperRelatedData: [
          { required: false, message: 'publication related data is null', trigger: 'change' }
        ],
        paperRelatedProjectAuthors: [
          { required: true, message: 'paper related author is required', trigger: 'change' }/* ,
          { validator: PAPER_FUNCS.changePaperAuthor, trigger: 'blur' } */
        ],
        paperFile: [
          { required: false, message: 'publication related files is null', trigger: 'change' }
        ]
      }
    }
  },
  methods: {
    async getManuscriptPaperDetail () {
      if (this.paperID !== null && this.paperID !== undefined) {
        const paperDetailResp = await PAPER_FUNCS.getPaperDetail(this.paperID)
        console.log('paper detail response content: ', paperDetailResp)
        this.formToken = paperDetailResp.headers['form-token']
        const paperDetail = paperDetailResp.data
        if (paperDetail.code === 1) {
          this.paperDataForm = paperDetail.data
          this.paperRelatedHistory = paperDetail.data.relatedHistories

          if (this.paperDataForm.paperDOI === null) {
            this.paperDataForm.paperDOI = ''
          }

          if (this.paperDataForm.paperURL === null) {
            this.paperDataForm.paperURL = ''
          }

          if (this.paperDataForm.paperMonth === null) {
            this.paperDataForm.paperMonth = ''
          }

          if (this.paperDataForm.paperVolume === null) {
            this.paperDataForm.paperVolume = ''
          }

          if (this.paperDataForm.paperPageRange === null) {
            this.paperDataForm.paperPageRange = ''
          }
        } else {
          this.$alert(paperDetailResp.msg, 'inbook paper detail page error', {
            confirmButtonText: 'OK',
            center: true
          })
        }
      } else {
        const paperTokenResp = await this.$http.get(this.serverAPI.PAPER_SUBMITTOKEN_URL)
        console.log('paper article add token response hrader: ', paperTokenResp)
        this.formToken = paperTokenResp.headers['form-token']
      }

      const startManuscriptDigest = new Date().getTime()
      const jsSHAManuscript = new JsSHA('SHA-256', 'TEXT')
      const manuscriptFormDataStr = JSON.stringify(this.paperDataForm)
      console.debug('manuscript form string: ', manuscriptFormDataStr)
      jsSHAManuscript.update(manuscriptFormDataStr)
      this.manuscriptFormDigest = jsSHAManuscript.getHash('HEX')
      console.log('publication manuscript form digest time cost: ', new Date().getTime() - startManuscriptDigest)
      this.manuscriptFormChange = false
    },
    async paperTypeChangeHandler (selectedType) {
      console.log('mauscript paper selected paper type:', selectedType)

      if (this.manuscriptFormChange) {
        const confirmChangePaperTypeResult = await PAPER_FUNCS.confirmChangePaperType(this)
        if (!confirmChangePaperTypeResult) {
          this.paperDataForm.paperType.typeCode = 5
          return
        }
      }

      this.resetPaperFormData()
      EVENT_CONFIG.EventBus.$emit(EVENT_CONFIG.PAPER_FORM_CHANGE_EVENT, false)
      EVENT_CONFIG.EventBus.$emit(EVENT_CONFIG.PAPER_TYPE_CHANGE_EVENT, selectedType)
    },
    async submitUnpublishedFormData () {
      const unpublishedFormRef = this.$refs.unpublishedFormRef
      const validateManuscriptFormResult = await formValidator.formValidate(unpublishedFormRef)
      console.log('validate manuscript type publication result: ', validateManuscriptFormResult)

      if (validateManuscriptFormResult !== true) {
        let msgContent = ''
        const validateValues = Object.values(validateManuscriptFormResult)
        validateValues.forEach(validateVal => {
          msgContent += validateVal[0].message + '<br>'
        })

        this.$alert('<span style="font-size: 15px; font-weight: bold;"> Exceptions in the publication form data: </span><br> <span style="color: red;">' + msgContent + '</span>', 'Notification of Publication Data', {
          dangerouslyUseHTMLString: true
        })

        return
      }

      this.manuscriptFormChange = false
      if (validateManuscriptFormResult === true) {
        console.log('submit the manuscript publication:', this.manuscriptFormChange)

        try {
          // const { data: basePaperResponse } = await this.$http.post('/paper/newBasePaper', JSON.stringify(this.paperDataForm), { headers: { 'content-type': 'application/json' } })
          const { data: basePaperResponse } = await this.$http.post(this.serverAPI.PAPER_NEWBASEPAPER_URL, JSON.stringify(this.paperDataForm), { headers: { 'content-type': 'application/json', 'form-token': this.formToken } })
          console.log('save manuscript type paper response', basePaperResponse)

          if (basePaperResponse.code === 1) {
            this.$message({
              showClose: true,
              message: 'Add publication successfully!',
              center: true,
              type: 'success'
            })
            // to the data list page automatically with time countdown
            this.$router.push({ path: `/paper/detail/${basePaperResponse.data}/5` })
            alertCountdown(this, 'To Publication Detail', this.backToPublicationList, 5)
          } else {
            this.$alert('<font color="red">' + basePaperResponse.msg + '</font>', 'Pulication Add Error', {
              dangerouslyUseHTMLString: true
            })

            this.manuscriptFormChange = true
          }
        } catch (error) {
          console.log('save manuscript type paper response error: ', error)
          this.$alert(error, 'save manuscript paper error', {
            confirmButtonText: 'OK',
            center: true
          })
          this.manuscriptFormChange = true
        }

        return
      }

      this.manuscriptFormChange = true
    },
    async updateUnpublishedFormData () {
      const unpublishedFormRef = this.$refs.unpublishedFormRef
      const validateManuscriptFormResult = await formValidator.formValidate(unpublishedFormRef)
      console.log('update manuscript publication page vlidate result: ', validateManuscriptFormResult)

      if (validateManuscriptFormResult !== true) {
        let msgContent = ''
        const validateValues = Object.values(validateManuscriptFormResult)
        validateValues.forEach(validateVal => {
          msgContent += validateVal[0].message + '<br>'
        })

        this.$alert('<span style="font-size: 15px; font-weight: bold;"> Exceptions in the publication form data: </span><br> <span style="color: red;">' + msgContent + '</span>', 'Notification of Publication Data', {
          dangerouslyUseHTMLString: true
        })

        return
      }

      this.manuscriptFormChange = false
      if (validateManuscriptFormResult === true) {
        console.log('update the manuscript publication: ', this.manuscriptFormChange)

        try {
          // const { data: updateUnpublishedResp } = await this.$http.post('/paper/updateUnpublished', JSON.stringify(this.unpublishedUpdateDataForm), { headers: { 'content-type': 'application/json' } })
          const { data: updateUnpublishedResp } = await this.$http.post(this.serverAPI.PAPER_UPDATEBASEPAPER_URL, JSON.stringify(this.paperDataForm), { headers: { 'content-type': 'application/json', 'form-token': this.formToken } })
          console.log('update unpublished type paper response', updateUnpublishedResp)

          if (updateUnpublishedResp.code === 1) {
            this.$message({
              showClose: true,
              message: 'Update publication successfully!',
              center: true,
              type: 'success'
            })
            // to the data list page automatically with time countdown
            alertCountdown(this, 'To Publication List', this.backToPublicationList, 5)
            await this.getManuscriptPaperDetail()
          } else {
            this.$alert('<font color="red">' + updateUnpublishedResp.msg + '</font>', 'Publication Update Error', {
              dangerouslyUseHTMLString: true
            })

            this.manuscriptFormChange = true
          }
        } catch (error) {
          console.log('update manuscript type paper response error: ', error)
          this.$alert(error, 'update manuscript paper error', {
            confirmButtonText: 'OK',
            center: true
          })
        }

        return
      }
      this.manuscriptFormChange = true
    },
    async resetPaperFormData () {
      const paperFormData = this.$refs.unpublishedFormRef
      if (this.paperID === null || this.paperID === undefined) {
        console.log('reset the manuscript type publication add page')
        paperFormData.resetFields()

        this.manuscriptFormChange = formValidator.formDataChangeValidate(this.paperDataForm, this.manuscriptFormDigest)
        return
      }

      console.log('reset the manuscript type publication update page')
      await this.getManuscriptPaperDetail()
      paperFormData.clearValidate()
    },
    backToPublicationList () {
      this.$router.push({
        path: '/paper/list'
      })
    },
    afterMenuscriptFormValidate (props, callback) {
      console.log('validate manuscript form field:', props)
      console.log('manuscript form data digest ', this.thesisFormDigest)
      this.manuscriptFormChange = formValidator.formDataChangeValidate(this.paperDataForm, this.manuscriptFormDigest)
      console.log('manuscript publication change: ', this.manuscriptFormChange)
    },
    checkFormDataField (isComon, fieldName) {
      console.log('check the manuscript form data specified field: ', fieldName)
      const unpublishedFormRef = this.$refs.unpublishedFormRef
      formValidator.formFieldValidate(unpublishedFormRef, fieldName)
    }
  }
}
</script>
<style scoped>
form /deep/ label {
  line-height: 30px !important;
  padding: 0 !important;
}
</style>
<style scoped>
@import './paper_res/PaperStyle.css';
</style>
