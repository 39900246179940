const fileUploadModule = {
  namespaced: true,
  state: {
    /**
     * 1. this is a list used to store the relationship of the dataForm fields and the uploaded files,
     *    and the related information, for example: uploading progress
     *
     * 2. user the map[array] data structure to store the relation information of the fields on the data add page and the files
     *    because the Vuex storage instance is public for all instances of the 'FileFormFile' component.
     *    use the fieldName as the key of the map, then the relation list is the corresponding value
    */
    fieldFileMap: new Map()
  },
  mutations: {
    /**
     * @param {*} state
     * @param {*} fieldFormRelation has:
     *                              'fieldName' is String type
     *                              'fileUniqueID' is String type
     *                              'fileName' is String type
     *                              'fileProgress' is Numberic type
     *                              'isFolder' is Boolean type
     *                              'fileProgressFlag' is Boolean type
     */
    addFieldFileRelation (state, fieldFormRelation) {
      // console.log('added file name: ', fieldFormRelation.fileObj.name)
      const mapKey = fieldFormRelation.fieldName
      let relationArray = state.fieldFileMap.get(mapKey)
      if (relationArray) {
        const duplicatedRelationIndex = relationArray.findIndex(relation => {
          if ((relation.fieldName === fieldFormRelation.fieldName) &&
              (relation.fileUniqueID === fieldFormRelation.fileUniqueID)) {
            return true
          }
        })
        if (duplicatedRelationIndex === -1) { // this value means current relation does not exist in the list
          relationArray.push(fieldFormRelation)
          return
        }
        console.log('fieldFileRelation exist in the Vuex storage skip adding', fieldFormRelation)
        return
      }

      relationArray = []
      relationArray.push(fieldFormRelation)
      state.fieldFileMap.set(mapKey, relationArray)
    },
    /**
     *
     * @param {*} state
     * @param {*} fieldFormRelation has:
     *                              'fieldName' is String type
     *                              'fileUniqueID' is String type
     *                              'fileName' is String type
     *                              'fileProgress' is Numberic type
     *                              'isFolder' is Boolean type
     *                              'fileProgressFlag' is Boolean type
     */
    removeFieldFileRelation (state, fieldFormRelation) {
      const mapKey = fieldFormRelation.fieldName
      const relationArray = state.fieldFileMap.get(mapKey)
      if (relationArray) {
        const relationIndex = relationArray.findIndex(relation => {
          return relation.fileUniqueID === fieldFormRelation.fileUniqueID && relation.fieldName === fieldFormRelation.fieldName
        })
        if (relationIndex >= 0) {
          relationArray.splice(relationIndex, 1)
        }
      }
      console.log('the %s field corresponding relationList is null', mapKey)
    },
    /**
     * empty file type file reation
     * @param {*} state
     */
    emptyFieldFileRelation (state, fieldName) {
      state.fieldFileMap.delete(fieldName)
    }
  }
}

export default fileUploadModule
